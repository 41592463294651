/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable arrow-body-style */
import React, { useContext, useState } from 'react';
import { HiXMark, HiMiniCreditCard } from 'react-icons/hi2';
import { IoIosArrowDown } from 'react-icons/io';
import { FaPaypal, FaApplePay } from 'react-icons/fa';
import { BsThreeDots } from 'react-icons/bs';
import s from './UpdatePaymentMethodModal.module.scss';
import visa from '../../assets/images/NewDashboard/visa.png';
import mastercard from '../../assets/images/NewDashboard/mastercard.png';
import americanExpress from '../../assets/images/NewDashboard/american-express.png';
import discover from '../../assets/images/NewDashboard/Discover.png';
import { ModalContext } from './Modal';

export type CardButtonType = 'Card' | 'Paypal' | 'Apple';

const UpdatePaymentMethodModal = () => {
  const modalContext = useContext(ModalContext);
  const [selectedTab, setSelectedTab] = useState<CardButtonType>('Card');

  const handleModalClose = () => {
    if (modalContext) {
      modalContext.close();
    }
  };

  return (
    <div className={s.modal}>
      <div className={s.modal__top}>
        <HiXMark onClick={handleModalClose} />
        <p className={s.modal__top__heading}>Update payment method</p>
      </div>
      <div className={s.container}>
        <div className={s.box}>
          <div className={s.box__payment}>
            <div
              className={`${s.box__payment__wrapper} ${
                selectedTab === 'Card' ? s.box__payment__wrapperSelected : ''
              }`}
              onClick={() => {
                setSelectedTab('Card');
              }}
            >
              <HiMiniCreditCard
                className={`${s.box__payment__card} ${
                  selectedTab === 'Card' ? s.box__payment__cardSelected : ''
                }`}
              />
              <p
                className={`${s.box__payment__textCard} ${
                  selectedTab === 'Card' ? s.box__payment__textCardSelected : ''
                }`}
              >
                Card
              </p>
            </div>
            <div
              className={`${s.box__payment__wrapper} ${
                selectedTab === 'Paypal' ? s.box__payment__wrapperSelected : ''
              }`}
              onClick={() => {
                setSelectedTab('Paypal');
              }}
            >
              <FaPaypal
                className={`${s.box__payment__paypal} ${
                  selectedTab === 'Paypal' ? s.box__payment__paypalSelected : ''
                }`}
              />
              <p
                className={`${s.box__payment__textPaypal} ${
                  selectedTab === 'Paypal'
                    ? s.box__payment__textPaypalSelected
                    : ''
                }`}
              >
                Paypal
              </p>
            </div>
            <div
              className={`${s.box__payment__wrapper} ${
                selectedTab === 'Apple' ? s.box__payment__wrapperSelected : ''
              }`}
              onClick={() => {
                setSelectedTab('Apple');
              }}
            >
              <FaApplePay
                className={`${s.box__payment__apple} ${
                  selectedTab === 'Apple' ? s.box__payment__appleSelected : ''
                }`}
              />
              <p
                className={`${s.box__payment__textApple} ${
                  selectedTab === 'Apple'
                    ? s.box__payment__textAppleSelected
                    : ''
                }`}
              >
                Apple Pay
              </p>
            </div>
            <div className={s.box__payment__wrapperSmall}>
              <BsThreeDots className={s.box__payment__dots} />
            </div>
          </div>
          {selectedTab === 'Card' && (
            <form className={s.form}>
              <div className={s.form__boxLg}>
                <label className={s.form__boxLg__label}>Card number</label>
                <input
                  className={s.form__boxLg__input}
                  type="number"
                  placeholder="1234 1234 1234 1234"
                />
                <div className={s.form__boxLg__platforms}>
                  <img
                    className={s.form__boxLg__platforms__image}
                    src={visa}
                    alt="visa icon"
                  />
                  <img
                    className={s.form__boxLg__platforms__image}
                    src={mastercard}
                    alt="master card"
                  />
                  <img
                    className={s.form__boxLg__platforms__image}
                    src={americanExpress}
                    alt="american express"
                  />
                  <img
                    className={s.form__boxLg__platforms__image}
                    src={discover}
                    alt="discover"
                  />
                </div>
              </div>
              <div className={s.form__container}>
                <div className={s.form__boxSm}>
                  <label className={s.form__boxSm__label}>Expiration</label>
                  <input
                    className={s.form__boxSm__input}
                    type="text"
                    placeholder="MM/YY"
                  />
                </div>
                <div className={s.form__boxSm}>
                  <label className={s.form__boxSm__label}>CVC</label>
                  <input
                    className={s.form__boxSm__input}
                    type="number"
                    placeholder="CVC"
                  />
                </div>
              </div>
              <div className={s.form__container}>
                <div className={s.form__boxSm}>
                  <label className={s.form__boxSm__label}>Country</label>
                  <div className={s.form__boxSm__select}>
                    <p className={s.form__boxSm__select__name}>United States</p>
                    <IoIosArrowDown />
                  </div>
                </div>
                <div className={s.form__boxSm}>
                  <label className={s.form__boxSm__label}>Postal code</label>
                  <input
                    className={s.form__boxSm__input}
                    type="number"
                    placeholder="90210"
                  />
                </div>
              </div>
              <button className={s.form__button} type="button">
                Update
              </button>
            </form>
          )}
          {selectedTab === 'Paypal' && (
            <p className={s.form__nullSection}>Paypal</p>
          )}
          {selectedTab === 'Apple' && (
            <p className={s.form__nullSection}>Apple Pay</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpdatePaymentMethodModal;
