import React, { useContext } from 'react';
import { Tooltip, Zoom } from '@mui/material';
import { ThemeContext } from '../../context/ThemeContext';
import styles from './icons.module.scss';

interface Props {
  onClick: () => void;
  active: boolean;
}

function ForegroundIcon({ onClick, active } : Props) {
  const { theme } = useContext(ThemeContext);
  return (
    <Tooltip
      title="Foreground"
      arrow
      placement="bottom"
      TransitionComponent={Zoom}
      componentsProps={{
        tooltip: {
          sx: {
            fontFamily: 'Passero One',
            fontWeight: '400px',
            fontSize: '16px',
            padding: '10px',
            bgcolor: '#3c3c3ce6',
            '& .MuiTooltip-arrow': {
              color: '#3c3c3ce6',
            },
            maxWidth: '250px',
          },
        },
      }}
    >
      <button
        onClick={onClick}
        type="button"
        className={`${styles.icon} ${styles.foreground_icon} ${styles[theme]} ${active && styles.active}`}
        aria-label="foreground"
      >
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 24 24"
          xmlSpace="preserve"
        >
          <path d="M4,1h16c1.7,0,3,1.3,3,3v16c0,1.7-1.3,3-3,3H4c-1.7,0-3-1.3-3-3V4C1,2.3,2.3,1,4,1z" />
          <path d="M5,12.8L12,6l7,6.8v9.6H5V12.8z" />
        </svg>
      </button>
    </Tooltip>
  );
}

export default ForegroundIcon;
