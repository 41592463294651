/* eslint-disable no-nested-ternary */
/* eslint-disable operator-linebreak */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable object-curly-newline */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Box, Stack, Tooltip, Zoom, useMediaQuery } from '@mui/material';
import {
  AttributeTextField,
  AttributeWrapper,
  HelpImg,
  SectionHeadingText,
  SmallHeadingText,
} from '../../styledComps/styledComps';
import HelpIcon from '../../../images/help.webp';
import CharacterFilterDropdown from '../../fixedFilter/filterDropDown/CharacterFilterDropdown';
import {
  LAEOptions,
  ProfessionOptions,
  RelationshipOptions,
} from './CharacterStaticOptions';
import appStore from '../../../store/AppStore';
import { GetUserCharacterGenerationQuery } from '../../../__generated__/graphql';
import getAttributeValue from '../../../util/aiGetCharAttribute';

interface Props {
  characterProfession: string;
  setCharacterProfession: (char: string) => void;
  characterRelationship: string;
  setCharacterRelationship: (char: string) => void;
  characterLifeAlteringEvent: string;
  setCharacterLifeAlteringEvent: (char: string) => void;
  characterBackStory: string;
  setCharacterBackStory: (char: string) => void;
  variant?: 'AIGen' | 'CharacterSheet';
}

function CharacterBackground({
  characterProfession,
  setCharacterProfession,
  characterRelationship,
  setCharacterRelationship,
  characterLifeAlteringEvent,
  setCharacterLifeAlteringEvent,
  characterBackStory,
  setCharacterBackStory,
  variant,
}: Props) {
  // const [birthStory, setBirthStory] = React.useState('');
  const [parents, setParents] = React.useState('');
  const viewMood = appStore((state) => state.characterSheetMood);
  const userCharacterGeneration:
    | GetUserCharacterGenerationQuery
    | null
    | undefined = appStore((state) => state.userCharacterGeneration);
  const selectedCharacter = appStore((state) => state.selectedCharacter);
  const createCharacterView = appStore((state) => state.createCharacterView);
  React.useEffect(() => {
    if (viewMood === 'edit' && createCharacterView === 'sheet') {
      if (selectedCharacter !== null) {
        setCharacterProfession(selectedCharacter?.profession as string);
        setCharacterRelationship(
          selectedCharacter?.relationship_status as string,
        );
        setCharacterLifeAlteringEvent(
          selectedCharacter?.life_altering_event as string,
        );
        setCharacterBackStory(selectedCharacter?.backstory as string);
        setParents('');
      }
    }
  }, []);
  React.useEffect(() => {
    if (userCharacterGeneration !== (null || undefined)) {
      const character =
        userCharacterGeneration?.user_character_generation?.attributes;
      if (character) {
        const charRelationship = getAttributeValue(
          character,
          'relationship_status',
        );
        if (charRelationship !== undefined) {
          setCharacterRelationship(charRelationship);
        }
        const charLifeAlteringEvent = getAttributeValue(
          character,
          'life_altering_event',
        );
        if (charLifeAlteringEvent !== undefined) {
          setCharacterLifeAlteringEvent(charLifeAlteringEvent);
        }
        const charBackStory = getAttributeValue(character, 'birth_story');
        if (charBackStory !== undefined) {
          setCharacterBackStory(charBackStory);
        }
        const charParents = getAttributeValue(character, 'parents');
        if (charParents !== undefined) {
          setParents(charParents);
        }
      }
    }
  }, [userCharacterGeneration]);

  const isTabletScreen = useMediaQuery('(max-width:865px)');
  const isMobileScreen = useMediaQuery('(max-width:530px)');

  return (
    <Stack gap="22px">
      <Stack
        gap="7px"
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <SectionHeadingText>Background</SectionHeadingText>
        <Tooltip
          title="Background of the character you are about to create are as follows "
          arrow
          placement="bottom"
          TransitionComponent={Zoom}
          componentsProps={{
            tooltip: {
              sx: {
                fontFamily: 'Passero One',
                fontWeight: '400px',
                fontSize: '14px',
                padding: '10px',
                bgcolor: '#3c3c3ce6',
                '& .MuiTooltip-arrow': {
                  color: '#3c3c3ce6',
                },
                maxWidth: '250px',
              },
            },
          }}
        >
          <HelpImg src={HelpIcon} />
        </Tooltip>
      </Stack>
      <Stack direction="row" flexWrap="wrap" gap="28px">
        <CharacterFilterDropdown
          width={
            variant === 'AIGen' ? '1319px' : isTabletScreen ? '100%' : '421px'
          }
          title="Professions"
          options={ProfessionOptions}
          tooltip
          toolTipMessage="Choose the profession you wish to apply to the character."
          value={characterProfession}
          onChange={setCharacterProfession}
        />
        {variant === 'CharacterSheet' && (
          <>
            <CharacterFilterDropdown
              width={isTabletScreen ? '100%' : '421px'}
              title="Relationship"
              options={RelationshipOptions}
              tooltip
              toolTipMessage="Choose the relationship you wish to apply to the character."
              value={characterRelationship}
              onChange={setCharacterRelationship}
            />
            <CharacterFilterDropdown
              width={isTabletScreen ? '100%' : '421px'}
              title="Life Altering Event"
              options={LAEOptions}
              tooltip
              toolTipMessage="Choose the life altering event you wish to apply to the character."
              value={characterLifeAlteringEvent}
              onChange={setCharacterLifeAlteringEvent}
            />
          </>
        )}
        <AttributeWrapper width={variant === 'AIGen' ? '1319px' : '646px'}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              sx={{
                minWidth: '100%',
              }}
            >
              <Stack direction="row" alignItems="center" gap="2px">
                <SmallHeadingText>Birth Story</SmallHeadingText>
                <Tooltip
                  title="Enter the birth story here "
                  arrow
                  placement="bottom"
                  TransitionComponent={Zoom}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        fontFamily: 'Passero One',
                        fontWeight: '400px',
                        fontSize: '14px',
                        padding: '10px',
                        bgcolor: '#3c3c3ce6',
                        '& .MuiTooltip-arrow': {
                          color: '#3c3c3ce6',
                        },
                        maxWidth: '250px',
                      },
                    },
                  }}
                >
                  <HelpImg src={HelpIcon} />
                </Tooltip>
              </Stack>
              <AttributeTextField
                rows={4}
                multiline
                placeholder="Enter short term goals below"
                value={characterBackStory}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCharacterBackStory(event.target.value);
                }}
                fullWidth
              />
            </Box>
          </Stack>
        </AttributeWrapper>
        {variant === 'CharacterSheet' && (
          <AttributeWrapper width="645px">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box
                sx={{
                  minWidth: '100%',
                }}
              >
                <Stack direction="row" alignItems="center" gap="2px">
                  <SmallHeadingText>Parents (Lineage)</SmallHeadingText>
                  <Tooltip
                    title="Enter the lineage of the character here "
                    arrow
                    placement="bottom"
                    TransitionComponent={Zoom}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          fontFamily: 'Passero One',
                          fontWeight: '400px',
                          fontSize: '14px',
                          padding: '10px',
                          bgcolor: '#3c3c3ce6',
                          '& .MuiTooltip-arrow': {
                            color: '#3c3c3ce6',
                          },
                          maxWidth: '250px',
                        },
                      },
                    }}
                  >
                    <HelpImg src={HelpIcon} />
                  </Tooltip>
                </Stack>
                <AttributeTextField
                  rows={4}
                  multiline
                  placeholder="Enter long term goals below"
                  value={parents}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setParents(event.target.value);
                  }}
                  fullWidth
                />
              </Box>
            </Stack>
          </AttributeWrapper>
        )}
      </Stack>
    </Stack>
  );
}

CharacterBackground.defaultProps = {
  variant: 'CharacterSheet',
};

export default CharacterBackground;
