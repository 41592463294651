/* eslint-disable object-curly-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable nonblock-statement-body-position */
/* eslint-disable curly */
/* eslint-disable max-len */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { ReactiveList, SearchBox } from '@appbaseio/reactivesearch';
import { Star, StarBorder } from '@mui/icons-material';
import GoldImg from '../../../../images/gold-small.png';
import RenderMediaAssets from '../../../RenderMediaAssets/RenderMediaAssets';
import Expansion from '../../Expansion/Expansion';
import appStore from '../../../../store/AppStore';
import { RWLibraryString } from '../../../../interfaces/rwlibrary';
// import '../../MyCollectionPanel/mycollectionpanel.scss';
import '../../MyCollectionPanel/mycollectionpanel.scss';
import '../../../../views/Realm/Characters/charactergenerator.scss';
import {
  CreateMyLibraryFavoritesDocument,
  DeleteOneMyLibraryFavoritesDocument,
  GetMyLibraryFavoritesDocument,
  User_Library_Content_Pack_FavoriteQueryInput,
} from '../../../../__generated__/graphql';
import { useAuth } from '../../../../hooks/use-auth';
import useGraphQLMutation from '../../../../hooks/useGraphQLMutation';
import useGraphQL from '../../../../hooks/useGraphQL';

export default function RWLibrary(): JSX.Element {
  const auth = useAuth();
  // Array to hold expansion states
  const [expandedStates, setExpandedStates] = useState<boolean[]>([]);
  const [queryOutput, setQueryOutput] = useState<string | string[]>('');
  /**
   * `allIDs` keeps a local cache of all ids of lib content packs that have been favorited
   * `favoritesList` holds the initialList of all the favorited objects on initial load.
   */
  const [allIDs, setAllIDs] = useState<any>([]);
  const [favoritesList, setfavoritesList] = useState<any>({});
  const activeRWLibraryFilter: RWLibraryString | null = appStore(
    (state) => state.activeRWLibraryFilter,
  );
  const { data: favoritesSet } = useGraphQL(GetMyLibraryFavoritesDocument, {});
  const TokenPackPurchaseSummary: any = appStore(
    (state) => state.TokenPackPurchaseSummary,
  );
  const setOpenTokenPackPurchaseSummaryModal = (condition: boolean) =>
    appStore.setState({
      openTokenPackPurchaseSummaryModal: condition,
    });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { mutate: createMyLibFavorites, error: createMyLibFavoritesError } =
    useGraphQLMutation(CreateMyLibraryFavoritesDocument);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { mutate: removeMyLibFavorites, error: removeMyLibFavoritesError } =
    useGraphQLMutation(DeleteOneMyLibraryFavoritesDocument);

  /**
   *  This function takes in the params listed below and
   * Adds library content pack to favorites collection.
   * It also updates the `allllIDs` array to add the id of the added content pack
   * @param packName
   * @param assetCatType
   * @param id
   */
  const handleAddToFavorites = async (
    packName: any,
    assetCatType: any,
    id: any,
  ) => {
    const inputData: any = {
      asset_category_type: assetCatType,
      created_at: new Date(),
      name: packName,
      owner: {
        link: auth?.realmUser?.id,
      },
      library_content_pack: {
        link: id,
      },
    };
    try {
      // Use the createMyLibFavorites mutation function from your GraphQL client
      await createMyLibFavorites({
        data: inputData,
      });
      setAllIDs((prev: []) => [id, ...prev]);
      // setfavoritesList((prev: any) => [inputData, ...prev]);
      console.log('new fave list', favoritesList);
      // Handle success response from the server
    } catch (error: any) {
      console.error('Error adding to fave:', error.message);
      // Handle unexpected errors
    }
  };

  /**
   * This function takes in the params listed below and
   * deletes library content pack to favorites collection.
   * It also updates the `allllIDs` array to remove the id of the removed content pack
   * @param packName
   * @param id
   */
  const handleDelToFavorites = async (packName: any, id: any) => {
    const delData: User_Library_Content_Pack_FavoriteQueryInput = {
      name: packName,
      library_content_pack: {
        _id: id,
      },
    };
    try {
      // Use the createMyLibFavorites mutation function from your GraphQL client
      await removeMyLibFavorites({
        query: delData,
      });
      // Handle success response from the server
      const idToRemove = id;
      const updatedArray = allIDs.filter((item: any) => item !== idToRemove);
      setAllIDs(updatedArray);
    } catch (error: any) {
      console.error('Error removing fave:', error.message);
      // Handle unexpected errors
    }
  };
  useEffect(() => {
    setfavoritesList(favoritesSet?.user_library_content_pack_favorites);
    const initialIDs = favoritesSet?.user_library_content_pack_favorites?.map(
      (libID: any) => libID?.library_content_pack?._id,
    );
    setAllIDs(initialIDs);
  }, [favoritesList, favoritesSet]);

  /**
   * The function checks each library content pack id against the local list available
   * It also handles the conditional rendering based on the returned value.
   * @param lib_content_pack_id
   * @returns boolean
   */
  const checkFavoriteStatus = (lib_content_pack_id: any) => {
    if (allIDs?.includes(lib_content_pack_id)) {
      return true;
    }
    return false;
  };
  let rawData;
  const sortData = (data: any) => {
    rawData = data;

    const newData = rawData.sort((a: any, b: any) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (activeRWLibraryFilter === 'A-Z') {
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      }
      if (activeRWLibraryFilter === 'Z-A') {
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }
      }
      return 0;
    });
    console.log(newData);
    return newData;
  };

  useEffect(() => {
    console.log('TokenPackPurchaseSummary', TokenPackPurchaseSummary);
  }, [TokenPackPurchaseSummary, setOpenTokenPackPurchaseSummaryModal]);

  useEffect(() => {
    if (activeRWLibraryFilter === null)
      setQueryOutput(['RWLibrary-Token-Pack', 'RWLibrary-Image-Pack']);
    // eslint-disable-next-line max-len
    // if (activeRWLibraryFilter === 'Favorites') setQueryOutput(['RWLibrary-Token-Pack', 'RWLibrary-Image-Pack']);
    if (activeRWLibraryFilter === 'Token Packs')
      setQueryOutput('RWLibrary-Token-Pack');
    if (activeRWLibraryFilter === 'Tokens')
      setQueryOutput('RWLibrary-Token-Pack');

    if (activeRWLibraryFilter === 'Realms')
      setQueryOutput(['RWLibrary-Token-Pack', 'RWLibrary-Image-Pack']);
    if (activeRWLibraryFilter === 'A-Z')
      setQueryOutput(['RWLibrary-Token-Pack', 'RWLibrary-Image-Pack']);
    if (activeRWLibraryFilter === 'Z-A')
      setQueryOutput(['RWLibrary-Token-Pack', 'RWLibrary-Image-Pack']);
  }, [activeRWLibraryFilter, rawData]);

  // Create a function to toggle the expansion state of a specific item
  const toggleExpansion = (index: number) => {
    const updatedStates = [...expandedStates];
    updatedStates[index] = !expandedStates[index];
    setExpandedStates(updatedStates);
  };

  return (
    <Box>
      {queryOutput && (
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            px: '11px',
          }}
        >
          <Box display="flex" justifyContent="end">
            <Box
              sx={{
                width: '100%',
                height: '40px',
                flexShrink: 0,
                borderRadius: '10px',
                // background: 'black',
              }}
            >
              <SearchBox
                key={activeRWLibraryFilter}
                componentId="fav-searchbox"
                iconPosition="right"
                dataField={['name']}
                includeFields={['name', 'thumbnail_url', 'asset_category_type']}
                autosuggest={false}
                AIConfig={{}}
                AIUIConfig={{}}
                type="search"
                compoundClause="must"
                enableIndexSuggestions={false}
                enterButton={false}
                className="my_custom_searchbox"
                // custom query to only search for realms where isFavorited = true
                customQuery={() => ({
                  $search: {
                    phrase: {
                      path: 'asset_category_type',
                      query: queryOutput,
                    },
                  },
                })}
              />
            </Box>
          </Box>

          <Box sx={{ maxWidth: '100%' }}>
            <ReactiveList
              componentId="fav-results"
              dataField="_score"
              compoundClause="must"
              className="my_custom_reactive_list"
              innerClass={{
                pagination: 'list_pagination',
                resultStats: 'list_resultStats',
                resultsInfo: 'list_resultsInfo',
                noResults: 'list_noResults',
              }}
              includeFields={[
                'name',
                'media_assets',
                'thumbnail_url',
                'asset_category_type',
              ]}
              react={{
                and: ['fav-searchbox'],
              }}
              onError={(err) => console.log(err)}
              showResultStats
              render={({ data }: any) => (
                <Box>
                  <Grid container sx={{ px: '11px' }}>
                    {/* Using favorites as a base case
                  for rendering since alot of its logic is done locally */}
                    <Grid item xs={12} mb={2} display="flex" flexWrap="wrap">
                      {data &&
                        data?.length > 0 &&
                        sortData(data).map((item: any, index: any) => (
                          <Expansion
                            key={item.media_assets[index]}
                            title={item.name}
                            isExpanded={expandedStates[index] || false}
                            setIsExpanded={() => toggleExpansion(index)}
                            headerChildren={
                              <>
                                <Typography
                                  sx={{
                                    color: 'var(--white, #FFF)',
                                    background: '#41531E',
                                    textAlign: 'center',
                                    borderRadius: 4,
                                    fontFamily: 'DM Sans',
                                    fontSize: '10px',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    lineHeight: '20px',
                                    letterSpacing: '0.1px',
                                    padding: '0 10px',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  In Library{' '}
                                  <span
                                    style={{ color: 'green', fontSize: '5px' }}
                                  >
                                    ✓
                                  </span>
                                </Typography>
                                <img
                                  src={GoldImg}
                                  alt="shield-img"
                                  onClick={() => {
                                    appStore.setState({
                                      TokenPackPurchaseSummary: item,
                                    });
                                    setOpenTokenPackPurchaseSummaryModal(true);
                                  }}
                                  style={{ cursor: 'pointer' }}
                                />
                                <Box>
                                  <IconButton>
                                    {item && checkFavoriteStatus(item?._id) ? (
                                      <Star
                                        sx={{ color: 'gold' }}
                                        onClick={() => {
                                          handleDelToFavorites(
                                            item.name,
                                            item._id,
                                          );
                                        }}
                                      />
                                    ) : (
                                      <StarBorder
                                        sx={{ color: 'gold' }}
                                        onClick={() => {
                                          handleAddToFavorites(
                                            item.name,
                                            item.asset_category_type,
                                            item._id,
                                          );
                                        }}
                                      />
                                    )}
                                  </IconButton>
                                </Box>
                              </>
                            }
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                columnGap: '8px',
                                rowGap: '8px',
                              }}
                            >
                              <RenderMediaAssets
                                mediaAssetIds={
                                  item.media_assets.slice(0, 5) as string[]
                                }
                              />
                              {expandedStates[index] && (
                                <RenderMediaAssets
                                  mediaAssetIds={
                                    item.media_assets.slice(5) as string[]
                                  }
                                />
                              )}
                            </Box>
                          </Expansion>
                        ))}

                      {!data && <p>No items found.</p>}
                    </Grid>
                  </Grid>
                </Box>
              )}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}
