/* eslint-disable arrow-body-style */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import s from './Subscription.module.scss';
import visa from '../../assets/images/NewDashboard/visa.png';
import Modal from '../Modal/Modal';
import UpdatePaymentMethodModal from '../Modal/UpdatePaymentMethodModal';
import { GetUserWalletDocument } from '../../__generated__/graphql';
import useGraphQL from '../../hooks/useGraphQL';
import { useAuth } from '../../hooks/use-auth';
import useNotification from '../../hooks/useNotification';
import FreeShield from '../../images/free-sub.png';
import SilverShield from '../../images/silver-sub.png';
import GoldShield from '../../images/gold-sub.png';
import PlatinumShield from '../../images/platinum-sub.png';

const Subscription = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { displayNotification } = useNotification();
  const { data: userProfile, error: userProfileError } = useGraphQL(GetUserWalletDocument, {
    query: {
      _id: auth?.user?.uid,
    },
  });
  if (userProfileError) {
    displayNotification({ message: 'Error loading user profile', type: 'error' });
  }
  console.log(userProfile?.user?.user_subscription);

  const getSubImage = (subname: string) => {
    switch (subname) {
      case 'Free Plan':
        return FreeShield;
      case 'Silver Plan':
        return SilverShield;
      case 'Gold Plan':
        return GoldShield;
      case 'Platinum Plan':
        return PlatinumShield;
      default:
        return undefined;
    }
  };
  return (
    <div className={s.layout}>
      <p className={s.layout__heading}>Subscription & Billing</p>
      <div className={s.box}>
        <div className={s.wrapper}>
          <p className={s.wrapper__heading}>
            Hello
            {userProfile?.user?.username ?? userProfile?.user?.email ?? 'ERROR'}
            ,
            <br />
            Thanks for being a
            {userProfile?.user?.user_subscription?.subscription_tier?.name}
            {' '}
            member!
          </p>
          <div className={s.wrapper__box}>
            <p className={s.wrapper__subscription}>Subscription Plan</p>
            <div className={s.wrapper__container}>
              <div className={s.wrapper__content}>
                <div className={s.wrapper__package}>
                  <img
                    // className={s.wrapper__package__background}
                    src={getSubImage(userProfile?.user?.user_subscription?.subscription_tier?.name || '')}
                    alt="shield icon"
                  />

                  <div className={s.wrapper__package__subscriptionContent}>
                    <p className={s.wrapper__package__name}>
                      {' '}
                      {userProfile?.user?.user_subscription?.subscription_tier?.name}
                    </p>
                    <p className={s.wrapper__package__price}>
                      $
                      {userProfile?.user?.user_subscription?.subscription_tier?.price}
                      <span className={s.wrapper__package__month}>/Month</span>
                    </p>
                  </div>
                </div>

                <div className={s.wrapper__package__actionsBox}>
                  <p className={s.wrapper__package__text}>
                    Next billing date:
                    {userProfile?.user?.user_subscription?.renew_date || 'N/A'}
                  </p>
                  <button
                    className={s.wrapper__package__button}
                    type="button"
                    onClick={() => navigate('/checkout')}
                  >
                    Level up
                  </button>
                </div>
              </div>
            </div>
          </div>
          {userProfile?.user?.user_subscription?.subscription_tier?.name !== 'Free' && (
            <div className={s.wrapper__box}>
              <p className={s.wrapper__subscription}>Payment Method</p>
              <div className={s.wrapper__container}>
                <div className={s.wrapper__payment}>
                  <div className={s.wrapper__payment__box}>
                    <img className={s.wrapper__payment__visa} src={visa} alt="visa" />
                    <p className={s.wrapper__payment__visaText}>Visa ending in ••••1234</p>
                    <Modal>
                      <Modal.Open opensWindowName="updatePayment">
                        <button className={s.wrapper__payment__visaButton} type="button">
                          Update
                        </button>
                      </Modal.Open>

                      <Modal.Window name="updatePayment">
                        <UpdatePaymentMethodModal />
                      </Modal.Window>
                    </Modal>
                  </div>
                  <p className={s.wrapper__payment__info}>
                    Pause Membership - pause up to 6 months, resume anytime!
                  </p>
                </div>
              </div>
            </div>
          )}
          <button
            type="button"
            className={s.wrapper__cancelButton}
            onClick={() => window.open('https://billing.stripe.com/p/login/test_14k1743YQ4lQ9LG6oo')}
          >
            Cancel Subscription
          </button>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
