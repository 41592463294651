/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
  Box, Button, TextField, Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useGenerateNewCharacterText } from '../../../__generated__/realmWorldsApiComponents';
import { CharacterGenerationWorkflowRequest } from '../../../__generated__/realmWorldsApiSchemas';
import useGraphQL from '../../../hooks/useGraphQL';
import {
  GetUserCharacterGenerationDocument, GetUserCharacterGenerationQuery,
  GetUserCharacterGenerationQueryVariables,
} from '../../../__generated__/graphql';
import fetchGraphQL from '../../../hooks/fetchers/fetchGraphQL';
import { useAuth } from '../../../hooks/use-auth';

type CharacterGenExampleProps = {

};

const CharacterGenExample: React.FC<CharacterGenExampleProps> = () => {
  const auth = useAuth();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [abilitiesCharisma, setAbilitiesCharisma] = React.useState<string | null>(null);
  const [abilitiesConstitution, setAbilitiesConstitution] = React.useState<string | null>(null);
  const [abilitiesDexterity, setAbilitiesDexterity] = React.useState<string | null>(null);
  const [abilitiesIntelligence, setAbilitiesIntelligence] = React.useState<string | null>(null);
  const [abilitiesStrength, setAbilitiesStrength] = React.useState<string | null>(null);
  const [abilitiesWisdom, setAbilitiesWisdom] = React.useState<string | null>(null);
  const [alignment, setAlignment] = React.useState<string | null | undefined>(null);
  const [aditionalNotes, setAditionalNotes] = React.useState<string | null | undefined>(null);
  const [age, setAge] = React.useState<string | null>(null);
  const [birthStory, setBirthStory] = React.useState<string | null | undefined>(null);
  const [characterName, setCharacterName] = React.useState<string | null | undefined>(null);
  const [characterType, setCharacterType] = React.useState<string | null | undefined>(null);
  const [classType, setClassType] = React.useState<string | null | undefined>(null);
  const [gender, setGender] = React.useState<string | null | undefined>(null);
  const [height, setHeight] = React.useState<string | null>(null);
  const [hairColor, setHairColor] = React.useState<string | null | undefined>(null);
  const [
    lifeAlteringEvents,
    setLifeAlteringEvents,
  ] = React.useState<string | null | undefined>(null);
  const [longTermGoals, setLongTermGoals] = React.useState<string | null | undefined>(null);
  const [motivations, setMotivations] = React.useState<string | null | undefined>(null);
  const [personalityType, setPersonalityType] = React.useState<string | null | undefined>(null);
  const [pronouns, setPronouns] = React.useState<string | null | undefined>(null);
  const [race, setRace] = React.useState<string | null | undefined>(null);
  const [shortTermGoals, setShortTermGoals] = React.useState<string | null | undefined>(null);
  const [weight, setWeight] = React.useState<string | null | undefined>(null);
  const [
    selectedTextGenerationSkuId,
    setSelectedTextGenerationSkuId,
  ] = React.useState<string | null | undefined>(null);
  const [
    selectedPromptTemplateId,
    setSelectedPromptTemplateId,
  ] = React.useState<string | null | undefined>(null);

  const createNewCharacterTextMutate = useGenerateNewCharacterText();

  const [
    userCharacterGeneration,
    setUserCharacterGeneration,
  ] = React.useState<GetUserCharacterGenerationQuery | null | undefined>(null);

  // const {
  //   data: userCharacterGeneration,
  //   error: userCharacterGenerationError,
  //   refetch: refetchUserCharacterGeneration,
  // } = useGraphQL(
  //   GetUserCharacterGenerationDocument,
  //   {
  //     query: {
  //       _id: userTextGenerationid,
  //     },
  //   },
  // );

  const handleSuccessfulJobSubmission = async (
    data: CharacterGenerationWorkflowRequest,
    workflowId: string,
  ) => {
    console.log('data: ', data.userTextGenerationId);
    let retriesLeft = 60; // 120 seconds total @ 2 seconds per retry
    // poll for completion
    const poll = setInterval(async () => {
      await auth?.getCurrentUserTokens();
      // eslint-disable-next-line max-len
      const userCharacterGenrationResponse = await fetchGraphQL <GetUserCharacterGenerationQuery, GetUserCharacterGenerationQueryVariables>(
        GetUserCharacterGenerationDocument,
        {
          query: {
            user_text_generation: {
              _id: data.userTextGenerationId,
            },
          },
        },
        localStorage.getItem('realmToken') || '',
      );
      console.log('userCharacterGenrationResponse: ', userCharacterGenrationResponse);
      setUserCharacterGeneration(userCharacterGenrationResponse);
      console.log('polling: ', userCharacterGeneration?.user_character_generation);
      if (userCharacterGeneration?.user_character_generation != null) {
        console.log('userCharacterGeneration: ', userCharacterGeneration);
        // update all of the state variables
        setIsLoading(false);
        // HERE IS WHERE YOU WILL UPDATE YOUR UI USING THE ATTRIBUTES
        setAbilitiesCharisma(
          userCharacterGeneration
            .user_character_generation
            .attributes?.find((attribute) => attribute?.name === 'charisma')?.value || '',
        );
        setUserCharacterGeneration(
          userCharacterGeneration.user_character_generation as GetUserCharacterGenerationQuery,
        );
        /// ... do the rest
        clearInterval(poll);
      }
      retriesLeft -= 1;
      if (retriesLeft === 0) clearInterval(poll);
    }, 2000);
  };

  const handleCreateNewCharacterText = () => {
    createNewCharacterTextMutate.mutate({
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: {
        abilities: {
          charisma: abilitiesCharisma,
          constitution: abilitiesConstitution,
          dexterity: abilitiesDexterity,
          intelligence: abilitiesIntelligence,
          strength: abilitiesStrength,
          wisdom: abilitiesWisdom,
        },
        alignment,
        aditionalNotes,
        age,
        birthStory,
        characterName,
        characterType,
        class: classType,
        gender,
        height,
        hairColor,
        lifeAlteringEvents,
        longTermGoals,
        motivations,
        personalityType,
        pronouns,
        race,
        shortTermGoals,
        weight,
        textGenerationSkuId: '658b9237330933edbd27e467',
        promptTemplateId: '65a8a559d65a200b6893bc7d',
      },
    }, {
      onSuccess: (data) => {
        console.log('data: ', data);
        console.log('setting userTextGenerationid: ', data.userTextGenerationId);
        if (data.userTextGenerationId === undefined || data.userTextGenerationId === null) {
          console.log('userTextGenerationId is undefined');
          return;
        }
        handleSuccessfulJobSubmission(data, data.userTextGenerationId);
      },
      onError: (error) => {
        console.log('error: ', error);
      },
    });
  };

  return (
    <Box>
      <TextField
        id="outlined-basic"
        label="Outlined"
        variant="outlined"
        title="Class"
        value={classType}
        onChange={(e) => setClassType(e.target.value)}
      />
      <TextField
        id="outlined-basic"
        label="Outlined"
        variant="outlined"
        title="Character Name"
        value={characterName}
        onChange={(e) => setCharacterName(e.target.value)}
      />

      <LoadingButton
        variant="contained"
        onClick={handleCreateNewCharacterText}
        loading={isLoading}
      />

      {/* Fill out the rest of the form */}

      <Typography>
        Response:
        {' '}
        {userCharacterGeneration?.user_character_generation === null ? 'loading' : JSON.stringify(userCharacterGeneration, null, 2)}
      </Typography>

    </Box>

  );
};
export default CharacterGenExample;
