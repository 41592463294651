/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable object-curly-newline */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
import React, { useContext } from 'react';
import { ArrowDropDown, ArrowLeft, ArrowRight } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, Typography, useMediaQuery } from '@mui/material';
import {
  ReactiveBase,
  ReactiveList,
  SearchBox,
} from '@appbaseio/reactivesearch';
import styled from '@emotion/styled';
import appStore, {
  CharacterViewOptions,
  ViewTypeOptions,
} from '../../../store/AppStore';
import { ThemeContext } from '../../../context/ThemeContext';
import {
  FilterImg,
  ShieldImg,
} from '../../../components/styledComps/styledComps';
import CharacterPack from './CharacterPack/CharacterPack';
import GridViewIcon from '../../../images/grid-view.svg';
import DetailedViewIcon from '../../../images/detailed-view.svg';
import BackgroundImage from '../../../images/character-catalog.webp';
import CharacterPackImage from '../../../images/character-pack.webp';
import RWLogoIcon from '../../../images/Realm_worlds_logo.png';
import FilterIcon from '../../../images/filter-slider.webp';
import AICharacterGenWelcomeModal from '../../../components/modals/AICharacterGenWelcomeModal';
import CharacterSummaryModal from '../../../components/modals/CharacterSummaryModal';
import CreateCharacterSetModal from '../../../components/modals/CreateCharacterSetModal';
import CharacterCatalogCPExpansion from '../../../components/panel/Expansion/CharacterCatalogCPExpansion';
import CharacterSetHeader from '../../../components/realms/Character/CharacterSetHeader';
import './charactergenerator.scss';
import useGraphQL from '../../../hooks/useGraphQL';
import { useAuth } from '../../../hooks/use-auth';
import {
  Character_Set,
  GetCharactersSetsDocument,
} from '../../../__generated__/graphql';
import { GeneralFilterString } from '../../../interfaces/generalFilters';
import FixedFilter from '../../../components/fixedFilter/FixedFilter';
import RealmSlider from '../../../components/realms/RealmSlider';

const RWLogoImg = styled('img')({
  height: '25px',
  width: '25px',
  objectFit: 'contain',
});

const optionsGeneral: GeneralFilterString[] = [
  'Recent',
  'Oldest',
  'A-Z',
  'Z-A',
];

// const isTabletScreen = useMediaQuery('(max-width:768px)');

const CharacterHeader = (theme: string) => (
  <Box
    sx={{
      backgroundImage: `url(${BackgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      pt: 15,
      width: '100vw',
      height: '220px',
      position: 'relative',
      boxSizing: 'border-box',
    }}
  >
    <Box
      sx={{
        position: 'absolute',
        bottom: 0,
        height: '100%',
        width: '100%',
        background: `linear-gradient(180deg, #ffffff00 30%, ${
          theme === 'light' ? '#E9DCC3' : '#313131'
        } 100%)`,
        zIndex: 2,
      }}
    />
    <Typography
      sx={{
        color: '#FFF',
        textAlign: 'center',
        fontFamily: 'Passero One',
        fontSize: '50px',
        fontStyle: 'normal',
        textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        fontWeight: 400,
        lineHeight: 'normal',
        paddingBottom: 5,
        zIndex: 3,
      }}
    >
      Character Catalog
    </Typography>
  </Box>
);

const setSelectedView = (view: CharacterViewOptions) => {
  appStore.setState({
    selectedCharacterView: view,
  });
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RenderCharacterPacks = (isExpanded: boolean) => (
  <div>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        ml: '120px',
        mr: '120px',
        mt: '10px',
        mb: '36px',
      }}
    >
      <ShieldImg src={CharacterPackImage} />
      <Typography
        sx={{
          fontFamily: 'Passero One',
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: 'normal',
          whiteSpace: 'nowrap',
          marginRight: '26px',
          marginLeft: '13px',
        }}
      >
        Character Packs
      </Typography>
      <LoadingButton
        type="submit"
        variant="contained"
        onClick={() => setSelectedView('store')}
        // loading={authLoading} TODO: Implement loading state when button is made functional
        sx={{
          mt: 1,
          mb: 1,
          ml: '13px',
          height: 40,
          width: '120px',
          borderRadius: '5px',
          background: '#41521F',
          color: '#FFFFFFCC',
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
          '&:hover': {
            background: '#41521F',
          },
        }}
      >
        <Stack direction="row" gap="10px" alignItems="center">
          <RWLogoImg src={RWLogoIcon} />
          <Typography
            sx={{
              fontFamily: 'Passero One',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal',
              whiteSpace: 'nowrap',
            }}
          >
            RW Store
          </Typography>
        </Stack>
      </LoadingButton>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          width: '100%',
        }}
      >
        <ArrowDropDown
          sx={{ cursor: 'pointer' }}
          // onClick={() => setIsExpanded(!isExpanded)}
          className={`expansionArrow cp ${isExpanded ? 'rotated' : ''}`}
        />
      </Box>
    </Box>

    <Stack
      direction="row"
      alignItems="center"
      columnGap="2%"
      rowGap="40px"
      flexWrap="wrap"
      minHeight="164px"
      px="120px"
    >
      <CharacterPack width="32%" />
      <CharacterPack width="32%" />
      <CharacterPack width="32%" />
      {isExpanded && (
        <>
          <CharacterPack width="32%" />
          <CharacterPack width="32%" />
          <CharacterPack width="32%" />
          <CharacterPack width="32%" />
          <CharacterPack width="32%" />
          <CharacterPack width="32%" />
        </>
      )}
    </Stack>
  </div>
);

/**
 * Represents the Character Catalog component.
 *
 * @component
 * @example
 * // Example usage of CharacterCatalog component
 * <CharacterCatalog />
 *
 * @returns {JSX.Element} React element representing the character catalog.
 */
function CharacterCatalog() {
  const isTabletScreen = useMediaQuery('(max-width:865px)');
  const isMobileScreen = useMediaQuery('(max-width:530px)');

  const { theme } = useContext(ThemeContext);
  const viewType: ViewTypeOptions = appStore((state) => state.viewType);
  const [openCreateCharacterSetModal, setOpenCreateCharacterSetModal] =
    React.useState(false);
  const openAICharacterGenModal: boolean = appStore(
    (state) => state.openAICharacterGenModal,
  );
  const setOpenAICharacterGenModal = (val: boolean) => {
    appStore.setState({
      openAICharacterGenModal: val,
    });
  };
  // const [isExpanded] = React.useState(false);
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(false);
  const [selectedFilterOption, setSelectedFilterOption] =
    React.useState<GeneralFilterString>(optionsGeneral[0]);
  console.log(selectedFilterOption);
  const openCharacterSummaryModal: boolean = appStore(
    (state) => state.openCharacterSummaryModal,
  );
  const setOpenCharacterSummaryModal = (condition: boolean) =>
    appStore.setState({
      openCharacterSummaryModal: condition,
    });
  const auth = useAuth();

  const { data: charactersSets, refetch: refetchCharacterSets } = useGraphQL(
    GetCharactersSetsDocument,
    {
      query: {
        owner: {
          _id: auth?.user?.uid,
        },
      },
    },
  );

  const sortData = () => {
    let rawData: any[] = [];
    if (charactersSets && charactersSets.character_sets !== undefined) {
      rawData = [...charactersSets.character_sets];
    }
    if (
      selectedFilterOption === 'Recent' ||
      selectedFilterOption === 'Oldest'
    ) {
      console.log(rawData);
      return rawData;
    }
    const newData = rawData?.sort((a: any, b: any) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (selectedFilterOption === 'A-Z') {
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      }
      if (selectedFilterOption === 'Z-A') {
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }
      }
      return 0;
    });
    return newData;
  };

  // State to track the value of the search box
  const [searchBoxValue, setSearchBoxValue] = React.useState('');

  // Function to handle changes in the search box value
  const handleSearchBoxChange = (value: any) => {
    setSearchBoxValue(value);
  };

  const handleEditCharacterSet = (set: any | Character_Set | null) => {
    appStore.setState({
      selectedCharacterSet: set,
      characterSetMood: 'edit',
    });
    setOpenCreateCharacterSetModal(true);
  };

  const handleCollapseToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const renderSideBarToggle = () => (
    <Button
      sx={{
        position: 'absolute',
        top: '120px',
        right: 0,
        transform: 'translateY(-50%)',
        zIndex: 3,
      }}
      type="button"
      onClick={handleCollapseToggle}
    >
      {!isCollapsed ? <ArrowLeft /> : <ArrowRight />}
    </Button>
  );

  React.useEffect(() => {
    console.log(charactersSets?.character_sets);
  }, [charactersSets]);

  return (
    <>
      {CharacterHeader(theme)}

      {
        // RenderCharacterPacks(isExpanded)
      }
      {renderSideBarToggle()}
      {isCollapsed && <RealmSlider currentRealmId="" />}
      {/* Character Sets */}
      <Box
        sx={{
          px: isMobileScreen ? '25px' : '50px',
          mt: isMobileScreen ? '20px' : '60px',
        }}
      >
        {/* Characters in Realm name and Buttons */}
        <CharacterSetHeader
          openCreateCharacterSetModal={() =>
            setOpenCreateCharacterSetModal(true)
          }
          openAICharacterGenModal={() => setOpenAICharacterGenModal(true)}
        />
        {/* Search and View Toggle */}
        <ReactiveBase
          app="characters_index"
          url={process.env.REACT_APP_MONGO_ATLAS_SEARCH_URL as string}
          transformRequest={(request: any) => ({
            ...request,
            headers: {
              ...request.headers,
              Authorization: `Bearer ${localStorage.getItem('realmToken')}`,
            },
          })}
          // @ts-ignore
          mongodb={{
            collection: 'characters',
            db: 'realm_worlds',
          }}
          analytics={false}
          credentials="none"
        >
          <Box
            sx={{
              display: 'flex',
              gap: '14px',
              alignItems: 'center',
              justifyContent: 'space-between',
              px: isTabletScreen ? '0' : '64px',
              mt: '30px',
              // flexDirection: isTabletScreen ? 'column' : 'row',
            }}
          >
            {/* Search Area */}
            <Stack gap="30px" alignItems="center" direction="row">
              <SearchBox
                componentId="mycharacterpacks-searchbox"
                iconPosition="right"
                dataField={['name']}
                includeFields={['name', 'character_set']}
                autosuggest={false}
                AIConfig={{}}
                AIUIConfig={{}}
                type="search"
                compoundClause="must"
                enableIndexSuggestions={false}
                enterButton={false}
                className="my_custom_searchbox cp"
                // Set the defaultValue based on the searchBoxValue
                defaultValue={searchBoxValue}
                // Set the onValueChange prop to update the searchBoxValue
                onValueChange={handleSearchBoxChange}
              />
              <Button
                sx={{
                  background: '#41521F',
                  padding: isMobileScreen ? '8px' : '10px',
                  boxSizing: 'border-box',
                  color: '#ffffffcc',
                  minWidth: '30px',
                  '&:hover': {
                    background: '#41521F',
                  },
                  width: 'fit-content',
                }}
                onClick={() => setShowFilter(!showFilter)}
              >
                <FilterImg src={FilterIcon} />
              </Button>
              <Box
                width={isTabletScreen ? '100px' : '200px'}
                zIndex={showFilter ? 20 : -1}
                ml={showFilter ? 0 : '-20px'}
                sx={{ transition: '.3s ease-in-out' }}
                position={isTabletScreen ? 'absolute' : 'static'}
              >
                <FixedFilter
                  options={optionsGeneral}
                  setSelectedFilterOption={(option) => {
                    setSelectedFilterOption(option as GeneralFilterString);
                  }}
                  disableFirstOption={false}
                  forGeneral
                />
              </Box>
            </Stack>
            {/* Toggle view area */}
            <Box
              sx={{
                display: 'flex',
                gap: isMobileScreen ? '10px' : '24px',
                // paddingTop: '40px',
              }}
            >
              <button
                // variant="contained"
                type="button"
                style={{
                  height: isMobileScreen ? '100%' : '50px',
                  maxWidth: '50px',
                  paddingInline: 0,
                  outline: 'none',
                  border: 'none',
                  background: viewType === 'detail' ? '#7A3900' : '#41521F',
                  boxSizing: 'border-box',
                  padding: isMobileScreen ? '8px' : '10px',
                  display: 'block',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  // '&:hover': {
                  //   background: '#41521F',
                  // },
                }}
                onClick={() =>
                  appStore.setState({
                    viewType: 'detail',
                  })
                }
              >
                <img
                  src={DetailedViewIcon}
                  alt="detailed-view"
                  width={isMobileScreen ? 20 : 30}
                  style={{
                    filter:
                      viewType === 'detail'
                        ? 'drop-shadow(0 0 2px #ffffffb3) drop-shadow(0 0 5px #ffffffb3)'
                        : 'none',
                  }}
                />
              </button>
              <button
                // variant="contained"
                type="button"
                style={{
                  height: isMobileScreen ? '100%' : '50px',
                  maxWidth: '50px',
                  paddingInline: 0,
                  outline: 'none',
                  border: 'none',
                  background: viewType === 'grid' ? '#7A3900' : '#41521F',
                  boxSizing: 'border-box',
                  padding: isMobileScreen ? '8px' : '10px',
                  display: 'block',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  // '&:hover': {
                  //   background: '#7A3900',
                  // },
                }}
                onClick={() =>
                  appStore.setState({
                    viewType: 'grid',
                  })
                }
              >
                <img
                  src={GridViewIcon}
                  alt="grid-view"
                  width={isMobileScreen ? 20 : 30}
                  style={{
                    filter:
                      viewType === 'grid'
                        ? 'drop-shadow(0 0 2px #ffffffb3) drop-shadow(0 0 5px #ffffffb3)'
                        : 'none',
                  }}
                />
              </button>
            </Box>
          </Box>
          {/* Content Area */}
          <ReactiveList
            componentId="mycharacterpacks-results"
            dataField="_score"
            size={1000}
            // pagination
            innerClass={{
              pagination: 'list_pagination',
              resultStats: 'list_resultStats',
              resultsInfo: 'list_resultsInfo hide',
            }}
            className="dashboard_reactive_list cp"
            compoundClause="must"
            react={{
              and: ['mycharacterpacks-searchbox'],
            }}
            onError={(err) => console.log(err)}
            sortOptions={[
              {
                dataField: 'updated_at',
                sortBy: 'desc',
                label: 'Recent',
              },
              {
                dataField: 'updated_at',
                sortBy: 'asc',
                label: 'Oldest',
              },
              {
                dataField: 'name',
                sortBy: 'asc',
                label: 'Name - A-Z',
              },
              {
                dataField: 'name',
                sortBy: 'desc',
                label: 'Name - Z-A',
              },
            ]}
            showResultStats={false}
            render={({ data }: any) => (
              <>
                {/* {console.log('placeholder: ', data)} */}
                {/* {handleOrganizeData(data)} */}
                <Stack py="10px" gap="20px">
                  {searchBoxValue === '' &&
                    sortData() !== undefined &&
                    sortData()
                      .filter(
                        (characterSets) =>
                          characterSets?.characters?.length === 0,
                      )
                      .map(
                        (characterSet) =>
                          characterSet?.characters && (
                            <CharacterCatalogCPExpansion
                              key={characterSet._id}
                              setID={characterSet._id as string}
                              title={characterSet?.name}
                              backgroundUrl={characterSet?.background_url}
                              characters={characterSet?.characters}
                              editCharacterSet={() =>
                                handleEditCharacterSet(characterSet)
                              }
                            />
                          ),
                      )}
                  {sortData() !== undefined &&
                    sortData()
                      .filter((characterSet) =>
                        characterSet?.characters?.some((character: any) =>
                          data.some(
                            (chars: any) => chars._id === character?._id,
                          ),
                        ),
                      )
                      .map((characterSet) =>
                        characterSet &&
                        characterSet.characters &&
                        characterSet.characters.length > 0 ? (
                          <CharacterCatalogCPExpansion
                            key={characterSet._id}
                            setID={characterSet._id as string}
                            title={characterSet?.name}
                            backgroundUrl={characterSet?.background_url}
                            characters={
                              searchBoxValue === ''
                                ? characterSet?.characters
                                : characterSet?.characters.filter(
                                    (character: any) =>
                                      data
                                        .map((sets: any) => sets._id)
                                        .includes(character?._id),
                                  )
                            }
                            editCharacterSet={() =>
                              handleEditCharacterSet(characterSet)
                            }
                          />
                        ) : null,
                      )}
                </Stack>
              </>
            )}
          />
        </ReactiveBase>
      </Box>

      {/* Modals */}
      <CreateCharacterSetModal
        open={openCreateCharacterSetModal}
        onClose={() => {}}
        onConfirm={() => {}}
        setOpen={setOpenCreateCharacterSetModal}
        refetch={refetchCharacterSets}
      />
      <AICharacterGenWelcomeModal
        open={openAICharacterGenModal}
        onClose={() => {}}
        onConfirm={() => {}}
        setOpen={setOpenAICharacterGenModal}
        refetch={refetchCharacterSets}
      />
      <CharacterSummaryModal
        open={openCharacterSummaryModal}
        onClose={() => {}}
        onConfirm={() => {}}
        setOpen={setOpenCharacterSummaryModal}
      />
    </>
  );
}

export default CharacterCatalog;
