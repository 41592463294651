/* eslint-disable no-plusplus */
function generateCharacterAbilities(): number[] {
  const numbers: number[] = [];

  for (let i = 0; i < 6; i++) {
    const random = Math.floor(Math.random() * 16) + 3;
    numbers.push(random);
  }

  return numbers;
}

export default generateCharacterAbilities;
