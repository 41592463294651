import request, { Variables } from 'graphql-request';
import { type TypedDocumentNode } from '@graphql-typed-document-node/core';

async function fetchGraphQL<TResult, TVariables>(
  document: TypedDocumentNode<TResult, TVariables>,
  variables: Variables | undefined, // Update the type of the variables parameter
  token?: string,
): Promise<TResult> {
  const response = await request(
    process.env.REACT_APP_GRAPHQL_URL ?? 'NO URL SPECIFIED',
    document,
    variables,
    token ? { Authorization: `Bearer ${token}` } : {},
  );
  return response;
}

export default fetchGraphQL;
