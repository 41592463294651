/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable nonblock-statement-body-position */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Button, Stack, Typography, useMediaQuery } from '@mui/material';
import { Stage } from 'konva/lib/Stage';
import CharacterSetModalPreview from '../realms/Character/CharacterSetModalPreview';
import CharacterLibraryView from '../realms/Character/CharacterLibraryView';

/**
 * Represents the props for the `CreateCharacterSetModal` component.
 *
 * @interface
 * @property {boolean} open - Controls the visibility of the modal.
 * @property {() => void} onClose - Callback function when the modal is closed.
 * @property {() => void} [setOpen] - Callback function to set the modal visibility.
 * @property {() => void} onConfirm - Callback function when the user confirms the action.
 */
type RWModalProps = {
  open: boolean;
  onClose: () => void;
  setOpen?: (v: boolean) => void;
  onConfirm: (url: string | null) => void;
} & React.PropsWithChildren;

type ViewOption = 'general' | 'library';

export default function ChooseFromLibraryModal({
  open,
  onClose,
  setOpen,
  onConfirm,
}: RWModalProps) {
  const [currentStage, setCurrentStage] = React.useState<Stage | null>(null);
  const [previewUrl, setPreviewUrl] = React.useState<string | null>(null);
  const [previewBackground, setPreviewBackground] = React.useState('');
  const [urlToRender, setUrlToRender] = React.useState<string | null>(null);
  const [canClose, setCanClose] = React.useState(false);

  const handleClose = () => {
    onClose?.();
    setOpen?.(false);
    setPreviewUrl(null);
    setPreviewBackground('');
  };

  /**
   * Closes the library view.
   *
   * @returns {void}
   */
  const handleCloseLibraryView = () => {
    // setShowPreview(true);
    // setView('general');
    console.log(currentStage);
  };

  /**
   * Converts a URL to a Blob and sets the background URL for preview.
   *
   * @param {string} url - The URL to convert.
   * @param {React.Dispatch<React.SetStateAction<string | null>>}
   * setUrl - The state setter function.
   * @returns {Promise<void>}
   */
  async function urlToBlob(url: string, setUrl: any) {
    try {
      // Fetch the data from the URL
      const response = await fetch(url);
      const blober = await fetch(url).then((r) => r.blob());
      const urlBlob = URL.createObjectURL(blober);
      // Check if the request was successful
      if (!response.ok) {
        setCanClose(false);
        throw new Error(`Failed to fetch data from ${url}`);
      }
      setUrlToRender(urlBlob);
      setCanClose(true);
      return setUrl(`url(${urlBlob})`);
    } catch (error) {
      console.error(error);
      // Handle the error appropriately
      return null;
    }
  }

  /**
   * Handles the click on a library image and sets it as the preview image.
   *
   * @param {string} link - The URL of the library image.
   * @returns {void}
   */
  const handleLibraryImageClick = (link: string) => {
    setCanClose(false);
    setPreviewUrl(link);
    urlToBlob(link, setPreviewBackground);
  };

  React.useEffect(() => {
    if (previewUrl && previewUrl !== null) {
      setPreviewBackground(`url(${previewUrl})`);
    }
  }, [previewUrl]);

  const isTabletScreen = useMediaQuery('(max-width:865px)');
  const isMobileScreen = useMediaQuery('(max-width:530px)');

  return (
    <div>
      <Modal
        open={open}
        onClose={() => handleClose()}
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              backdropFilter: 'blur(4px)',
            },
          },
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ zIndex: 999 }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '30%',
            left: '50%',
            transform: 'translate(-50%, -30%)',
            width: isTabletScreen ? '90%' : '600px',
            outline: 'none',
            overflow: 'hidden',
          }}
        >
          {/* heading and close button */}
          <Box
            sx={{
              width: '100%',
              borderRadius: '20px 20px 0px 0px',
              background: '#41521F',
              padding: '12px 20px',
              boxSizing: 'border-box',
              display: 'flex',
              alignItems: 'center',
              gap: '35px',
            }}
          >
            <button
              type="button"
              onClick={handleClose}
              style={{
                display: 'block',
                background: 'transparent',
                border: 'none',
                outline: 'none',
                filter: 'none',
                cursor: 'pointer',
              }}
              aria-label="close"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 10 10"
                width={10}
              >
                <polygon
                  style={{ fill: '#fff', strokeWidth: 0 }}
                  points="6.03 5 10 8.97 9.49 9.49 8.97 10 5 6.03 1.03 10 .51 9.49 0 8.97 3.97 5 0 1.03 .51 .51 1.03 0 5 3.97 8.97 0 9.49 .51 10 1.03 6.03 5"
                />
              </svg>
            </button>
            <Typography
              sx={{
                color: 'white',
                fontFamily: 'Passero One',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '26px' /* 130% */,
                letterSpacing: '0.46px',
                textTransform: 'uppercase',
              }}
            >
              Choose from my library
            </Typography>
          </Box>
          {/* character set name and thumnbail options */}
          <Box
            sx={{
              width: '100%',
              borderRadius: '0px 0px 20px 20px',
              background: '#FFF',
              padding: isTabletScreen ? '20px' : '45px 65px',
              boxSizing: 'border-box',
              maxHeight: '800px',
            }}
          >
            <CharacterLibraryView
              handleCloseLibraryView={handleCloseLibraryView}
              previewBackground={previewUrl!}
              handleLibraryImageClick={(link: string) =>
                handleLibraryImageClick(link)
              }
              variant="general"
            />
            <Stack direction="row" justifyContent="flex-end">
              <Button
                onClick={() => {
                  onConfirm(urlToRender);
                  console.log(urlToRender);
                  handleClose();
                }}
                sx={{
                  background: '#7A3900',
                  borderRadius: '4px',
                  color: 'white',
                  fontSize: '20px',
                  fontFamily: 'Passero One',
                  fontWeight: '400',
                  textTransform: 'capitalize',
                  paddingInline: '24px',
                  '&:hover': {
                    background: '#7A3900',
                  },
                }}
                disabled={!canClose}
              >
                Apply
              </Button>
            </Stack>
          </Box>
          {/* Preview */}
          {/* <CharacterSetModalPreview
            background={previewBackground}
            handleStage={(stage: Stage) => {
              setCurrentStage(stage);
            }}
            onConfirm={onConfirm}
            variant="general"
          /> */}
        </Box>
      </Modal>
    </div>
  );
}

/**
 * Default props for the `CreateCharacterSetModal` component.
 */
ChooseFromLibraryModal.defaultProps = {
  setOpen: () => {},
};
