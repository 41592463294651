import React, { useContext } from 'react';
import { Tooltip, Zoom } from '@mui/material';
import { ThemeContext } from '../../context/ThemeContext';
import styles from './icons.module.scss';

interface Props {
  onClick: () => void;
  active: boolean;
}

function CharacterSheetIcon({ onClick, active } : Props) {
  const { theme } = useContext(ThemeContext);
  return (
    <Tooltip
      title="Character Sheet"
      arrow
      placement="bottom"
      TransitionComponent={Zoom}
      componentsProps={{
        tooltip: {
          sx: {
            fontFamily: 'Passero One',
            fontWeight: '400px',
            fontSize: '16px',
            padding: '10px',
            bgcolor: '#3c3c3ce6',
            '& .MuiTooltip-arrow': {
              color: '#3c3c3ce6',
            },
            maxWidth: '250px',
          },
        },
      }}
    >
      <button
        onClick={onClick}
        type="button"
        className={`${styles.icon} ${styles.charactersheet_icon} ${styles[theme]} ${active && styles.active}`}
        aria-label="character sheet"
      >
        <svg
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 23.25 25"
        >
          <path className={styles.charactersheet_cls_1} d="m4.32,12.63v-5.33c-.06,0-.11,0-.16,0-.85,0-1.69,0-2.54,0-.7,0-1.27-.53-1.27-1.24-.01-1.22-.02-2.45,0-3.67C.37,1.28,1.14.33,2.2.07c.19-.05.38-.06.58-.06C8.78,0,14.78.01,20.77,0c1.48,0,2.46,1.19,2.47,2.4.01,1.66,0,3.31,0,4.97,0,5.04,0,10.08,0,15.12,0,.67-.2,1.27-.66,1.76-.45.49-1.01.75-1.68.75-2.74,0-5.48,0-8.22-.01-3.43,0-6.86-.02-10.3-.03-.55,0-1.09,0-1.64,0-.47,0-.75-.28-.75-.75,0-.51,0-1.03,0-1.54,0-1.23.85-2.28,2.04-2.51.26-.05.53-.04.79-.04.13,0,.17-.05.18-.17.01-.2-.02-.35-.22-.48-.59-.38-.95-.94-1.11-1.62-.02-.09-.06-.15-.15-.2-.61-.36-.75-1.22-.29-1.77.08-.1.12-.19.11-.31,0-.34.01-.69,0-1.03-.03-.71.32-1.17.92-1.48.55-.28,1.14-.39,1.75-.41.09,0,.18,0,.3,0Zm4.23,11.57c.08,0,.14,0,.19,0,.65,0,1.3,0,1.95,0,3.4,0,6.81,0,10.21,0,.55,0,.98-.24,1.29-.68.23-.32.3-.68.3-1.07,0-4.88,0-9.77,0-14.65,0-1.79,0-3.57,0-5.36,0-.91-.77-1.63-1.66-1.63-5.38.01-10.77,0-16.15,0-.06,0-.11,0-.19,0,.07.1.13.18.18.26.33.49.43,1.04.43,1.63,0,3.28,0,6.55,0,9.83,0,.13.04.17.16.19.42.07.83.21,1.19.44.47.3.73.72.71,1.29-.01.37,0,.75,0,1.12,0,.1.02.18.09.26.49.55.36,1.41-.27,1.8-.08.05-.12.1-.15.2-.17.68-.53,1.24-1.12,1.62-.22.14-.23.31-.21.51,0,.09.05.14.15.13.11,0,.22,0,.33,0,1.2,0,2.17.75,2.48,1.91.11.42.08.85.09,1.27,0,.3,0,.6,0,.92Zm-2.37-8.28c-.53-.08-1.02-.14-1.5-.23-.58-.11-1.13-.32-1.55-.76-.05-.06-.11-.12-.17-.18-.18.16-.36.3-.52.45-.05.05-.09.13-.09.19,0,.49,0,.98,0,1.47,0,.21,0,.42.03.62.1.72.74,1.62,1.71,1.58.29-.01.6,0,.86-.09.62-.23,1.02-.71,1.13-1.35.09-.56.07-1.13.1-1.7Zm-2.22,8.45s.01-.05.01-.07c0-.61,0-1.23,0-1.84,0-.06-.03-.12-.07-.16-.46-.52-.91-1.04-1.37-1.55-.03-.03-.1-.05-.15-.05-.93.02-1.79.89-1.82,1.86-.02.55,0,1.1,0,1.65,0,.12.05.17.17.17.34,0,.68,0,1.02,0,.74,0,1.47,0,2.22,0Zm-.3-3.66c.18.2.38.43.6.67.21-.24.42-.47.59-.67-.09-.27-.17-.51-.24-.75h-.7c-.08.25-.16.48-.24.75Z" />
          <path className={styles.charactersheet_cls_1} d="m13.85,6.08c-2.17,0-4.34,0-6.51,0-.47,0-.72-.31-.72-.72,0-.8,0-1.6,0-2.4,0-.29.11-.52.39-.64.07-.03.16-.04.24-.04,4.4,0,8.79,0,13.19,0,.34,0,.62.26.62.6,0,.86,0,1.72,0,2.59,0,.37-.27.6-.65.6-2.19,0-4.38,0-6.56,0Z" />
          <path className={styles.charactersheet_cls_1} d="m11.56,10.53c0,.7,0,1.4,0,2.1,0,.46-.25.7-.7.7-1.14,0-2.28,0-3.43,0-.47,0-.71-.24-.71-.7,0-1.4,0-2.8,0-4.2,0-.48.24-.71.72-.71,1.15,0,2.29,0,3.44,0,.43,0,.68.24.68.67,0,.71,0,1.42,0,2.13Z" />
          <path className={styles.charactersheet_cls_1} d="m15.31,18.03c-1.81,0-3.62,0-5.43,0-.26,0-.44-.16-.44-.4,0-.24.18-.4.44-.4,1.52,0,3.04,0,4.56,0,2.08,0,4.15,0,6.23,0,.07,0,.14,0,.2.02.19.04.32.23.3.42-.02.22-.18.36-.4.36-1.12,0-2.25,0-3.37,0-.7,0-1.39,0-2.09,0Z" />
          <path className={styles.charactersheet_cls_1} d="m15.31,14.89c1.81,0,3.61,0,5.42,0,.24,0,.38.1.43.29.07.28-.12.51-.41.51-.63,0-1.27,0-1.9,0-2.92,0-5.84,0-8.76,0-.08,0-.15,0-.23,0-.23,0-.41-.18-.41-.41,0-.22.17-.39.41-.39.79,0,1.58,0,2.37,0,1.03,0,2.06,0,3.09,0h0Z" />
          <path className={styles.charactersheet_cls_1} d="m15.31,21.93c1.82,0,3.63,0,5.45,0,.3,0,.48.26.39.53-.06.17-.2.26-.42.26-1.25,0-2.5,0-3.75,0-2.37,0-4.74,0-7.12,0-.21,0-.35-.1-.4-.29-.05-.17,0-.36.16-.44.08-.04.17-.06.26-.06,1.81,0,3.62,0,5.43,0Z" />
          <path className={styles.charactersheet_cls_1} d="m15.31,20.37c-1.8,0-3.6,0-5.4,0-.18,0-.34-.04-.42-.22-.08-.16-.06-.3.05-.43.09-.11.21-.15.35-.15,1.78,0,3.55,0,5.33,0,1.84,0,3.68,0,5.52,0,.25,0,.41.13.44.35.03.25-.14.44-.41.44-.72,0-1.43,0-2.15,0-1.1,0-2.2,0-3.3,0h0Z" />
          <path className={styles.charactersheet_cls_1} d="m17.27,8.65c-1.14,0-2.28,0-3.42,0-.26,0-.41-.1-.46-.29-.07-.26.11-.51.4-.51.27,0,.53,0,.8,0,2.04,0,4.08,0,6.12,0,.32,0,.52.22.45.51-.05.19-.2.3-.44.3-.68,0-1.36,0-2.05,0-.46,0-.93,0-1.39,0Z" />
          <path className={styles.charactersheet_cls_1} d="m17.27,10.99c-1.16,0-2.31,0-3.47,0-.18,0-.31-.07-.38-.23-.07-.15-.04-.29.06-.42.09-.12.22-.15.36-.15,1.38,0,2.77,0,4.15,0,.92,0,1.84,0,2.76,0,.33,0,.53.31.38.59-.08.15-.21.21-.38.21-.98,0-1.97,0-2.95,0-.17,0-.35,0-.52,0Z" />
          <path className={styles.charactersheet_cls_1} d="m17.29,12.54c1.16,0,2.32,0,3.48,0,.21,0,.36.13.39.33.03.19-.07.37-.26.44-.05.02-.11.02-.17.02-2.31,0-4.62,0-6.93,0-.26,0-.44-.16-.44-.4,0-.24.18-.39.44-.39,1.16,0,2.31,0,3.47,0Z" />
        </svg>
      </button>
    </Tooltip>
  );
}

export default CharacterSheetIcon;
