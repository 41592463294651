/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/require-default-props */
/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { Box, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import s from './Sidebar.module.scss';
import RealmsIcon from '../../images/Realm_worlds_logo.png';
import avatar from '../../images/avatar.png';
import shield from '../../images/gold-small.png';
import coin from '../../images/azamel_Coin.png';
import { TabButtonType } from './Settings';

interface SidebarProps {
  selectedTab: TabButtonType;
  setSelectedTab: (button: TabButtonType) => void;
  isCollapsed?: boolean;
  toggleSidebar?: () => void;
}

const Sidebar = ({
  selectedTab,
  setSelectedTab,
  isCollapsed,
  toggleSidebar,
}: SidebarProps) => (
  <div>
    {toggleSidebar && (
      <div className={s.menuButton} onClick={toggleSidebar}>
        {isCollapsed ? <MenuIcon /> : <CloseIcon />}
      </div>
    )}
    <div className={`${s.sidebar} ${isCollapsed ? '' : s.expanded}`}>
      <div className={s.sidebar__box}>
        <img className={s.sidebar__box__logo} src={RealmsIcon} alt="logo" />
        <p className={s.sidebar__box__logoText}>REALM WORLDS</p>
      </div>
      <div className={s.sidebar__options}>
        <div
          className={`${s.sidebar__optionsBox} ${
            selectedTab === 'Account' ? s.sidebar__optionsBoxActive : ''
          }`}
          onClick={() => setSelectedTab('Account')}
        >
          <div className={s.sidebar__optionsBox__container}>
            <img
              className={s.sidebar__optionsBox__image}
              src={avatar}
              alt="avatar"
            />
          </div>
          <p className={s.sidebar__optionsBox__text}>Account</p>
        </div>
        <div
          className={`${s.sidebar__optionsBox} ${
            selectedTab === 'Subscription' ? s.sidebar__optionsBoxActive : ''
          }`}
          onClick={() => setSelectedTab('Subscription')}
        >
          <img
            className={s.sidebar__optionsBox__imageLg}
            src={shield}
            alt="shield"
          />
          <p className={s.sidebar__optionsBox__text}>Subscription</p>
        </div>
        <div
          className={`${s.sidebar__optionsBox} ${
            selectedTab === 'Credits' ? s.sidebar__optionsBoxActive : ''
          }`}
          onClick={() => setSelectedTab('Credits')}
        >
          <img
            className={s.sidebar__optionsBox__imageLg}
            src={coin}
            alt="coin"
          />
          <p className={s.sidebar__optionsBox__text}>Credits</p>
        </div>
        {(process.env.REACT_APP_VERSION || '').includes('dev') && (
          <Box>
            <div
              className={`${s.sidebar__optionsBox} ${
                selectedTab === 'Devtools' ? s.sidebar__optionsBoxActive : ''
              }`}
              onClick={() => setSelectedTab('Devtools')}
            >
              <img
                className={s.sidebar__optionsBox__imageLg}
                src={shield}
                alt="shield"
              />
              <p className={s.sidebar__optionsBox__text}>Dev Tools</p>
            </div>
          </Box>
        )}
      </div>
      <div className={s.sidebar__footer}>
        <p className={s.sidebar__footer__text}>Realm worlds LLC ® 2023</p>
        <p className={s.sidebar__footer__recognition}>
          Icons by{' '}
          <span className={s.sidebar__footer__span}>
            <a className={s.sidebar__footer__link} href="#">
              Icons8
            </a>
          </span>
        </p>
      </div>
    </div>
  </div>
);

export default Sidebar;
