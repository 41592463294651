import React from 'react';
import s from './DashboardHeader.module.scss';
import HeaderTabs from './HeaderTabs';
import HeaderMenu from './HeaderMenu';
import { useAuth } from '../../hooks/use-auth';

/**
 * Header component for the dashboard, consisting of tabs and a menu.
 *
 * @component
 * @example
 * // Example usage of DashboardHeader component
 * <DashboardHeader />
 *
 * uses auth hook and disables Realms if user is not logged in
 *
 * @returns {JSX.Element} React element representing the dashboard header.
 */

const DashboardHeader = () => (
  <div className={s.header}>
    <HeaderTabs
      tabs={[
        { name: 'Realms', path: '/dashboard-view', disabled: !useAuth() },
        { name: 'Community', path: '/community-view', disabled: false },
      ]}
    />
    <HeaderMenu />
  </div>
);

export default DashboardHeader;
