/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-confusing-arrow */
import React, { useState, useContext } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Paper,
  Stack,
  Typography,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  Container,
  useMediaQuery,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CardHeader,
} from '@mui/material';
import {
  ExpandCircleDown,
  Info,
  MapOutlined,
  TheatersOutlined,
  MasksOutlined,
  PictureAsPdfOutlined,
  DownloadForOfflineOutlined,
  FortOutlined,
  ArrowLeft,
  ArrowRight,
  CasinoOutlined,
} from '@mui/icons-material';
import useGraphQL from '../../../hooks/useGraphQL';
import {
  Character_Set,
  GetCharactersSetsDocument,
  GetRealmDocument,
} from '../../../__generated__/graphql';
import { ThemeContext } from '../../../context/ThemeContext';
import BackgroundImage from '../../../images/character-catalog.webp';
import PlusIcon from '../../../images/plus-math.svg';
import KindredIcon from '../../../images/kindred.svg';
import PeopleIcon from '../../../images/people.svg';
import FantasyIcon from '../../../images/fantasy-icon.png';
import CreateCampaignModal from '../../modals/CreateCampaignModal';
import { useAuth } from '../../../hooks/use-auth';
import getRealmCampaigns from './Campaigns.data';
import AICampaignCharacterGenModal from '../../modals/AICampaignCharacterGenModal';
import CharacterSummaryModal from '../../modals/CharacterSummaryModal';
import RealmSlider from '../RealmSlider';
import SideWindow from '../../../ui/DetailView/SideWindow';
import appStore from '../../../store/AppStore';
import fetchGraphQL from '../../../hooks/fetchers/fetchGraphQL';

import { useGenerateNewCharacterText } from '../../../__generated__/realmWorldsApiComponents';
import { CharacterGenerationWorkflowRequest } from '../../../__generated__/realmWorldsApiSchemas';
import './campaigns.module.scss';

type Campaign = ReturnType<typeof getRealmCampaigns>[0];
const actType = getRealmCampaigns()[0].acts[0];
const plotType = actType.plots[0];
const outcomeType = plotType?.outcomes?.[0];
const questType = actType?.quests?.[0];
const encounterType = actType?.encounters?.[0];

/*
const setSelectedCampaign = (campaign: Campaign | null): void => {
  appStore.setState({
    selectedCampaign: campaign,
  });
};
*/

const CampaignsHeader = (currentCampaign: Campaign | null) => (
  <Box
    sx={{
      backgroundImage: `url(${
        // eslint-disable-next-line react/destructuring-assignment
        currentCampaign ? currentCampaign.image : BackgroundImage
      })`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      pt: 15,
      width: '100vw',
      height: '270px',
      position: 'relative',
      boxSizing: 'border-box',
    }}
  >
    <Typography sx={{
      color: '#FFF',
      textAlign: 'center',
      fontFamily: 'Passero One',
      fontSize: '50px',
      fontStyle: 'normal',
      textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      fontWeight: 400,
      lineHeight: 'normal',
      paddingBottom: 5,
      zIndex: 3,
    }}
    >
      {currentCampaign?.name}
      &nbsp;Campaign
    </Typography>
  </Box>
);

// process id param
function CampaignDetail() {
  try {
    const auth = useAuth();
    const { theme } = useContext(ThemeContext);
    const onMobile = useMediaQuery('(max-width: 600px)');
    const onXSMMobile = useMediaQuery('(max-width: 400px)');
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [openPlotEdit, setOpenPlotEdit] = useState(false);
    const [openActEdit, setOpenActEdit] = useState(false);
    const [openOutcomeEdit, setOpenOutcomeEdit] = useState(false);
    const [openQuestEdit, setOpenQuestEdit] = useState(false);
    const [openEncounterEdit, setOpenEncounterEdit] = useState(false);
    const [openCreateCampaign, setOpenCreateCampaign] = useState(false);
    const [openCreateCampaignModal, setOpenCreateCampaignModal] = React.useState(false);
    const [openCreateLocation, setOpenCreateLocation] = useState(false);
    const [openCreateCharacter, setOpenCreateCharacter] = useState(false);
    const [openCreateCharacterSetModal, setOpenCreateCharacterSetModal] = React.useState(false);
    const [openCreateCampaignAct, setOpenCreateCampaignAct] = useState(false);
    const [openCreateQuest, setOpenCreateQuest] = useState(false);
    const [openCreateEncounter, setOpenCreateEncounter] = useState(false);

    const [currentCampaign, setCurrentCampaign] = useState<Campaign | null>(null);

    const [aiGenIsLoading, setAiGenIsLoading] = React.useState(false);
    const createNewCharacterTextMutate = useGenerateNewCharacterText();
    // const [openCreateCharacterSetModal, setOpenCreateCharacterSetModal] = React.useState(false);
    const openAICharacterGenModal: boolean = appStore((state) => state.openAICharacterGenModal);
    const setOpenAICharacterGenModal = (val: boolean) => {
      appStore.setState({
        openAICharacterGenModal: val,
      });
    };
    const { data: charactersSets, refetch: refetchCharacterSets } = useGraphQL(
      GetCharactersSetsDocument,
      {
        query: {
          owner: {
            _id: auth?.user?.uid,
          },
        },
      },
    );

    const openCharacterSummaryModal:
    boolean = appStore(
      (state) => state.openCharacterSummaryModal,
    );
    const setOpenCharacterSummaryModal = (condition: boolean) => appStore.setState({
      openCharacterSummaryModal: condition,
    });

    React.useEffect(() => {
    // get id from url
      const url = window.location.href;
      const id = url.substring(url.lastIndexOf('/') + 1);

      // retrieve current campaign
      const campaign = getRealmCampaigns().find((c) => c.id === id);
      console.log('CAMPAIGN: ', campaign);

      if (campaign) {
        setCurrentCampaign(campaign);
      }
    }, []);

    const handleCollapseToggle = () => {
      setIsCollapsed(!isCollapsed);
    };

    const renderCreateCharacterButton = () => (
      <Button
        type="button"
        className={`headingButton ${theme === 'light' ? 'light' : 'dark'}`}
        sx={{
          zIndex: 3,
          color: '#FFF',
          background: '#41521F',
          marginRight: '10px',
          ':hover': {
            background: '$realm-green',
          },
          margin: '10px',
          padding: '10px',
          height: '32px',
          alignContent: 'center',
        }}
        onClick={() => setOpenAICharacterGenModal(!openAICharacterGenModal)}
      >
        <div
          style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
        >
          <img src={PlusIcon} style={{ width: '8px', height: 'auto' }} alt="add" />
          <img src={KindredIcon} style={{ width: '25px', height: 'auto' }} alt="kindred" />
          <Typography className={`headingButtonTitle ${theme === 'light' ? 'light' : 'dark'}`}>Create New</Typography>
        </div>
      </Button>
    );

    const renderCreateCharacterAIButton = () => (
      <Button
        type="button"
        className={`headingButton ai-gen ${theme === 'light' ? 'light' : 'dark'}`}
        onClick={() => setOpenAICharacterGenModal(!openAICharacterGenModal)}
        style={{
          margin: '10px',
          padding: '10px',
          height: '32px',
          alignContent: 'center',
        }}
      >
        <div
          style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
        >
          <img src={PlusIcon} style={{ width: '8px', height: 'auto' }} alt="add" />
          <img src={FantasyIcon} style={{ width: '25px', height: 'auto' }} alt="kindred" />
          <Typography className={`headingButtonTitle ${theme === 'light' ? 'light' : 'dark'}`}>AI Gen</Typography>
        </div>
      </Button>
    );

    const renderCreateCampaignButton = () => (
      <Button
        variant="contained"
        type="button"
        sx={{
          transform: 'translateY(-50%)',
          zIndex: 3,
          marginRight: '10px',
          ':hover': {
            background: '$realm-green',
          },
        }}
        onClick={() => setOpenCreateCampaign(!openCreateCampaign)}
      >
        Create New Campaign
      </Button>
    );

    const renderCreateCampaignActButton = () => (
      <Button
        variant="contained"
        type="button"
        sx={{
          transform: 'translateY(-50%)',
          zIndex: 3,
          marginRight: '10px',
          ':hover': {
            background: '$realm-green',
          },
        }}
        onClick={() => setOpenCreateCampaignAct(!openCreateCampaignAct)}
      >
        Create New Campaign
      </Button>
    );

    const renderCreateCampaignAIButton = () => (
      <Button
        type="button"
        className={`headingButton ai-gen ${theme === 'light' ? 'light' : 'dark'}`}
        onClick={() => setOpenCreateCampaignModal(!openCreateCampaignModal)}
      >
        <div
          style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
        >
          <img src={PlusIcon} style={{ width: '8px', height: 'auto' }} alt="add" />
          <img src={FantasyIcon} style={{ width: '25px', height: 'auto' }} alt="kindred" />
          <Typography className={`headingButtonTitle ${theme === 'light' ? 'light' : 'dark'}`}>AI Generate Character</Typography>
        </div>
      </Button>
    );

    const renderCreateLocationButton = () => (
      <Button
        type="button"
        className={`headingButton ${theme === 'light' ? 'light' : 'dark'}`}
        sx={{
          zIndex: 3,
          color: '#FFF',
          background: '#41521F',
          marginRight: '10px',
          ':hover': {
            background: '$realm-green',
          },
          margin: '10px',
          padding: '10px',
          height: '32px',
          alignContent: 'center',
        }}
        onClick={() => setOpenCreateLocation(!openCreateLocation)}
      >
        <div
          style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
        >
          <img src={PlusIcon} style={{ width: '8px', height: 'auto' }} alt="add" />
          <MapOutlined />
          <Typography
            className={`headingButtonTitle ${theme === 'light' ? 'light' : 'dark'}`}
          >
            Create New Location
          </Typography>
        </div>
      </Button>
    );
    const renderCreateRandomEncounter = () => (
      <Button
        type="button"
        className={`headingButton ${theme === 'light' ? 'light' : 'dark'}`}
        sx={{
          zIndex: 3,
          color: '#FFF',
          background: '#41521F',
          marginRight: '10px',
          ':hover': {
            background: '$realm-green',
          },
          margin: '10px',
          padding: '10px',
          height: '32px',
          alignContent: 'center',
        }}
        onClick={() => setOpenCreateLocation(!openCreateLocation)}
      >
        <div
          style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
        >
          <img src={PlusIcon} style={{ width: '8px', height: 'auto' }} alt="add" />
          <CasinoOutlined
            sx={{
              translateX: '15px',
            }}
          />
          <CasinoOutlined
            sx={{
              transform: 'rotate(-15deg)',
            }}
          />
          <Typography
            className={`headingButtonTitle ${theme === 'light' ? 'light' : 'dark'}`}
          >
            Create Random Encounter
          </Typography>
        </div>
      </Button>
    );
    const renderExportCampaignButton = () => (
      <Button
        type="button"
        className={`headingButton ${theme === 'light' ? 'light' : 'dark'}`}
        sx={{
          zIndex: 10,
          color: '#FFF',
          background: '#FF6746',
          marginRight: '10px',
          ':hover': {
            background: '$realm-green',
          },
          margin: '10px',
          padding: '10px',
          height: '32px',
          alignContent: 'center',
        }}
        onClick={() => setOpenCreateLocation(!openCreateLocation)}
      >
        <div
          style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
        >
          <DownloadForOfflineOutlined />
          <PictureAsPdfOutlined />
          <Typography
            className={`headingButtonTitle ${theme === 'light' ? 'light' : 'dark'}`}
          >
            Export Campaign
          </Typography>
        </div>
      </Button>
    );
    const renderLaunchCampaignButton = () => (
      <Button
        type="button"
        disabled
        className={`headingButton ${theme === 'light' ? 'light' : 'dark'}`}
        sx={{
          zIndex: 10,
          color: (true ? 'slategray' : '#FF6746'),
          background: (true ? 'slategray' : '#FF6746'),
          marginRight: '10px',
          ':hover': {
            background: '$realm-green',
          },
          margin: '10px',
          padding: '10px',
          height: '32px',
          alignContent: 'center',
        }}
        onClick={() => setOpenCreateLocation(!openCreateLocation)}
      >
        <div
          style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
        >
          <FortOutlined />
          <Typography
            className={`headingButtonTitle ${theme === 'light' ? 'light' : 'dark'}`}
          >
            Launch Campaign - BETA
          </Typography>
        </div>
      </Button>
    );
    const renderAddActButton = () => (
      <Button
        type="button"
        className={`headingButton ${theme === 'light' ? 'light' : 'dark'}`}
        sx={{
          zIndex: 3,
          color: '#FFF',
          background: '#41521F',
          marginRight: '10px',
          ':hover': {
            background: '$realm-green',
          },
          margin: '10px',
          padding: '10px',
          height: '32px',
          width: 'fit-content',
        }}
        onClick={() => setOpenCreateLocation(!openCreateLocation)}
      >
        <div
          style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
        >
          <img src={PlusIcon} style={{ width: '8px', height: 'auto' }} alt="add" />
          <TheatersOutlined />
          <Typography
            className={`headingButtonTitle ${theme === 'light' ? 'light' : 'dark'}`}
          >
            Create New Act
          </Typography>
        </div>
      </Button>
    );

    const renderPlotEdit = () => (
      openPlotEdit ? (
        <Card
          sx={{
            background: 'rgba(224, 224, 224, 0.4)',
            borderRadius: '0px 5px 5px 0px',
            boxShadow: '2px 2px 6px 3px rgba(0, 0, 0, 0.3)',
            marginBottom: 2,
          }}
        >
          <CardContent>
            <Stack spacing={2}>
              <Typography
                sx={{
                  fontSize: '20px',
                  fontWeight: 'bold',
                }}
              >
                New Plot
                &nbsp;act
              </Typography>
              <Typography
                sx={{
                  fontSize: '14px',
                }}
              >
                Plotline
              </Typography>
              <Button
                type="button"
                onClick={() => {
                  setOpenOutcomeEdit(true);
                }}
                className={`headingButton ${theme === 'light' ? 'light' : 'dark'}`}
                sx={{
                  zIndex: 3,
                  color: '#FFF',
                  background: '#41521F',
                  marginRight: '10px',
                  ':hover': {
                    background: '$realm-green',
                  },
                }}
              >
                Outcome &nbsp; +
              </Button>
            </Stack>
          </CardContent>
        </Card>
      ) : null
    );

    const renderPlot = (_plot: typeof plotType) => (
      <Card
        sx={{
          background: 'rgba(224, 224, 224, 0.4)',
          borderRadius: '0px 5px 5px 0px',
          boxShadow: '2px 2px 6px 3px rgba(0, 0, 0, 0.3)',
          marginBottom: 2,
        }}
      >
        <CardContent>
          <Stack spacing={2}>
            <Typography
              sx={{
                fontSize: '20px',
                fontWeight: 'bold',
              }}
            >
              {_plot.name}
          &nbsp; plot
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
              }}
            >
              {_plot.plotline}
            </Typography>
            {_plot.outcomes ? (
              <Stack direction="row" spacing={1}>
                {_plot.outcomes.map((_outcome) => (
                  <Card
                    sx={{
                      background: 'beige',
                      borderRadius: '0px 5px 5px 0px',
                      boxShadow: '2px 2px 6px 3px rgba(0, 0, 0, 0.3)',
                      marginBottom: 2,
                      transition: 'transform 0.2s',
                      '&:hover': {
                        transform: 'scale(1.05)',
                      },
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="140"
                      image={_outcome.image}
                      alt={_outcome.name}
                    />
                    <CardContent>
                      <Stack spacing={2}>
                        <Typography
                          sx={{
                            fontSize: '20px',
                            fontWeight: 'bold',
                          }}
                        >
                          {_outcome.name}
                &nbsp;outcome
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '14px',
                          }}
                        >
                          {_outcome.description}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '14px',
                          }}
                        >
                          {_outcome.result}
                        </Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                ))}
              </Stack>
            ) : null}
          </Stack>
        </CardContent>
      </Card>
    );

    const renderPlots = (plots: typeof actType.plots) => (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandCircleDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{
            cursor: 'pointer',
            background: 'rgba(255, 255, 255, 0.4)',
            height: '50px',
          }}
        >
          <Typography
            sx={{
              font: 'Passero One',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '26px',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              color: '#000',
              padding: '10px',
              margin: '0px',
            }}
          >
            Plots (
            {plots?.length}
            )
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              setOpenPlotEdit(!openPlotEdit);
            }}
            sx={{
              marginLeft: 'auto',
              background: '#41521F',
              color: '#FFF',
              margin: '10px',
              padding: '10px',
              height: '30px',
              alignContent: 'center',
            }}
          >
            Plot &nbsp;+
          </Button>
        </AccordionSummary>
        <AccordionDetails
          style={{
            cursor: 'pointer',
            background: 'rgba(255, 255, 255, 0.4)',
          }}
        >
          { renderPlotEdit() }
          <Stack spacing={2}>
            {plots.map((_plot) => (
              renderPlot(_plot)
            ))}
          </Stack>
        </AccordionDetails>
      </Accordion>
    );

    const renderEncounters = (encounters : typeof actType.encounters) => (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandCircleDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{
            cursor: 'pointer',
            background: 'rgba(255, 255, 255, 0.4)',
            height: '50px',
          }}
        >
          <Typography
            sx={{
              font: 'Passero One',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '26px',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              color: '#000',
              padding: '10px',
              margin: '0px',
            }}
          >
            Encounters (
            {encounters?.length}
            )
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              setOpenCreateEncounter(!openCreateEncounter);
            }}
            sx={{
              marginLeft: 'auto',
              background: '#41521F',
              color: '#FFF',
              margin: '10px',
              padding: '10px',
              height: '30px',
              alignContent: 'right',
              alignItems: 'right',
            }}
          >
            Encounter &nbsp;+
          </Button>
        </AccordionSummary>
        <AccordionDetails
          style={{
            cursor: 'pointer',
            background: 'rgba(255, 255, 255, 0.4)',
          }}
        >
          <Stack spacing={2}>
            {encounters?.map((_encounter) => (
              <Card
                sx={{
                  background: 'rgba(224, 224, 224, 0.4)',
                  borderRadius: '0px 5px 5px 0px',
                  boxShadow: '2px 2px 6px 3px rgba(0, 0, 0, 0.3)',
                  marginBottom: 2,
                }}
              >
                <CardMedia
                  component="img"
                  image={_encounter.environment.image}
                  alt={_encounter.name}
                  style={{
                    width: '256px',
                  }}
                />
                <CardContent>
                  <Stack spacing={2}>
                    <Typography
                      sx={{
                        fontSize: '20px',
                        fontWeight: 'bold',
                      }}
                    >
                      {_encounter.name}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '14px',
                      }}
                    >
                      {_encounter.description}
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            ))}
          </Stack>
        </AccordionDetails>
      </Accordion>
    );
    const renderQuests = (quests : typeof actType.quests) => (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandCircleDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{
            cursor: 'pointer',
            background: 'rgba(255, 255, 255, 0.4)',
            height: '50px',
          }}
        >
          <Typography
            sx={{
              font: 'Passero One',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '26px',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              color: '#000',
              padding: '10px',
              margin: '0px',
            }}
          >
            Quests (
            {quests?.length}
            )
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              setOpenCreateQuest(!openCreateQuest);
            }}
            sx={{
              marginLeft: 'auto',
              background: '#41521F',
              color: '#FFF',
              margin: '10px',
              padding: '10px',
              height: '30px',
              alignContent: 'right',
              alignItems: 'right',
            }}
          >
            Quest &nbsp;+
          </Button>
        </AccordionSummary>
        <AccordionDetails
          style={{
            cursor: 'pointer',
            background: 'rgba(255, 255, 255, 0.4)',
          }}
        >
          <Stack spacing={2}>
            {quests?.map((_quest) => (
              <Card
                sx={{
                  background: 'rgba(224, 224, 224, 0.4)',
                  borderRadius: '0px 5px 5px 0px',
                  boxShadow: '2px 2px 6px 3px rgba(0, 0, 0, 0.3)',
                  marginBottom: 2,
                }}
              >
                <CardMedia
                  component="img"
                  image={_quest.image}
                  alt={_quest.name}
                  style={{
                    width: '256px',
                  }}
                />
                <CardContent>
                  <CharacterSummaryModal
                    open={openCharacterSummaryModal}
                    onClose={() => {}}
                    onConfirm={() => {}}
                    setOpen={setOpenCharacterSummaryModal}
                  />
                  <AICampaignCharacterGenModal
                    open={openAICharacterGenModal}
                    onClose={() => {}}
                    onConfirm={() => {}}
                    setOpen={setOpenAICharacterGenModal}
                    refetch={() => {}}
                  />
                  <Stack spacing={2}>
                    <Typography
                      sx={{
                        fontSize: '20px',
                        fontWeight: 'bold',
                      }}
                    >
                      {_quest.name}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '14px',
                      }}
                    >
                      {_quest.description}
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            ))}
          </Stack>
        </AccordionDetails>
      </Accordion>
    );
    const renderCharacters = (characters: typeof actType.characters) => (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandCircleDown />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{
            cursor: 'pointer',
            background: 'rgba(255, 255, 255, 0.4)',
            height: '50px',
          }}
        >
          <Typography
            sx={{
              font: 'Passero One',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '26px',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              color: '#000',
              padding: '10px',
              margin: '0px',
            }}
          >
            Characters (
            {characters?.length}
            )
          </Typography>
          { renderCreateCharacterButton() }
          { renderCreateCharacterAIButton() }
        </AccordionSummary>
        <AccordionDetails
          style={{
            cursor: 'pointer',
            background: 'rgba(255, 255, 255, 0.4)',
          }}
        >
          <Stack spacing={2}>
            {characters?.map((character) => (
              <Card
                sx={{
                  background: 'rgba(224, 224, 224, 0.4)',
                  borderRadius: '0px 5px 5px 0px',
                  boxShadow: '2px 2px 6px 3px rgba(0, 0, 0, 0.3)',
                  marginBottom: 2,
                }}
              >
                <CardMedia
                  component="img"
                  image={character.image}
                  alt={character.name}
                  style={{
                    width: '256px',
                  }}
                />
                <CardContent>
                  <AICampaignCharacterGenModal
                    open={openAICharacterGenModal}
                    onClose={() => {}}
                    onConfirm={() => {}}
                    setOpen={setOpenAICharacterGenModal}
                    refetch={() => {}}
                  />
                  <Stack spacing={2}>
                    <Typography
                      sx={{
                        fontSize: '20px',
                        fontWeight: 'bold',
                      }}
                    >
                      {character.name}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '14px',
                      }}
                    >
                      {character.description}
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            ))}
          </Stack>
        </AccordionDetails>
      </Accordion>
    );

    const renderLocations = () => (
      currentCampaign?.locations ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Typography
            sx={{
              font: 'Passero One',
              fontSize: '12px',
              fontWeight: 'bold',
              color: '#000',
              top: '-5px',
              left: '2px',
              position: 'relative',
              zIndex: 3,
            }}
          >
            Locations / Maps
          </Typography>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              padding: '10px',
              borderRadius: '0px 5px 5px 0px',
              boxShadow: '2px 2px 6px 3px rgba(0, 0, 0, 0.3)',
            }}
          >
            {currentCampaign?.locations.map((loc) => (
              <Card
                sx={{
                  background: 'rgba(224, 224, 224, 0.4)',
                  borderRadius: '0px 2px 2px 0px',
                  marginBottom: 2,
                }}
              >
                <CardMedia
                  component="img"
                  height="140"
                  image={loc.image}
                  alt={loc.name}
                />
                <CardContent>
                  <Stack spacing={2}>
                    <Typography
                      sx={{
                        fontSize: '20px',
                        fontWeight: 'bold',
                      }}
                    >
                      {loc.name}
              &nbsp;location
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '14px',
                      }}
                    >
                      {loc.description}
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            ))}
          </Stack>
        </div>
      ) : null
    );

    const renderSideBarToggle = () => (
      <Button
        sx={{
          position: 'absolute',
          top: '120px',
          right: 0,
          transform: 'translateY(-50%)',
          zIndex: 3,
        }}
        type="button"
        onClick={handleCollapseToggle}
      >
        {!isCollapsed ? <ArrowLeft /> : <ArrowRight />}
      </Button>
    );

    return (
      auth?.user ? (
        <Box
          sx={{
            height: '100%',
            width: '100%',
            background: `linear-gradient(180deg, #ffffff00 30%, ${theme === 'light' ? '#E9DCC3' : '#313131'} 100%)`,
            zIndex: 2,
            marginTop: '0px',
          }}
        >
          { isCollapsed
          && <RealmSlider currentRealmId="" /> }
          { renderSideBarToggle() }

          {CampaignsHeader(currentCampaign)}
          <Container
            maxWidth="md"
            sx={{
              marginTop: 5,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 30,
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
            }}
          >
            { currentCampaign ? (
              <Card
                sx={{
                  borderRadius: '0px 5px 5px 0px',
                  boxShadow: '2px 2px 6px 3px rgba(0, 0, 0, 0.3)',
                  marginBottom: 2,
                  backgroundColor: 'rgba(255, 255, 255, 1.0)',
                  width: '100%',
                  height: 'auto',
                }}
              >
                <CardHeader
                  title={(
                    <Typography
                      sx={{
                        font: 'Passero One',
                        fontSize: '20px',
                        fontWeight: 'bold',
                        color: '#000',
                        position: 'relative',
                        zIndex: 3,
                      }}
                    >
                      {currentCampaign.name}
                    </Typography>
                  )}
                  sx={{
                    background: 'rgba(224, 224, 224, 0.4)',

                    boxShadow: '1px 1px 3px 2px rgba(0, 0, 0, 0.3)',
                  }}
                  action={(
                    <Stack
                      direction="row"
                      spacing={1}
                    >
                      { renderExportCampaignButton() }
                      { renderLaunchCampaignButton() }
                    </Stack>
                  )}
                />
                <CardContent>
                  <div
                    style={{
                      width: '100%',
                      height: '500px',
                      backgroundImage: `url(${currentCampaign.image})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                    }}
                  />

                  <Stack spacing={2}>
                    <Typography
                      sx={{
                        font: 'Raleway',
                        fontSize: '16px',
                        padding: '10px',
                      }}
                    >
                      {currentCampaign.description}
                    </Typography>
                    <Paper
                      variant="outlined"
                      title="Campaign History"
                      elevation={3}
                      sx={{
                        padding: '10px',
                        background: 'rgba(224, 224, 224, 0.4)',
                        borderRadius: '0px 5px 5px 0px',
                        boxShadow: '1px 1px 3px 1px rgba(0, 0, 0, 0.3)',
                        marginTop: '10px',
                        marginBottom: '10px',
                      }}
                    >
                      { // place a small header title and icon here
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography
                            sx={{
                              font: 'Passero One',
                              fontSize: '12px',
                              fontWeight: 'bold',
                              color: '#000',
                              top: '-5px',
                              left: '2px',
                              position: 'relative',
                              zIndex: 3,
                            }}
                          >
                            Campaign History
                          </Typography>
                          <IconButton
                            onClick={() => {}}
                            sx={{
                              padding: '5px',
                              background: '#41521F',
                              color: '#FFF',
                              top: '-5px',
                              position: 'relative',
                              width: '20px',
                              height: '20px',
                            }}
                          >
                            <Info />
                          </IconButton>
                        </div>
                      }
                      {currentCampaign.history}
                    </Paper>
                    {currentCampaign.tags ? (
                      <Stack
                        direction="row"
                        spacing={1}
                        sx={{
                          padding: '10px',
                        }}
                      >
                        {currentCampaign.tags.map((tag) => (
                          <Chip
                            key={tag}
                            label={tag}
                          />
                        ))}
                      </Stack>
                    ) : null}

                    { renderLocations() }
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{
                        padding: '4px',
                      }}
                    >
                      { renderAddActButton() }
                      { renderCreateLocationButton() }
                      { renderCreateRandomEncounter() }
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>
            ) : null}
            {currentCampaign?.acts ? (
              currentCampaign?.acts.map((_act) => (
                <Card
                  sx={{
                    background: 'rgba(255, 255, 255, 0.6)',
                    backgroundImage: `url(${_act.image})`,
                    backgroundSize: 'cover',
                    borderRadius: '0px 5px 5px 0px',
                    boxShadow: '2px 2px 6px 3px rgba(0, 0, 0, 0.3)',
                    margin: 2,
                    padding: 2,
                    transition: 'transform 0.2s', // Add transition effect
                    '&:hover': {
                      transform: 'scale(1.02)', // Enlarge slightly when hovered over
                    },
                  }}
                >
                  <CardContent>
                    <Stack spacing={2}>
                      <Typography
                        sx={{
                          fontSize: '20px',
                          fontWeight: 'bold',
                          color: '#FFF',
                        }}
                      >
                        {_act.name}
                            &nbsp;act
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          color: '#FFF',
                        }}
                      >
                        {_act.description}
                      </Typography>

                      { renderPlots(_act.plots) }
                      { renderQuests(_act.quests) }
                      { renderCharacters(_act.characters) }
                      { renderEncounters(_act.encounters) }
                    </Stack>
                  </CardContent>
                </Card>
              ))
            ) : null}
          </Container>
        </Box>
      ) : (
        <Box>
          <Typography variant="h1">You must be logged in to view this page</Typography>
        </Box>
      )
    );
  } catch (err) {
    console.log('Error: ', err);
  }
}

export default CampaignDetail;
