/* eslint-disable @typescript-eslint/indent */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import badge from '../../assets/images/azamel_Simple_UI_Shield_for_Website_flat_Minimalistic_Modern_UI_80f35a7e-9d54-451e-a766-5dfa8fb53c5e-removebg-preview 2.png';
import userImage from '../../assets/images/3d_avatar_28.png';
import token from '../../assets/images/azamel_Coin.png';
import plus from '../../assets/images/Add.png';
import realmWorldLogo from '../../assets/images/Realm_worlds APP LOGO_v1 2.png';
import s from './HeaderMenu.module.scss';
import { useAuth } from '../../hooks/use-auth';
import useGraphQL from '../../hooks/useGraphQL';
import appStore from '../../store/AppStore';
import {
  GetUserProfileDocument,
  GetUserWalletDocument,
} from '../../__generated__/graphql';
import { getAvaliableCredits } from '../../views/Dashboard/Dashboard';

/**
 * Header menu component containing user information and tokens.
 *
 * @component
 * @example
 * // Example usage of HeaderMenu component
 * <HeaderMenu />
 *
 * @returns {JSX.Element} React element representing the header menu.
 */

const HeaderMenu = () => {
  const auth = useAuth();

  const userProfile = auth?.user?.uid
    ? useGraphQL(GetUserProfileDocument, {
        query: {
          _id: auth.user.uid,
        },
      }).data
    : null;

  const userWallet = auth?.user?.uid
    ? useGraphQL(GetUserWalletDocument, {
        query: {
          _id: auth.user.uid,
        },
      }).data
    : null;

  appStore.setState({
    userName:
      userProfile?.user?.username ?? (auth?.user?.displayName as string),
    userCredits: getAvaliableCredits(userWallet ?? {}),
  });

  const userCredits: number = appStore((state) => state.userCredits);

  const userName: string = appStore((state) => state.userName as string);

  const userPhoto: string | null = appStore(
    (state) => state.userProfilePhoto as string,
  );

  const navigate = useNavigate();

  return auth === null || !auth.user ? (
    <Button
      variant="contained"
      color="primary"
      onClick={() => navigate('/login')}
    >
      Login
    </Button>
  ) : (
    // establish a realm background with lowest z-index
    <div className={s.realm_wrapper}>
      <img
        className={s.logo}
        src={realmWorldLogo}
        alt="realm world {} logo"
        onClick={() => navigate('/')}
      />
      <div className={s.token_wrapper} onClick={() => navigate('/settings')}>
        <img
          className={s.token}
          src={token}
          alt="token"
          onClick={() => navigate('/settings')}
        />
        <p>{userCredits !== null ? userCredits : 'loading...'}</p>
        <button type="button" className={s.addButton}>
          <img src={plus} alt="token" />
        </button>
      </div>
      <img
        className={s.userImage}
        src={userPhoto ?? userProfile?.user?.photo_url ?? userImage}
        alt="user profile"
        onClick={() => navigate('/settings')}
      />
      <p className={s.userName}>{userName || 'loading...'}</p>
      <img
        className={s.shield}
        src={badge}
        alt="shield"
        onClick={() => navigate('/settings')}
      />
    </div>
  );
};

export default HeaderMenu;
