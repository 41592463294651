/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable nonblock-statement-body-position */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Typography, useMediaQuery } from '@mui/material';
import { ColorResult, RGBColor } from 'react-color';
import { Stage } from 'konva/lib/Stage';
import useGraphQLMutation from '../../hooks/useGraphQLMutation';
import { useAuth } from '../../hooks/use-auth';
import useNotification from '../../hooks/useNotification';
import {
  Character_Set,
  CreateCharacterSetDocument,
  DeleteOneCharacterDocument,
  UpdateCharacterSetDocument,
} from '../../__generated__/graphql';
import CharacterSetModalPreview from '../realms/Character/CharacterSetModalPreview';
import appStore, {
  CharacterSheetMoodOptions,
  ThumbnailSource,
} from '../../store/AppStore';
import RWColorPicker from '../colorPicker/RWColorPicker';
import CharacterLibraryView from '../realms/Character/CharacterLibraryView';
import CharacterGeneralView from '../realms/Character/CharacterGeneralView';

/**
 * Represents the props for the `CreateCharacterSetModal` component.
 *
 * @interface
 * @property {boolean} open - Controls the visibility of the modal.
 * @property {() => void} onClose - Callback function when the modal is closed.
 * @property {() => void} [setOpen] - Callback function to set the modal visibility.
 * @property {() => void} onConfirm - Callback function when the user confirms the action.
 * @property {() => void} refetch - Callback function to refetch data after an action.
 */
type RWModalProps = {
  open: boolean;
  onClose: () => void;
  setOpen?: (v: boolean) => void;
  onConfirm: () => void;
  refetch: () => void;
} & React.PropsWithChildren;

type ViewOption = 'general' | 'library';

export default function CreateCharacterSetModal({
  open,
  onClose,
  setOpen,
  refetch,
}: RWModalProps) {
  const isTabletScreen = useMediaQuery('(max-width:865px)');
  const isMobileScreen = useMediaQuery('(max-width:530px)');

  const [showPreview, setShowPreview] = React.useState(false);
  const [view, setView] = React.useState<ViewOption>('general');
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const [selectedFileBlob, setSelectedFileBlob] = React.useState<Blob | null>(
    null,
  );
  const [currentStage, setCurrentStage] = React.useState<Stage | null>(null);
  const [previewUrl, setPreviewUrl] = React.useState<string | null>(null);
  const [previewBackground, setPreviewBackground] = React.useState('');
  const [characterSetTitle, setCharacterSetTitle] = React.useState('');

  const selectedThumbnailSource: ThumbnailSource = appStore(
    (state) => state.previewThumbnailSource,
  );
  const selectedCharacterSet: Character_Set = appStore(
    (state) => state.selectedCharacterSet,
  );
  // console.log(selectedCharacterSet);
  const characterSetMood: CharacterSheetMoodOptions = appStore(
    (state) => state.characterSetMood,
  );
  const setSelectedThumbnailSource = (source: ThumbnailSource) => {
    appStore.setState({
      previewThumbnailSource: source,
    });
  };
  const [color, setColor] = React.useState<RGBColor>(() => ({
    r: 0,
    g: 0,
    b: 0,
  }));
  const handleClose = () => {
    setShowPreview(false);
    onClose?.();
    setOpen?.(false);
    setView('general');
    setSelectedThumbnailSource('upload');
    appStore.setState({
      characterSetMood: 'create',
      selectedCharacterSet: null,
    });
    // setPreviewUrl(null);
    setPreviewBackground('');
    setCharacterSetTitle('');
    setColor({
      r: 0,
      g: 0,
      b: 0,
    });
  };

  const convertFileToBlob = (file: File) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const blob = new Blob([reader.result as ArrayBuffer], {
        type: file.type,
      });
      setSelectedFileBlob(blob);
    };

    reader.readAsArrayBuffer(file);
  };

  /**
   * Handles the change of the file input for uploading a character set image.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event - The change event.
   * @returns {void}
   */
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      convertFileToBlob(file);
      setPreviewUrl(URL.createObjectURL(file));
      setSelectedThumbnailSource('upload');
      setShowPreview(true);
    }
  };

  /**
   * Switches to the library view for selecting a character set image.
   *
   * @returns {void}
   */
  const handleLibraryView = () => {
    setSelectedThumbnailSource('library');
    setShowPreview(true);
    setView('library');
  };

  /**
   * Closes the library view.
   *
   * @returns {void}
   */
  const handleCloseLibraryView = () => {
    // setShowPreview(true);
    setView('general');
  };

  /**
   * Switches to the color view for selecting a character set color.
   *
   * @returns {void}
   */
  const handleColorView = () => {
    setSelectedThumbnailSource('color');
    setShowPreview(true);
  };

  /**
   * Handles the change of color in the color picker.
   *
   * @param {RGBColor} ccolor - The RGB color object.
   * @returns {void}
   */
  const handleColorChange = (ccolor: any) => {
    setColor(ccolor.rgb);
  };

  /**
   * Handles the completion of color selection in the color picker.
   *
   * @param {ColorResult} chcolor - The color result object.
   * @returns {void}
   */
  const handleColorChangeCompleted = (chcolor: ColorResult) => {
    setColor(chcolor.rgb);
    setPreviewBackground(chcolor.hex);
    setPreviewUrl('');
  };

  /**
   * Converts a URL to a Blob and sets the background URL for preview.
   *
   * @param {string} url - The URL to convert.
   * @param {React.Dispatch<React.SetStateAction<string | null>>}
   * setUrl - The state setter function.
   * @returns {Promise<void>}
   */
  async function urlToBlob(url: string, setUrl: any) {
    try {
      // Fetch the data from the URL
      const response = await fetch(url);
      const blober = await fetch(url).then((r) => r.blob());
      const urlBlob = URL.createObjectURL(blober);
      // Check if the request was successful
      if (!response.ok) {
        throw new Error(`Failed to fetch data from ${url}`);
      }
      return setUrl(`url(${urlBlob})`);
    } catch (error) {
      console.error(error);
      // Handle the error appropriately
      return null;
    }
  }

  /**
   * Handles the click on a library image and sets it as the preview image.
   *
   * @param {string} link - The URL of the library image.
   * @returns {void}
   */
  const handleLibraryImageClick = (link: string) => {
    setPreviewUrl(link);
    urlToBlob(link, setPreviewBackground);
  };

  React.useEffect(() => {
    if (previewUrl && previewUrl !== null && view !== 'library') {
      setPreviewBackground(`url(${previewUrl})`);
    }
  }, [previewUrl]);

  const { displayNotification } = useNotification();
  const auth = useAuth();
  const {
    data: createdCharacterSet,
    mutate: createCharacterSet,
    status: createCharacterSetStatus,
  } = useGraphQLMutation(CreateCharacterSetDocument);

  const {
    data: updatedCharacterSet,
    mutate: updateCharacterSet,
    status: updateCharacterSetStatus,
  } = useGraphQLMutation(UpdateCharacterSetDocument);

  const { mutate: deleteCharacterSet, status: deleteCharacterSetStatus } =
    useGraphQLMutation(DeleteOneCharacterDocument);

  /**
   * Handles the creation of a character set and uploads the background image if applicable.
   *
   * @returns {void}
   */
  const handleCreateCharacterSet = () => {
    createCharacterSet({
      data: {
        name: characterSetTitle,
        owner: {
          link: auth?.realmUser?.id,
        },
        background_url: previewUrl ?? null,
      },
    });
  };
  const handleUpdateCharacterSet = () => {
    updateCharacterSet({
      set: {
        name: characterSetTitle,
        owner: {
          link: auth?.realmUser?.id,
        },
        background_url: previewUrl ?? null,
      },
      query: {
        _id: selectedCharacterSet._id,
      },
    });
  };
  const handleDeleteCharacterSet = () => {
    deleteCharacterSet({
      query: {
        _id: selectedCharacterSet._id,
      },
    });
  };

  /**
   * Uploads the character set background image.
   *
   * @param {string} id - The ID of the character set.
   * @returns {void}
   */
  const handleUploadCharacterSetImage = (id: string) => {
    const formData = new FormData();
    formData.append('BackgroundImage', selectedFileBlob!);
    formData.append('OriginalImage', selectedFileBlob!);
    displayNotification({
      message: 'Uploading character set background image ...',
      type: 'info',
    });
    fetch(`${process.env.REACT_APP_API_URL}/CharacterSet/${id}/images`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          displayNotification({
            message: 'Failed to upload character set background image',
            type: 'error',
          });
        }
        return response.json();
      })
      .then((result) => {
        handleClose();
        displayNotification({
          message: 'Character set background image uploaded successfully',
          type: 'success',
        });
        refetch();
      })
      .catch((error) => {
        console.error('There was a problem: ', error);
        displayNotification({
          message: 'Failed to upload character set background image',
          type: 'error',
        });
      });
  };

  /**
   * Converts the current stage to a Blob and initiates character set creation.
   *
   * @returns {void}
   */
  const convertStageToBlob = () => {
    if (currentStage) {
      const dataUrl = currentStage.toDataURL();
      fetch(dataUrl)
        .then((res) => res.blob())
        .then((blob) => {
          if (blob) {
            setSelectedFileBlob(blob);
            if (characterSetMood === 'create') handleCreateCharacterSet();
            if (characterSetMood === 'edit') handleUpdateCharacterSet();
          }
        });
    }
  };

  React.useEffect(() => {
    if (createCharacterSetStatus === 'loading') {
      displayNotification({
        message: 'Creating character set ...',
        type: 'info',
      });
    }
    if (createCharacterSetStatus === 'error') {
      displayNotification({
        message: 'Failed to create character set',
        type: 'error',
      });
    }
    if (createCharacterSetStatus === 'success') {
      displayNotification({
        message: 'Character set created successfully',
        type: 'success',
      });
      handleUploadCharacterSetImage(
        createdCharacterSet.insertOneCharacter_set?._id,
      );
    }
  }, [createCharacterSetStatus]);

  React.useEffect(() => {
    if (updateCharacterSetStatus === 'loading') {
      displayNotification({
        message: 'Updating character set ...',
        type: 'info',
      });
    }
    if (updateCharacterSetStatus === 'error') {
      displayNotification({
        message: 'Failed to update character set',
        type: 'error',
      });
    }
    if (updateCharacterSetStatus === 'success') {
      displayNotification({
        message: 'Character set updated successfully',
        type: 'success',
      });
      handleUploadCharacterSetImage(
        updatedCharacterSet.updateOneCharacter_set?._id,
      );
    }
  }, [updateCharacterSetStatus]);

  React.useEffect(() => {
    if (deleteCharacterSetStatus === 'loading') {
      displayNotification({
        message: 'Deleting character set ...',
        type: 'info',
      });
    }
    if (deleteCharacterSetStatus === 'error') {
      displayNotification({
        message: 'Failed to delete character set',
        type: 'error',
      });
    }
    if (deleteCharacterSetStatus === 'success') {
      displayNotification({
        message: 'Character set deleted successfully',
        type: 'success',
      });
      handleClose();
      refetch();
    }
  }, [deleteCharacterSetStatus]);

  React.useEffect(() => {
    if (
      characterSetMood === 'edit' &&
      selectedCharacterSet !== (null || undefined)
    ) {
      setCharacterSetTitle(selectedCharacterSet.name as string);
      urlToBlob(
        selectedCharacterSet.background_url as string,
        setPreviewBackground,
      );
      setShowPreview(true);
    }
  }, [characterSetMood]);

  React.useEffect(() => {
    if (
      characterSetMood === 'edit' &&
      selectedCharacterSet !== (null || undefined)
    ) {
      setCharacterSetTitle(selectedCharacterSet.name as string);
      urlToBlob(
        selectedCharacterSet.background_url as string,
        setPreviewBackground,
      );
      setShowPreview(true);
    }
  }, []);

  return (
    <div>
      <Modal
        open={open}
        onClose={() => handleClose()}
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              backdropFilter: 'blur(4px)',
            },
          },
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ zIndex: 997 }}
      >
        <>
          <Box
            sx={{
              position: 'absolute',
              top: isTabletScreen ? '50%' : '30%',
              left: '50%',
              transform: isTabletScreen ? 'translate(-50%, -50%)' : 'translate(-50%, -30%)',
              width: isMobileScreen ? '90%' : '600px',
              outline: 'none',
              overflow: 'hidden',
            }}
          >
            {/* heading and close button */}
            <Box
              sx={{
                width: '100%',
                borderRadius: '20px 20px 0px 0px',
                background: '#41521F',
                padding: '12px 20px',
                boxSizing: 'border-box',
                display: 'flex',
                alignItems: 'center',
                gap: '35px',
              }}
            >
              <button
                type="button"
                onClick={handleClose}
                style={{
                  display: 'block',
                  background: 'transparent',
                  border: 'none',
                  outline: 'none',
                  filter: 'none',
                  cursor: 'pointer',
                }}
                aria-label="close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 10 10"
                  width={10}
                >
                  <polygon
                    style={{ fill: '#fff', strokeWidth: 0 }}
                    points="6.03 5 10 8.97 9.49 9.49 8.97 10 5 6.03 1.03 10 .51 9.49 0 8.97 3.97 5 0 1.03 .51 .51 1.03 0 5 3.97 8.97 0 9.49 .51 10 1.03 6.03 5"
                  />
                </svg>
              </button>
              <Typography
                sx={{
                  color: 'white',
                  fontFamily: 'Passero One',
                  fontSize: isMobileScreen ? '18px' : '20px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: '26px' /* 130% */,
                  letterSpacing: '0.46px',
                  textTransform: 'uppercase',
                }}
              >
                Create a character set
              </Typography>
            </Box>
            {/* character set name and thumnbail options */}
            <Box
              sx={{
                width: '100%',
                borderRadius: showPreview ? '0px' : '0px 0px 20px 20px',
                background: '#FFF',
                padding: isMobileScreen ? '25px 25px' : '45px 65px',
                boxSizing: 'border-box',
                maxHeight: isMobileScreen ? '500px' : '600px',
              }}
            >
              {view === 'general' && (
                <CharacterGeneralView
                  handleFileChange={(
                    event: React.ChangeEvent<HTMLInputElement>,
                  ) => handleFileChange(event)}
                  handleLibraryView={handleLibraryView}
                  handleColorView={handleColorView}
                  handleSaveSet={convertStageToBlob}
                  handleDeleteSet={handleDeleteCharacterSet}
                  disableSave={
                    previewBackground === '' || characterSetTitle === ''
                  }
                  characterSetTitle={characterSetTitle}
                  setCharacterTitle={(value: string) =>
                    setCharacterSetTitle(value)
                  }
                />
              )}
              {view === 'library' && (
                <CharacterLibraryView
                  handleCloseLibraryView={handleCloseLibraryView}
                  previewBackground={previewUrl!}
                  handleLibraryImageClick={(link: string) =>
                    handleLibraryImageClick(link)
                  }
                />
              )}
            </Box>
            {/* Preview */}
            {showPreview && (
              <CharacterSetModalPreview
                background={previewBackground}
                handleStage={(stage: Stage) => {
                  setCurrentStage(stage);
                }}
                onConfirm={() => {}}
              />
            )}
          </Box>
          {selectedThumbnailSource === 'color' && (
            <Box
              sx={{
                position: 'absolute',
                top: '45%',
                left: '80%',
                transform: 'translate(-80%, -45%)',
                width: '330px',
                outline: 'none',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  borderRadius: '20px',
                  background: '#FFF',
                  padding: '23px 12px',
                  boxSizing: 'border-box',
                  maxHeight: '480px',
                  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                }}
              >
                <RWColorPicker
                  options={{
                    variant: 'character',
                  }}
                  color={color}
                  onChange={handleColorChange}
                  onChangeComplete={handleColorChangeCompleted}
                />
              </Box>
            </Box>
          )}
        </>
      </Modal>
    </div>
  );
}

/**
 * Default props for the `CreateCharacterSetModal` component.
 */
CreateCharacterSetModal.defaultProps = {
  setOpen: () => {},
};
