/* eslint-disable object-curly-newline */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/comma-dangle */
/* eslint-disable operator-linebreak */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
/* eslint-disable @typescript-eslint/indent */
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import {
  ImageList,
  ImageListItem,
  CardActionArea,
  Box,
  Grid,
  Button,
  ToggleButton,
  Divider,
  TextField,
  Stack,
} from '@mui/material';
// import { MuiColorInput, MuiColorInputColors } from 'mui-color-input';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ContentState, DraftHandleValue, Editor, EditorState } from 'draft-js';
import { StageData } from '../../../redux/currentStageData';
import useItem from '../../../hooks/useItem';
import {
  StyledTab,
  StyledTabs,
  StyledToggleButtonGroup,
  setTabId,
} from '../StyledPanel/StyledPanel';
import TabPanel from '../../tab/TabPanel';
import { canvasHeight, canvasWidth } from '../../CanvasConstants';
import ConfirmationModal from '../../modals/ConfirmationModal';
import useToken from '../../../hooks/useToken';
import styles from './notespanel.module.scss';
import { ThemeContext } from '../../../context/ThemeContext';

export default function NotesPanel() {
  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(0);
  };
  const [noteContent, setNoteContent] = React.useState('');
  const [editingIndex, setEditingIndex] = React.useState<number | null>(null);
  const { token, updateToken } = useToken();
  console.log(token?.data?.notes);

  const [openConfirmationModal, setOpenConfirmationModal] =
    React.useState(false);

  const handleSaveNote = () => {
    if (editingIndex !== null) {
      const oldNotes = [...token.data.notes];
      oldNotes[editingIndex] = {
        title: oldNotes[editingIndex].title,
        content: noteContent,
      };
      updateToken(() => ({
        notes: oldNotes,
      }));
    }
    if (editingIndex === null) {
      if (token?.data?.notes?.title === 'My Note' || token?.data?.notes === undefined) {
        updateToken(() => ({
          notes: [{
            title: `New Note - ${
              new Date().getMonth() + 1
            }/${new Date().getDate()}/${new Date().getFullYear().toString().slice(-2)}`,
            content: noteContent,
          }],
        }));
      } else {
        updateToken(() => ({
          notes: [...token.data.notes, {
            title: `New Note - ${
              new Date().getMonth() + 1
            }/${new Date().getDate()}/${new Date().getFullYear().toString().slice(-2)}`,
            content: noteContent,
          }],
        }));
      }
    }
    setOpenConfirmationModal(false);
  };

  const handleDeleteNote = () => {
    const oldNotes = [...token.data.notes];
    if (oldNotes.length > 1) {
      if (editingIndex !== null) oldNotes.splice(editingIndex, 1);
      updateToken(() => ({
        notes: oldNotes,
      }));
    } else {
      updateToken(() => ({
        notes: {
          title: 'My Note',
          content: '',
        }
      }));
    }
    setOpenConfirmationModal(false);
  };

  React.useEffect(() => {
    console.log('notes: ', token?.data?.notes);
  }, [token]);

  React.useEffect(() => {
    if (!openConfirmationModal) {
      setNoteContent('');
      setEditingIndex(null);
    }
  }, [openConfirmationModal]);

  const { theme } = useContext(ThemeContext);

  return (
    <>
      <StyledTabs value={tabValue} onChange={handleTabChange}>
        <StyledTab label="Notes" {...setTabId(0)} />
      </StyledTabs>
      <TabPanel value={tabValue} index={0}>
        <Grid container sx={{ px: '11px', pt: '28px' }}>
          <Grid item xs={12} mb={2} display="flex" flexWrap="wrap">
            <Button
              onClick={() => setOpenConfirmationModal(true)}
              variant="contained"
              fullWidth
              className={styles.notes_panel_default_button}
              startIcon={<FontAwesomeIcon icon={solid('plus')} size="lg" />}
            >
              New Note
            </Button>
            <Stack gap="10px" mt="50px" width="100%">
              {Array.isArray(token?.data?.notes) && token?.data?.notes.length > 0
                && token?.data?.notes.map((note: any, index: number) => (
                  <Button
                    key={`${note.title}_${index}`}
                    onClick={() => {
                      setEditingIndex(index);
                      setNoteContent(note.content);
                      setOpenConfirmationModal(true);
                    }}
                    variant="contained"
                    fullWidth
                    className={styles.notes_panel_default_button}
                    startIcon={<FontAwesomeIcon icon={solid('pen')} size="sm" />}
                  >
                    {note.title}
                  </Button>
              ))}
            </Stack>
          </Grid>
        </Grid>
      </TabPanel>
      <ConfirmationModal
        open={openConfirmationModal}
        onClose={() => {}}
        onConfirm={() => {}}
        setOpen={setOpenConfirmationModal}
      >
        <StyledTabs value={tabValue} onChange={handleTabChange}>
          <StyledTab label="Notes" {...setTabId(0)} />
        </StyledTabs>
        <TabPanel value={tabValue} index={0} width="100%">
          <Grid container sx={{ px: '11px', pt: '28px' }}>
            <Grid
              item
              xs={12}
              mb={2}
              display="flex"
              flexWrap="wrap"
              p={2}
              sx={{
                backgroundColor: 'rgba(217, 217, 217, 0.2)',
                border: '1px solid #FFFFFF',
                borderRadius: '10px',
                height: 320,
                maxHeight: 320,
              }}
            >
              <TextField
                id="multiline-text"
                multiline
                defaultValue={noteContent}
                placeholder="Write here"
                className={`text_editor_field ${styles.text_editor_field}`}
                fullWidth
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                  setNoteContent(event.target.value);
                }}
                // onBlur={onEditorChange}
                variant="standard"
                minRows={5}
              />
              {/* <Editor
                editorState={editorState}
                onChange={onEditorChange}
                handleBeforeInput={handleBeforeInput}
                handleReturn={handleReturn}
              /> */}
            </Grid>
            <Grid item xs={12} mb={2} display="flex" flexWrap="wrap" sx={{ justifyContent: 'flex-end', columnGap: '16px' }}>
              <Stack direction="row" width={editingIndex !== null ? '100%' : 'fit-content'} justifyContent="space-between">
                {editingIndex !== null && (
                  <Button
                    className={`notes_editor_button ${styles.notes_editor_button_delete} ${styles[theme]}`}
                    onClick={handleDeleteNote}
                  >
                    Delete Note
                  </Button>
                )}
                <Stack direction="row" gap="16px">
                  <Button
                    className={`notes_editor_button ${styles.notes_editor_button} ${styles[theme]}`}
                    onClick={() => {
                      setOpenConfirmationModal(false);
                      setEditingIndex(null);
                      setNoteContent('');
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={`notes_editor_button ${styles.notes_editor_button} ${styles[theme]}`}
                    onClick={handleSaveNote}
                  >
                    {editingIndex !== null ? 'Update' : 'Save'}
                  </Button>
                </Stack>

              </Stack>
            </Grid>
          </Grid>
        </TabPanel>
      </ConfirmationModal>
    </>
  );
}
