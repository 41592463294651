/* eslint-disable no-nested-ternary */
/* eslint-disable object-curly-newline */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable operator-linebreak */
import React from 'react';
import Konva from 'konva';
import { Stage } from 'konva/lib/Stage';
import { Box, Slider, Stack, useMediaQuery } from '@mui/material';
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi';
import { BsArrowsMove } from 'react-icons/bs';
import { FaTrashAlt } from 'react-icons/fa';
import {
  OptionsStack,
  OptionsStackIcon,
  OptionsStackIconContainer,
  OptionsStackText,
  OptionsStackTextContainer,
  SmallHeadingText,
} from '../../styledComps/styledComps';
import UploadIcon from '../../../images/upload-image.webp';
import LibraryIcon from '../../../images/library.webp';
import PanIcon from '../../../images/pan.webp';
import ChooseFromLibraryModal from '../../modals/ChooseFromLibrary';

interface Props {
  previewUrl: string | null;
  setPreviewUrl: (url: string | null) => void;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleStage: (stage: Stage) => void;
  handleImageEdited?: (edited: boolean) => void;
}

function CharacterImageBox({
  previewUrl,
  setPreviewUrl,
  handleFileChange,
  handleStage,
  handleImageEdited,
}: Props) {
  const isTabletScreen = useMediaQuery('(max-width:865px)');
  const isMobileScreen = useMediaQuery('(max-width:530px)');

  const [zoomValue, setZoomValue] = React.useState<number>(0);
  const [imagePostion, setImagePosition] = React.useState({ x: 0, y: 0 });
  const handleZoomChange = (event: Event, newValue: number | number[]) => {
    setZoomValue(newValue as number);
  };
  const handleZoomIn = () => {
    if (zoomValue < 100) {
      setZoomValue(zoomValue + 1);
    }
    if (handleImageEdited !== undefined) {
      handleImageEdited(true);
    }
  };
  const handleZoomOut = () => {
    if (zoomValue > 0) {
      setZoomValue(zoomValue - 1);
    }
    if (handleImageEdited !== undefined) {
      handleImageEdited(true);
    }
  };
  const [draggableImage, setDraggableImage] = React.useState(false);
  const [openChooseFromLibraryModal, setOpenChooseFromLibraryModal] =
    React.useState(false);

  const handleImageDragEnd = (e: Konva.KonvaEventObject<DragEvent>) => {
    setImagePosition({
      x: e.target.x(),
      y: e.target.y(),
    });
    if (handleImageEdited !== undefined) {
      handleImageEdited(true);
    }
  };

  /**
   * Draws an image on the preview canvas.
   *
   * @param {HTMLImageElement} imageObj - The image to be drawn.
   */
  const drawPreviewCanvasImg = (imageObj: HTMLImageElement) => {
    // const imageWidth = stage.width() * (zoomValue > 0 ? (zoomValue / 100) * 2 : 1);
    const prevCanvas = document.getElementById('previewCanvas');

    if (prevCanvas !== null) {
      const stage = new Konva.Stage({
        container: 'previewCanvas',
        width: isMobileScreen ? 220 : 333,
        height: isMobileScreen ? 270 : 410,
      });
      stage.remove();
      const imageWidth =
        zoomValue > 0
          ? stage.width() + stage.width() * (zoomValue / 100) * 2
          : stage.width();
      const imageHeight =
        (imageObj.naturalHeight / imageObj.naturalWidth) * imageWidth;
      const previewLayer = new Konva.Layer();
      const previewImage = new Konva.Image({
        image: imageObj,
        x: imagePostion.x,
        y: imagePostion.y,
        width: imageWidth,
        height: imageHeight,
        draggable: draggableImage,
      });
      previewImage.on('mouseover', () => {
        if (draggableImage) {
          document.body.style.cursor = `url(${PanIcon}),auto`;
        }
      });
      previewImage.on('mouseout', () => {
        document.body.style.cursor = 'default';
      });
      previewImage.on('dragmove', () => {
        handleStage(stage);
      });
      previewImage.on('dragend', (e) => {
        handleImageDragEnd(e);
      });
      previewLayer.add(previewImage);
      stage.add(previewLayer);
      handleStage(stage);
    }
  };

  React.useEffect(() => {
    const imageObj = new Image();
    imageObj.onload = () => {
      drawPreviewCanvasImg(imageObj);
    };
    if (previewUrl) {
      imageObj.src = previewUrl;
      // const imageWidth = zoomValue > 0
      //   ? 333 + (333 * (zoomValue / 100) * 2)
      //   : 333;
      // const imageHeight = (imageObj.naturalHeight / imageObj.naturalWidth) * imageWidth;
      // setImagePosition({ x: 166.5 - (imageWidth / 2), y: 205 - (imageHeight / 2) });
    }
  }, [previewUrl, zoomValue, draggableImage]);

  return (
    <>
      <Stack
        sx={{
          borderRadius: '4px',
          background: '#E3D5BC',
          width: isTabletScreen ? '100%' : '396px',
          height: isMobileScreen ? '306px' : 'auto',
          overflow: 'hidden',
        }}
        gap="8px"
      >
        <SmallHeadingText pt="9px" pl="21px">
          Character Image
        </SmallHeadingText>
        {previewUrl === null && (
          <Stack
            height={isMobileScreen ? '270px' : '410px'}
            alignItems="center"
            justifyContent="center"
            gap="38px"
            px={isTabletScreen ? '19px' : '38px'}
            boxSizing="border-box"
          >
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="fileInput" style={{ width: '100%' }}>
              <OptionsStack>
                <OptionsStackIconContainer>
                  <OptionsStackIcon src={UploadIcon} />
                </OptionsStackIconContainer>
                <OptionsStackTextContainer>
                  <OptionsStackText>Upload Image</OptionsStackText>
                </OptionsStackTextContainer>
              </OptionsStack>
            </label>
            <input
              type="file"
              accept=".jpg,.png,.webp"
              style={{ display: 'none' }}
              onChange={handleFileChange}
              id="fileInput"
            />
            <OptionsStack
              onClick={() => {
                setOpenChooseFromLibraryModal(true);
              }}
            >
              <OptionsStackIconContainer>
                <OptionsStackIcon src={LibraryIcon} />
              </OptionsStackIconContainer>
              <OptionsStackTextContainer>
                <OptionsStackText>My Library</OptionsStackText>
              </OptionsStackTextContainer>
            </OptionsStack>
          </Stack>
        )}
        {previewUrl !== null && (
          <Stack
            sx={{
              width: '100%',
              height: isMobileScreen ? '270px' : '410px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Stack
              sx={{
                // height: '410px',
                // width: '333px',
                boxSizing: 'border-box',
                position: 'relative',
                '.replaceImage': {
                  display: 'none',
                },
                ':hover': {
                  '.replaceImage': {
                    display: 'flex',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  },
                },
              }}
            >
              <Box
                id="previewCanvas"
                height={isTabletScreen ? '100%' : 410}
                width={isTabletScreen ? '100%' : 333}
              />
              <Stack direction="row" gap="20px" className="replaceImage">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="fileInput" style={{ width: '100%' }}>
                  <OptionsStack>
                    <OptionsStackIconContainer>
                      <OptionsStackIcon src={UploadIcon} />
                    </OptionsStackIconContainer>
                  </OptionsStack>
                </label>
                <input
                  type="file"
                  accept=".jpg,.png,.webp"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                  id="fileInput"
                />
                <OptionsStack
                  onClick={() => {
                    setOpenChooseFromLibraryModal(true);
                  }}
                >
                  <OptionsStackIconContainer>
                    <OptionsStackIcon src={LibraryIcon} />
                  </OptionsStackIconContainer>
                </OptionsStack>
              </Stack>
            </Stack>
            {/* side green bar */}
            <Stack
              sx={{
                backgroundColor: '#41521F',
                width: '63px',
                height: isMobileScreen ? '270px' : '410px',
              }}
            >
              <Stack spacing={isMobileScreen ? '3px' : '9px'} alignItems="center" paddingTop="12.5px">
                <FiPlusCircle
                  style={{
                    width: isMobileScreen ? '20px' : '33px',
                    height: isMobileScreen ? '20px' : '33px',
                    color: '#fff',
                    cursor: 'pointer',
                  }}
                  onClick={handleZoomIn}
                />
                <Slider
                  aria-label="zoom"
                  value={zoomValue}
                  onChange={handleZoomChange}
                  orientation="vertical"
                  sx={{
                    // width: '15px',
                    height: isMobileScreen ? '140px' : '167px',
                    color: '#D0B17A',
                    '.MuiSlider-rail': {
                      color: 'white',
                    },
                    '.MuiSlider-thumb': {
                      width: '15px',
                      height: '15px',
                    },
                    '.MuiSlider-thumb:hover': {
                      boxShadow: '0px 0px 0px 8px #D0B17A3C',
                    },
                  }}
                  min={0}
                  max={100}
                />
                <FiMinusCircle
                  style={{
                    width: isMobileScreen ? '20px' : '33px',
                    height: isMobileScreen ? '20px' : '33px',
                    color: '#fff',
                    cursor: 'pointer',
                  }}
                  onClick={handleZoomOut}
                />
                <div
                  style={{
                    width: isMobileScreen ? '18px' : '28.67px',
                    height: isMobileScreen ? '18px' : '28.67px',
                    borderRadius: '50%',
                    border: '1px solid #fff',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '11px',
                  }}
                >
                  <BsArrowsMove
                    style={{
                      width: isMobileScreen ? '12px' : '18px',
                      height: isMobileScreen ? '12px' : '18px',
                      color: '#fff',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setDraggableImage(!draggableImage);
                    }}
                  />
                </div>
                <div
                  style={{
                    width: isMobileScreen ? '18px' : '28.67px',
                    height: isMobileScreen ? '18px' : '28.67px',
                    borderRadius: '50%',
                    border: '1px solid #fff',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: isMobileScreen ? '10px' : '57px',
                  }}
                >
                  <FaTrashAlt
                    style={{
                      width: isMobileScreen ? '12px' : '18px',
                      height: isMobileScreen ? '12px' : '18px',
                      color: '#fff',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setPreviewUrl(null);
                      setZoomValue(0);
                      setDraggableImage(false);
                      setImagePosition({ x: 0, y: 0 });
                    }}
                  />
                </div>
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>
      <Box sx={{ position: 'fixed' }}>
        <ChooseFromLibraryModal
          open={openChooseFromLibraryModal}
          onClose={() => {
            setOpenChooseFromLibraryModal(false);
          }}
          setOpen={setOpenChooseFromLibraryModal}
          onConfirm={(url) => {
            setPreviewUrl(url);
            if (handleImageEdited !== undefined) {
              handleImageEdited(true);
            }
          }}
        />
      </Box>
    </>
  );
}

CharacterImageBox.defaultProps = {
  handleImageEdited: undefined,
};

export default CharacterImageBox;
