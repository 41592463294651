import * as React from 'react';
import { useAuth } from '../../hooks/use-auth';

// If using TypeScript, add the following snippet to your file as well.
declare global {
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

export default function SubscriptionCheckout() {
  const auth = useAuth();
  return (
    <stripe-pricing-table
      pricing-table-id="prctbl_1OeU5mDpaTNuCctocp274Lp4"
      publishable-key="pk_test_51Ns89bDpaTNuCctoLxLg5Auyr65LZtbaR4WUPghlXhEMkSdLb7rw4t88ygd7o0T8bX5LTaSzWuwOvjMGXhSMhIe5008Rw5xhBM"
      customer-email={auth?.user?.email}
      client-reference-id={localStorage.getItem('rwUserId')}
    />
  );
}
