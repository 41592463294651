// function getRandomItems<T>(arr: T[]): T[] {
//   const result = new Array(3);
//   let len = arr.length;
//   const taken = new Array(len);
//   if (len < 3) throw new RangeError('getRandom: more elements taken than available');
//   while (result.length) {
//     const x = Math.floor(Math.random() * len);
//     result[result.length - 1] = arr[x in taken ? taken[x] : x];
//     // eslint-disable-next-line no-plusplus
//     taken[x] = --len in taken ? taken[len] : len;
//   }
//   return result;
// }

function pickThreeRandomItems<T>(items: T[]): T[] {
  if (items.length < 3) {
    throw new Error('Array must contain at least 3 items');
  }

  const result: T[] = [];
  const tempItems = [...items]; // Create a copy of the array

  for (let i = 0; i < 3; i += 1) {
    const randomIndex = Math.floor(Math.random() * tempItems.length);
    result.push(tempItems[randomIndex]);
    tempItems.splice(randomIndex, 1); // Remove the selected item from the array
  }

  return result;
}

export default pickThreeRandomItems;
