/* eslint-disable react/require-default-props */
import React, { useContext } from 'react';
import { Tooltip, Zoom } from '@mui/material';
import { ThemeContext } from '../../context/ThemeContext';
import styles from './icons.module.scss';

interface Props {
  onClick?: () => void;
  active: boolean;
}

function FantasyIcon({ onClick, active } : Props) {
  const { theme } = useContext(ThemeContext);
  return (
    <Tooltip
      title="AI Gen"
      arrow
      placement="bottom"
      TransitionComponent={Zoom}
      componentsProps={{
        tooltip: {
          sx: {
            fontFamily: 'Passero One',
            fontWeight: '400px',
            fontSize: '16px',
            padding: '10px',
            bgcolor: '#3c3c3ce6',
            '& .MuiTooltip-arrow': {
              color: '#3c3c3ce6',
            },
            maxWidth: '250px',
          },
        },
      }}
    >
      <button
        onClick={onClick}
        type="button"
        className={`${styles.icon} ${styles.fantasy_icon} ${styles[theme]} ${active && styles.active}`}
        aria-label="fantasy"
      >
        <svg version="1.1" id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.62 24.54">
          <g id="Layer_1-2" data-name="Layer 1">
            <g>
              <path className={styles.fantasy_cls_2} d="m24.62,8.55l-4.44-1.71,2.16-4.24-4.35,1.93-1.48-4.53-1.71,4.44-4.25-2.16,1.93,4.36-4.52,1.47,3.94,1.52L.38,21.16c-.5.5-.5,1.31,0,1.81l1.2,1.2c.5.5,1.31.5,1.81,0l11.41-11.42,1.28,3.92,1.71-4.45,4.24,2.16-1.92-4.35,4.52-1.48Zm-12.12,5.02c-.21.21-.49.32-.76.32-.22,0-.44-.07-.63-.21-.05-.03-.1-.07-.14-.11-.13-.13-.21-.28-.25-.44-.13-.37-.04-.8.25-1.09l3.53-3.53c.42-.42,1.1-.42,1.52,0h.01c.42.42.42,1.11,0,1.53l-3.53,3.53Z" />
              <path className={styles.fantasy_cls_2} d="m13.5,16.87l.42.77c.11.2.28.37.48.48l.77.42-.77.42c-.2.11-.37.28-.48.48l-.42.77-.42-.77c-.11-.2-.28-.37-.48-.48l-.77-.42.77-.42c.2-.11.37-.28.48-.48l.42-.77Z" />
              <path className={styles.fantasy_cls_2} d="m20.21,17.11l.83,1.53c.22.4.55.73.95.95l1.53.83-1.53.83c-.4.22-.73.55-.95.95l-.83,1.53-.83-1.53c-.22-.4-.55-.73-.95-.95l-1.53-.83,1.53-.83c.4-.22.73-.55.95-.95l.83-1.53Z" />
            </g>
            <path className={styles.fantasy_cls_1} d="m16.38,9.37h-.62v-1.25c0-1.72-1.4-3.12-3.12-3.12s-3.12,1.4-3.12,3.12v1.25h-.62c-.69,0-1.25.56-1.25,1.25v6.25c0,.69.56,1.25,1.25,1.25h7.5c.69,0,1.25-.56,1.25-1.25v-6.25c0-.69-.56-1.25-1.25-1.25Zm-3.75,5.63c-.69,0-1.25-.56-1.25-1.25s.56-1.25,1.25-1.25,1.25.56,1.25,1.25-.56,1.25-1.25,1.25Zm1.94-5.63h-3.88v-1.25c0-1.07.87-1.94,1.94-1.94s1.94.87,1.94,1.94v1.25Z" />
          </g>
        </svg>
      </button>
    </Tooltip>
  );
}

export default FantasyIcon;
