import React from 'react';
import request from 'graphql-request';
import { type TypedDocumentNode } from '@graphql-typed-document-node/core';
import { useQuery, useQueryClient, type UseQueryResult } from '@tanstack/react-query';
import { useAuth } from './use-auth';

function useGraphQL<TResult, TVariables, TStateVariable = undefined>(
  document: TypedDocumentNode<TResult, TVariables>,
  variables: TVariables,
  stateVariable?: TStateVariable,
): UseQueryResult<TResult> {
  const auth = useAuth();
  const queryClient = useQueryClient();

  const queryKey = [(document.definitions[0] as any).name.value, variables];

  const query = useQuery(queryKey, async ({ queryKey: innerQueryKey }) => {
    const token = await auth?.getCurrentUserTokens();
    // console.log('useGraphQL token: ', token);
    const req = request(
      process.env.REACT_APP_GRAPHQL_URL ?? 'NO URL SPECIFIED',
      document,
      innerQueryKey[1],
      {
        Authorization: `Bearer ${token.realm}`,
      },
    );
    return req;
  });

  React.useEffect(() => {
    if (stateVariable !== undefined) {
      // Refetch the query when the state variable changes
      queryClient.invalidateQueries(queryKey);
    }
  }, [stateVariable, queryClient, queryKey]);

  return query;
}

export default useGraphQL;
