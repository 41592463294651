/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
  Typography,
  Zoom,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { Stage } from 'konva/lib/Stage';
import {
  AIButton,
  AIButtonImg,
  AIButtonText,
  AttributeTextField,
  AttributeWrapper,
  BookmarkImg, BookmarkTitleImg, CoinImg, FlexibleHeightImg, HelpImg,
} from '../styledComps/styledComps';
import CustomBorder from '../../images/custom-border-image-1.webp';
import BookmarkImage from '../../images/bookmark.webp';
import TexturedBackground from '../../images/flowerbglite.webp';
import FantasyIcon from '../../images/fantasy.webp';
import HelpIcon from '../../images/help.webp';
import CoinIcon from '../../images/azamel_Coin.png';
import loadingGif from '../../assets/images/loading.gif';
import CharacterAttributes from '../realms/Character/CharacterAttributes';
import {
  Gender, Race, Class, Personality, CharacterAbility, Character_Set,
  GetCharactersSetsDocument, CreateCharacterDocument,
  UpdateCharacterSetDocument, CharacterInsertInput, GetPrompt_TemplateQueryInputDocument,
  GetText_Generation_SkusDocument, Body_Shape, Skin, Eye,
  GetUserCharacterGenerationQuery, GetUserCharacterGenerationQueryVariables,
  GetUserCharacterGenerationDocument,
  CharacterShort_Term_Goal,
  CharacterLong_Term_Goal,
  NoteInsertInput,
  CreateNoteDocument,
} from '../../__generated__/graphql';
import CharacterAbilitiesGen from '../realms/Character/CharacterAbilitiesGen';
import CharacterBackground from '../realms/Character/CharacterBackground';
import { useAuth } from '../../hooks/use-auth';
import useNotification from '../../hooks/useNotification';
import useGraphQL from '../../hooks/useGraphQL';
import useGraphQLMutation from '../../hooks/useGraphQLMutation';
import CharacterPhysicalProfile from '../realms/Character/CharacterPhysicalProfile';
import fetchGraphQL from '../../hooks/fetchers/fetchGraphQL';
import { useGenerateNewCharacterText } from '../../__generated__/realmWorldsApiComponents';
import { CharacterGenerationWorkflowRequest } from '../../__generated__/realmWorldsApiSchemas';
import CharacterImageBox from '../realms/Character/CharacterImageBox';
import appStore from '../../store/AppStore';
import CharacterNotesWrapper from '../notes/CharacterNotesWrapper';
import getAttributeValue from '../../util/aiGetCharAttribute';

type RWModalProps = {
  open: boolean;
  onClose: () => void;
  setOpen?: (v: boolean) => void;
  onConfirm: () => void;
  refetch: () => void;
} & React.PropsWithChildren;

export default function AICampaignCharacterGenModal({
  open,
  onClose,
  setOpen,
  refetch,
}: RWModalProps) {
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const [selectedFileBlob, setSelectedFileBlob] = React.useState<Blob | null>(null);
  const [previewUrl, setPreviewUrl] = React.useState<string | null>(null);
  const [currentStage, setCurrentStage] = React.useState <Stage | null>(null);
  const [characterName, setCharacterName] = React.useState('');
  const [characterAge, setCharacterAge] = React.useState<number>(0);
  const [characterPronouns, setCharacterPronouns] = React.useState('');
  const [characterGender, setCharacterGender] = React.useState<Gender | null>(null);
  const [characterRace, setCharacterRace] = React.useState<Race | null>(null);
  const [characterClass, setCharacterClass] = React.useState<Class | null>(null);
  const [characterAlignment, setCharacterAlignment] = React.useState('');
  const [characterMotivations, setCharacterMotivations] = React.useState<string[]>([]);
  const [characterPersonalityType, setCharacterPersonalityType] = React
    .useState<Personality | null>(null);
  const [characterBodyType, setCharacterBodyType] = React.useState<Body_Shape | null>(null);
  const [characterSkinColor, setCharacterSkinColor] = React.useState<Skin | null>(null);
  const [characterHairColor, setCharacterHairColor] = React.useState('');
  const [characterHairStyle, setCharacterHairStyle] = React.useState('');
  const [characterEyeColor, setCharacterEyeColor] = React.useState<Eye | null>(null);
  const [characterAbilities, setCharacterAbilities] = React.useState<CharacterAbility>({
    strength: '0',
    dexterity: 0,
    consitution: 0,
    intelligence: 0,
    wisdom: 0,
    charisma: 0,
  });
  const [characterProfession, setCharacterProfession] = React.useState('');
  const [characterRelationship, setCharacterRelationship] = React.useState('');
  const [characterLifeAlteringEvent, setCharacterLifeAlteringEvent] = React.useState('');
  const [characterBackStory, setCharacterBackStory] = React.useState('');
  const [characterShortTermGoals, setCharacterShortTermGoals] = React.useState<
  CharacterShort_Term_Goal[]
  >([]);
  const [characterLongTermGoals, setCharacterLongTermGoals] = React.useState<
  CharacterLong_Term_Goal[]
  >([]);
  const [characterNotes, setCharacterNotes] = React.useState<NoteInsertInput[]>(
    [],
  );
  const [charactersCharacterSet, setCharactersCharacterSet] = React.useState({
    name: 'Select a Character Set',
    _id: '',
  });
  const [noteSavedIds, setNoteSavedIds] = React.useState<string[]>([]);
  const [notesSaved, setNotesSaved] = React.useState<number>(0);
  const [readyToSave, setReadyToSave] = React.useState(false);
  const [aiGenIsLoading, setAiGenIsLoading] = React.useState(false);
  const handleClose = () => {
    onClose?.();
    setOpen?.(false);
    appStore.setState({
      userCharacterGeneration: null,
    });
    setPreviewUrl(null);
    setCurrentStage(null);
    setSelectedFile(null);
    setSelectedFileBlob(null);
    setCharacterName('');
    setCharacterAge(0);
    setCharacterPronouns('');
    setCharacterGender(null);
    setCharacterRace(null);
    setCharacterClass(null);
    setCharacterAlignment('');
    setCharacterMotivations([]);
    setCharacterPersonalityType(null);
    setCharacterBodyType(null);
    setCharacterSkinColor(null);
    setCharacterHairColor('');
    setCharacterHairStyle('');
    setCharacterEyeColor(null);
    setCharacterAbilities({
      strength: '0',
      dexterity: 0,
      consitution: 0,
      intelligence: 0,
      wisdom: 0,
      charisma: 0,
    });
    setCharacterProfession('');
    setCharacterRelationship('');
    setCharacterLifeAlteringEvent('');
    setCharacterBackStory('');
    setCharacterShortTermGoals([]);
    setCharacterLongTermGoals([]);
    setCharacterNotes([]);
    setCharactersCharacterSet({
      name: 'Select a Character Set',
      _id: '',
    });
    setNoteSavedIds([]);
    setNotesSaved(0);
    setReadyToSave(false);
    setAiGenIsLoading(false);
  };

  const auth = useAuth();
  const { id: realmId } = useParams();
  const { displayNotification } = useNotification();

  const { data: charactersSets } = useGraphQL(
    GetCharactersSetsDocument,
    {
      query: {
        owner: {
          _id: auth?.user?.uid,
        },
      },
    },
  );

  const { data: promptTemplates } = useGraphQL(
    GetPrompt_TemplateQueryInputDocument,
    {},
  );
  const { data: textGenerationSkus } = useGraphQL(
    GetText_Generation_SkusDocument,
    {},
  );
  const createNewCharacterTextMutate = useGenerateNewCharacterText();

  // const [
  //   userCharacterGeneration,
  //   setUserCharacterGeneration,
  // ] = React.useState<GetUserCharacterGenerationQuery | null | undefined>(null);
  const userCharacterGeneration:
  GetUserCharacterGenerationQuery | null | undefined = appStore((state) => state
    .userCharacterGeneration);
  const setUserCharacterGeneration = (gen: GetUserCharacterGenerationQuery) => {
    appStore.setState({
      userCharacterGeneration: gen,
    });
  };

  const convertFileToBlob = (file: File) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const blob = new Blob([reader.result as ArrayBuffer], { type: file.type });
      setSelectedFileBlob(blob);
    };

    reader.readAsArrayBuffer(file);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      convertFileToBlob(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  React.useEffect(() => {
    if (previewUrl !== null && charactersCharacterSet._id !== ''
    && characterName !== '') {
      setReadyToSave(true);
    } else {
      setReadyToSave(false);
    }
  }, [
    previewUrl, characterAbilities, characterAge, characterAlignment,
    characterClass, characterGender, characterMotivations, characterName,
    characterPersonalityType, characterProfession, characterPronouns,
    characterPronouns, characterRace, charactersCharacterSet,
  ]);

  React.useEffect(() => {
    if (userCharacterGeneration !== (null || undefined)) {
      const character = userCharacterGeneration?.user_character_generation?.attributes;
      if (character) {
        const charShortTermGoal = getAttributeValue(character, 'short_term_goals');
        if (charShortTermGoal !== undefined) {
          setCharacterShortTermGoals([{
            title: 'Short Term Goal 1',
            content: charShortTermGoal,
          }]);
        }
        const charLongTermGoal = getAttributeValue(character, 'long_term_goals');
        if (charLongTermGoal !== undefined) {
          setCharacterLongTermGoals([{
            title: 'Long Term Goal 1',
            content: charLongTermGoal,
          }]);
        }
        const charNotes = getAttributeValue(character, 'additional_notes');
        if (charNotes !== undefined) {
          setCharacterNotes([{
            _id: '',
            title: 'Additional Note 1',
            content: charNotes,
            realm: {
              link: realmId,
            },
          }]);
        }
        // setCharacterName(character[0]?.value as string);
        // setCharacterAge(character[1]?.value)
      }
    }
  }, [userCharacterGeneration]);

  const {
    data: createdCharacter,
    mutate: createCharacter,
    status: createdCharacterStatus,
    error: createCharacterError,
  } = useGraphQLMutation(
    CreateCharacterDocument,
  );

  const {
    data: createdNote,
    mutate: createNote,
    status: createNoteStatus,
    error: createNoteError,
  } = useGraphQLMutation(CreateNoteDocument);

  if (createCharacterError) console.log(createCharacterError);

  const {
    mutate: updateCharacterSet,
    status: updateCharacterSetStatus,
  } = useGraphQLMutation(UpdateCharacterSetDocument);

  const handleSaveCharacter = () => {
    const character: CharacterInsertInput = {
      name: characterName,
      avatar_url: previewUrl,
      character_image_thumbnail_url: previewUrl,
      owner: {
        link: auth?.realmUser?.id,
      },
      realms: {
        link: [realmId],
      },
      race: characterRace?.name,
      class: characterClass?.name,
      gender: characterGender?.name,
      pronoun: characterPronouns,
      motivations: characterMotivations,
      abilities: characterAbilities,
      alignment: characterAlignment,
      physical_profile: {
        body_type: characterBodyType?.name,
        skin_color: characterSkinColor?.name,
        hair_color: characterHairColor,
        hair_style: characterHairStyle,
        eye_color: characterEyeColor?.name,
      },
      profession: characterProfession,
      relationship_status: characterRelationship,
      life_altering_event: characterLifeAlteringEvent,
      backstory: characterBackStory,
      short_term_goals: characterShortTermGoals,
      long_term_goals: characterLongTermGoals,
      notes: {
        link: noteSavedIds,
      },
    };
    console.log(character);
    createCharacter({
      data: character,
    });
  };

  const handleSaveNote = () => {
    if (characterNotes.length > 0 && notesSaved <= characterNotes.length - 1) {
      const newNote: NoteInsertInput = {
        content: characterNotes[notesSaved].content,
        owner: {
          link: auth?.realmUser?.id,
        },
        realm: {
          link: realmId,
        },
        title: characterNotes[notesSaved].title,
      };
      createNote({
        data: newNote,
      });
    } else {
      handleSaveCharacter();
    }
  };

  const handleUploadCharacterImage = (id: string) => {
    const formData = new FormData();
    formData.append('AvatarImage', selectedFileBlob!);
    formData.append('CharacterImage', selectedFileBlob!);
    displayNotification({ message: 'Uploading character image ...', type: 'info' });
    fetch(`${process.env.REACT_APP_API_URL}/Characters/${id}/Images`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          displayNotification({ message: 'Failed to upload character image', type: 'error' });
        }
        return response.json();
      })
      .then((result) => {
        console.log(result);
        displayNotification({ message: 'Character image uploaded successfully', type: 'success' });
        handleClose();
        refetch();
      })
      .catch((error) => {
        console.error('There was a problem: ', error);
        displayNotification({ message: 'Failed to upload character image', type: 'error' });
      });
  };

  /**
   * Converts the current stage to a Blob and initiates character creation.
   *
   * @returns {void}
   */
  const convertStageToBlob = () => {
    console.log(currentStage);
    if (currentStage) {
      const dataUrl = currentStage.toDataURL();
      console.log(dataUrl);
      fetch(dataUrl)
        .then((res) => res.blob())
        .then((blob) => {
          if (blob) {
            setSelectedFileBlob(blob);
            handleSaveNote();
          }
        });
    }
  };

  React.useEffect(() => {
    if (createdCharacterStatus === 'loading') {
      displayNotification({ message: 'Creating character ...', type: 'info' });
    }
    if (createdCharacterStatus === 'error') {
      displayNotification({ message: 'Failed to create character ...', type: 'error' });
    }
    if (createdCharacterStatus === 'success') {
      displayNotification({ message: 'Character created successfully ...', type: 'success' });
      handleUploadCharacterImage(createdCharacter.insertOneCharacter?._id);
      const oldCharacterIDs = charactersSets?.character_sets.find(
        (characterset) => characterset?._id === charactersCharacterSet._id,
      )?.characters?.map((characters) => characters?._id) ?? [];
      // console.log([...oldCharacterIDs, createdCharacter.insertOneCharacter?._id]);
      updateCharacterSet({
        set: {
          characters: {
            link: [...oldCharacterIDs, createdCharacter.insertOneCharacter?._id],
          },
        },
        query: {
          _id: charactersCharacterSet._id,
        },
      });
    }
  }, [createdCharacterStatus]);

  const handleSuccessfulJobSubmission = async (
    data: CharacterGenerationWorkflowRequest,
    workflowId: string,
  ) => {
    console.log('data: ', data.userTextGenerationId);
    let retriesLeft = 60; // 120 seconds total @ 2 seconds per retry
    // poll for completion
    const poll = setInterval(async () => {
      await auth?.getCurrentUserTokens();
      // eslint-disable-next-line max-len
      const userCharacterGenrationResponse = await fetchGraphQL <GetUserCharacterGenerationQuery, GetUserCharacterGenerationQueryVariables>(
        GetUserCharacterGenerationDocument,
        {
          query: {
            user_text_generation: {
              _id: data.userTextGenerationId,
            },
          },
        },
        localStorage.getItem('realmToken') || '',
      );
      console.log('userCharacterGenrationResponse: ', userCharacterGenrationResponse);
      // setUserCharacterGeneration(userCharacterGenrationResponse);
      console.log('polling: ', userCharacterGenrationResponse?.user_character_generation);
      if (userCharacterGenrationResponse?.user_character_generation !== null) {
        console.log('userCharacterGeneration: ', userCharacterGeneration);
        // update all of the state variables
        // setIsLoading(false);
        // HERE IS WHERE YOU WILL UPDATE YOUR UI USING THE ATTRIBUTES
        // setAbilitiesCharisma(
        //   userCharacterGeneration
        //     .user_character_generation
        //     .attributes?.find((attribute) => attribute?.name === 'charisma')?.value || '',
        // );
        setUserCharacterGeneration(
          userCharacterGenrationResponse as GetUserCharacterGenerationQuery,
        );
        /// ... do the rest
        clearInterval(poll);
        setAiGenIsLoading(false);
        displayNotification({ message: 'Successfully generated character text', type: 'success' });
      }
      retriesLeft -= 1;
      console.log('retries left: ', retriesLeft);
      if (retriesLeft === 0) {
        clearInterval(poll);
        setAiGenIsLoading(false);
        displayNotification({ message: 'Failed to generate character text', type: 'error' });
      }
    }, 2000);
  };

  const handleCreateNewCharacterText = () => {
    setAiGenIsLoading(true);
    createNewCharacterTextMutate.mutate({
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: {
        abilities: {
          charisma: characterAbilities.charisma?.toString() ?? undefined,
          constitution: characterAbilities.consitution?.toString() ?? undefined,
          dexterity: characterAbilities.dexterity?.toString() ?? undefined,
          intelligence: characterAbilities.intelligence?.toString() ?? undefined,
          strength: characterAbilities.strength ?? undefined,
          wisdom: characterAbilities.wisdom?.toString() ?? undefined,
        },
        alignment: characterAlignment,
        // aditionalNotes,
        age: characterAge.toString() ?? undefined,
        birthStory: characterBackStory,
        characterName,
        // characterType,
        class: characterClass?.name ?? undefined,
        gender: characterGender?.name ?? undefined,
        // height,
        hairColor: characterHairColor,
        // lifeAlteringEvents,
        // longTermGoals,
        motivations: characterMotivations[0] ?? undefined,
        personalityType: characterPersonalityType?.name ?? undefined,
        pronouns: characterPronouns,
        race: characterRace?.name ?? undefined,
        // shortTermGoals,
        // weight,
        textGenerationSkuId: textGenerationSkus?.text_generation_skus[0]?._id,
        promptTemplateId: promptTemplates?.prompt_templates[0]?._id,
      },
    }, {
      onSuccess: (data) => {
        console.log('data: ', data);
        console.log('setting userTextGenerationid: ', data.userTextGenerationId);
        if (data.userTextGenerationId === undefined || data.userTextGenerationId === null) {
          console.log('userTextGenerationId is undefined');
          return;
        }
        handleSuccessfulJobSubmission(data, data.userTextGenerationId);
      },
      onError: (error) => {
        console.log('error: ', error);
      },
    });
  };

  React.useEffect(() => {
    if (notesSaved > 0) {
      handleSaveNote();
    }
  }, [notesSaved]);

  React.useEffect(() => {
    if (createNoteStatus === 'loading') {
      displayNotification({ message: 'Creating note ...', type: 'info' });
    }
    if (createNoteStatus === 'error') {
      displayNotification({
        message: 'Failed to create note ...',
        type: 'error',
      });
    }
    if (createNoteStatus === 'success') {
      // notesSavedIds = [...notesSavedIds, createdNote.insertOneNote?._id];
      const totalNotes = characterNotes.length;
      setNoteSavedIds([...noteSavedIds, createdNote.insertOneNote?._id]);
      if (notesSaved < totalNotes) {
        setNotesSaved(notesSaved + 1);
        // console.log('notes saved', notesSaved);
      } else if (notesSaved === totalNotes) {
        displayNotification({
          message: 'Note created successfully ...',
          type: 'success',
        });
        console.log('note ids', noteSavedIds);
        // console.log('note info', createdNote);
        handleSaveCharacter();
      }
    }
  }, [createNoteStatus]);

  React.useEffect(() => {
    if (updateCharacterSetStatus === 'loading') {
      displayNotification({ message: 'Adding character to character set ...', type: 'info' });
    }
    if (updateCharacterSetStatus === 'error') {
      displayNotification({ message: 'Failed to add character to a character set', type: 'error' });
    }
    if (updateCharacterSetStatus === 'success') {
      displayNotification({ message: 'Successfully added character to character set', type: 'success' });
    }
  }, [updateCharacterSetStatus]);

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            backdropFilter: 'blur(4px)',
          },
        },
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ zIndex: 997 }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '1507px',
          minHeight: '540px',
          maxHeight: '75%',
          outline: 'none',
          // overflow: 'hidden',
          boxSizing: 'border-box',
          border: '8px solid #68583D',
          borderImageSource: `url(${CustomBorder})`,
          borderImageSlice: '17',
          borderImageRepeat: 'round',
          background: `url(${TexturedBackground})`,
          backgroundPosition: 'top center',
          backgroundSize: 'cover',
          // backgroundColor: '#D0B17A',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            boxSizing: 'border-box',
            '&:before, &:after': {
              boxSizing: 'inherit',
            },
            pb: '20px',
          }}
        >
          {/* Bookmark Title */}
          <Box
            sx={{
              position: 'relative',
              width: '360px',
              height: '64px',
              mt: '-45px',
              ml: '-9px',
              zIndex: '10',
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              gap="15px"
              zIndex={2}
              position="absolute"
              left="44px"
              top="50%"
              sx={{
                transform: 'translate(0%, -50%)',
              }}
            >
              <BookmarkTitleImg src={FantasyIcon} />
              <Typography
                sx={{
                  color: '#FFF',
                  fontFamily: 'Passero One',
                  fontSize: '20px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                }}
              >
                AI Character Generator
              </Typography>
            </Stack>
            <BookmarkImg src={BookmarkImage} />
          </Box>
          {/* Greetings */}
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              minHeight: 'calc(540px - 43px)',
              maxHeight: 'calc(75vh - 43px)',
              boxSizing: 'border-box',
              ml: '-20px',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '8px',
                backgroundColor: '#CFCAAD',
                borderRadius: '8px',
              },
              '&.scrollbar': {
                scrollbarWidth: 'thin',
                scrollbarColor: '#CFCAAD',
                backgroundColor: '#CFCAAD',
                borderRadius: '8px',
              },
              '&::-webkit-scrollbar-thumb, &.scrollbar-thumb, &::-webkit-scrollbar-thumb:hover, &.scrollbar-thumb:hover':
                {
                  backgroundColor: '#41521F',
                  borderRadius: '8px',
                },
            }}
          >
            <Stack pl="92px" pr="72px" py="92px" gap="40px">
              <Stack>
                <Typography
                  sx={{
                    color: '#250503',
                    fontFamily: 'Passero One',
                    fontSize: '36px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '50px',
                    letterSpacing: '.1px',
                  }}
                >
                  👋  Hello, fellow adventurer,
                </Typography>
                <Typography
                  sx={{
                    color: '#250503',
                    fontFamily: 'DM Sans',
                    fontSize: '24px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '30px',
                    letterSpacing: '.1px',
                    mt: '30px',
                  }}
                >
                  {/* eslint-disable-next-line max-len */}
                  In our &quot;AI Generate&quot; feature, just choose basics like race, class, and gender, and our AI will magically craft the rest of your character&apos;s tale. Want full control? Fill in all details and save. Or mix it up - add some traits and let the AI do the rest. Let&apos;s create something epic together! 🐉 ✨
                </Typography>

                <Box
                  pt="25px"
                >
                  <Stack direction="row" gap="27px" alignItems="flex-start">
                    {/* Character Image Box */}
                    <CharacterImageBox
                      previewUrl={previewUrl}
                      setPreviewUrl={setPreviewUrl}
                      handleFileChange={handleFileChange}
                      handleStage={(stage: Stage) => {
                        setCurrentStage(stage);
                      }}
                    />
                    {/* Character Attributes */}
                    <CharacterAttributes
                      characterName={characterName}
                      setCharacterName={(char: string) => setCharacterName(char)}
                      characterAge={characterAge}
                      setCharacterAge={(char: number) => setCharacterAge(char)}
                      characterPronouns={characterPronouns}
                      setCharacterPronouns={(char: string) => setCharacterPronouns(char)}
                      characterGender={characterGender}
                      setCharacterGender={(char: Gender) => setCharacterGender(char)}
                      characterRace={characterRace}
                      setCharacterRace={(char: Race) => setCharacterRace(char)}
                      characterClass={characterClass}
                      setCharacterClass={(char: Class) => setCharacterClass(char)}
                      characterAlignment={characterAlignment}
                      setCharacterAlignment={(char: string) => setCharacterAlignment(char)}
                      characterPersonalityType={characterPersonalityType}
                      setCharacterPersonalityType={
                        (char: Personality) => setCharacterPersonalityType(char)
                      }
                      characterMotivations={characterMotivations}
                      setCharacterMotivations={(char: string[]) => setCharacterMotivations(char)}
                    />
                  </Stack>
                </Box>
              </Stack>
              {/* Character Physical Profile */}
              <CharacterPhysicalProfile
                characterBodyType={characterBodyType}
                setCharacterBodyType={(char: Body_Shape) => setCharacterBodyType(char)}
                characterSkinColor={characterSkinColor}
                setCharacterSkinColor={(char: Skin) => setCharacterSkinColor(char)}
                characterHairColor={characterHairColor}
                setCharacterHairColor={(char: string) => setCharacterHairColor(char)}
                characterHairStyle={characterHairStyle}
                setCharacterHairStyle={(char: string) => setCharacterHairStyle(char)}
                characterEyeColor={characterEyeColor}
                setCharacterEyeColor={(char: Eye) => setCharacterEyeColor(char)}
              />
              {/* Character Background */}
              <CharacterBackground
                characterProfession={characterProfession}
                setCharacterProfession={(char: string) => setCharacterProfession(char)}
                characterBackStory={characterBackStory}
                setCharacterBackStory={(char: string) => setCharacterBackStory(char)}
                characterRelationship={characterRelationship}
                setCharacterRelationship={(char: string) => setCharacterRelationship(char)}
                characterLifeAlteringEvent={characterLifeAlteringEvent}
                setCharacterLifeAlteringEvent={(char: string) => {
                  setCharacterLifeAlteringEvent(char);
                }}
                // variant="AIGen"
              />
              {/* <CharacterAbilitiesGen /> */}
              <CharacterAbilitiesGen
                abilities={characterAbilities}
                setCharacterAbilities={(ab: CharacterAbility) => setCharacterAbilities(ab)}
              />
              {/* Notes */}
              <Stack direction="row" gap="28px" flexWrap="nowrap">
                <Box sx={{ width: '33.3333%' }}>
                  <CharacterNotesWrapper
                    title="Notes"
                    notes={characterNotes}
                    setNotes={(notes: any[]) => setCharacterNotes(notes)}
                    realmNote
                  />
                </Box>
                <Box sx={{ width: '33.3333%' }}>
                  <CharacterNotesWrapper
                    title="short term goals"
                    notes={characterShortTermGoals}
                    setNotes={(goals: any[]) => setCharacterShortTermGoals(goals)}
                  />
                </Box>
                <Box sx={{ width: '33.3333%' }}>
                  <CharacterNotesWrapper
                    title="long term goals"
                    notes={characterLongTermGoals}
                    setNotes={(goals: any[]) => setCharacterLongTermGoals(goals)}
                  />
                </Box>
              </Stack>
              {/* Helpers and Save */}
              <Stack direction="row" alignItems="top" justifyContent="space-between">
                <Stack direction="row" gap="30px" alignItems="center">
                  <Stack direction="row" gap="3px" alignItems="center">
                    <AIButton onClick={handleCreateNewCharacterText}>
                      <AIButtonImg src={FantasyIcon} />
                      <AIButtonText>AI Scribe</AIButtonText>
                    </AIButton>
                    <Tooltip
                      title="A help note about AI scribe"
                      arrow
                      placement="bottom"
                      TransitionComponent={Zoom}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            fontFamily: 'Passero One',
                            fontWeight: '400px',
                            fontSize: '14px',
                            padding: '10px',
                            bgcolor: '#3c3c3ce6',
                            '& .MuiTooltip-arrow': {
                              color: '#3c3c3ce6',
                            },
                            maxWidth: '250px',
                          },
                        },
                      }}
                    >
                      <HelpImg src={HelpIcon} style={{ marginTop: '-40px' }} />
                    </Tooltip>
                    <Stack direction="row" alignItems="center">
                      <CoinImg src={CoinIcon} />
                      <Typography
                        sx={{
                          color: '#250503',
                          fontFamily: 'Passero One',
                          fontSize: '20px',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          lineHeight: '20px',
                          letterSpacing: '.1px',
                        }}
                      >
                        2
                      </Typography>
                    </Stack>
                  </Stack>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        inputProps={{ 'aria-label': 'Include AI Image Generation' }}
                        sx={{
                          color: '#E9DCC3',
                          boxSizing: 'border-box',
                          borderRadius: '4px',
                          '&.Mui-checked': {
                            color: '#41521F',
                          },
                        }}
                      />
                    )}
                    label="Include Image Generation"
                    sx={{
                      '.MuiTypography-root': {
                        color: '#1D1B20',
                        fontFamily: 'DM Sans',
                        fontSize: '16pxpx',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '24px',
                        letterSpacing: '.5px',
                      },
                    }}
                  />
                </Stack>
                <Stack direction="row" gap="13px" maxHeight="60px">
                  <AttributeWrapper
                    width="260px"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                  >
                    <Select
                      value={charactersCharacterSet.name}
                      onChange={(event: SelectChangeEvent<string>) => {
                        const setName:
                        Character_Set | undefined | null = charactersSets?.character_sets.find(
                          (set) => set?._id === event.target.value,
                        ) as Character_Set | undefined | null;
                        setCharactersCharacterSet({
                          name: setName?.name ?? 'Select Character Set',
                          _id: event.target.value,
                        });
                      }}
                      input={(
                        <AttributeTextField
                          fullWidth
                          sx={{
                            width: '248px',
                          }}
                        />
                      )}
                      renderValue={(selected) => {
                        if (selected === '') {
                          return 'Select Character Set';
                        }
                        return selected;
                      }}
                    >
                      <MenuItem value="">Select Character Set</MenuItem>
                      {charactersSets?.character_sets.map((character_set) => (
                        <MenuItem key={character_set?._id} value={character_set?._id}>
                          {character_set?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </AttributeWrapper>
                  <Button
                    sx={{
                      display: 'flex',
                      padding: '10px 24px',
                      borderRadius: '4px',
                      color: 'white',
                      fontFamily: 'Passero One',
                      fontSize: '20px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '16px', /* 150% */
                      letterSpacing: '.1px',
                      height: '60px',
                      background: '#41521F',
                      '&:hover': {
                        background: '#41521F',
                      },
                    }}
                    disabled={!readyToSave}
                    onClick={convertStageToBlob}
                  >
                    Save Character
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Box>
        {aiGenIsLoading && (
          <FlexibleHeightImg
            width="520px"
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1000,
            }}
            src={loadingGif}
          />
        )}
      </Box>
    </Modal>
  );
}

AICampaignCharacterGenModal.defaultProps = {
  setOpen: () => {},
};
