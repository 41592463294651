/* eslint-disable object-curly-newline */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable operator-linebreak */
/* eslint-disable nonblock-statement-body-position */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../hooks/use-auth';
import useGraphQLMutation from '../../../hooks/useGraphQLMutation';
import {
  CreateCharacterDocument,
  CreateCharacterSetDocument,
  UpdateCharacterSetDocument,
  GetCharactersDocument,
  GetCharactersSetsDocument,
  DeleteOneCharacterDocument,
  CreateRealmDocument,
  DeleteManyRealmsDocument,
  GetUserWalletDocument,
  GetUserWalletQuery,
  RealmInsertInput,
  CharacterInsertInput,
} from '../../../__generated__/graphql';
import useGraphQL from '../../../hooks/useGraphQL';

const getAvaliableCreidts = (query: GetUserWalletQuery) => {
  let credits = 0;
  if (query?.user?.wallet) {
    credits += query.user.wallet?.ssgpCreditPackCreditsRemaining ?? 0;
    credits += query.user.wallet?.ssgpSubscriptionCreditsRemaining ?? 0;
    credits -= query.user.wallet?.ssgpPendingCredits ?? 0;
  }
  return credits;
};

export default function RWSettings(): JSX.Element {
  const [characterSetId, setCharacterSetId] = React.useState('');
  const [characterId, setCharacterId] = React.useState('');
  // const [cachedId, setCachedId]
  const auth = useAuth();
  const navigate = useNavigate();
  const [counter, setCounter] = React.useState(0);
  const [generatedCharacterIDs, setGeneratedCharacterIDs] = React.useState<
    any[]
  >([]);
  const sampleData: RealmInsertInput[] = [
    {
      name: 'Eiralia: The Verdant Plains',
      description:
        'A realm of rolling green hills, expansive farmlands, and dense forests that stretch as far as the eye can see.',
      is_favorited: false,
      is_public: false,
      tags: [
        'hills',
        'farmlands',
        'forests',
        'humans',
        'elves',
        'dryads',
        'centaurs',
        'woodland',
        'dire wolves',
        'satyrs',
        'treants',
      ],
      thumbnail_url:
        'https://storage.googleapis.com/smartai-stockimage-dev-storage-watermark-na/Environments/20230517/88c41670-55a7-4095-be20-c3d05107ffe8.png',
      owner: {
        link: auth?.realmUser?.id,
      },
    },
    {
      name: 'Frostholme: The Eternal Winter',
      description:
        'A tundra-like wasteland covered in snow and ice with blizzards that never cease.',
      is_favorited: false,
      is_public: false,
      tags: [
        'tundra',
        'snow',
        'ice',
        'blizzards',
        'Frost Giants',
        'Ice Dwarves',
        'yetis',
        'White Dragons',
        'Ice Elementals',
        'Winter Wolves',
      ],
      thumbnail_url:
        'https://storage.googleapis.com/smartai-stockimage-dev-storage-watermark-na/Environments/20230704/f768d0c3-344a-4828-94ca-aad7818ce2c3.png',
      owner: {
        link: auth?.realmUser?.id,
      },
    },
    {
      name: 'Pyronia: Land of Infernos',
      description:
        'A volcanic realm filled with flowing rivers of lava and spewing volcanoes.',
      is_favorited: false,
      is_public: false,
      tags: [
        'volcanos',
        'lava',
        'fire elementals',
        'salamanders',
        'red dragons',
        'magma golems',
        'hell hounds',
      ],
      thumbnail_url:
        'https://storage.googleapis.com/smartai-stockimage-dev-storage-watermark-na/Environments/20230517/bad3ba6e-15ea-4b1f-8c6c-e681deae0efd.png',
      owner: {
        link: auth?.realmUser?.id,
      },
    },
    {
      name: 'Skymist Isles: Floating Archipelago',
      description: 'Islands floating in the sky, held aloft by ancient magics.',
      is_favorited: false,
      is_public: false,
      tags: [
        'islands',
        'sky',
        'Aarakocra',
        'Air Genasi',
        'Cloud Giants',
        'Rocs',
        'Griffins',
        'Harpies',
      ],
      thumbnail_url: '',
      owner: {
        link: auth?.realmUser?.id,
      },
    },
    {
      name: 'Oceantide: The Abyssal Depths',
      description:
        'Underwater cities built into coral reefs and the dark abyssal plains.',
      is_favorited: false,
      is_public: false,
      tags: [
        'Underwater',
        'coral',
        'reefs',
        'abyss',
        'Merfolk',
        'Water Genasi',
        'Tritons',
        'Krakens',
        'Aboleths',
        'Sea Serpents',
      ],
      thumbnail_url:
        'https://storage.googleapis.com/smartai-stockimage-dev-storage-watermark-na/Robots/20230825/aa608acc-6f39-4bb1-a958-3ca30c9121f2.png',
      owner: {
        link: auth?.realmUser?.id,
      },
    },
    {
      name: 'Shadowfen: The Dark Marshes',
      description:
        'Swampy terrain filled with dense fog, twisted trees, and eerie darkness.',
      is_favorited: false,
      is_public: false,
      tags: [
        'swamp',
        'fog',
        'eerie',
        'Lizardfolk',
        'Hags',
        'Shadowfell',
        "Will-o'-the-Wisps",
        'Shambling Mounds',
        'Chuuls',
      ],
      thumbnail_url:
        'https://storage.googleapis.com/smartai-stockimage-dev-storage-watermark-na/Fantasy/20230517/fee6a354-6d61-42c1-9654-2be03fb17e81.png',
      owner: {
        link: auth?.realmUser?.id,
      },
    },
    {
      name: 'Quartzpeak: Crystal Caverns',
      description:
        'A subterranean realm of glittering crystal caverns illuminated by bioluminescent fungi.',
      is_favorited: false,
      is_public: false,
      tags: [
        'subterranean',
        'crystal',
        'caverns',
        'bioluminescence',
        'fungi',
        'Dwarves',
        'Gnomes',
        'Myconids',
        'Umber Hulks',
        'Ropers',
        'Cave Trolls',
      ],
      thumbnail_url:
        'https://storage.googleapis.com/smartai-stockimage-dev-storage-watermark-na/Environments/20230306/ab44c5c7-197e-4e89-89b3-45a1c26b846f.png',
      owner: {
        link: auth?.realmUser?.id,
      },
    },
    {
      name: 'Zenithar: The Celestial Spires',
      description:
        'Towering mountain ranges that pierce the heavens, with air thinning at the peaks.',
      is_favorited: false,
      is_public: false,
      tags: [
        'mountains',
        'heavens',
        'peaks',
        'Goliaths',
        'Celestials',
        'Monks',
        'Mountain Giants',
        'Thunderbirds',
        'Couatls',
      ],
      thumbnail_url:
        'https://storage.googleapis.com/smartai-stockimage-dev-storage-watermark-na/Environments/20230602/8e5c558c-bed9-42d0-a2fc-d21d96566a95.png',
      owner: {
        link: auth?.realmUser?.id,
      },
    },
    {
      name: 'Eldara: The Arcane Nexus',
      description:
        'A cityscape that is the hub of all magical arts and arcane research in the multiverse.',
      is_favorited: true,
      is_public: false,
      tags: [
        'magic',
        'arts',
        'arcane',
        'multiverse',
        'Wizards',
        'Sorcerers',
        'Warlocks',
        'Golems',
        'Animated Objects',
        'Arcane Aberrations',
      ],
      thumbnail_url:
        'https://storage.googleapis.com/smartai-stockimage-dev-storage-watermark-na/Fantasy/20230804/719bc992-67c8-4226-afc0-19f828bf5faa.png',
      owner: {
        link: auth?.realmUser?.id,
      },
    },
    {
      name: 'Lunaris: Realm of Dreams',
      description:
        'A dimension where dreams, nightmares, and fantasies come to life.',
      is_favorited: true,
      is_public: true,
      tags: [
        'dreams',
        'nightmares',
        'fantasies',
        'Dreamwalkers',
        'Night Hags',
        'Ethereal Beings',
        'Thoughtforms',
        'Dream Dragons',
        'Sleeping Spirits',
      ],
      thumbnail_url:
        'https://storage.googleapis.com/smartai-stockimage-dev-storage-watermark-na/Fantasy/20230517/88c41670-55a7-4095-be20-c3d05107ffe8.png',
      owner: {
        link: auth?.realmUser?.id,
      },
    },
  ];

  const characters: CharacterInsertInput[] = [
    {
      name: 'Sacred Mother',
      avatar_url:
        'https://danielrunor.com/realms-worlds-temp/purchase-placeholder-1.webp',
      character_image_thumbnail_url:
        'https://danielrunor.com/realms-worlds-temp/purchase-placeholder-1.webp',

      owner: {
        link: auth?.realmUser?.id,
      },
      race: 'elf',
      motivations: ['heroism survivor', 'explorer'],
      abilities: {
        strength: '15',
        dexterity: 25,
        consitution: 12,
        intelligence: 23,
        wisdom: 17,
        charisma: 8,
      },
      physical_profile: {
        body_type: 'slim',
        skin_color: 'fair',
        hair_color: 'blonde',
        hair_style: 'curly',
        eye_color: 'silver',
      },
      // notes: {
      //   create: [
      //     {
      //       title: 'Note 1',
      //       content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.',
      //       owner: {
      //         link: auth?.realmUser?.id,
      //       },
      //       realm: {
      //         link: '6553edc3b24bd08ce51396cd',
      //       },
      //     },
      //   ],
      // },
      // goals:
      //   [
      //     {
      //       title: 'Goal 1',
      //       content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.',
      //     },
      //   ],
    },
    {
      name: 'Stained Glass',
      avatar_url:
        'https://danielrunor.com/realms-worlds-temp/purchase-placeholder-2.webp',
      character_image_thumbnail_url:
        'https://danielrunor.com/realms-worlds-temp/purchase-placeholder-2.webp',

      owner: {
        link: auth?.realmUser?.id,
      },
      race: 'tiefling',
      motivations: ['explorer'],
      abilities: {
        strength: '15',
        dexterity: 15,
        consitution: 12,
        intelligence: 19,
        wisdom: 17,
        charisma: 12,
      },
      physical_profile: {
        body_type: 'slim',
        skin_color: 'fair',
        hair_color: 'blue',
        hair_style: 'curly',
        eye_color: 'blue',
      },
      // notes: {
      //   create: [
      //     {
      //       title: 'Note 1',
      //       content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.',
      //       owner: {
      //         link: auth?.realmUser?.id,
      //       },
      //       realm: {
      //         link: '6553edc3b24bd08ce51396cd',
      //       },
      //     },
      //   ],
      // },
      // goals:
      //       [
      //         {
      //           title: 'Goal 1',
      //           content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.',
      //         },
      //       ],
    },
    {
      name: 'Shayera',
      avatar_url:
        'https://danielrunor.com/realms-worlds-temp/purchase-placeholder-3.webp',
      character_image_thumbnail_url:
        'https://danielrunor.com/realms-worlds-temp/purchase-placeholder-3.webp',

      owner: {
        link: auth?.realmUser?.id,
      },
      race: 'human',
      motivations: ['explorer'],
      abilities: {
        strength: '15',
        dexterity: 15,
        consitution: 12,
        intelligence: 19,
        wisdom: 17,
        charisma: 12,
      },
      physical_profile: {
        body_type: 'slim',
        skin_color: 'brown',
        hair_color: 'black',
        hair_style: 'curly',
        eye_color: 'brown',
      },
      // notes: {
      //   create: [
      //     {
      //       title: 'Note 1',
      //       content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.',
      //       owner: {
      //         link: auth?.realmUser?.id,
      //       },
      //       realm: {
      //         link: '6553edc3b24bd08ce51396cd',
      //       },
      //     },
      //   ],
      // },
      // goals:
      //       [
      //         {
      //           title: 'Goal 1',
      //           content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.',
      //         },
      //       ],
    },
    {
      name: 'Rynera',
      avatar_url:
        'https://danielrunor.com/realms-worlds-temp/purchase-placeholder-4.webp',
      character_image_thumbnail_url:
        'https://danielrunor.com/realms-worlds-temp/purchase-placeholder-4.webp',

      owner: {
        link: auth?.realmUser?.id,
      },
      race: 'dragonborn',
      motivations: ['explorer'],
      abilities: {
        strength: '24',
        dexterity: 24,
        consitution: 24,
        intelligence: 24,
        wisdom: 24,
        charisma: 24,
      },
      physical_profile: {
        body_type: 'slim',
        skin_color: 'brown',
        hair_color: 'black',
        hair_style: 'curly',
        eye_color: 'brown',
      },
      // notes: {
      //   create: [
      //     {
      //       title: 'Note 1',
      //       content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.',
      //       owner: {
      //         link: auth?.realmUser?.id,
      //       },
      //       realm: {
      //         link: '6553edc3b24bd08ce51396cd',
      //       },
      //     },
      //   ],
      // },
      // goals:
      //       [
      //         {
      //           title: 'Goal 1',
      //           content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.',
      //         },
      //       ],
    },
    {
      name: 'Javierre',
      avatar_url:
        'https://danielrunor.com/realms-worlds-temp/purchase-placeholder-5.webp',
      character_image_thumbnail_url:
        'https://danielrunor.com/realms-worlds-temp/purchase-placeholder-5.webp',

      owner: {
        link: auth?.realmUser?.id,
      },
      race: 'elf',
      motivations: ['explorer'],
      abilities: {
        strength: '14',
        dexterity: 15,
        consitution: 12,
        intelligence: 19,
        wisdom: 17,
        charisma: 24,
      },
      physical_profile: {
        body_type: 'slim',
        skin_color: 'brown',
        hair_color: 'black',
        hair_style: 'curly',
        eye_color: 'brown',
      },
      // notes: {
      //   create: [
      //     {
      //       title: 'Note 1',
      //       content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.',
      //       owner: {
      //         link: auth?.realmUser?.id,
      //       },
      //       realm: {
      //         link: '6553edc3b24bd08ce51396cd',
      //       },
      //     },
      //   ],
      // },
      // goals:
      //       [
      //         {
      //           title: 'Goal 1',
      //           content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.',
      //         },
      //       ],
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    data: createdCharacter,
    mutate: createCharacter,
    error: createCharacterError,
    status: createdCharacterStatus,
  } = useGraphQLMutation(CreateCharacterDocument);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    data: createdCharacterSet,
    mutate: createCharacterSet,
    error: createCharacterSetError,
  } = useGraphQLMutation(CreateCharacterSetDocument);

  const {
    mutate: updateCharacterSet,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    status: updateCharacterSetStatus,
  } = useGraphQLMutation(UpdateCharacterSetDocument);
  const { mutate: createRealm, error: createRealmError } =
    useGraphQLMutation(CreateRealmDocument);

  const {
    mutate: deleteManyRealms,
    error: deleteManyError,
    isSuccess: deleteManySuccess,
    data: deleteManyData,
  } = useGraphQLMutation(DeleteManyRealmsDocument);
  const { data: userProfile, error: userProfileError } = useGraphQL(
    GetUserWalletDocument,
    {
      query: {
        _id: auth?.user?.uid,
      },
    },
  );
  const { data: charactersList } = useGraphQL(GetCharactersDocument, {});

  const { data: charactersSetsList } = useGraphQL(
    GetCharactersSetsDocument,
    {},
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    data: removedCharacter,
    mutate: removeCharacter,
    error: removeCharacterError,
  } = useGraphQLMutation(DeleteOneCharacterDocument);

  if (userProfileError) {
    console.log('user profile error: ', userProfileError);
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleDelCharacter = async (id: any) => {
    const delData: any = {
      _id: id,
    };
    try {
      await removeCharacter({
        query: delData,
      });
      console.log('Deleted', removedCharacter);
    } catch (error: any) {
      console.error('Error removing fave:', error.message);
      // Handle unexpected errors
    }
  };
  // const [cachedCharacterList, setCachedCharacterList] = React.useState<any>([]);
  React.useEffect(() => {
    // if (charactersList) setCachedCharacterList(charactersList?.characters);
    // console.log(cachedCharacterList?.map((character: any) => character._id), 'Characters');
    // cachedCharacterList?.map((character: any) => handleDelCharacter(character._id));
    console.log(charactersList, 'Character');
    console.log(charactersSetsList, 'Character Set');
  }, [charactersList, charactersSetsList]);

  const handleUpdateCharacterSet = async (charSetId: string, charId: any[]) => {
    try {
      console.log('updating character_set and character');

      await updateCharacterSet({
        set: {
          characters: {
            link: charId,
          },
        },
        query: {
          _id: charSetId,
        },
      });
      console.log('character update success');
      // Handle success response from the server
    } catch (error: any) {
      console.error('Error adding to fave:', error.message);
      // Handle unexpected errors
    }
  };

  const generateCharacters = async () => {
    createCharacter({
      data: characters[counter],
    });
    // for (let i = 0; i < characters.length; i += 1) {
    //   const character = characters[i];
    //   createCharacter({
    //     data: character,
    //   });
    // }
  };
  React.useEffect(() => {
    console.log('Inside status', createdCharacter);
    console.log('create status:', createdCharacterStatus);
  }, [createdCharacterStatus, createdCharacter]);

  // const handleAddCharacter = async () => {
  //   try {
  //     console.log('creating character with data: ', characters);
  //     // Use the createCharacter mutation function from your GraphQL client
  //     await createCharacter({
  //       data: characters,
  //     });

  //     counter += 1;
  //     console.log('counter', counter);
  //     console.log('character creation success');
  //     // Handle success response from the server
  //   } catch (error: any) {
  //     console.error('Error adding character:', error.message);
  //     // Handle unexpected errors
  //   }
  // };

  const handleAddCharacterSet = async () => {
    const inputData: any = {
      // _id: '656e31063593c685ba3c08e3',
      name: 'RW Character Set',
      owner: {
        link: auth?.realmUser?.id,
      },
      background_url:
        'https://cdn.midjourney.com/86bb53de-c4f7-4ccb-9860-eb90d6cd9822/0_0.webp',
    };
    try {
      // Use the createCharacter mutation function from your GraphQL client
      await createCharacterSet({
        data: inputData,
      });

      console.log('character_set creation success', createdCharacterSet);
      generateCharacters();
      // Handle success response from the server
    } catch (error: any) {
      console.error('Error adding character set:', error.message);
      // Handle unexpected errors
    }
  };
  // eslint-disable-next-line consistent-return
  // React.useEffect(() => {
  //   if (createdCharacterSet !== undefined && createdCharacter !== undefined) handleUpdateCharacterSet(characterSetId, characterId);
  // }, [characterSetId, characterId]);

  React.useEffect(() => {
    setCharacterSetId(createdCharacterSet?.insertOneCharacter_set?._id);
  }, [createdCharacterSet]);

  React.useEffect(() => {
    if (createdCharacterStatus === 'success') {
      setGeneratedCharacterIDs([
        ...generatedCharacterIDs,
        createdCharacter.insertOneCharacter?._id,
      ]);
      // generatedCharacterIDs.push(createdCharacter.insertOneCharacter?._id);
      setCounter(counter + 1);
      setTimeout(() => {
        console.log(counter);
        console.log(generatedCharacterIDs);
        if (counter < characters.length) generateCharacters();
        if (generatedCharacterIDs.length === characters.length) {
          handleUpdateCharacterSet(characterSetId, generatedCharacterIDs);
          //   for (let i = 0; i < generatedCharacterIDs.length; i += 1) {
          //     const characterID = generatedCharacterIDs[i];
          //     setTimeout(() => {
          //     }, 10000);
          //   }
        }
      }, 1500);
    }
  }, [createdCharacterStatus]);

  React.useEffect(() => {
    setCharacterId(createdCharacter?.insertOneCharacter?._id);
  }, [createdCharacter]);

  function unsecuredCopyToClipboard(text: string) {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
    }
    document.body.removeChild(textArea);
  }

  const handleCopyAccessToken = async () => {
    const tokens = await auth?.getCurrentUserTokens();
    console.log('tokens: ', tokens);
    const token = tokens.fb ?? '';
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(token);
    } else {
      unsecuredCopyToClipboard(token);
      console.log('copied token: ', token);
    }
  };

  const handleCopyAtlasAccessToken = async () => {
    const tokens = await auth?.getCurrentUserTokens();
    console.log('tokens: ', tokens);
    const token = tokens.realm ?? '';
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(token);
    } else {
      unsecuredCopyToClipboard(token);
      console.log('copied token: ', token);
    }
  };

  if (createRealmError) {
    console.log('error creating dev realms: ', createRealmError);
  }

  const generateRealms = async () => {
    for (let i = 0; i < sampleData.length; i += 1) {
      const realm = sampleData[i];
      console.log('creating realm with data: ', realm);
      createRealm({
        data: realm,
      });
    }
  };

  if (deleteManyError) {
    console.log('error deleting dev realms: ', deleteManyError);
  }
  if (deleteManySuccess) {
    alert(
      `dev realms deleted: ${deleteManyData.deleteManyRealms?.deletedCount}`,
    );
  }

  const handleDeleteDevRealms = async () => {
    console.log('deleting dev realms');
    const confirmedToDelete = window.confirm(
      'Are you sure that you want to delete all dev realms?',
    );
    if (confirmedToDelete) {
      deleteManyRealms({
        query: {
          name_in: sampleData.map((realm) => realm.name),
        },
      });
      console.log('dev realms deleted');
    }
  };
  return (
    <Box sx={{ m: 8 }}>
      <Box
        sx={{
          width: '30vw',
          mt: 4,
          background: 'white',
          pl: 8,
          pr: 8,
          pt: 2,
          pb: 2,
          borderRadius: 2,
          boxShadow: 2,
        }}
      >
        <Box>
          <Typography variant="h6">Account Magement</Typography>
          <Divider />
          <Box
            sx={{ ml: 4 }}
            display="flex"
            flexDirection="column"
            alignItems="left"
            justifyContent="center"
          >
            <Typography sx={{ mt: 4 }} variant="body1">
              {' '}
              Account Details{' '}
            </Typography>
            <Divider />
            <Typography variant="body1">Email: </Typography>
            <Typography variant="overline">{auth?.user?.email}</Typography>
            <Typography variant="body1">Username: </Typography>
            <Typography variant="overline">
              {auth?.user?.displayName}
            </Typography>
            {(process.env.REACT_APP_VERSION || '').includes('dev') && (
              <div>
                <Typography variant="body1">User ID: </Typography>
                <Typography variant="overline">{auth?.user?.uid}</Typography>
                <Typography variant="body1">Realm User ID: </Typography>
                <Typography variant="overline">
                  {auth?.realmUser?.id}
                </Typography>
                <Typography variant="body1">Credits: </Typography>
                <Typography variant="overline">
                  {getAvaliableCreidts(userProfile ?? {})}
                </Typography>
                <Typography variant="body1">Pending Credits: </Typography>
                <Typography variant="overline">
                  {userProfile?.user?.wallet?.ssgpPendingCredits ?? 0}
                </Typography>
              </div>
            )}
          </Box>
          <Box
            sx={{ ml: 4 }}
            display="flex"
            flexDirection="column"
            alignItems="left"
            justifyContent="center"
          >
            <Typography sx={{ mt: 4 }} variant="body1">
              {' '}
              Signout{' '}
            </Typography>
            <Divider />
            <Button
              sx={{ m: 2 }}
              variant="contained"
              onClick={() => {
                auth?.signout();
                navigate('/login');
              }}
            >
              Signout Here
            </Button>
          </Box>
          <Box>
            <Typography variant="body1">Realm World Version: </Typography>
            <Typography variant="overline">
              {process.env.REACT_APP_VERSION}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider />

      {/* Developer Tools -- only render if the app version contains dev */}
      {(process.env.REACT_APP_VERSION || '').includes('dev') && (
        <Box
          sx={{
            width: '30vw',
            mt: 8,
            background: 'white',
            pl: 8,
            pr: 8,
            pt: 2,
            pb: 2,
            borderRadius: 2,
            boxShadow: 2,
          }}
        >
          <Box>
            <Typography variant="h6"> Developer Tools </Typography>
            <Divider />
            <Box
              sx={{ ml: 4 }}
              display="flex"
              flexDirection="column"
              alignItems="left"
              justifyContent="center"
            >
              <Typography sx={{ mt: 4 }} variant="body1">
                {' '}
                Generate Sample Data{' '}
              </Typography>
              <Divider />
              <Button
                sx={{ m: 2 }}
                variant="contained"
                color="secondary"
                onClick={() => handleAddCharacterSet()}
              >
                Generate Character Set and Characters
              </Button>
              <Divider />
              <Button
                sx={{ m: 2 }}
                variant="contained"
                onClick={() => generateRealms()}
              >
                Populate Dev Realms
              </Button>
              <Button
                sx={{ m: 2 }}
                variant="contained"
                color="warning"
                onClick={() => handleDeleteDevRealms()}
              >
                Delete Dev Realms
              </Button>
              <Button
                sx={{ m: 2 }}
                variant="contained"
                color="error"
                onClick={() => navigate('/imageGen')}
              >
                Launch Generator
              </Button>
              <Typography variant="body1"> Copy Access Tokens </Typography>
              <Divider />
              <Button
                sx={{ m: 2 }}
                variant="contained"
                onClick={() => handleCopyAccessToken()}
              >
                Copy SSGP Access Token
              </Button>
              <Button
                sx={{ m: 2 }}
                variant="contained"
                onClick={() => handleCopyAtlasAccessToken()}
              >
                Copy Atlas Access Token
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
