import {
  Box, Card, CardMedia, Grid,
} from '@mui/material';
import React from 'react';

const mockData = [1, 2, 3, 4, 5, 6, 7, 8, 9];

const CharacterCarousel = mockData?.map((result: any) => (
  <Grid
    item
    xs={3}
    sx={{
      p: 1.75,
    }}
    key={result}
  >

    <Card
      sx={{
        display: 'flex',
        borderRadius: '5px',
        background: '#FFF',
        border: '1px solid white',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
      }}
    >
      <Box sx={{
        flex: 1,
        width: 90,
        height: 90,
      }}
      >

        <CardMedia
          component="img"
          image="https://picsum.photos/90/90"
          alt="Live from space album cover"
          height="100%"
        />

      </Box>
    </Card>
  </Grid>
));

export default CharacterCarousel;
