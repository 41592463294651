import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import styles from './panels.module.scss';
import { FloatingMenu, FloatingMenuItem } from '../floatingMenu';
import appStore, { RealmDefaultViewOptions } from '../../store/AppStore';

interface Props {
  closeMenu: () => void;
  // handleSave: () => void;
  // handleSaveAs: () => void;
  handleStageClear: () => void;
  // eslint-disable-next-line react/require-default-props
  // saveDisabled?: boolean;
  openMyCollection: () => void;
  activeTab: string;
}

function Menu({
  closeMenu,
  // handleSave,
  // handleSaveAs,
  handleStageClear,
  // saveDisabled,
  openMyCollection,
  activeTab,
}: Props) {
  const handleFilesClick = () => {
    openMyCollection();
    closeMenu();
  };
  const isMobileScreen = useMediaQuery('(max-width:530px)');

  // const handleSaveClick = () => {
  //   handleSave();
  //   closeMenu();
  // };

  // const handleSaveAsClick = () => {
  //   handleSaveAs();
  //   closeMenu();
  // };

  const handleClearCanvasClick = () => {
    handleStageClear();
    closeMenu();
  };

  const { id } = useParams();
  const navigate = useNavigate();
  const realmDefaultView: RealmDefaultViewOptions = appStore(
    (state) => state.realmDefaultView,
  );

  return (
    <div className={styles.menu}>
      <FloatingMenu style={{ top: isMobileScreen ? 'calc(100vh - 420px)' : '', left: isMobileScreen ? '-16px' : '' }}>
        <FloatingMenuItem
          onClick={handleFilesClick}
          active={activeTab === 'mycollection'}
        >
          Files
        </FloatingMenuItem>
        {/* <FloatingMenuItem onClick={handleSaveClick} disabled={saveDisabled}>
          Save
        </FloatingMenuItem>
        <FloatingMenuItem onClick={handleSaveAsClick}>
          Save as
        </FloatingMenuItem> */}
        <FloatingMenuItem onClick={handleClearCanvasClick}>
          Clear Canvas
        </FloatingMenuItem>
        {/* <FloatingMenuItem>View Collection</FloatingMenuItem> */}
        <FloatingMenuItem
          onClick={() => {
            handleClearCanvasClick();
            navigate(`/realm/${id}/${realmDefaultView}`);
          }}
        >
          Exit Session
        </FloatingMenuItem>
      </FloatingMenu>
    </div>
  );
}

export default Menu;
