import React from 'react';
import s from './DashboardTabButton.module.scss';

interface TabButtonProps {
  active: boolean;
  name: string;
  disabled: boolean;
  onClick: () => void;
}

/**
 * A button component representing a tab in the dashboard.
 *
 * @component
 * @example
 * // Example usage of DashboardTabButton component
 * <DashboardTabButton active={true} name="Home" onClick={() => handleTabClick("Home")} />
 *
 * @param {Object} props - The properties of the component.
 * @param {boolean} props.active - Indicates whether the tab is currently active.
 * @param {string} props.name - The name or label of the tab.
 * @param {Function} props.onClick - The function to be called when the tab is clicked.
 *
 * @returns {JSX.Element} React element representing a dashboard tab button.
 */

function DashboardTabButton({
  active,
  name,
  disabled,
  onClick,
}: TabButtonProps) {
  return (
    <button
      type="button"
      disabled={disabled}
      onClick={onClick}
      className={active ? `${s.tab_button} ${s.active}` : `${s.tab_button}`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="160"
        height="48"
        viewBox="0 0 160 48"
        fill="none"
      >
        <path
          d="M0 10C0 4.47715 4.47715 0 10 0H111.116C117.101 0 122.771 2.68025 126.57 7.30485L160 48H0V10Z"
          fill="#E9E9E9"
        />
      </svg>
      <div className={s.button_name}>{name}</div>
    </button>
  );
}

export default DashboardTabButton;
