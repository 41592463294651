/* eslint-disable object-curly-newline */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/comma-dangle */
/* eslint-disable operator-linebreak */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
/* eslint-disable @typescript-eslint/indent */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ImageList, ImageListItem, Box, Grid, useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ColorResult, RGBColor } from 'react-color';
import useItem from '../../../hooks/useItem';
import { StageData } from '../../../redux/currentStageData';
import TabPanel from '../../tab/TabPanel';
import { StyledTab, StyledTabs, setTabId } from '../StyledPanel/StyledPanel';
import { canvasHeight, canvasWidth } from '../../CanvasConstants';
import backgroundList from '../../../config/backgrounds.json';
import { PanelItemProps } from '../FramePanel/FramePanel';
import RWColorPicker from '../../colorPicker/RWColorPicker';
import PanelItemNew from '../PanelItem/PanelItemNew';
import { ThemeContext } from '../../../context/ThemeContext';
import '../eachpanelmodifier.scss';

export default function BackgroundPanel({ onStageChange }: PanelItemProps) {
  const { createItem, removeItem, updateItem, stageData } = useItem();
  const targetItemBorder = useMemo(
    () =>
      stageData.find(
        (data) =>
          data.id === 'background-image' ||
          data.attrs.id === 'background-image',
      ),
    [stageData],
  );

  const [imageColor, setImageColor] = React.useState<RGBColor>(() => {
    if (targetItemBorder?.attrs?.color) {
      return targetItemBorder.attrs.color;
    }
    return {
      r: 255,
      g: 255,
      b: 255,
      a: 1.0,
    };
  });

  const [imageColors, setImageColors] = React.useState<ColorResult>();

  const handleColorChange = (color: ColorResult) => {
    setImageColor(color.rgb);
    setImageColors(color);
  };

  const handleColorChangeCompleted = (color: ColorResult) => {
    if (targetItemBorder) {
      const { h, s, l, a } = color.hsl;
      onStageChange('Updated Background Color');
      updateItem(targetItemBorder.id, () => ({
        ...targetItemBorder.attrs,
        hue: h,
        saturation: s,
        luminance: l,
        opacity: a,
        color: color.rgb,
        updatedAt: Date.now(),
      }));

      setImageColor(color.rgb);
      setImageColors(color);
    }
  };

  const insertImage = (data: { [key: string]: any }) => {
    const imageSrc = new Image();
    const imageSource = `${process.env.PUBLIC_URL}/images/backgrounds/${data.imageSrc}`;

    const { h, s, l, a } = imageColors?.hsl ?? {
      h: 0.0,
      s: 0.0,
      l: 0.0,
      a: 1.0,
    };

    removeItem('background-image');
    onStageChange('Updated Background Image');
    imageSrc.onload = () => {
      const isTabletScreen = useMediaQuery('(max-width:865px)');
      const newBackground: StageData = {
        id: 'background-image',
        attrs: {
          name: 'label-target',
          dataItemType: 'image',
          x: isTabletScreen ? (canvasWidth - (data.imageWidth / 1.5)) / 2 : (canvasWidth - data.imageWidth) / 2,
          y: isTabletScreen ? (canvasHeight - (data.imageHeight / 1.5)) / 2 : (canvasHeight - data.imageHeight) / 2,
          width: isTabletScreen ? data.imageWidth / 1.5 : data.imageWidth,
          height: isTabletScreen ? data.imageHeight / 1.5 : data.imageHeight,
          hue: h,
          saturation: s,
          luminance: l,
          opacity: a,
          src: imageSource,
          zIndex: 1,
          draggable: false,
          brightness: 0,
          color: { imageColor },
          filters: ['HSL'],
          updatedAt: Date.now(),
        },
      };
      createItem(newBackground);
    };

    imageSrc.src = imageSource;
  };

  const targetItemBorderBackground = useMemo(
    () =>
      stageData.find(
        (data) =>
          data.id === 'border-background' ||
          data.attrs.id === 'border-background',
      ),
    [stageData],
  );

  const [backgroundColor, setBackgroundColor] = React.useState<RGBColor>(() => {
    if (targetItemBorder?.attrs?.color) {
      return targetItemBorder.attrs.color;
    }
    return {
      r: 0,
      g: 0,
      b: 0,
      a: 1.0,
    };
  });
  const [backgroundColors, setBackgroundColors] = React.useState<ColorResult>();

  const handleBackgroundColorChange = (color: ColorResult) => {
    setBackgroundColor(color.rgb);
    setBackgroundColors(color);
    // document.body.style.backgroundColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
  };

  const handleBackgroundColorChangeCompleted = (color: ColorResult) => {
    if (targetItemBorderBackground) {
      const { r, b, g, a } = color.rgb;
      onStageChange('Updated Background Color');
      updateItem(targetItemBorderBackground.id, (prevData) => ({
        ...targetItemBorderBackground.attrs,
        red: r,
        green: g,
        blue: b,
        opacity: a,
        color: color.rgb,
        updatedAt: Date.now(),
      }));

      setBackgroundColor(color.rgb);
      setBackgroundColors(color);
    }
  };

  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const { theme } = useContext(ThemeContext);

  const [active, setActive] = useState('');
  const localActive = localStorage.getItem('activeBackground');

  useEffect(() => {
    const oldBackgroundColorData = targetItemBorderBackground?.attrs.color;
    setBackgroundColor(oldBackgroundColorData);
    if (localActive !== null) {
      setActive(JSON.parse(localActive));
    } else {
      setActive('');
    }
  }, []);

  // set localstorage for active item
  useEffect(() => {
    localStorage.setItem('activeBackground', JSON.stringify(active));
  }, [active]);

  return (
    <>
      <StyledTabs value={tabValue} onChange={handleTabChange}>
        <StyledTab label="Background" {...setTabId(0)} />
        <StyledTab label="Colors" {...setTabId(0)} />
      </StyledTabs>
      <TabPanel value={tabValue} index={0}>
        <Grid container>
          <Grid item xs={12} display="flex" flexWrap="wrap">
            <ImageList
              cols={2}
              className={
                theme === 'light' ? 'imageList light' : 'imageList dark'
              }
            >
              <PanelItemNew
                onClick={() => {
                  setActive('');
                  removeItem('background-image');
                }}
              >
                <ImageListItem>
                  <Typography textAlign="center">None</Typography>
                </ImageListItem>
              </PanelItemNew>
              {backgroundList.map((item, index) => (
                <PanelItemNew
                  key={index}
                  onClick={() => {
                    setActive(`${item.imageSrc}`);
                    insertImage(item);
                  }}
                  active={active === item.imageSrc}
                >
                  <ImageListItem
                    key={index}
                    className={
                      active === item.imageSrc ? 'active-background' : ''
                    }
                  >
                    <img
                      key={index}
                      src={`${process.env.PUBLIC_URL}/images/backgrounds/${item.imageSrc}`}
                      srcSet={`${process.env.PUBLIC_URL}/images/backgrounds/${item.imageSrc}`}
                      alt={item.id}
                      loading="lazy"
                    />
                  </ImageListItem>
                </PanelItemNew>
              ))}
            </ImageList>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Box>
          <Grid container>
            {/* <Grid item xs={12} mb={2} display="flex" flexWrap="wrap">
              <RWColorPicker
                color={imageColor}
                onChange={handleColorChange}
                onChangeComplete={handleColorChangeCompleted}
              />
            </Grid> */}
            <Grid item xs={12} mb={2} display="flex" flexWrap="wrap">
              <RWColorPicker
                options={{
                  fillLabel: 'Background',
                  showHue: true,
                  showAlpha: false,
                  showSaturation: true,
                  fillClickable: true,
                }}
                color={backgroundColor}
                onChange={handleBackgroundColorChange}
                onChangeComplete={handleBackgroundColorChangeCompleted}
              />
            </Grid>
          </Grid>
        </Box>
      </TabPanel>
    </>
  );
}
