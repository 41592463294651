import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ThemeContext } from '../../../../context/ThemeContext';
import styles from './navbar.module.scss';
import TabButton from '../TabButton';
import HeaderMenu from '../../../../ui/DashboardHeader/HeaderMenu';

interface Tab {
  name: string;
  path: string;
}

interface NavBarProps {
  tabs: Tab[];
}

function NavBar({ tabs }: NavBarProps) {
  const { theme, setTheme } = useContext(ThemeContext);
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className={`${styles.nav} ${styles[theme]}`}>
      <div className={styles.tab_button_wrapper}>
        {tabs.map((tab) => (
          <TabButton
            active={location.pathname.includes(tab.path)}
            name={tab.name}
            key={tab.name}
            onClick={() => navigate(tab.path)}
          />
        ))}
      </div>
      <div className={styles.realm_wrapper}>
        {/* <p className={`${styles.userName} ${styles[theme]}`}>{userName}</p> */}
        <HeaderMenu />
        <button
          type="button"
          className={`${styles.themeButton} ${styles[theme]}`}
          onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')}
        >
          {theme === 'dark' ? (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35">
              <path d="M12 16.625a4.625 4.625 0 1 0 0-9.25 4.625 4.625 0 0 0 0 9.25ZM12 18a6 6 0 1 0 0-12 6 6 0 0 0 0 12Zm-.23-17c.38 0 .688.308.688.688v2.245a.688.688 0 0 1-1.375 0V1.688c0-.38.308-.688.688-.688Zm0 18.38c.38 0 .688.308.688.687v2.245a.687.687 0 1 1-1.375 0v-2.245c0-.38.308-.687.688-.687ZM23 11.77c0 .38-.308.688-.688.688h-2.245a.687.687 0 1 1 0-1.375h2.245c.38 0 .688.308.688.688Zm-18.38 0c0 .38-.308.688-.687.688H1.688a.687.687 0 1 1 0-1.375h2.245c.38 0 .687.308.687.688Zm15.2-7.534a.687.687 0 0 1 0 .972l-1.588 1.588a.688.688 0 0 1-.972-.972l1.587-1.588a.688.688 0 0 1 .973 0ZM6.422 17.07a.687.687 0 0 1 0 .972l-1.588 1.587a.687.687 0 1 1-.972-.972l1.587-1.587a.687.687 0 0 1 .973 0Zm13.798 2.75a.688.688 0 0 1-.973 0l-1.587-1.588a.687.687 0 1 1 .972-.972l1.588 1.587a.688.688 0 0 1 0 .973ZM6.93 6.986a.688.688 0 0 1-.972 0L4.371 5.4a.687.687 0 1 1 .972-.973L6.93 6.014a.688.688 0 0 1 0 .972Z" />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35">
              <path d="M20.113 16.184a9.527 9.527 0 0 1-3.379.616c-5.275 0-9.55-4.278-9.55-9.553 0-1.18.215-2.31.607-3.356a8.991 8.991 0 0 0-4.427 7.752c0 4.968 4.025 8.994 8.988 8.994a8.984 8.984 0 0 0 7.76-4.453Zm.331-1.635c.779-.397 1.824.206 1.493 1.014C20.392 19.34 16.683 22 12.352 22 6.635 22 2 17.363 2 11.643a10.36 10.36 0 0 1 6.408-9.58c.807-.332 1.413.71 1.019 1.489a8.159 8.159 0 0 0-.879 3.695 8.188 8.188 0 0 0 11.896 7.302Zm-2.327-9.134a.227.227 0 0 1 .432 0l.732 2.226c.02.062.068.113.129.138l2.05.853a.227.227 0 0 1 0 .42l-2.05.853a.227.227 0 0 0-.129.139l-.732 2.225a.227.227 0 0 1-.432 0l-.732-2.225a.227.227 0 0 0-.128-.139l-2.05-.853a.227.227 0 0 1 0-.42l2.05-.853a.227.227 0 0 0 .128-.138l.732-2.226ZM14.161 3.66a.227.227 0 0 0-.427 0l-.288.779a.227.227 0 0 1-.134.134l-.78.289a.227.227 0 0 0 0 .426l.78.288a.227.227 0 0 1 .134.134l.288.78a.227.227 0 0 0 .427 0l.288-.78a.227.227 0 0 1 .134-.134l.78-.288a.227.227 0 0 0 0-.426l-.78-.289a.227.227 0 0 1-.134-.134l-.288-.78Z" />
            </svg>
          )}
        </button>
      </div>
    </div>
  );
}

export default NavBar;
