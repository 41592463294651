/* eslint-disable react/require-default-props */
import React, { ReactNode, useContext } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
import styles from './floatingmenu.module.scss';

interface Props {
  children: ReactNode;
  fullWidth?: boolean;
  style?: React.CSSProperties;
}

function FloatingMenu({ children, fullWidth, style }: Props) {
  const { theme } = useContext(ThemeContext);
  console.log(style);

  return (
    <div className={`${styles.floating_menu} ${fullWidth ? styles.floating_menu_fullwidth : ''} ${styles[theme]}`} style={{ ...style }}>
      {children}
    </div>
  );
}

export default FloatingMenu;
