/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  Box,
  Button,
  FormControl,
  IconButton, Input, InputAdornment, InputLabel, OutlinedInput, TextField, Typography,
} from '@mui/material';
import s from './AccountManagement.module.scss';
import { useAuth } from '../../hooks/use-auth';
import useNotification from '../../hooks/useNotification';

interface State {
  originalPassword: string;
  newPassword: string;
  confirmPassword: string;
  showCurrentPassword: boolean;
  showNewPassword: boolean;
  showNewConfirmedPassword: boolean;
}

const PasswordReset: React.FC = () => {
  const auth = useAuth();
  const { displayNotification } = useNotification();
  const [values, setValues] = useState<State>({
    originalPassword: '',
    newPassword: '',
    confirmPassword: '',
    showCurrentPassword: false,
    showNewPassword: false,
    showNewConfirmedPassword: false,
  });

  const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowCurrentPassword = () => {
    setValues({
      ...values,
      showCurrentPassword: !values.showCurrentPassword,
    });
  };

  const handleClickShowNewPassword = () => {
    setValues({
      ...values,
      showNewPassword: !values.showNewPassword,
    });
  };

  const handleClickShowNewConfirmedPassword = () => {
    setValues({
      ...values,
      showNewConfirmedPassword: !values.showNewConfirmedPassword,
    });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const isPasswordSecure = (password: string): boolean => {
    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/;
    return regex.test(password);
  };

  const isValid = () => isPasswordSecure(values.newPassword)
    && (values.newPassword === values.confirmPassword)
    && (values.newPassword !== '');

  const handleResetPassword = async () => {
    // use auth to reset the password
    console.log('handleResetPassword');
    try {
      await auth?.changePassword(values.originalPassword, values.newPassword);
      displayNotification({
        message: 'Password successfully reset!',
        type: 'success',
      });
      // clear the form
      setValues({
        originalPassword: '',
        newPassword: '',
        confirmPassword: '',
        showCurrentPassword: false,
        showNewPassword: false,
        showNewConfirmedPassword: false,
      });
      console.log('Password successfully reset!');
    } catch (error: any) {
      const errorCode = error.code;
      const errorMessage = error.message;

      if (errorCode === 'auth/invalid-email') {
        console.log('Invalid email.');
        displayNotification({
          message: 'Invalid email!',
          type: 'error',
        });
      } else if (errorCode === 'auth/user-not-found') {
        console.log('User not found.');
        displayNotification({
          message: 'User not found!',
          type: 'error',
        });
      } else if (errorCode === 'auth/wrong-password') {
        console.log('Wrong password.');
        displayNotification({
          message: 'Invalid password!',
          type: 'error',
        });
      }
    }
  };

  return (
    <div className={s.theme__password}>
      <p className={s.theme__password__heading}>Update Password</p>
      <Box marginTop={3}>
        {/* Current Password */}
        <Typography
          variant="body1"
          sx={{
            marginTop: 5, color: '$dark-brown', fontFamily: 'DM Sans', fontSize: '16px', fontStyle: 'normal', fontWeight: 500, lineHeight: '26px', letterSpacing: '0.46px', textTransform: 'capitalize', textAlign: 'left', height: '26px',
          }}
        >
          Current Password
        </Typography>
        <FormControl
          sx={{
            width: '364px',
            boxSizing: 'border-box',
            height: '32px',
            borderRadius: '4px',
            border: '0.5px solid #250503',
            borderBottom: 'none',
            background: '#fff',
            boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
            outline: 'none',
            color: 'rgba(37, 5, 3, 0.5)',
            fontFamily: 'DM Sans',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '26px',
            letterSpacing: '0.46px',
          }}
          variant="standard"
        >
          <OutlinedInput
            id="oldPassword"
            type={values.showCurrentPassword ? 'text' : 'password'}
            inputProps={{
              style: {
                boxSizing: 'border-box',
                height: '32px',
                borderBottom: 'none',
                outline: 'none',
                color: 'rgba(37, 5, 3, 0.5)',
                fontFamily: 'DM Sans',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 500,
                letterSpacing: '0.46px',
              },
            }}
            value={values.originalPassword}
            onChange={(event) => setValues({ ...values, originalPassword: event.target.value })}
            endAdornment={(
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowCurrentPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )}
          />
        </FormControl>
      </Box>

      {/* New Password */}
      <Box marginTop={3} display="flex" flexDirection="column">
        <Typography
          variant="body1"
          sx={{
            marginTop: 5, color: '$dark-brown', fontFamily: 'DM Sans', fontSize: '16px', fontStyle: 'normal', fontWeight: 500, lineHeight: '26px', letterSpacing: '0.46px', textTransform: 'capitalize', textAlign: 'left', height: '26px',
          }}
        >
          New Password
        </Typography>

        <FormControl
          sx={{
            width: '364px',
            boxSizing: 'border-box',
            height: '32px',
            borderRadius: '4px',
            border: '0.5px solid #250503',
            borderBottom: 'none',
            background: '#fff',
            boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
            outline: 'none',
            color: 'rgba(37, 5, 3, 0.5)',
            fontFamily: 'DM Sans',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '26px',
            letterSpacing: '0.46px',
          }}
          variant="standard"
        >
          <OutlinedInput
            id="newPassword"
            type={values.showNewPassword ? 'text' : 'password'}
            inputProps={{
              style: {
                boxSizing: 'border-box',
                height: '32px',
                borderBottom: 'none',
                outline: 'none',
                color: 'rgba(37, 5, 3, 0.5)',
                fontFamily: 'DM Sans',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 500,
                letterSpacing: '0.46px',
              },
            }}
            value={values.newPassword}
            onChange={(event) => setValues({ ...values, newPassword: event.target.value })}
            error={values.newPassword !== '' && !isPasswordSecure(values.newPassword)}
            endAdornment={(
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowNewPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showNewPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )}
          />
        </FormControl>
        {/* Password requirements */}
        <Typography
          variant="caption"
          sx={{
            marginTop: 1, color: 'grey', fontFamily: 'DM Sans', fontStyle: 'normal', fontWeight: 500, lineHeight: '15px', letterSpacing: '0.46px', textAlign: 'left', height: '26px',
          }}
          style={{ whiteSpace: 'pre-line' }}
        >
          {'Password must be at least 8 characters long\ncontain at least 1 number\n1 uppercase letter, and 1 lowercase letter'}

        </Typography>
      </Box>

      <Box marginTop={3}>
        {/* Confirm Password */}
        <Typography
          variant="body1"
          sx={{
            marginTop: 5, color: '$dark-brown', fontFamily: 'DM Sans', fontSize: '16px', fontStyle: 'normal', fontWeight: 500, lineHeight: '26px', letterSpacing: '0.46px', textTransform: 'capitalize', textAlign: 'left', height: '26px',
          }}
        >
          Confirm New Password
        </Typography>
        <FormControl
          sx={{
            width: '364px',
            boxSizing: 'border-box',
            height: '32px',
            borderRadius: '4px',
            border: '0.5px solid #250503',
            borderBottom: 'none',
            background: '#fff',
            boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
            outline: 'none',
            color: 'rgba(37, 5, 3, 0.5)',
            fontFamily: 'DM Sans',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '26px',
            letterSpacing: '0.46px',
          }}
          variant="standard"
        >
          <OutlinedInput
            id="newPassword"
            type={values.showNewConfirmedPassword ? 'text' : 'password'}
            inputProps={{
              style: {
                boxSizing: 'border-box',
                height: '32px',
                borderBottom: 'none',
                outline: 'none',
                color: 'rgba(37, 5, 3, 0.5)',
                fontFamily: 'DM Sans',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 500,
                letterSpacing: '0.46px',
              },
            }}
            value={values.confirmPassword}
            onChange={(event) => setValues({ ...values, confirmPassword: event.target.value })}
            error={values.newPassword !== values.confirmPassword}
            endAdornment={(
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowNewConfirmedPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showNewConfirmedPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )}
          />
        </FormControl>
      </Box>
      <Box marginTop={3}>
        <Button type="button" className={s.theme__password__button} disabled={!isValid()} onClick={handleResetPassword}>
          Update
        </Button>
      </Box>
    </div>
  );
};

export default PasswordReset;
