import React from 'react';
import {
  Circle, ClipPath, Defs, Document, Font, G, Image, Page, Path,
  StyleSheet, Svg, Text, View,
} from '@react-pdf/renderer';
import { Character } from '../../__generated__/graphql';

interface Props {
  character: Character | null;
}

Font.register({
  family: 'PasseroOne',
  src: 'https://fonts.gstatic.com/s/passeroone/v8/5eWwFFxCBNQER-1nDlru9Z0EAVxt0G0biEntp43Qt6E.ttf',
});

Font.register({
  family: 'OpenSans',
  src: 'https://fonts.gstatic.com/s/opensans/v13/cJZKeOuBrn4kERxqtaUH3aCWcynf_cDxXwCLxiixG1c.ttf',
});

function PDFFile({
  character,
}: Props) {
  const AbilityBg = '/images/pdf/abilities-background.png';
  const PageBg = '/images/pdf/flowerbglite.png';
  // console.log(FlowerBg);

  const styles = StyleSheet.create({
    body: {
      backgroundColor: '#e3d5bc',
      position: 'relative',
    },
    bgImage: {
      position: 'absolute',
      minWidth: '100vw',
      minHeight: '100vh',
      height: '100vh',
      width: '100vw',
      resizeMode: 'cover',
      objectFit: 'cover',
      objectPosition: 'center',
    },
    pageWrapper: {
      padding: '20pt',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      width: '100vw',
    },
    title: {
      color: '#250503',
      fontSize: '24pt',
      fontFamily: 'PasseroOne',
      fontWeight: 400,
      textAlign: 'left',
      paddingTop: '10pt',
      paddingBottom: '10pt',
    },
    subTitle: {
      color: '#250503',
      fontSize: '20pt',
      fontFamily: 'PasseroOne',
      fontWeight: 400,
      textAlign: 'center',
    },
    section: {
      backgroundColor: '#f7f2e9aa',
      padding: '20pt',
      borderTopLeftRadius: '4pt',
      borderTopRightRadius: '4pt',
      borderBottomLeftRadius: '4pt',
      borderBottomRightRadius: '4pt',
      marginTop: '20pt',
      zIndex: '10',
    },
    flexWrapper: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
    },
    stack: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
    dpBackground: {
      height: '100pt',
      width: '100pt',
      borderTopLeftRadius: '50%',
      borderTopRightRadius: '50%',
      borderBottomLeftRadius: '50%',
      borderBottomRightRadius: '50%',
      objectFit: 'cover',
      objectPosition: 'center',
      position: 'absolute',
      top: '13pt',
      left: '50%',
      transform: 'translateX(-50%)',
      marginLeft: '3.5pt',
    },
    attrTitle: {
      color: '#49454F',
      fontSize: '10pt',
      fontFamily: 'OpenSans',
      fontWeight: 400,
      textAlign: 'left',
    },
    attrValue: {
      color: '#1D1B20',
      fontSize: '12pt',
      fontFamily: 'OpenSans',
      fontWeight: 400,
      textAlign: 'left',
    },
    abilityWrapper: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
    },
    abilityTitle: {
      color: '#250503',
      fontSize: '12pt',
      fontFamily: 'OpenSans',
      fontWeight: 700,
      textAlign: 'center',
      paddingTop: '15pt',
      paddingBottom: '2pt',
    },
    abilityValue: {
      color: '#250503',
      fontSize: '50pt',
      marginTop: '-12pt',
      fontFamily: 'PasseroOne',
      fontWeight: 400,
      textAlign: 'center',
    },
  });

  const attributes = [
    {
      key: 'Age',
      value: 'Not Available',
    },
    {
      key: 'Gender',
      value: character?.gender ?? 'None selected',
    },
    {
      key: 'Pronouns',
      value: character?.pronoun ?? 'None selected',
    },
    {
      key: 'Race',
      value: character?.race ?? 'Tiefling',
    },
    {
      key: 'Class',
      value: character?.class ?? 'None selected',
    },
    {
      key: 'Alignment',
      value: character?.alignment ?? 'None selected',
    },
    {
      key: 'Personality Type',
      value: 'Not Available',
    },
  ];

  const abilities = [
    {
      key: 'STR',
      value: character?.abilities?.strength ?? 0,
    },
    {
      key: 'DEX',
      value: character?.abilities?.dexterity ?? 0,
    },
    {
      key: 'CON',
      value: character?.abilities?.consitution ?? 0,
    },
    {
      key: 'INT',
      value: character?.abilities?.intelligence ?? 0,
    },
    {
      key: 'WIS',
      value: character?.abilities?.wisdom ?? 0,
    },
    {
      key: 'CHA',
      value: character?.abilities?.charisma ?? 0,
    },
  ];

  const physicals = [
    {
      key: 'Body Type',
      value: character?.physical_profile?.body_type ?? 'Not available',
    },
    {
      key: 'Skin Color',
      value: character?.physical_profile?.skin_color ?? 'Not available',
    },
    {
      key: 'Hair Color',
      value: character?.physical_profile?.hair_color ?? 'Not available',
    },
    {
      key: 'Hair Style',
      value: character?.physical_profile?.hair_style ?? 'Not available',
    },
    {
      key: 'Eye Color',
      value: character?.physical_profile?.eye_color ?? 'Not available',
    },
  ];

  const background = [
    {
      key: 'Profession',
      value: character?.profession ?? 'Not available',
    },
    {
      key: 'Relationship',
      value: character?.relationship_status ?? 'Not available',
    },
    {
      key: 'Parents',
      value: 'Not available',
    },
    // {
    //   key: 'Life Altering Event',
    //   value: character?.life_altering_event ?? 'Not available',
    // },
    // {
    //   key: 'Birth Story',
    //   value: character?.backstory ?? 'Not available',
    // },
  ];

  return (
    <Document>
      <Page style={styles.body}>
        <View style={{ position: 'relative' }}>
          <Image style={styles.bgImage} src={PageBg} />
          <View style={styles.pageWrapper}>
            <View
              style={[
                styles.flexWrapper,
                {
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  paddingTop: '20pt',
                  paddingBottom: '10pt',
                },
              ]}
            >
              <View style={{ width: '100%' }}>
                <Text
                  style={[styles.title, { textAlign: 'center' }]}
                >
                  Character Summary
                </Text>
              </View>
              <Svg id="fancy_underline_1" viewBox="0 0 180 8.87" width="102pt">
                <Path fill="#250503" strokeWidth={0} d="m180,4.43l-7.81,4.43-7-3.98h-1.61l-4.72,2.68-4.72-2.68h-61.77c-.21,1.11-1.19,1.95-2.36,1.95-1.24,0-2.27-.95-2.39-2.16,0,.07.02.14.03.21H25.87l-4.72,2.68-4.72-2.68h-1.61l-7,3.98L0,4.43,7.81,0l7,3.98h1.61l4.72-2.68,4.72,2.68h61.77c-.01.07-.02.14-.03.21.12-1.21,1.15-2.16,2.39-2.16,1.17,0,2.15.84,2.36,1.95h61.77l4.72-2.68,4.72,2.68h1.61l7-3.98,7.81,4.43Z" />
              </Svg>
            </View>
            <View style={styles.section}>
              <View style={[styles.flexWrapper, { gap: '20pt', flexWrap: 'nowrap' }]}>
                <View style={{ position: 'relative', width: '130pt' }}>
                  <Svg id="dp_background" viewBox="0 0 132.92 127.62" width="130pt">
                    <Defs>
                      <ClipPath id="clippath-1">
                        <Path
                          fill="none"
                          strokeWidth={0}
                          d="m3.38,64.91l-.24-.54-.15-.26-.07-.13-.04-.07-.02-.03v-.02s-.01,0-.01,0h0s0,0,0,0h0s0,0,0,0h0s.02-.02.03-.03h-1.41l.13.2.06.1.02.02v.02s.04.05.04.05l.22.38.11.19.05.1h0s0,.02,0,.02v.03s.03.05.03.05l.18.41,1.07-.47Z"
                        />
                      </ClipPath>
                      <ClipPath id="clippath-2">
                        <Path
                          fill="none"
                          strokeWidth={0}
                          d="m3.38,62.71l-.24.54-.15.26-.07.13-.04.07-.02.03v.02s-.01,0-.01,0h0s0,0,0,0h0s0,0,0,0h0s.02.02.03.03h-1.41l.13-.2.06-.1.02-.02v-.02s.04-.05.04-.05l.22-.38.11-.19.05-.1h0s0-.02,0-.02v-.03s.03-.05.03-.05l.18-.41,1.07.47Z"
                        />
                      </ClipPath>
                      <ClipPath id="clippath-3">
                        <Path
                          fill="none"
                          strokeWidth={0}
                          d="m129.53,62.71l.24.54.15.26.07.13.04.07.02.03v.02s.01,0,.01,0h0s0,0,0,0h0s0,0,0,0h0s-.02.02-.03.03h1.41l-.13-.2-.06-.1-.02-.02v-.02s-.04-.05-.04-.05l-.22-.38-.11-.19-.05-.1h0s0-.02,0-.02v-.03s-.03-.05-.03-.05l-.18-.41-1.07.47Z"
                        />
                      </ClipPath>
                      <ClipPath id="clippath-4">
                        <Path
                          fill="none"
                          strokeWidth={0}
                          d="m129.53,64.91l.24-.54.15-.26.07-.13.04-.07.02-.03v-.02s.01,0,.01,0h0s0,0,0,0h0s0,0,0,0h0s-.02-.02-.03-.03h1.41l-.13.2-.06.1-.02.02v.02s-.04.05-.04.05l-.22.38-.11.19-.05.1h0s0,.02,0,.02v.03s-.03.05-.03.05l-.18.41-1.07-.47Z"
                        />
                      </ClipPath>
                    </Defs>
                    <G>
                      <Path
                        fill="#2e3c11"
                        d="m66.46,124.62c-11.94-2.09-33.98-5.97-35.95-16.22l-.08-.44-.44-.08c-12.77-2.42-18.55-14.1-21.15-23.46-1.09-3.95-1.61-7.62-2.07-10.86-.58-4.11-1.05-7.39-2.42-9.75,1.38-2.35,1.84-5.64,2.42-9.75.46-3.24.98-6.91,2.07-10.86,2.6-9.37,8.37-21.04,21.15-23.46l.44-.08.08-.44c1.96-10.25,24.01-14.13,35.95-16.22,11.94,2.09,33.98,5.97,35.95,16.22l.08.44.44.08c12.77,2.42,18.55,14.1,21.15,23.46,1.09,3.95,1.61,7.62,2.07,10.86.58,4.11,1.05,7.39,2.42,9.75-1.38,2.35-1.84,5.64-2.42,9.75-.46,3.24-.98,6.91-2.07,10.86-2.6,9.37-8.38,21.04-21.15,23.46l-.44.08-.08.44c-1.97,10.25-24.01,14.13-35.95,16.22Z"
                      />
                      <Path
                        fill="#29370d"
                        strokeWidth={0}
                        d="m66.46,3.67c8.02,1.41,16.35,3.05,23.02,5.55,7.49,2.8,11.51,6.11,12.28,10.12l.17.88.88.17c12.44,2.36,18.09,13.81,20.64,22.99,1.08,3.91,1.6,7.56,2.06,10.78.57,3.99,1.02,7.22,2.32,9.65-1.3,2.43-1.76,5.66-2.32,9.65-.46,3.22-.97,6.87-2.06,10.78-2.55,9.19-8.2,20.64-20.64,22.99l-.88.17-.17.88c-.77,4.01-4.79,7.33-12.28,10.12-6.68,2.49-15,4.14-23.02,5.55-8.02-1.41-16.35-3.05-23.02-5.55-7.49-2.8-11.51-6.11-12.28-10.12l-.17-.88-.88-.17c-12.44-2.36-18.09-13.81-20.64-22.99-1.08-3.91-1.6-7.56-2.06-10.78-.57-3.99-1.02-7.22-2.32-9.65,1.3-2.43,1.76-5.66,2.32-9.65.46-3.22.97-6.87,2.06-10.78,2.55-9.19,8.2-20.64,20.64-22.99l.88-.17.17-.88c.77-4.01,4.79-7.33,12.28-10.12,6.68-2.49,15-4.14,23.02-5.55m0-1.33c-15.75,2.75-34.67,6.73-36.59,16.76-12.4,2.35-18.67,13.16-21.66,23.93-2.53,9.12-2.02,16.78-4.64,20.78,2.61,4,2.11,11.66,4.64,20.78,2.99,10.77,9.26,21.59,21.66,23.93,1.92,10.03,20.84,14.01,36.59,16.76,15.75-2.75,34.67-6.73,36.59-16.76,12.4-2.35,18.67-13.16,21.66-23.93,2.53-9.12,2.02-16.78,4.64-20.78-2.61-4-2.11-11.66-4.64-20.78-2.99-10.77-9.26-21.59-21.66-23.93-1.92-10.03-20.84-14.01-36.59-16.76h0Z"
                      />
                    </G>
                    <G>
                      <Path fill="#29370d" strokeWidth={0} d="m101.87,113.06c.19-.32.4-.63.57-.96.16-.34.34-.66.45-1.01.1-.29.33-.51.58-.66.25-.15.57-.21.88-.14-.39,1.64-1.2,3.17-2.27,4.42h0c-.25-.21-.37-.49-.42-.79-.04-.3.02-.6.21-.85Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m98.69,116.3c.15-.12.31-.23.46-.35l.43-.37c.3-.24.56-.51.83-.77.23-.21.51-.34.81-.36.3-.02.61.07.86.27h0c-1.07,1.27-2.34,2.32-3.68,3.23-.36-.54-.21-1.25.29-1.64Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m94.74,118.72c.35-.17.69-.36,1.03-.54.34-.18.67-.38,1-.58.55-.32,1.27-.2,1.63.34-1.35.91-2.77,1.67-4.22,2.36-.14-.29-.14-.61-.03-.89.1-.29.3-.53.59-.68Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m90.45,120.6l1.09-.42,1.08-.45c.59-.26,1.29-.01,1.57.57-1.45.68-2.94,1.28-4.45,1.81h0c-.22-.61.11-1.27.72-1.5Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m85.99,122.11c.75-.23,1.5-.46,2.23-.72.62-.2,1.29.11,1.51.71h0c-1.51.53-3.03,1.02-4.56,1.46-.18-.62.19-1.27.81-1.46Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m81.44,123.37c.76-.19,1.52-.39,2.27-.6.63-.17,1.29.19,1.46.8-1.53.44-3.07.84-4.62,1.22-.15-.63.24-1.26.88-1.42Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m76.83,124.44c.77-.17,1.53-.34,2.3-.52.64-.15,1.28.24,1.43.86-1.55.37-3.1.72-4.65,1.05-.13-.63.28-1.25.92-1.39Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m72.19,125.39l2.31-.46c.64-.13,1.27.27,1.4.9-1.55.32-3.11.63-4.67.93-.12-.63.31-1.25.95-1.37Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m67.53,126.25l2.32-.42c.65-.12,1.27.3,1.39.93l-2.34.43-2.44.44c0-.16.06-.33.11-.48.04-.15.1-.29.19-.41.18-.25.45-.43.77-.48Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m63.06,125.83l2.32.42c.32.06.59.24.77.48.09.12.15.26.19.41.05.15.11.32.11.48l-2.44-.44-2.34-.43c.12-.63.74-1.05,1.39-.93Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m58.41,124.93l2.31.46c.65.12,1.07.74.95,1.37-1.56-.29-3.12-.6-4.67-.93.13-.63.76-1.03,1.4-.9Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m53.79,123.92c.76.18,1.53.35,2.3.52.64.14,1.05.76.92,1.39-1.55-.33-3.1-.68-4.65-1.05.15-.62.79-1.01,1.43-.86Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m49.21,122.77c.75.21,1.51.4,2.27.6.64.16,1.03.79.88,1.42-1.55-.38-3.08-.78-4.62-1.22.18-.62.83-.98,1.46-.8Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m44.7,121.4c.74.26,1.49.48,2.23.72.63.18.99.84.81,1.46-1.53-.44-3.05-.93-4.56-1.46h0c.22-.6.89-.91,1.51-.71Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m40.31,119.72l1.08.45,1.09.42c.61.23.93.9.72,1.5h0c-1.51-.53-2.99-1.13-4.45-1.81.28-.58.97-.83,1.57-.57Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m36.15,117.6c.33.2.66.4,1,.58.34.19.68.38,1.03.54.29.14.49.39.59.68.1.28.1.6-.03.89-1.45-.68-2.88-1.45-4.22-2.36.37-.53,1.08-.66,1.63-.34Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m32.5,114.8c.27.27.54.53.83.77l.43.37c.15.12.3.23.46.35.5.39.65,1.1.29,1.64-1.34-.91-2.61-1.96-3.68-3.23h0c.25-.21.56-.29.86-.27.3.02.57.15.81.36Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m30.02,111.09c.11.35.29.68.45,1.01.16.33.38.64.57.96.19.25.25.55.21.85-.04.3-.17.58-.42.79h0c-1.08-1.25-1.89-2.78-2.27-4.42.31-.07.63,0,.88.14.24.15.47.37.58.66Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m25.57,108.25c.7.25,1.41.47,2.13.65.6.16.99.76.86,1.39h0c-1.53-.31-3.04-.77-4.49-1.37h0c.12-.3.35-.51.62-.63.27-.12.58-.15.87-.04Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m21.53,106.33c.63.39,1.29.73,1.96,1.05.55.27.83.93.58,1.53h0c-1.45-.6-2.84-1.35-4.14-2.22.36-.53,1.07-.68,1.6-.37Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m17.93,103.67c.28.25.56.5.85.72.29.24.58.47.88.68.49.38.63,1.08.27,1.62-1.3-.87-2.52-1.87-3.64-2.96.46-.46,1.17-.47,1.64-.06Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m14.84,100.41c.24.29.47.58.72.86.25.28.49.56.75.83.43.45.42,1.18-.02,1.64-1.12-1.09-2.14-2.28-3.07-3.53.52-.38,1.25-.29,1.63.2Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m12.28,96.7c.38.64.79,1.27,1.21,1.89.36.51.24,1.23-.28,1.62-.93-1.25-1.76-2.57-2.52-3.93.56-.31,1.27-.12,1.59.42Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m10.19,92.69c.31.69.64,1.36.98,2.03.28.56.08,1.25-.48,1.57-.76-1.36-1.43-2.76-2.03-4.19.6-.25,1.27.02,1.53.59Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m8.52,88.47c.25.71.5,1.42.78,2.12.23.58-.05,1.26-.64,1.51-.6-1.43-1.14-2.88-1.61-4.35h0c.62-.2,1.26.13,1.47.73Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m7.22,84.11c.18.73.38,1.46.6,2.18.18.6-.16,1.25-.77,1.45h0c-.24-.74-.46-1.48-.67-2.22-.2-.75-.4-1.49-.58-2.25.62-.15,1.25.23,1.41.84Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m6.24,79.67c.14.74.29,1.48.45,2.22.14.61-.26,1.23-.88,1.38-.36-1.5-.65-3.02-.91-4.54.64-.11,1.24.31,1.35.93Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m5.52,75.16c.11.75.22,1.5.34,2.25.1.62-.33,1.22-.96,1.33-.25-1.52-.48-3.04-.69-4.56.64-.09,1.23.35,1.32.98Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m4.85,70.6c.13.76.23,1.52.34,2.27.09.63-.35,1.22-.99,1.31-.22-1.52-.42-3.03-.7-4.51.64-.12,1.24.3,1.35.94Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m3.81,66.04c.25.75.44,1.51.61,2.27.14.64-.29,1.25-.92,1.36-.28-1.48-.61-2.95-1.19-4.29.29-.13.61-.13.9-.02.28.12.49.36.6.67Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m4.42,59.32c-.17.76-.36,1.52-.61,2.27-.11.31-.33.55-.6.67-.28.12-.6.11-.9-.02.58-1.33.92-2.8,1.19-4.29.63.11,1.06.72.92,1.36Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m5.19,54.75c-.11.76-.22,1.51-.34,2.27-.1.64-.71,1.06-1.35.94.27-1.49.48-3,.7-4.51.64.09,1.08.67.99,1.31Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m5.86,50.21c-.12.75-.23,1.5-.34,2.25-.09.63-.68,1.07-1.32.98.21-1.52.44-3.04.69-4.56.63.11,1.06.7.96,1.33Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m6.69,45.73c-.16.74-.31,1.48-.45,2.22-.11.62-.71,1.04-1.35.93.26-1.52.56-3.03.91-4.54.63.15,1.02.77.88,1.38Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m7.82,41.33c-.22.72-.42,1.45-.6,2.18-.16.61-.79.98-1.41.84.18-.75.38-1.5.58-2.25.21-.74.43-1.49.67-2.22.61.2.95.85.77,1.45Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m9.31,37.04c-.28.7-.54,1.41-.78,2.12-.21.59-.85.92-1.47.73.47-1.47,1.01-2.93,1.61-4.35.59.25.87.92.64,1.51Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m11.18,32.91c-.35.67-.68,1.34-.98,2.03-.26.57-.94.84-1.53.59.6-1.43,1.28-2.83,2.03-4.19.56.32.76,1.01.48,1.57Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m13.49,29.03c-.42.62-.83,1.25-1.21,1.89-.32.54-1.02.73-1.59.42.75-1.36,1.59-2.67,2.52-3.93.52.39.63,1.11.28,1.62Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m16.31,25.52c-.26.27-.5.55-.75.83-.24.28-.48.57-.72.86-.38.49-1.11.58-1.63.2.93-1.25,1.95-2.44,3.07-3.53.44.46.45,1.19.02,1.64Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m19.66,22.54c-.3.22-.59.45-.88.68-.29.23-.57.48-.85.72-.47.41-1.18.4-1.64-.06,1.12-1.09,2.34-2.09,3.64-2.96.36.54.22,1.24-.27,1.62Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m23.49,20.24c-.67.32-1.33.67-1.96,1.05-.53.31-1.24.17-1.6-.37,1.3-.87,2.69-1.62,4.14-2.22.24.6-.03,1.26-.58,1.53Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m27.7,18.73c-.72.18-1.43.4-2.13.65-.29.11-.6.08-.87-.04-.27-.12-.5-.33-.62-.63,1.45-.6,2.96-1.06,4.49-1.37.13.64-.27,1.23-.86,1.39Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m31.04,14.56c-.19.32-.4.63-.57.96-.16.34-.34.66-.45,1.01-.1.29-.33.51-.58.66-.25.15-.57.21-.88.14.39-1.64,1.2-3.17,2.27-4.42.25.21.37.49.42.79.04.3-.02.6-.21.85Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m34.23,11.33c-.15.12-.31.23-.46.35l-.43.37c-.3.24-.56.51-.83.77-.23.21-.51.34-.81.36-.3.02-.61-.07-.86-.27,1.07-1.27,2.34-2.32,3.68-3.23.36.54.21,1.25-.29,1.64Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m38.18,8.9c-.35.17-.69.36-1.03.54-.34.18-.67.38-1,.58-.55.32-1.27.2-1.63-.34,1.35-.91,2.77-1.67,4.22-2.36.14.29.14.61.03.89-.1.29-.3.53-.59.68Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m42.47,7.02l-1.09.42-1.08.45c-.59.26-1.29.01-1.57-.57,1.45-.68,2.94-1.28,4.45-1.81.22.61-.11,1.27-.72,1.5Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m46.93,5.51c-.75.23-1.5.46-2.23.72-.62.2-1.29-.11-1.51-.71,1.51-.53,3.03-1.02,4.56-1.46.18.62-.19,1.27-.81,1.46Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m51.48,4.26c-.76.19-1.52.39-2.27.6-.63.17-1.29-.19-1.46-.8,1.53-.44,3.07-.84,4.62-1.22.15.63-.24,1.26-.88,1.42Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m56.09,3.18c-.77.17-1.53.34-2.3.52-.64.15-1.28-.24-1.43-.86,1.55-.37,3.1-.72,4.65-1.05.13.63-.28,1.25-.92,1.39Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m60.72,2.24l-2.31.46c-.64.13-1.27-.27-1.4-.9,1.55-.32,3.11-.63,4.67-.93.12.63-.31,1.25-.95,1.37Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m65.39,1.37l-2.32.42c-.65.12-1.27-.3-1.39-.93l2.34-.43,2.44-.44c0,.16-.06.33-.11.48-.04.15-.1.29-.19.41-.18.25-.45.43-.77.48Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m69.85,1.8l-2.32-.42c-.32-.06-.59-.24-.77-.48-.09-.12-.15-.26-.19-.41-.05-.15-.11-.32-.11-.48l2.44.44,2.34.43c-.12.63-.74,1.05-1.39.93Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m74.5,2.69l-2.31-.46c-.65-.12-1.07-.74-.95-1.37,1.56.29,3.12.6,4.67.93-.13.63-.76,1.03-1.4.9Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m79.13,3.7c-.76-.18-1.53-.35-2.3-.52-.64-.14-1.05-.76-.92-1.39,1.55.33,3.1.68,4.65,1.05-.15.62-.79,1.01-1.43.86Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m83.71,4.86c-.75-.21-1.51-.4-2.27-.6-.64-.16-1.03-.79-.88-1.42,1.55.38,3.08.78,4.62,1.22-.18.62-.83.98-1.46.8Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m88.22,6.23c-.74-.26-1.49-.48-2.23-.72-.63-.18-.99-.84-.81-1.46,1.53.44,3.05.93,4.56,1.46-.22.6-.89.91-1.51.71Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m92.61,7.9l-1.08-.45-1.09-.42c-.61-.23-.93-.9-.72-1.5,1.51.53,2.99,1.13,4.45,1.81-.28.58-.97.83-1.57.57Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m96.76,10.02c-.33-.2-.66-.4-1-.58-.34-.19-.68-.38-1.03-.54-.29-.14-.49-.39-.59-.68-.1-.28-.1-.6.03-.89,1.45.68,2.88,1.45,4.22,2.36-.37.53-1.08.66-1.63.34Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m100.41,12.82c-.27-.27-.54-.53-.83-.77l-.43-.37c-.15-.12-.3-.23-.46-.35-.5-.39-.65-1.1-.29-1.64,1.34.91,2.61,1.96,3.68,3.23-.25.21-.56.29-.86.27-.3-.02-.57-.15-.81-.36Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m102.89,16.53c-.11-.35-.29-.68-.45-1.01-.16-.33-.38-.64-.57-.96-.19-.25-.25-.55-.21-.85.04-.3.17-.58.42-.79,1.08,1.25,1.89,2.78,2.27,4.42-.31.07-.63,0-.88-.14-.24-.15-.47-.37-.58-.66Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m107.35,19.38c-.7-.25-1.41-.47-2.13-.65-.6-.16-.99-.76-.86-1.39,1.53.31,3.04.77,4.49,1.37-.12.3-.35.51-.62.63-.27.12-.58.15-.87.04Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m111.39,21.29c-.63-.39-1.29-.73-1.96-1.05-.55-.27-.83-.93-.58-1.53,1.45.6,2.84,1.35,4.14,2.22-.36.53-1.07.68-1.6.37Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m114.99,23.95c-.28-.25-.56-.5-.85-.72-.29-.24-.58-.47-.88-.68-.49-.38-.63-1.08-.27-1.62,1.3.87,2.52,1.87,3.64,2.96-.46.46-1.17.47-1.64.06Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m118.07,27.21c-.24-.29-.47-.58-.72-.86-.25-.28-.49-.56-.75-.83-.43-.45-.42-1.18.02-1.64,1.12,1.09,2.14,2.28,3.07,3.53-.52.38-1.25.29-1.63-.2Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m120.63,30.92c-.38-.64-.79-1.27-1.21-1.89-.36-.51-.24-1.23.28-1.62.93,1.25,1.76,2.57,2.52,3.93-.56.31-1.27.12-1.59-.42Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m122.72,34.94c-.31-.69-.64-1.36-.98-2.03-.28-.56-.08-1.25.48-1.57.76,1.36,1.43,2.76,2.03,4.19-.6.25-1.27-.02-1.53-.59Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m124.39,39.16c-.25-.71-.5-1.42-.78-2.12-.23-.58.05-1.26.64-1.51.6,1.43,1.14,2.88,1.61,4.35-.62.2-1.26-.13-1.47-.73Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m125.7,43.51c-.18-.73-.38-1.46-.6-2.18-.18-.6.16-1.25.77-1.45.24.74.46,1.48.67,2.22.2.75.4,1.49.58,2.25-.62.15-1.25-.23-1.41-.84Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m126.67,47.95c-.14-.74-.29-1.48-.45-2.22-.14-.61.26-1.23.88-1.38.36,1.5.65,3.02.91,4.54-.64.11-1.24-.31-1.35-.93Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m127.4,52.47c-.11-.75-.22-1.5-.34-2.25-.1-.62.33-1.22.96-1.33.25,1.52.48,3.04.69,4.56-.64.09-1.23-.35-1.32-.98Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m128.06,57.02c-.13-.76-.23-1.52-.34-2.27-.09-.63.35-1.22.99-1.31.22,1.52.42,3.03.7,4.51-.64.12-1.24-.3-1.35-.94Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m129.1,61.59c-.25-.75-.44-1.51-.61-2.27-.14-.64.29-1.25.92-1.36.28,1.48.61,2.95,1.19,4.29-.29.13-.61.13-.9.02-.28-.12-.49-.36-.6-.67Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m128.49,68.3c.17-.76.36-1.52.61-2.27.11-.31.33-.55.6-.67.28-.12.6-.11.9.02-.58,1.33-.92,2.8-1.19,4.29-.63-.11-1.06-.72-.92-1.36Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m127.72,72.87c.11-.76.22-1.51.34-2.27.1-.64.71-1.06,1.35-.94-.27,1.49-.48,3-.7,4.51-.64-.09-1.08-.67-.99-1.31Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m127.06,77.41c.12-.75.23-1.5.34-2.25.09-.63.68-1.07,1.32-.98-.21,1.52-.44,3.04-.69,4.56-.63-.11-1.06-.7-.96-1.33Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m126.23,81.89c.16-.74.31-1.48.45-2.22.11-.62.71-1.04,1.35-.93-.26,1.52-.56,3.03-.91,4.54-.63-.15-1.02-.77-.88-1.38Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m125.09,86.3c.22-.72.42-1.45.6-2.18.16-.61.79-.98,1.41-.84-.18.75-.38,1.5-.58,2.25-.21.74-.43,1.49-.67,2.22h0c-.61-.2-.95-.85-.77-1.45Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m123.61,90.59c.28-.7.54-1.41.78-2.12.21-.59.85-.92,1.47-.73h0c-.47,1.47-1.01,2.93-1.61,4.35-.59-.25-.87-.92-.64-1.51Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m121.74,94.71c.35-.67.68-1.34.98-2.03.26-.57.94-.84,1.53-.59-.6,1.43-1.28,2.83-2.03,4.19-.56-.32-.76-1.01-.48-1.57Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m119.42,98.59c.42-.62.83-1.25,1.21-1.89.32-.54,1.02-.73,1.59-.42-.75,1.36-1.59,2.67-2.52,3.93-.52-.39-.63-1.11-.28-1.62Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m116.6,102.1c.26-.27.5-.55.75-.83.24-.28.48-.57.72-.86.38-.49,1.11-.58,1.63-.2-.93,1.25-1.95,2.44-3.07,3.53-.44-.46-.45-1.19-.02-1.64Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m113.26,105.08c.3-.22.59-.45.88-.68.29-.23.57-.48.85-.72.47-.41,1.18-.4,1.64.06-1.12,1.09-2.34,2.09-3.64,2.96-.36-.54-.22-1.24.27-1.62Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m109.42,107.39c.67-.32,1.33-.67,1.96-1.05.53-.31,1.24-.17,1.6.37-1.3.87-2.69,1.62-4.14,2.22h0c-.24-.6.03-1.26.58-1.53Z" />
                      <Path fill="#29370d" strokeWidth={0} d="m105.21,108.9c.72-.18,1.43-.4,2.13-.65.29-.11.6-.08.87.04.27.12.5.33.62.63h0c-1.45.6-2.96,1.06-4.49,1.37h0c-.13-.64.27-1.23.86-1.39Z" />
                      <G>
                        <G clipPath="url(#clippath-1)">
                          <Path fill="#29370d" strokeWidth={0} d="m1.34,62.9l1,.59.5.29h0s0,0,0,0c0,.04,0,.04,0,.07h0s0,0,0,0h0s0,.02,0,.02v.02s.04.04.04.04l.05.09.1.17.02.04.02.04v.04s.02.04.02.04v.04s0,.04,0,.04v.04s0,.04,0,.04v.04s-.02.04-.02.04v.04s-.03.04-.03.04l-.02.04-.02.04-.02.04-.02.04-.03.04-.03.04-.03.04-.03.04-.04.04-.04.04-.04.04-.04.03-.04.03-.04.03-.05.03-.05.03-.1.06-.11.05-.14-.33-.07-.16-.09-.15-.35-.61-.21-.32-.49-.32-.97-.63.02-.03.02-.03.03-.03.03-.03.03-.02.03-.02.03-.02.04-.02.04-.02h.04s.02-.02.02-.02h.02s.04,0,.04,0h.04s.04,0,.04,0h.08s.09.01.09.01l.09.02.1.02.1.03.1.04.1.04.1.05.1.06Z" />
                        </G>
                        <G clipPath="url(#clippath-2)">
                          <Path fill="#29370d" strokeWidth={0} d="m3.04,63.43l-.1.17-.05.09-.02.04v.02s-.02.01-.02.01h0s0,0,0,0h0s0,.04,0,.07h0s0,0,0,0l-.5.29-1,.59-.1.06-.1.05-.1.04-.1.04-.1.03-.1.02-.09.02h-.09s-.08.01-.08.01h-.04s-.04,0-.04,0h-.04s-.02,0-.02,0h-.02s-.04-.02-.04-.02l-.04-.02-.04-.02-.03-.02-.03-.02-.03-.02-.03-.03-.03-.03-.02-.03-.02-.03.97-.63.49-.32.21-.32.35-.61.09-.15.07-.16.14-.33.11.05.1.06.05.03.05.03.04.03.04.03.04.03.04.04.04.04.04.04.03.04.03.04.03.04.03.04.02.04.02.04.02.04.02.04v.04s.03.04.03.04v.04s.02.04.02.04v.04s0,.04,0,.04v.04s0,.04,0,.04v.04s-.02.04-.02.04l-.02.04-.02.04Z" />
                        </G>
                      </G>
                      <G>
                        <G clipPath="url(#clippath-3)">
                          <Path fill="#29370d" strokeWidth={0} d="m131.57,64.73l-1-.59-.5-.29h0s0,0,0,0c0-.04,0-.04,0-.07h0s0,0,0,0h0s0-.02,0-.02v-.02s-.04-.04-.04-.04l-.05-.09-.1-.17-.02-.04-.02-.04v-.04s-.02-.04-.02-.04v-.04s0-.04,0-.04v-.04s0-.04,0-.04v-.04s.02-.04.02-.04v-.04s.03-.04.03-.04l.02-.04.02-.04.02-.04.02-.04.03-.04.03-.04.03-.04.03-.04.04-.04.04-.04.04-.04.04-.03.04-.03.04-.03.05-.03.05-.03.1-.06.11-.05.14.33.07.16.09.15.35.61.21.32.49.32.97.63-.02.03-.02.03-.03.03-.03.03-.03.02-.03.02-.03.02-.04.02-.04.02h-.04s-.02.02-.02.02h-.02s-.04,0-.04,0h-.04s-.04,0-.04,0h-.08s-.09-.01-.09-.01l-.09-.02-.1-.02-.1-.03-.1-.04-.1-.04-.1-.05-.1-.06Z" />
                        </G>
                        <G clipPath="url(#clippath-4)">
                          <Path fill="#29370d" strokeWidth={0} d="m129.88,64.19l.1-.17.05-.09.02-.04v-.02s.02-.01.02-.01h0s0,0,0,0h0s0-.04,0-.07h0s0,0,0,0l.5-.29,1-.59.1-.06.1-.05.1-.04.1-.04.1-.03.1-.02.09-.02h.09s.08-.01.08-.01h.04s.04,0,.04,0h.04s.02,0,.02,0h.02s.04.02.04.02l.04.02.04.02.03.02.03.02.03.02.03.03.03.03.02.03.02.03-.97.63-.49.32-.21.32-.35.61-.09.15-.07.16-.14.33-.11-.05-.1-.06-.05-.03-.05-.03-.04-.03-.04-.03-.04-.03-.04-.04-.04-.04-.04-.04-.03-.04-.03-.04-.03-.04-.03-.04-.02-.04-.02-.04-.02-.04-.02-.04v-.04s-.03-.04-.03-.04v-.04s-.02-.04-.02-.04v-.04s0-.04,0-.04v-.04s0-.04,0-.04v-.04s.02-.04.02-.04l.02-.04.02-.04Z" />
                        </G>
                      </G>
                    </G>
                    <G>
                      <Circle fill="#3f4a21" strokeWidth={0} cx="66.46" cy="63.81" r="54.11" transform="translate(-25.66 65.68) rotate(-45)" />
                      <Path fill="#28360d" strokeWidth={0} d="m66.46,10.03c14.37,0,27.87,5.59,38.03,15.75,10.16,10.16,15.75,23.66,15.75,38.03s-5.59,27.87-15.75,38.03c-10.16,10.16-23.66,15.75-38.03,15.75s-27.87-5.59-38.03-15.75c-10.16-10.16-15.75-23.66-15.75-38.03s5.59-27.87,15.75-38.03c10.16-10.16,23.66-15.75,38.03-15.75m0-.66c-30.07,0-54.44,24.37-54.44,54.44s24.37,54.44,54.44,54.44,54.44-24.37,54.44-54.44S96.52,9.37,66.46,9.37h0Z" />
                    </G>
                  </Svg>
                  <Image
                    style={styles.dpBackground}
                    src={character?.avatar_url ?? ''}
                  />
                </View>
                <View style={{ display: 'flex', flexDirection: 'column', gap: '5pt' }}>
                  <View>
                    <Text style={styles.title}>{character?.name}</Text>
                  </View>
                  <View style={[styles.flexWrapper, { flexWrap: 'wrap', gap: '20pt' }]}>
                    {attributes.map((attr) => (
                      <View id={attr.key} style={{ width: '23%' }}>
                        <Text style={styles.attrTitle}>{attr.key}</Text>
                        <Text style={styles.attrValue}>{attr.value}</Text>
                      </View>
                    ))}
                    <View style={{ width: '46%' }}>
                      <Text style={styles.attrTitle}>Motivations</Text>
                      <Text style={styles.attrValue}>
                        {
                          character?.motivations
                            ? character.motivations.map((item) => `${item}`).join(', ')
                            : 'Not Available'
                        }
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.section}>
              <View style={[styles.flexWrapper, { flexWrap: 'wrap', gap: '20pt', justifyContent: 'center' }]}>
                <Text style={styles.subTitle}>Abilities</Text>
                <View style={[styles.flexWrapper, { flexWrap: 'wrap', gap: '20pt', justifyContent: 'center' }]}>
                  {abilities.map((ability) => (
                    <View key={ability.key} style={{ width: '120pt', position: 'relative' }}>
                      <Image
                        src={AbilityBg}
                        style={{
                          width: '120pt',
                        }}
                      />
                      <View style={styles.abilityWrapper}>
                        <Text style={styles.abilityTitle}>{ability.key}</Text>
                        <Text style={styles.abilityValue}>{ability.value}</Text>
                      </View>
                    </View>
                  ))}
                </View>
              </View>
            </View>
            <View style={styles.section}>
              <View style={[styles.flexWrapper, { flexWrap: 'wrap', gap: '20pt', justifyContent: 'center' }]}>
                <Text style={styles.subTitle}>Physical Profile</Text>
                <View style={[styles.flexWrapper, { flexWrap: 'nowrap', gap: '20pt' }]}>
                  {physicals.map((attr) => (
                    <View id={attr.key} style={{ width: '20%' }}>
                      <Text style={styles.attrTitle}>{attr.key}</Text>
                      <Text style={styles.attrValue}>{attr.value}</Text>
                    </View>
                  ))}
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>
      <Page style={styles.body}>
        <View style={{ position: 'relative' }}>
          <Image style={styles.bgImage} src={PageBg} />
          <View style={styles.pageWrapper}>
            <View style={styles.section}>
              <View style={[styles.flexWrapper, { flexWrap: 'wrap', gap: '20pt', justifyContent: 'center' }]}>
                <Text style={styles.subTitle}>Background</Text>
                <View style={[styles.flexWrapper, { flexWrap: 'wrap', gap: '20pt' }]}>
                  {background.map((attr) => (
                    <View id={attr.key} style={{ width: '30%' }}>
                      <Text style={styles.attrTitle}>{attr.key}</Text>
                      <Text style={styles.attrValue}>{attr.value}</Text>
                    </View>
                  ))}
                  <View style={{ width: '100%' }}>
                    <Text style={styles.attrTitle}>Birth Story</Text>
                    <Text style={styles.attrValue}>{character?.backstory ?? 'Not Available'}</Text>
                  </View>
                  <View style={{ width: '100%' }}>
                    <Text style={styles.attrTitle}>Life Altering Event</Text>
                    <Text style={styles.attrValue}>{character?.life_altering_event ?? 'Not Available'}</Text>
                  </View>
                </View>
              </View>
            </View>
            {character?.short_term_goals !== null && character?.short_term_goals !== undefined
              && character.short_term_goals.length > 0 && (
                <View style={styles.section}>
                  <View style={[styles.flexWrapper, { flexWrap: 'wrap', gap: '20pt', justifyContent: 'center' }]}>
                    <Text style={styles.subTitle}>
                      {`Short Term Goal${character.short_term_goals.length > 1 ? 's' : ''}`}
                    </Text>
                    <View style={[styles.flexWrapper, { flexWrap: 'wrap', gap: '20pt' }]}>
                      {character?.short_term_goals.map((note, index) => (
                        <View id={`${note?.title}_${index}`} style={{ width: '100%' }}>
                          <Text style={styles.attrTitle}>{note?.title}</Text>
                          <Text style={styles.attrValue}>{note?.content}</Text>
                        </View>
                      ))}
                    </View>
                  </View>
                </View>
            )}
            {character?.long_term_goals !== null && character?.long_term_goals !== undefined
              && character.long_term_goals.length > 0 && (
                <View style={styles.section}>
                  <View style={[styles.flexWrapper, { flexWrap: 'wrap', gap: '20pt', justifyContent: 'center' }]}>
                    <Text style={styles.subTitle}>
                      {`Long Term Goal${character.long_term_goals.length > 1 ? 's' : ''}`}
                    </Text>
                    <View style={[styles.flexWrapper, { flexWrap: 'wrap', gap: '20pt' }]}>
                      {character?.long_term_goals.map((note, index) => (
                        <View id={`${note?.title}_${index}`} style={{ width: '100%' }}>
                          <Text style={styles.attrTitle}>{note?.title}</Text>
                          <Text style={styles.attrValue}>{note?.content}</Text>
                        </View>
                      ))}
                    </View>
                  </View>
                </View>
            )}
            {character?.notes !== null && character?.notes !== undefined
              && character.notes.length > 0 && (
                <View style={styles.section}>
                  <View style={[styles.flexWrapper, { flexWrap: 'wrap', gap: '20pt', justifyContent: 'center' }]}>
                    <Text style={styles.subTitle}>
                      {`Additional Note${character.notes.length > 1 ? 's' : ''}`}
                    </Text>
                    <View style={[styles.flexWrapper, { flexWrap: 'wrap', gap: '20pt' }]}>
                      {character?.notes.map((note, index) => (
                        <View id={`${note?.title}_${index}`} style={{ width: '100%' }}>
                          <Text style={styles.attrTitle}>{note?.title}</Text>
                          <Text style={styles.attrValue}>{note?.content}</Text>
                        </View>
                      ))}
                    </View>
                  </View>
                </View>
            )}
          </View>
        </View>
      </Page>
    </Document>
  );
}

export default PDFFile;
