/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useEffect, useState } from 'react';
import { Stage } from 'konva/lib/Stage';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
  Typography,
  Zoom,
  useMediaQuery,
} from '@mui/material';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { useParams } from 'react-router-dom';
import {
  AIButton,
  AIButtonImg,
  AIButtonText,
  AttributeTextField,
  AttributeWrapper,
  BookmarkImg,
  BookmarkTitleImg,
  CoinImg,
  FlexibleHeightImg,
  HelpImg,
} from '../../styledComps/styledComps';
import { useAuth } from '../../../hooks/use-auth';
import useGraphQL from '../../../hooks/useGraphQL';
import CharacterSheetBGImg from '../../../images/character-sheet-background.webp';
import CustomBorder from '../../../images/custom-border-image-1.webp';
import BookmarkImage from '../../../images/bookmark.webp';
import TexturedBackground from '../../../images/textured-background-1@3x.webp';
import CharacterSheetIcon from '../../../images/character-sheet-light.webp';
import HeraldIcon from '../../../images/herald.webp';
import HelpIcon from '../../../images/help.webp';
import HelpIcon2 from '../../../images/question-mark-1.webp';
import FantasyIcon from '../../../images/fantasy.webp';
import CoinIcon from '../../../images/azamel_Coin.png';
import BlackDice from '../../../images/dice-black-outlined.webp';
import WhiteDice from '../../../images/dice-white.webp';
import BlackFantasy from '../../../images/fantasy-black.webp';
import BlackHerald from '../../../images/Herald-black.webp';
import BlackHelp from '../../../images/help-black.webp';
import loadingGif from '../../../assets/images/loading.gif';
import CharacterAttributes from './CharacterAttributes';
import CharacterNotesWrapper from '../../notes/CharacterNotesWrapper';
import CharacterAbilitiesGen from './CharacterAbilitiesGen';
import CharacterBackground from './CharacterBackground';
import CharacterPhysicalProfile from './CharacterPhysicalProfile';
import {
  Body_Shape,
  CharacterAbility,
  CharacterInsertInput,
  CharacterLong_Term_Goal,
  CharacterShort_Term_Goal,
  CharacterUpdateInput,
  Character_Set,
  Class,
  CreateCharacterDocument,
  CreateNoteDocument,
  DeleteManyNotesDocument,
  DeleteOneCharacterDocument,
  Eye,
  Gender,
  GetCharactersSetsDocument,
  GetPrompt_TemplateQueryInputDocument,
  GetText_Generation_SkusDocument,
  GetUserCharacterGenerationDocument,
  GetUserCharacterGenerationQuery,
  GetUserCharacterGenerationQueryVariables,
  NoteInsertInput,
  Personality,
  Race,
  Skin,
  UpdateCharacterSetDocument,
  UpdateOneCharacterDocument,
  UpdateOneNoteDocument,
} from '../../../__generated__/graphql';
import useGraphQLMutation from '../../../hooks/useGraphQLMutation';
import useNotification from '../../../hooks/useNotification';
import CharacterTitBit from './CharacterTitBit';
import cookieUtil from '../../../util/cookieUtil';
import CharacterSheetRandomizer from './CharacterSheetRandomizer';
import CharacterImageBox from './CharacterImageBox';
import appStore from '../../../store/AppStore';
import CharacterWarningModal from '../../modals/CharacterWarningModal';
import fetchGraphQL from '../../../hooks/fetchers/fetchGraphQL';
import { CharacterGenerationWorkflowRequest } from '../../../__generated__/realmWorldsApiSchemas';
import { useGenerateNewCharacterText } from '../../../__generated__/realmWorldsApiComponents';
import getAttributeValue from '../../../util/aiGetCharAttribute';

function CharacterSheet() {
  const { id: realmId } = useParams();

  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const [selectedFileBlob, setSelectedFileBlob] = React.useState<Blob | null>(
    null,
  );
  const [previewUrl, setPreviewUrl] = React.useState<string | null>(null);
  const [currentStage, setCurrentStage] = React.useState<Stage | null>(null);
  // let currentStage: Stage | null = null;
  const [characterName, setCharacterName] = React.useState('');
  const [characterAge, setCharacterAge] = React.useState<number>(0);
  const [characterPronouns, setCharacterPronouns] = React.useState('');
  const [characterGender, setCharacterGender] = React.useState<Gender | null>(
    null,
  );
  const [characterRace, setCharacterRace] = React.useState<Race | null>(null);
  const [characterClass, setCharacterClass] = React.useState<Class | null>(
    null,
  );
  const [characterAlignment, setCharacterAlignment] = React.useState('');
  const [characterMotivations, setCharacterMotivations] = React.useState<
    string[]
  >([]);
  const [characterPersonalityType, setCharacterPersonalityType] =
    React.useState<Personality | null>(null);
  const [characterBodyType, setCharacterBodyType] =
    React.useState<Body_Shape | null>(null);
  const [characterSkinColor, setCharacterSkinColor] =
    React.useState<Skin | null>(null);
  const [characterHairColor, setCharacterHairColor] = React.useState('');
  const [characterHairStyle, setCharacterHairStyle] = React.useState('');
  const [characterEyeColor, setCharacterEyeColor] = React.useState<Eye | null>(
    null,
  );
  const [characterProfession, setCharacterProfession] = React.useState('');
  const [characterRelationship, setCharacterRelationship] = React.useState('');
  const [characterLifeAlteringEvent, setCharacterLifeAlteringEvent] =
    React.useState('');
  const [characterBackStory, setCharacterBackStory] = React.useState('');
  const [characterShortTermGoals, setCharacterShortTermGoals] = React.useState<
    CharacterShort_Term_Goal[]
  >([]);
  const [characterLongTermGoals, setCharacterLongTermGoals] = React.useState<
    CharacterLong_Term_Goal[]
  >([]);
  const [characterNotes, setCharacterNotes] = React.useState<NoteInsertInput[]>(
    [],
  );
  const [charactersCharacterSet, setCharactersCharacterSet] = React.useState({
    name: 'Select a Character Set',
    _id: '',
  });

  const [characterAbilities, setCharacterAbilities] =
    React.useState<CharacterAbility>({
      strength: '0',
      dexterity: 0,
      consitution: 0,
      intelligence: 0,
      wisdom: 0,
      charisma: 0,
    });
  const [readyToSave, setReadyToSave] = React.useState(false);
  const [hasBeenVisited, setHasBeenVisited] = React.useState(true);
  const [welcomeExpanded, setWelcomeExpanded] = React.useState(false);
  const [characterImageEdited, setCharacterImageEdited] = React.useState(false);
  const [deletingCharacter, setDeletingCharacter] = React.useState(false);

  const auth = useAuth();
  const { displayNotification } = useNotification();
  const viewMood = appStore((state) => state.characterSheetMood);
  const selectedCharacter = appStore((state) => state.selectedCharacter);
  const createCharacterView = appStore((state) => state.createCharacterView);

  const userCharacterGeneration:
    | GetUserCharacterGenerationQuery
    | null
    | undefined = appStore((state) => state.userCharacterGeneration);
  const setUserCharacterGeneration = (gen: GetUserCharacterGenerationQuery) => {
    appStore.setState({
      userCharacterGeneration: gen,
    });
  };

  const [notesSaved, setNotesSaved] = React.useState<number>(0);
  const [notesUpdated, setNotesUpdated] = React.useState<number>(0);
  const [notesToUpdate, setNotesToUpdate] = React.useState<any[]>([]);
  const [notesToSave, setNotesToSave] = React.useState<any[]>([]);
  const [openCharacterWarningModal, setOpenCharacterWarningModal] =
    React.useState(false);
  const [oldCharacterSetID, setOldCharacterSetID] = React.useState('');

  const [noteSavedIds, setNoteSavedIds] = React.useState<string[]>([]);
  const [noteUpdatedIds, setNoteUpdatedIds] = React.useState<string[]>([]);
  const [aiGenIsLoading, setAiGenIsLoading] = React.useState(false);

  const { data: charactersSets } = useGraphQL(GetCharactersSetsDocument, {
    query: {
      owner: {
        _id: auth?.user?.uid,
      },
    },
  });

  async function urlToBlob(url: string, setUrl: any) {
    try {
      // Fetch the data from the URL
      const response = await fetch(url);
      const blober = await fetch(url).then((r) => r.blob());
      const urlBlob = URL.createObjectURL(blober);
      // Check if the request was successful
      if (!response.ok) {
        throw new Error(`Failed to fetch data from ${url}`);
      }
      return setUrl(urlBlob);
    } catch (error) {
      console.error(error);
      // Handle the error appropriately
      return null;
    }
  }

  const convertFileToBlob = (file: File) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const blob = new Blob([reader.result as ArrayBuffer], {
        type: file.type,
      });
      setSelectedFileBlob(blob);
    };

    reader.readAsArrayBuffer(file);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      convertFileToBlob(file);
      setPreviewUrl(URL.createObjectURL(file));
      setCharacterImageEdited(true);
    }
  };

  React.useEffect(() => {
    if (
      previewUrl !== null &&
      charactersCharacterSet._id !== '' &&
      characterName !== ''
    ) {
      setReadyToSave(true);
    } else {
      setReadyToSave(false);
    }
  }, [
    previewUrl,
    characterAbilities,
    characterAge,
    characterAlignment,
    characterBodyType,
    characterClass,
    characterEyeColor,
    characterGender,
    characterHairColor,
    characterHairStyle,
    characterLifeAlteringEvent,
    characterMotivations,
    characterName,
    characterPersonalityType,
    characterProfession,
    characterPronouns,
    characterPronouns,
    characterRace,
    characterRelationship,
    characterSkinColor,
    charactersCharacterSet,
  ]);

  const {
    data: createdCharacter,
    mutate: createCharacter,
    status: createdCharacterStatus,
    error: createCharacterError,
  } = useGraphQLMutation(CreateCharacterDocument);

  const { mutate: deleteCharacter, status: deleteCharacterStatus } =
    useGraphQLMutation(DeleteOneCharacterDocument);

  const {
    data: updatedCharacter,
    mutate: updateCharacter,
    status: updatedCharacterStatus,
    error: updateCharacterError,
  } = useGraphQLMutation(UpdateOneCharacterDocument);

  const {
    data: createdNote,
    mutate: createNote,
    status: createNoteStatus,
    error: createNoteError,
  } = useGraphQLMutation(CreateNoteDocument);

  const {
    data: updatedNote,
    mutate: updateNote,
    status: updateNoteStatus,
    error: updateNoteError,
  } = useGraphQLMutation(UpdateOneNoteDocument);

  const {
    mutate: deleteNotes,
    status: deleteNotesStatus,
    error: deleteNotesError,
  } = useGraphQLMutation(DeleteManyNotesDocument);

  if (createCharacterError) console.log(createCharacterError);

  const { mutate: updateCharacterSet, status: updateCharacterSetStatus } =
    useGraphQLMutation(UpdateCharacterSetDocument);

  const { data: promptTemplates } = useGraphQL(
    GetPrompt_TemplateQueryInputDocument,
    {},
  );
  const { data: textGenerationSkus } = useGraphQL(
    GetText_Generation_SkusDocument,
    {},
  );
  const createNewCharacterTextMutate = useGenerateNewCharacterText();

  const handleSaveCharacter = () => {
    const character: CharacterInsertInput = {
      name: characterName,
      avatar_url: previewUrl,
      character_image_thumbnail_url: previewUrl,
      owner: {
        link: auth?.realmUser?.id,
      },
      race: characterRace?.name,
      class: characterClass?.name,
      gender: characterGender?.name,
      pronoun: characterPronouns,
      motivations: characterMotivations,
      abilities: characterAbilities,
      alignment: characterAlignment,
      physical_profile: {
        body_type: characterBodyType?.name,
        skin_color: characterSkinColor?.name,
        hair_color: characterHairColor,
        hair_style: characterHairStyle,
        eye_color: characterEyeColor?.name,
      },
      profession: characterProfession,
      relationship_status: characterRelationship,
      life_altering_event: characterLifeAlteringEvent,
      backstory: characterBackStory,
      short_term_goals: characterShortTermGoals,
      long_term_goals: characterLongTermGoals,
      notes: {
        link: noteSavedIds,
      },
      realms: {
        link: [realmId],
      },
    };
    createCharacter({
      data: character,
    });
  };

  const handleUpdateCharacter = () => {
    const oldNoteIDs = selectedCharacter?.notes?.map((note) => note?._id);
    console.log(noteSavedIds, noteUpdatedIds);
    const character: CharacterUpdateInput = {
      name: characterName,
      avatar_url: selectedCharacter?.avatar_url,
      character_image_thumbnail_url: previewUrl,
      owner: {
        link: auth?.realmUser?.id,
      },
      race: characterRace?.name,
      class: characterClass?.name,
      gender: characterGender?.name,
      pronoun: characterPronouns,
      motivations: characterMotivations,
      abilities: characterAbilities,
      alignment: characterAlignment,
      physical_profile: {
        body_type: characterBodyType?.name,
        skin_color: characterSkinColor?.name,
        hair_color: characterHairColor,
        hair_style: characterHairStyle,
        eye_color: characterEyeColor?.name,
      },
      profession: characterProfession,
      relationship_status: characterRelationship,
      life_altering_event: characterLifeAlteringEvent,
      backstory: characterBackStory,
      short_term_goals: characterShortTermGoals,
      long_term_goals: characterLongTermGoals,
      notes: {
        link:
          oldNoteIDs !== undefined
            ? [...oldNoteIDs, ...noteSavedIds]
            : noteSavedIds,
      },
      realms: {
        link: [realmId],
      },
    };
    updateCharacter({
      set: character,
      query: {
        _id: selectedCharacter?._id,
      },
    });
  };

  const handleSaveNote = () => {
    if (viewMood === 'create') {
      if (
        characterNotes.length > 0 &&
        notesSaved <= characterNotes.length - 1
      ) {
        const newNote: NoteInsertInput = {
          content: characterNotes[notesSaved].content,
          owner: {
            link: auth?.realmUser?.id,
          },
          realm: {
            link: realmId,
          },
          title: characterNotes[notesSaved].title,
        };
        createNote({
          data: newNote,
        });
      } else {
        handleSaveCharacter();
      }
    }
    if (viewMood === 'edit') {
      console.log(notesToSave);
      if (notesToSave.length > 0 && notesSaved <= notesToSave.length - 1) {
        const newNote: NoteInsertInput = {
          content: notesToSave[notesSaved].content,
          owner: {
            link: auth?.realmUser?.id,
          },
          realm: {
            link: realmId,
          },
          title: notesToSave[notesSaved].title,
        };
        createNote({
          data: newNote,
        });
      } else {
        handleUpdateCharacter();
      }
    }
  };

  const handleNotesToSave = () => {
    const newNotes = characterNotes.filter(
      (note) => note._id === '' || note._id === null || note._id === undefined,
    );
    setNotesToSave(newNotes);
    if (newNotes.length === 0) {
      handleUpdateCharacter();
    }
  };

  const handleDeleteCharacter = () => {
    setDeletingCharacter(true);
    if (
      selectedCharacter?.notes?.length !== undefined &&
      selectedCharacter?.notes?.length > 0
    ) {
      const deleteIDs = selectedCharacter.notes.map((note) => note?._id);
      deleteNotes({
        query: {
          _id_in: deleteIDs,
        },
      });
    } else {
      deleteCharacter({
        query: {
          _id: selectedCharacter?._id,
        },
      });
    }
  };

  React.useEffect(() => {
    if (notesToSave && notesToSave.length > 0) {
      handleSaveNote();
    }
  }, [notesToSave]);

  const handleUpdateNote = () => {
    console.log(notesToUpdate);
    if (notesToUpdate.length > 0 && notesUpdated <= notesToUpdate.length - 1) {
      updateNote({
        set: notesToUpdate[notesUpdated],
        query: {
          _id: notesToUpdate[notesUpdated]._id,
        },
      });
    }
  };

  const handleDeleteNote = () => {
    if (selectedCharacter?.notes) {
      const deletedNotesIds = selectedCharacter?.notes
        .filter(
          (existingNote) =>
            !characterNotes.some(
              (newNote) => newNote._id === existingNote?._id,
            ),
        )
        .map((deletedNote) => deletedNote?._id);
      if (deletedNotesIds.length > 0) {
        deleteNotes({
          query: {
            _id_in: deletedNotesIds,
          },
        });
      } else {
        handleNotesToSave();
      }
    }
  };

  const handleSortUpdate = () => {
    const oldNotes = selectedCharacter?.notes;
    console.log('oldnotes: ', oldNotes);
    if (oldNotes !== null && oldNotes !== undefined) {
      if (characterNotes.length > 0) {
        const updated = [];
        for (let i = 0; i < characterNotes.length; i += 1) {
          const existingNote = oldNotes.find(
            (note) => note?._id === characterNotes[i]?._id,
          );
          if (existingNote) {
            console.log(existingNote);
            if (
              existingNote.title !== characterNotes[i].title ||
              existingNote.content !== characterNotes[i].content
            ) {
              updated.push(characterNotes[i]);
            }
          }
          if (i === characterNotes.length - 1) {
            console.log(updated);
            setNotesToUpdate(updated);
            handleDeleteNote();
            console.log('nothing');
          }
        }
      } else {
        handleDeleteNote();
      }
    } else {
      handleDeleteNote();
    }
  };

  const handleSuccessfulJobSubmission = async (
    data: CharacterGenerationWorkflowRequest,
    workflowId: string,
  ) => {
    console.log('data: ', data.userTextGenerationId);
    let retriesLeft = 60; // 120 seconds total @ 2 seconds per retry
    // poll for completion
    const poll = setInterval(async () => {
      await auth?.getCurrentUserTokens();
      // eslint-disable-next-line max-len
      const userCharacterGenrationResponse = await fetchGraphQL<
        GetUserCharacterGenerationQuery,
        GetUserCharacterGenerationQueryVariables
      >(
        GetUserCharacterGenerationDocument,
        {
          query: {
            user_text_generation: {
              _id: data.userTextGenerationId,
            },
          },
        },
        localStorage.getItem('realmToken') || '',
      );
      console.log(
        'userCharacterGenrationResponse: ',
        userCharacterGenrationResponse,
      );
      // setUserCharacterGeneration(userCharacterGenrationResponse);
      console.log(
        'polling: ',
        userCharacterGenrationResponse?.user_character_generation,
      );
      if (userCharacterGenrationResponse?.user_character_generation !== null) {
        console.log('userCharacterGeneration: ', userCharacterGeneration);
        // update all of the state variables
        // setIsLoading(false);
        // HERE IS WHERE YOU WILL UPDATE YOUR UI USING THE ATTRIBUTES
        // setAbilitiesCharisma(
        //   userCharacterGeneration
        //     .user_character_generation
        //     .attributes?.find((attribute) => attribute?.name === 'charisma')?.value || '',
        // );
        setUserCharacterGeneration(
          userCharacterGenrationResponse as GetUserCharacterGenerationQuery,
        );
        /// ... do the rest
        clearInterval(poll);
        setAiGenIsLoading(false);
        displayNotification({
          message: 'Successfully generated character text',
          type: 'success',
        });
      }
      retriesLeft -= 1;
      console.log('retries left: ', retriesLeft);
      if (retriesLeft === 0) {
        clearInterval(poll);
        setAiGenIsLoading(false);
        displayNotification({
          message: 'Failed to generate character text',
          type: 'error',
        });
      }
    }, 2000);
  };

  const handleCreateNewCharacterText = () => {
    setAiGenIsLoading(true);
    createNewCharacterTextMutate.mutate(
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: {
          abilities: {
            charisma: characterAbilities.charisma?.toString() ?? undefined,
            constitution:
              characterAbilities.consitution?.toString() ?? undefined,
            dexterity: characterAbilities.dexterity?.toString() ?? undefined,
            intelligence:
              characterAbilities.intelligence?.toString() ?? undefined,
            strength: characterAbilities.strength ?? undefined,
            wisdom: characterAbilities.wisdom?.toString() ?? undefined,
          },
          alignment: characterAlignment,
          // aditionalNotes,
          age: characterAge.toString() ?? undefined,
          birthStory: characterBackStory,
          characterName,
          // characterType,
          class: characterClass?.name ?? undefined,
          gender: characterGender?.name ?? undefined,
          // height,
          hairColor: characterHairColor,
          // lifeAlteringEvents,
          // longTermGoals,
          motivations: characterMotivations[0] ?? undefined,
          personalityType: characterPersonalityType?.name ?? undefined,
          pronouns: characterPronouns,
          race: characterRace?.name ?? undefined,
          // shortTermGoals,
          // weight,
          textGenerationSkuId: textGenerationSkus?.text_generation_skus[0]?._id,
          promptTemplateId: promptTemplates?.prompt_templates[0]?._id,
        },
      },
      {
        onSuccess: (data) => {
          console.log('data: ', data);
          console.log(
            'setting userTextGenerationid: ',
            data.userTextGenerationId,
          );
          if (
            data.userTextGenerationId === undefined ||
            data.userTextGenerationId === null
          ) {
            console.log('userTextGenerationId is undefined');
            return;
          }
          handleSuccessfulJobSubmission(data, data.userTextGenerationId);
        },
        onError: (error) => {
          console.log('error: ', error);
        },
      },
    );
  };

  React.useEffect(() => {
    console.log(notesToUpdate);
    if (notesToUpdate.length > 0) {
      handleUpdateNote();
    }
  }, [notesToUpdate]);

  React.useEffect(() => {
    if (notesSaved > 0) {
      handleSaveNote();
    }
  }, [notesSaved]);

  React.useEffect(() => {
    if (notesUpdated > 0) {
      handleUpdateNote();
    }
  }, [notesUpdated]);

  if (createdNote) {
    console.log('note info:', createdNote.insertOneNote);
  }

  if (createNoteError) {
    console.log('note error:', createNoteError);
  }

  const handleUploadCharacterImage = (id: string) => {
    const formData = new FormData();
    console.log(selectedFile, selectedFileBlob);
    formData.append('AvatarImage', selectedFileBlob!);
    formData.append('CharacterImage', selectedFileBlob!);
    displayNotification({
      message: 'Uploading character image ...',
      type: 'info',
    });
    fetch(`${process.env.REACT_APP_API_URL}/Characters/${id}/Images`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          displayNotification({
            message: 'Failed to upload character image',
            type: 'error',
          });
        }
        const contentType = response.headers.get('Content-Type');
        if (contentType && contentType.includes('application/json')) {
          return response.json();
        }
        return null; // or handle it based on your requirements
      })
      .then((result) => {
        displayNotification({
          message: 'Character image uploaded successfully',
          type: 'success',
        });
        appStore.setState({
          selectedCharacterView: 'catalog',
        });
      })
      .catch((error) => {
        console.error('There was a problem: ', error);
        displayNotification({
          message: 'Failed to upload character image',
          type: 'error',
        });
      });
  };

  /**
   * Converts the current stage to a Blob and initiates character set creation.
   *
   * @returns {void}
   */
  const convertStageToBlob = () => {
    if (viewMood === 'create') {
      handleSaveNote();
    } else if (viewMood === 'edit') {
      handleSortUpdate();
    }
    if (currentStage) {
      console.log(currentStage);
      const dataUrl = currentStage.toDataURL();
      fetch(dataUrl)
        .then((res) => res.blob())
        .then((blob) => {
          if (blob) {
            setSelectedFileBlob(blob);
          }
        });
    }
  };

  React.useEffect(() => {
    if (createNoteStatus === 'loading') {
      displayNotification({ message: 'Creating note ...', type: 'info' });
    }
    if (createNoteStatus === 'error') {
      displayNotification({
        message: 'Failed to create note ...',
        type: 'error',
      });
    }
    if (createNoteStatus === 'success') {
      // notesSavedIds = [...notesSavedIds, createdNote.insertOneNote?._id];
      const totalNotes =
        viewMood === 'create' ? characterNotes.length : notesToSave.length;
      setNoteSavedIds([...noteSavedIds, createdNote.insertOneNote?._id]);
      if (notesSaved < totalNotes) {
        setNotesSaved(notesSaved + 1);
        // console.log('notes saved', notesSaved);
      } else if (notesSaved === totalNotes) {
        displayNotification({
          message: 'Note created successfully ...',
          type: 'success',
        });
        console.log('note ids', noteSavedIds);
        // console.log('note info', createdNote);
        if (viewMood === 'create') {
          handleSaveCharacter();
        }
        if (viewMood === 'edit') {
          handleUpdateCharacter();
        }
      }
    }
  }, [createNoteStatus]);

  React.useEffect(() => {
    if (updateNoteStatus === 'loading') {
      displayNotification({ message: 'Updating note ...', type: 'info' });
    }
    if (updateNoteStatus === 'error') {
      displayNotification({
        message: 'Failed to update note ...',
        type: 'error',
      });
    }
    if (updateNoteStatus === 'success') {
      setNoteUpdatedIds([...noteUpdatedIds, updatedNote.updateOneNote?._id]);
      if (notesUpdated + 1 < notesToUpdate.length) {
        setNotesUpdated(notesUpdated + 1);
      } else if (notesUpdated + 1 === notesToUpdate.length) {
        displayNotification({
          message: 'Notes updated successfully ...',
          type: 'success',
        });
        handleDeleteNote();
      } else {
        // console.log('note info', createdNote);
        // handleSaveCharacter();
      }
    }
  }, [updateNoteStatus]);

  React.useEffect(() => {
    if (deleteNotesStatus === 'loading') {
      displayNotification({ message: 'Deleting notes ...', type: 'info' });
    }
    if (deleteNotesStatus === 'error') {
      displayNotification({
        message: 'Failed to delete notes ...',
        type: 'error',
      });
    }
    if (deleteNotesStatus === 'success') {
      displayNotification({
        message: 'Note deleted successfully ...',
        type: 'success',
      });
      if (!deletingCharacter) handleNotesToSave();
      if (deletingCharacter) {
        deleteCharacter({
          query: {
            _id: selectedCharacter?._id,
          },
        });
      }
    }
  }, [deleteNotesStatus]);

  React.useEffect(() => {
    if (deleteCharacterStatus === 'loading') {
      displayNotification({ message: 'Deleting character ...', type: 'info' });
    }
    if (deleteCharacterStatus === 'error') {
      displayNotification({
        message: 'Failed to delete character ...',
        type: 'error',
      });
    }
    if (deleteCharacterStatus === 'success') {
      displayNotification({
        message: 'Character deleted successfully ...',
        type: 'success',
      });
      appStore.setState({
        selectedCharacterView: 'catalog',
      });
    }
  }, [deleteCharacterStatus]);

  React.useEffect(() => {
    if (createdCharacterStatus === 'loading') {
      displayNotification({ message: 'Creating character ...', type: 'info' });
    }
    if (createdCharacterStatus === 'error') {
      displayNotification({
        message: 'Failed to create character ...',
        type: 'error',
      });
    }
    if (createdCharacterStatus === 'success') {
      displayNotification({
        message: 'Character created successfully ...',
        type: 'success',
      });
      handleUploadCharacterImage(createdCharacter.insertOneCharacter?._id);
      const oldCharacterIDs =
        charactersSets?.character_sets
          .find(
            (characterset) => characterset?._id === charactersCharacterSet._id,
          )
          ?.characters?.map((characters) => characters?._id) ?? [];
      updateCharacterSet({
        set: {
          characters: {
            link: [
              ...oldCharacterIDs,
              createdCharacter.insertOneCharacter?._id,
            ],
          },
        },
        query: {
          _id: charactersCharacterSet._id,
        },
      });
    }
  }, [createdCharacterStatus]);

  React.useEffect(() => {
    if (updatedCharacterStatus === 'loading') {
      displayNotification({ message: 'Updating character ...', type: 'info' });
    }
    if (updatedCharacterStatus === 'error') {
      displayNotification({
        message: 'Failed to update character ...',
        type: 'error',
      });
    }
    if (updatedCharacterStatus === 'success') {
      displayNotification({
        message: 'Character updated successfully ...',
        type: 'success',
      });
      handleUploadCharacterImage(updatedCharacter.updateOneCharacter?._id);
      const oldCharacterIDs =
        charactersSets?.character_sets
          .find((characterset) => characterset?._id === oldCharacterSetID)
          ?.characters?.map((characters) => characters?._id) ?? [];
      const newCharacterIDs =
        charactersSets?.character_sets
          .find(
            (characterset) => characterset?._id === charactersCharacterSet._id,
          )
          ?.characters?.map((characters) => characters?._id) ?? [];
      if (oldCharacterSetID === charactersCharacterSet._id) {
        updateCharacterSet({
          set: {
            characters: {
              link: [
                ...newCharacterIDs,
                updatedCharacter.updateOneCharacter?._id,
              ],
            },
          },
          query: {
            _id: charactersCharacterSet._id,
          },
        });
      } else {
        const newIds = oldCharacterIDs.filter(
          (id) => id !== selectedCharacter?._id,
        );
        updateCharacterSet({
          set: {
            characters: {
              link: newIds,
            },
          },
          query: {
            _id: oldCharacterSetID,
          },
        });
        updateCharacterSet({
          set: {
            characters: {
              link: [
                ...newCharacterIDs,
                updatedCharacter.updateOneCharacter?._id,
              ],
            },
          },
          query: {
            _id: charactersCharacterSet._id,
          },
        });
      }
    }
  }, [updatedCharacterStatus]);

  React.useEffect(() => {
    if (updateCharacterSetStatus === 'loading') {
      displayNotification({
        message: 'Updating character set ...',
        type: 'info',
      });
    }
    if (updateCharacterSetStatus === 'error') {
      displayNotification({
        message: 'Failed to update to a character set',
        type: 'error',
      });
    }
    if (updateCharacterSetStatus === 'success') {
      displayNotification({
        message: 'Successfully updated character set',
        type: 'success',
      });
    }
  }, [updateCharacterSetStatus]);

  React.useEffect(() => {
    if (userCharacterGeneration !== (null || undefined)) {
      const character =
        userCharacterGeneration?.user_character_generation?.attributes;
      if (character) {
        const charShortTermGoal = getAttributeValue(
          character,
          'short_term_goals',
        );
        if (charShortTermGoal !== undefined) {
          setCharacterShortTermGoals([
            {
              title: 'Short Term Goal 1',
              content: charShortTermGoal,
            },
          ]);
        }
        const charLongTermGoal = getAttributeValue(
          character,
          'long_term_goals',
        );
        if (charLongTermGoal !== undefined) {
          setCharacterLongTermGoals([
            {
              title: 'Long Term Goal 1',
              content: charLongTermGoal,
            },
          ]);
        }
        const charNotes = getAttributeValue(character, 'additional_notes');
        if (charNotes !== undefined) {
          setCharacterNotes([
            {
              _id: '',
              title: 'Additional Note 1',
              content: charNotes,
              realm: {
                link: realmId,
              },
            },
          ]);
        }
        // setCharacterName(character[0]?.value as string);
        // setCharacterAge(character[1]?.value)
      }
    }
  }, [userCharacterGeneration]);

  React.useEffect(() => {
    console.log(viewMood, selectedCharacter);
    if (viewMood === 'edit' && createCharacterView === 'sheet') {
      console.log(selectedCharacter);
      if (selectedCharacter !== null) {
        if (selectedCharacter?.notes !== (undefined || null)) {
          setCharacterNotes(selectedCharacter?.notes as NoteInsertInput[]);
        }
        if (selectedCharacter?.short_term_goals !== (undefined || null)) {
          // eslint-disable-next-line max-len
          setCharacterShortTermGoals(
            selectedCharacter?.short_term_goals as CharacterShort_Term_Goal[],
          );
        }
        if (selectedCharacter?.long_term_goals !== (undefined || null)) {
          // eslint-disable-next-line max-len
          setCharacterLongTermGoals(
            selectedCharacter?.long_term_goals as CharacterLong_Term_Goal[],
          );
        }
        // setPreviewUrl(selectedCharacter?.avatar_url as string);
        urlToBlob(selectedCharacter?.avatar_url as string, setPreviewUrl);
        const sets = selectedCharacter?.character_sets;
        console.log(sets, charactersSets?.character_sets);
        if (sets !== null && sets !== undefined) {
          const selectedCharacterSet = charactersSets?.character_sets.find(
            (set) => set?._id === sets[0],
          );
          if (
            selectedCharacterSet !== null &&
            selectedCharacterSet !== undefined
          ) {
            setCharactersCharacterSet({
              name: selectedCharacterSet?.name as string,
              _id: selectedCharacterSet?._id,
            });
            setOldCharacterSetID(selectedCharacterSet._id);
          }
        }
      }
      console.log(characterNotes);
    }
    const userVisitCharacterSheet = cookieUtil.getCookie(
      'userVisitCharacterSheet',
    );
    // console.log('cookieCharacterSheet:- ', userVisitCharacterSheet);
    if (userVisitCharacterSheet === 'visited') {
      setHasBeenVisited(true);
    } else {
      setHasBeenVisited(false);
    }
    return () => {
      cookieUtil.setCookie('userVisitCharacterSheet', 'visited', 365);
      appStore.setState({
        characterSheetMood: 'create',
      });
      appStore.setState({
        userCharacterGeneration: null,
      });
      setPreviewUrl(null);
      setCurrentStage(null);
      setSelectedFile(null);
      setSelectedFileBlob(null);
      setCharacterName('');
      setCharacterAge(0);
      setCharacterPronouns('');
      setCharacterGender(null);
      setCharacterRace(null);
      setCharacterClass(null);
      setCharacterAlignment('');
      setCharacterMotivations([]);
      setCharacterPersonalityType(null);
      setCharacterBodyType(null);
      setCharacterSkinColor(null);
      setCharacterHairColor('');
      setCharacterHairStyle('');
      setCharacterEyeColor(null);
      setCharacterAbilities({
        strength: '0',
        dexterity: 0,
        consitution: 0,
        intelligence: 0,
        wisdom: 0,
        charisma: 0,
      });
      setCharacterProfession('');
      setCharacterRelationship('');
      setCharacterLifeAlteringEvent('');
      setCharacterBackStory('');
      setCharacterShortTermGoals([]);
      setCharacterLongTermGoals([]);
      setCharacterNotes([]);
      setCharactersCharacterSet({
        name: 'Select a Character Set',
        _id: '',
      });
      setNoteSavedIds([]);
      setNotesSaved(0);
      setReadyToSave(false);
      setAiGenIsLoading(false);
    };
  }, []);

  const isTabletScreen = useMediaQuery('(max-width:865px)');
  const isMobileScreen = useMediaQuery('(max-width:530px)');

  return (
    <>
      <Stack
        sx={{
          height: 'calc(100vh - 48px)',
          background: `url(${CharacterSheetBGImg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          boxSizing: 'border-box',
        }}
        alignItems="center"
        // justifyContent="center"
      >
        <Box
          sx={{
            width: isTabletScreen ? '90%' : '1507px',
            minHeight: '540px',
            maxHeight: '85%',
            boxSizing: 'border-box',
            border: '8px solid #68583D',
            borderImageSource: `url(${CustomBorder})`,
            borderImageSlice: '17',
            borderImageRepeat: 'round',
            background: `url(${TexturedBackground})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            marginTop: '130px',
            position: 'relative',
          }}
        >
          {/* Bookmark Title */}
          <Box
            sx={{
              position: 'relative',
              width: isMobileScreen ? '230px' : '360px',
              height: isMobileScreen ? '50px' : '64px',
              mt: isMobileScreen ? '-38px' : '-45px',
              ml: '-9px',
              zIndex: '10',
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              gap={isMobileScreen ? '8px' : '15px'}
              zIndex={2}
              position="absolute"
              left={isMobileScreen ? '12px' : '44px'}
              top="50%"
              sx={{
                transform: 'translate(0%, -50%)',
              }}
            >
              <BookmarkTitleImg src={CharacterSheetIcon} />
              <Typography
                sx={{
                  color: '#FFF',
                  fontFamily: 'Passero One',
                  fontSize: isMobileScreen ? '16px' : '20px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                  textTransform: 'uppercase',
                }}
              >
                Character Sheet
              </Typography>
            </Stack>
            <BookmarkImg src={BookmarkImage} />
          </Box>
          {hasBeenVisited === true && (
            <Stack
              position="absolute"
              direction="row"
              alignItems="center"
              gap="10px"
              top="20px"
              right="30px"
              zIndex={20}
            >
              {/* <FlexibleHeightImg
                width="32px"
                src={WhiteDice}
                onClick={() => setWelcomeExpanded(!welcomeExpanded)}
                style={{
                  cursor: 'pointer',
                }}
              /> */}
              <div
                style={{
                  width: '34px',
                  height: '34px',
                  backgroundColor: '#7a3900',
                  borderRadius: '100px',
                  display: isMobileScreen ? 'flex' : 'none',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img
                  style={{ height: '15px', width: '15px', objectFit: 'cover' }}
                  src={WhiteDice}
                  alt="dice icon"
                />
              </div>
              <FlexibleHeightImg
                width="43px"
                src={HelpIcon2}
                onClick={() => setWelcomeExpanded(!welcomeExpanded)}
                style={{
                  cursor: 'pointer',
                }}
              />
              <KeyboardArrowDown
                className={`expansionArrow ${
                  welcomeExpanded ? 'rotated' : ''
                } cp`}
                onClick={() => setWelcomeExpanded(!welcomeExpanded)}
                sx={{
                  cursor: 'pointer',
                }}
              />
            </Stack>
          )}
          {/* Greetings */}
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              minHeight: 'calc(540px - 43px)',
              maxHeight: 'calc(100% - 43px)',
              boxSizing: 'border-box',
              ml: '-20px',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '8px',
                backgroundColor: '#CFCAAD',
                borderRadius: '8px',
              },
              '&.scrollbar': {
                scrollbarWidth: 'thin',
                scrollbarColor: '#CFCAAD',
                backgroundColor: '#CFCAAD',
                borderRadius: '8px',
              },
              '&::-webkit-scrollbar-thumb, &.scrollbar-thumb, &::-webkit-scrollbar-thumb:hover, &.scrollbar-thumb:hover':
                {
                  backgroundColor: '#41521F',
                  borderRadius: '8px',
                },
            }}
          >
            <Stack
              pl={isMobileScreen ? '42px' : '92px'}
              pr={isMobileScreen ? '22px' : '72px'}
              py={isMobileScreen ? '18px' : isTabletScreen ? '46px' : '92px'}
              gap={isMobileScreen ? '30px' : '40px'}
            >
              <Stack gap="45px">
                {/* welcome greetings / instructions / help */}
                {(hasBeenVisited === false || welcomeExpanded === true) && (
                  <Stack gap="29px">
                    <Typography
                      sx={{
                        color: '#250503',
                        fontFamily: 'Passero One',
                        fontSize: isMobileScreen
                          ? '22px'
                          : isTabletScreen
                          ? '28px'
                          : '36px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '50px',
                        letterSpacing: '.1px',
                      }}
                    >
                      👋 Hello, fellow adventurer,
                    </Typography>
                    <Typography
                      sx={{
                        color: '#250503',
                        fontFamily: 'DM Sans',
                        fontSize: isMobileScreen
                          ? '16px'
                          : isTabletScreen
                          ? '19px'
                          : '24px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '30px',
                        letterSpacing: '.1px',
                        mt: '30px',
                        textAlign: isTabletScreen ? 'justify' : 'left',
                      }}
                    >
                      {/* eslint-disable-next-line max-len */}
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat.
                    </Typography>
                    <Stack
                      gap={isTabletScreen ? '20px' : '60px'}
                      direction={isTabletScreen ? 'column' : 'row'}
                      flexWrap="nowrap"
                      mt="27px"
                    >
                      <Box maxWidth={isTabletScreen ? '100%' : '25%'}>
                        <CharacterTitBit
                          title="Need inspiration?"
                          message="Click the D20 dice to randomize the field"
                          imageSource={BlackDice}
                        />
                      </Box>
                      <Box maxWidth={isTabletScreen ? '100%' : '25%'}>
                        <CharacterTitBit
                          title="AI Scribe"
                          message="Add some traits and let AI do the rest"
                          imageSource={BlackFantasy}
                        />
                      </Box>
                      <Box maxWidth={isTabletScreen ? '100%' : '25%'}>
                        <CharacterTitBit
                          title="AI Town Crier"
                          message="Vocalize your vision and let AI do its magic"
                          imageSource={BlackHerald}
                        />
                      </Box>
                      <Box maxWidth={isTabletScreen ? '100%' : '25%'}>
                        <CharacterTitBit
                          title="Need help?"
                          message="Hover over the “?” to get information on each field"
                          imageSource={BlackHelp}
                        />
                      </Box>
                    </Stack>
                  </Stack>
                )}
                <CharacterSheetRandomizer
                  setCharacterName={setCharacterName}
                  setCharacterAge={setCharacterAge}
                  setCharacterPronouns={setCharacterPronouns}
                  setCharacterGender={setCharacterGender}
                  setCharacterRace={setCharacterRace}
                  setCharacterClass={setCharacterClass}
                  setCharacterAlignment={setCharacterAlignment}
                  setCharacterPersonalityType={setCharacterPersonalityType}
                  setCharacterMotivations={setCharacterMotivations}
                  setCharacterAbilities={setCharacterAbilities}
                  setCharacterBodyType={setCharacterBodyType}
                  setCharacterSkinColor={setCharacterSkinColor}
                  setCharacterHairColor={setCharacterHairColor}
                  setCharacterHairStyle={setCharacterHairStyle}
                  setCharacterEyeColor={setCharacterEyeColor}
                  setCharacterProfession={setCharacterProfession}
                  setCharacterRelationship={setCharacterRelationship}
                  setCharacterLifeAlteringEvent={setCharacterLifeAlteringEvent}
                />
                <Box>
                  <Stack
                    direction={isTabletScreen ? 'column' : 'row'}
                    gap="27px"
                    alignItems="flex-start"
                  >
                    {/* Character Image Box */}
                    <CharacterImageBox
                      previewUrl={previewUrl}
                      setPreviewUrl={setPreviewUrl}
                      handleFileChange={handleFileChange}
                      handleStage={(stage: Stage) => {
                        setCurrentStage(stage);
                      }}
                      handleImageEdited={(edited: boolean) =>
                        setCharacterImageEdited(edited)
                      }
                    />
                    {/* Character Attributes */}
                    <CharacterAttributes
                      characterName={characterName}
                      setCharacterName={(char: string) =>
                        setCharacterName(char)
                      }
                      characterAge={characterAge}
                      setCharacterAge={(char: number) => setCharacterAge(char)}
                      characterPronouns={characterPronouns}
                      setCharacterPronouns={(char: string) =>
                        setCharacterPronouns(char)
                      }
                      characterGender={characterGender}
                      setCharacterGender={(char: Gender) =>
                        setCharacterGender(char)
                      }
                      characterRace={characterRace}
                      setCharacterRace={(char: Race) => setCharacterRace(char)}
                      characterClass={characterClass}
                      setCharacterClass={(char: Class) =>
                        setCharacterClass(char)
                      }
                      characterAlignment={characterAlignment}
                      setCharacterAlignment={(char: string) =>
                        setCharacterAlignment(char)
                      }
                      characterPersonalityType={characterPersonalityType}
                      setCharacterPersonalityType={(char: Personality) =>
                        setCharacterPersonalityType(char)
                      }
                      characterMotivations={characterMotivations}
                      setCharacterMotivations={(char: string[]) =>
                        setCharacterMotivations(char)
                      }
                    />
                  </Stack>
                </Box>
              </Stack>
              {/* Character Abilities */}
              <CharacterAbilitiesGen
                abilities={characterAbilities}
                setCharacterAbilities={(ab: CharacterAbility) =>
                  setCharacterAbilities(ab)
                }
              />
              {/* Character Physical Profile */}
              <CharacterPhysicalProfile
                characterBodyType={characterBodyType}
                setCharacterBodyType={(char: Body_Shape) =>
                  setCharacterBodyType(char)
                }
                characterSkinColor={characterSkinColor}
                setCharacterSkinColor={(char: Skin) =>
                  setCharacterSkinColor(char)
                }
                characterHairColor={characterHairColor}
                setCharacterHairColor={(char: string) =>
                  setCharacterHairColor(char)
                }
                characterHairStyle={characterHairStyle}
                setCharacterHairStyle={(char: string) =>
                  setCharacterHairStyle(char)
                }
                characterEyeColor={characterEyeColor}
                setCharacterEyeColor={(char: Eye) => setCharacterEyeColor(char)}
              />
              {/* Character Background */}
              <CharacterBackground
                characterProfession={characterProfession}
                setCharacterProfession={(char: string) =>
                  setCharacterProfession(char)
                }
                characterRelationship={characterRelationship}
                setCharacterRelationship={(char: string) =>
                  setCharacterRelationship(char)
                }
                characterLifeAlteringEvent={characterLifeAlteringEvent}
                setCharacterLifeAlteringEvent={(char: string) =>
                  setCharacterLifeAlteringEvent(char)
                }
                characterBackStory={characterBackStory}
                setCharacterBackStory={(char: string) =>
                  setCharacterBackStory(char)
                }
              />
              {/* Notes */}
              <Stack
                direction={isTabletScreen ? 'column' : 'row'}
                gap="28px"
                flexWrap="nowrap"
              >
                <Box sx={{ width: isTabletScreen ? '100%' : '33.3333%' }}>
                  <CharacterNotesWrapper
                    title="Notes"
                    notes={characterNotes}
                    setNotes={(notes: any[]) => setCharacterNotes(notes)}
                    realmNote
                  />
                </Box>
                <Box sx={{ width: isTabletScreen ? '100%' : '33.3333%' }}>
                  <CharacterNotesWrapper
                    title="short term goals"
                    notes={characterShortTermGoals}
                    setNotes={(goals: any[]) =>
                      setCharacterShortTermGoals(goals)
                    }
                  />
                </Box>
                <Box sx={{ width: isTabletScreen ? '100%' : '33.3333%' }}>
                  <CharacterNotesWrapper
                    title="long term goals"
                    notes={characterLongTermGoals}
                    setNotes={(goals: any[]) =>
                      setCharacterLongTermGoals(goals)
                    }
                  />
                </Box>
              </Stack>
              {/* Helpers and Save */}
              <Stack
                direction={isTabletScreen ? 'column' : 'row'}
                alignItems="top"
                justifyContent="space-between"
                gap={isMobileScreen ? '10px' : isTabletScreen ? '30px' : '0px'}
              >
                <Stack
                  direction={isMobileScreen ? 'column' : 'row'}
                  gap={isMobileScreen ? '10px' : '30px'}
                  alignItems="center"
                >
                  {viewMood === 'create' && (
                    <>
                      <Stack>
                        <Stack direction="row" gap="3px" alignItems="center">
                          <AIButton>
                            <AIButtonImg src={HeraldIcon} />
                            <AIButtonText>AI Town Crier</AIButtonText>
                          </AIButton>
                          <Tooltip
                            title="A help note about AI town crier"
                            arrow
                            placement="bottom"
                            TransitionComponent={Zoom}
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  fontFamily: 'Passero One',
                                  fontWeight: '400px',
                                  fontSize: '14px',
                                  padding: '10px',
                                  bgcolor: '#3c3c3ce6',
                                  '& .MuiTooltip-arrow': {
                                    color: '#3c3c3ce6',
                                  },
                                  maxWidth: '250px',
                                },
                              },
                            }}
                          >
                            <HelpImg
                              src={HelpIcon}
                              style={{ marginTop: '-40px' }}
                            />
                          </Tooltip>
                          <Stack direction="row" alignItems="center">
                            <CoinImg src={CoinIcon} />
                            <Typography
                              sx={{
                                color: '#250503',
                                fontFamily: 'Passero One',
                                fontSize: '20px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: '20px',
                                letterSpacing: '.1px',
                              }}
                            >
                              1
                            </Typography>
                          </Stack>
                        </Stack>
                        <FormControlLabel
                          control={
                            <Checkbox
                              inputProps={{
                                'aria-label': 'Include Image Generation',
                              }}
                              sx={{
                                color: '#E9DCC3',
                                boxSizing: 'border-box',
                                borderRadius: '4px',
                                '&.Mui-checked': {
                                  color: '#41521F',
                                },
                              }}
                            />
                          }
                          label="Include Image Generation"
                          sx={{
                            '.MuiTypography-root': {
                              color: '#1D1B20',
                              fontFamily: 'DM Sans',
                              fontSize: '16pxpx',
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: '24px',
                              letterSpacing: '.5px',
                            },
                          }}
                        />
                      </Stack>
                      <Stack>
                        <Stack direction="row" gap="3px" alignItems="center">
                          <AIButton onClick={handleCreateNewCharacterText}>
                            <AIButtonImg src={FantasyIcon} />
                            <AIButtonText>AI Scribe</AIButtonText>
                          </AIButton>
                          <Tooltip
                            title="A help note about AI scribe"
                            arrow
                            placement="bottom"
                            TransitionComponent={Zoom}
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  fontFamily: 'Passero One',
                                  fontWeight: '400px',
                                  fontSize: '14px',
                                  padding: '10px',
                                  bgcolor: '#3c3c3ce6',
                                  '& .MuiTooltip-arrow': {
                                    color: '#3c3c3ce6',
                                  },
                                  maxWidth: '250px',
                                },
                              },
                            }}
                          >
                            <HelpImg
                              src={HelpIcon}
                              style={{ marginTop: '-40px' }}
                            />
                          </Tooltip>
                          <Stack direction="row" alignItems="center">
                            <CoinImg src={CoinIcon} />
                            <Typography
                              sx={{
                                color: '#250503',
                                fontFamily: 'Passero One',
                                fontSize: '20px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: '20px',
                                letterSpacing: '.1px',
                              }}
                            >
                              2
                            </Typography>
                          </Stack>
                        </Stack>
                        <FormControlLabel
                          control={
                            <Checkbox
                              inputProps={{
                                'aria-label': 'Include Image Generation',
                              }}
                              sx={{
                                color: '#E9DCC3',
                                boxSizing: 'border-box',
                                borderRadius: '4px',
                                '&.Mui-checked': {
                                  color: '#41521F',
                                },
                              }}
                            />
                          }
                          label="Include Image Generation"
                          sx={{
                            '.MuiTypography-root': {
                              color: '#1D1B20',
                              fontFamily: 'DM Sans',
                              fontSize: '16pxpx',
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: '24px',
                              letterSpacing: '.5px',
                            },
                          }}
                        />
                      </Stack>
                    </>
                  )}
                  {viewMood === 'edit' && (
                    <Button
                      sx={{
                        display: 'flex',
                        padding: '10px 24px',
                        borderRadius: '4px',
                        color: 'white',
                        fontFamily: 'Passero One',
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '16px' /* 150% */,
                        letterSpacing: '.1px',
                        height: '60px',
                        background: 'red',
                        '&:hover': {
                          background: 'red',
                        },
                      }}
                      onClick={() => setOpenCharacterWarningModal(true)}
                    >
                      Delete Character
                    </Button>
                  )}
                </Stack>
                <Stack
                  direction={isMobileScreen ? 'column' : 'row'}
                  gap="13px"
                  maxHeight="60px"
                >
                  {/* <CharacterFilterDropdown
                    title="Character Set"
                    tooltip
                    toolTipMessage="Be sure to choose a character set"
                    options={CharacterSetOptions}
                    width="260px"
                    value={charactersCharacterSet}
                    onChange={setCharactersCharacterSet}
                  /> */}
                  <AttributeWrapper
                    width="260px"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                  >
                    <Select
                      value={charactersCharacterSet.name}
                      onChange={(event: SelectChangeEvent<string>) => {
                        const setName: Character_Set | undefined | null =
                          charactersSets?.character_sets.find(
                            (set) => set?._id === event.target.value,
                          ) as Character_Set | undefined | null;
                        setCharactersCharacterSet({
                          name: setName?.name ?? 'Select Character Set',
                          _id: event.target.value,
                        });
                      }}
                      input={
                        <AttributeTextField
                          fullWidth
                          sx={{
                            width: '248px',
                          }}
                        />
                      }
                      renderValue={(selected) => {
                        if (selected === '') {
                          return 'Select Character Set';
                        }
                        return selected;
                      }}
                    >
                      <MenuItem value="">Select Character Set</MenuItem>
                      {charactersSets?.character_sets.map((character_set) => (
                        <MenuItem
                          key={character_set?._id}
                          value={character_set?._id}
                        >
                          {character_set?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </AttributeWrapper>
                  <Button
                    sx={{
                      display: 'flex',
                      padding: '10px 24px',
                      borderRadius: '4px',
                      color: 'white',
                      fontFamily: 'Passero One',
                      fontSize: '20px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '16px' /* 150% */,
                      letterSpacing: '.1px',
                      height: '60px',
                      background: '#41521F',
                      '&:hover': {
                        background: '#41521F',
                      },
                    }}
                    disabled={!readyToSave}
                    onClick={convertStageToBlob}
                  >
                    {`${
                      viewMood === 'edit'
                        ? 'Update Character'
                        : 'Save Character'
                    }`}
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Stack>
      <CharacterWarningModal
        open={openCharacterWarningModal}
        onClose={() => {}}
        onConfirm={() => {
          handleDeleteCharacter();
          setOpenCharacterWarningModal(false);
        }}
        setOpen={setOpenCharacterWarningModal}
        message="Deleting a character is irreversible, do you wish to proceed?"
        variant="delete"
      />
      {aiGenIsLoading && (
        <FlexibleHeightImg
          width="520px"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1000,
          }}
          src={loadingGif}
        />
      )}
    </>
  );
}

export default CharacterSheet;
