/* eslint-disable object-curly-newline */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/comma-dangle */
/* eslint-disable operator-linebreak */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
/* eslint-disable @typescript-eslint/indent */
import React, {
  FocusEventHandler,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ImageList, ImageListItem, Box, Grid, useMediaQuery } from '@mui/material';
// import { MuiColorInput, MuiColorInputColors } from 'mui-color-input';
import Typography from '@mui/material/Typography';
// import { nanoid } from '@reduxjs/toolkit';
// import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColorResult, RGBColor } from 'react-color';
import useItem from '../../../hooks/useItem';
import { StageData } from '../../../redux/currentStageData';
import TabPanel from '../../tab/TabPanel';
import {
  StyledTab,
  StyledTabs,
  StyledToggleButtonGroup,
  setTabId,
} from '../StyledPanel/StyledPanel';
import { canvasHeight, canvasWidth } from '../../CanvasConstants';
import overlayList from '../../../config/overlays.json';
import { PanelItemProps } from '../FramePanel/FramePanel';
import RWColorPicker from '../../colorPicker/RWColorPicker';
import PanelItemNew from '../PanelItem/PanelItemNew';
import { ThemeContext } from '../../../context/ThemeContext';
import '../eachpanelmodifier.scss';

export default function OverlayPanel({ onStageChange }: PanelItemProps) {
  const colorToRGB = (
    rgb: string,
  ): { red: number; green: number; blue: number; alpha: number } => {
    const result =
      rgb.length > 0 ? rgb.replace(/[^\d,.]/g, '').split(',') : null;

    return result
      ? {
          red: parseInt(result[0], 10),
          green: parseInt(result[1], 10),
          blue: parseInt(result[2], 10),
          alpha: parseFloat(result[3] ?? '1.0'),
        }
      : {
          red: 0,
          green: 0,
          blue: 0,
          alpha: 0,
        };
  };

  const { createItem, removeItem, updateItem, stageData } = useItem();

  const targetItemBorder = useMemo(
    () =>
      stageData.find(
        (data) =>
          data.id === 'overlay-image' || data.attrs.id === 'overlay-image',
      ),
    [stageData],
  );

  const [color, setColor] = React.useState<RGBColor>(() =>
    targetItemBorder?.attrs?.color
      ? targetItemBorder.attrs.color
      : {
          r: 255,
          g: 255,
          b: 255,
          a: 1.0,
        },
  );

  const handleColorChange = (color: any) => {
    setColor(color.rgb);
  };

  const handleColorChangeCompleted = (color: ColorResult) => {
    if (targetItemBorder) {
      const { r, b, g, a } = color.rgb;

      onStageChange('Updated Foreground Color');
      updateItem(targetItemBorder.id, (prevData) => ({
        ...targetItemBorder.attrs,
        red: r,
        green: g,
        blue: b,
        opacity: a,
        color: color.rgb,
        updatedAt: Date.now(),
      }));

      setColor(color.rgb);
    }
  };

  const isTabletScreen = useMediaQuery('(max-width:865px)');

  const insertImage = (data: { [key: string]: any }) => {
    const imageSrc = new Image();
    const imageSource = `${process.env.PUBLIC_URL}/images/overlays/${data.imageSrc}`;
    const { r, b, g, a } = color;

    removeItem('overlay-image');
    onStageChange('Updated Foreground Image');
    imageSrc.onload = () => {
      const newBackground: StageData = {
        id: 'overlay-image',
        attrs: {
          name: 'label-target',
          dataItemType: 'image',
          x: isTabletScreen ? (canvasWidth - (data.imageWidth / 1.5)) / 2 : (canvasWidth - data.imageWidth) / 2,
          y: isTabletScreen ? (canvasHeight - (data.imageHeight / 1.5)) / 2 : (canvasHeight - data.imageHeight) / 2,
          width: isTabletScreen ? data.imageWidth / 1.5 : data.imageWidth,
          height: isTabletScreen ? data.imageHeight / 1.5 : data.imageHeight,
          red: r,
          green: g,
          blue: b,
          opacity: a,
          src: imageSource,
          zIndex: 9,
          draggable: false,
          brightness: 0,
          color: { color },
          filters: ['RGB'],
          updatedAt: Date.now(),
        },
      };

      createItem(newBackground);
    };
    imageSrc.src = imageSource;
  };

  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const { theme } = useContext(ThemeContext);

  const [active, setActive] = useState('');
  const localActive = localStorage.getItem('activeForeground');

  useEffect(() => {
    if (localActive !== null) {
      setActive(JSON.parse(localActive));
    } else {
      setActive('');
    }
  }, []);

  // set localstorage for active item
  useEffect(() => {
    localStorage.setItem('activeForeground', JSON.stringify(active));
  }, [active]);

  return (
    <>
      <StyledTabs value={tabValue} onChange={handleTabChange}>
        <StyledTab label="Foreground" {...setTabId(0)} />
        <StyledTab label="Colors" {...setTabId(0)} />
      </StyledTabs>
      <TabPanel value={tabValue} index={0}>
        <Grid container>
          <Grid item xs={12} display="flex" flexWrap="wrap">
            <ImageList
              cols={2}
              className={
                theme === 'light' ? 'imageList light' : 'imageList dark'
              }
            >
              <PanelItemNew
                onClick={() => {
                  setActive('');
                  removeItem('overlay-image');
                }}
              >
                <ImageListItem>
                  <Typography textAlign="center">None</Typography>
                </ImageListItem>
              </PanelItemNew>
              {overlayList.map((item, index) => (
                <PanelItemNew
                  onClick={() => {
                    insertImage(item);
                    setActive(`${item.imageSrc}`);
                  }}
                  active={active === item.imageSrc}
                >
                  <ImageListItem
                    key={item.id}
                    className="panelCard__item"
                    sx={{ margin: 2 }}
                  >
                    <img
                      key={`${index}-${item.id}`}
                      src={`${process.env.PUBLIC_URL}/images/overlays/${item.imageSrc}`}
                      srcSet={`${process.env.PUBLIC_URL}/images/overlays/${item.imageSrc}`}
                      alt={item.id}
                      loading="lazy"
                    />
                  </ImageListItem>
                </PanelItemNew>
              ))}
            </ImageList>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Box>
          <Grid container>
            <Grid item xs={12} mb={2} display="flex" flexWrap="wrap">
              <RWColorPicker
                color={color}
                onChange={handleColorChange}
                onChangeComplete={handleColorChangeCompleted}
              />
            </Grid>
          </Grid>
        </Box>
      </TabPanel>
    </>
  );
}
