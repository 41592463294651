/* eslint-disable function-paren-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext } from 'react';
import Konva from 'konva';
import {
  Box,
  Button,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from '@mui/material';
// import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
// import WarningIcon from '@mui/icons-material/Warning';
import CloseIcon from '@mui/icons-material/Close';
// import { HiLockClosed } from 'react-icons/hi';
import { RiLock2Fill } from 'react-icons/ri';
import {
  GetRealmQuery,
  GetTokenSetsDocument,
  Note,
  Token_Set,
  Token_SetInsertInput,
  UpdateOneTokenSetDocument,
} from '../../../__generated__/graphql';
import useGraphQLMutation from '../../../hooks/useGraphQLMutation';
import useToken from '../../../hooks/useToken';
import { TokenState } from '../../../redux/token';
import useItem from '../../../hooks/useItem';
import { useAuth } from '../../../hooks/use-auth';
import styles from './newtokensetform.module.scss';
import { ThemeContext } from '../../../context/ThemeContext';
import SelectTokenOptions from './SelectTokenOptions';
import NewTokenSetForm from './NewTokenSetForm';
import useGraphQL from '../../../hooks/useGraphQL';
import SpinnerMini from '../../../ui/Spinners/SpinnerMini';

interface Props {
  realm: GetRealmQuery['realm'];
  currentStage: Konva.Stage;
  closeSaveTokenModal: () => void;
}

const WIDTH = 260;
const HEIGHT = 260;

export default function SaveTokenForm({
  realm,
  currentStage,
  closeSaveTokenModal,
}: Props) {
  const isTabletScreen = useMediaQuery('(max-width:865px)');
  const [selectedTokenSet, setSelectedTokenSet] = React.useState('');
  const [selectedTokenSetsIDs, setSelectedTokenSetIDs] = React.useState<any[]>(
    [],
  );
  const [openNewTokenSet, setOpenNewTokenSet] = React.useState(false);
  const [savingTokenStatus, setSavingTokenStatus] = React.useState('');
  const { token, setToken, updateToken } = useToken();
  // console.log('tokenData: ', token.data);
  const { stageData } = useItem();
  const [previewImageUrl, setPreviewImageUrl] = React.useState('');
  const [label, setLabel] = React.useState('Select Token Set');
  const [statusInfo, setStatusInfo] = React.useState({
    status: '',
    message: '',
    open: false,
    timeout: 5000,
  });

  const { theme } = useContext(ThemeContext);

  const auth = useAuth();

  const { data: tokenSets } = useGraphQL(GetTokenSetsDocument, {
    query: {
      realm: {
        _id: realm?._id,
      },
    },
  });
  // localStorage.setItem('workingSet', JSON.stringify(tokenSets?.token_sets as Token_Set[]));

  React.useEffect(() => {
    const thisSet = tokenSets?.token_sets.filter(
      (tokenSet) => tokenSet?._id === selectedTokenSet,
    );
    if (thisSet !== undefined && thisSet.length > 0) {
      const thisSetsTokenIDs: any[] = thisSet.map((tokenSet) =>
        tokenSet?.tokens?.map((tokens) => tokens?._id),
      );
      if (thisSetsTokenIDs !== undefined && thisSetsTokenIDs !== null) {
        const singleIDSArray = [].concat(...thisSetsTokenIDs);
        setSelectedTokenSetIDs(singleIDSArray ?? []);
      }
    }
  }, [selectedTokenSet]);

  const {
    data: updatedTokenSet,
    mutate: updateTokenSet,
    error: updateTokenSetError,
    status: updateTokenSetStatus,
  } = useGraphQLMutation(UpdateOneTokenSetDocument);

  const handleSetSelectLabel = (value: any) => {
    if (value !== null || value !== undefined) {
      setLabel(value);
    }
  };

  // const tokenSets = realm ? realm.token_sets : [];

  const handleTokenTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newToken: TokenState = {
      data: {
        ...token.data,
        name: event.target.value,
      },
    };
    setToken(newToken);
  };

  const handleSaveToken = () => {
    setSavingTokenStatus('loading');
    const info = token?.data;
    const tokenIDS = selectedTokenSetsIDs;
    const formData = new FormData();
    formData.append('TokenName', info?.name ?? '');
    formData.append('TokenDescription', info?.description ?? '');
    formData.append('OriginalImage', info.droppedImageBlob);
    formData.append('Token', info.finalImageBlob);
    formData.append('IsPublic', info?.is_public);
    formData.append('IsVisibleInTool', info?.isVisibleInTool);
    formData.append('IsFavorited', info?.is_favorited);
    // formData.append('TokenSetId', selectedTokenSet);
    formData.append('TokenEdits', info?.edits);
    formData.append('Notes', info?.notes);
    // info?.edits?.forEach((obj: any) => {
    //   console.log('edit object: ', obj);
    //   formData.append('TokenEdits', obj);
    // });
    // if (info.notes) {
    //   info?.notes?.forEach((obj: any) => {
    //     console.log('A note: ', obj);
    //     formData.append('Notes', obj);
    //   });
    // }

    fetch(`${process.env.REACT_APP_API_URL}/Realms/${realm?._id}/Tokens`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          setSavingTokenStatus('done');
          setStatusInfo({
            open: true,
            message: 'Failed to save token',
            status: 'error',
            timeout: 5000,
          });
          throw new Error('Failed to save token');
        }
        return response.json();
      })
      .then((result) => {
        console.log(result?.insertOneToken);
        tokenIDS.push(result.insertOneToken._id);
        setStatusInfo({
          open: true,
          message: `${result?.insertOneToken.name} Token saved successfully!`,
          status: 'success',
          timeout: 5000,
        });
        updateTokenSet({
          set: {
            tokens: {
              link: tokenIDS,
            },
          },
          query: {
            _id: selectedTokenSet,
          },
        });
      })
      .catch((error) => {
        console.error('There was a problem: ', error);
        setStatusInfo({
          open: true,
          message: 'Failed to save token',
          status: 'error',
          timeout: 5000,
        });
      });
  };

  const [newTokenSet, setNewTokenSet] = React.useState<Token_SetInsertInput>({
    name: '',
    description: '',
    thumbnail_url: '',
    tokens: null,
  });

  const handleEditNewTokenSetName = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const data = event.target.value;
    setNewTokenSet({ ...newTokenSet, name: data });
  };

  const handleEditNewTokenSetDescription = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const data = event.target.value;
    setNewTokenSet({ ...newTokenSet, description: data });
  };

  const handleEditNewTokenSetThumbnailUrl = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const data = event.target.value;
    setNewTokenSet({ ...newTokenSet, thumbnail_url: data });
  };

  React.useEffect(() => {
    if (updateTokenSetStatus === 'error') {
      setSavingTokenStatus('done');
      setStatusInfo({
        open: true,
        message: `${token.data.name} Token failed to save to ${label} Token Set!`,
        status: 'error',
        timeout: 5000,
      });
    } else if (updateTokenSetStatus === 'success') {
      setSavingTokenStatus('done');
      setStatusInfo({
        open: true,
        message: `${token.data.name} Token saved successfully to ${label} Token Set!`,
        status: 'success',
        timeout: 5000,
      });
      setTimeout(() => {
        closeSaveTokenModal();
      }, 5000);
    }
  }, [updateTokenSetStatus]);

  React.useEffect(() => {
    // updateTokenSet({
    //   set: {
    //     // _id: selectedTokenSet,
    //     tokens: {
    //       link: [
    //         '6532fdcef4f565becc3919b6', '6532fc76c13c990eacf5fe34',
    //       ],
    //     },
    //   },
    //   query: {
    //     _id: '6532f364f4f565becc354a9c',
    //   },
    // });
    if (currentStage) {
      const image = document.createElement('img');
      const stage = currentStage;
      const uri = stage.toDataURL({
        x: 0,
        y: 0,
        width: WIDTH,
        height: HEIGHT,
        pixelRatio: 1,
      });
      setPreviewImageUrl(uri);
      stage.toBlob({
        x: 0,
        y: 0,
        width: WIDTH,
        height: HEIGHT,
        pixelRatio: 1,
        callback: (blob) => {
          image.src = stage.toDataURL({
            x: 0,
            y: 0,
            width: WIDTH,
            height: HEIGHT,
            pixelRatio: 1,
          });
          updateToken((prevData) => ({
            finalImageBlob: blob,
            finalImageSrc: image.src,
            edits: stageData,
            edited: prevData.data?.edited,
          }));
        },
      });
    }
  }, []);

  React.useEffect(() => {
    setTimeout(() => {
      setStatusInfo({
        open: false,
        message: '',
        status: '',
        timeout: 5000,
      });
    }, statusInfo.timeout);
  }, [statusInfo.open]);

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        mb={2}
        display="flex"
        flexWrap="wrap"
        p={2}
        py={3}
        sx={{
          backgroundColor: 'rgba(217, 217, 217, 0.2)',
          border: '1px solid #FFFFFF',
          borderRadius: '10px',
          height: 'fit-content',
          maxHeight: 'fit-content',
          boxSizing: 'border-box',
          justifyContent: 'center',
        }}
      >
        <img
          className={styles.preview_image}
          src={previewImageUrl}
          alt="save preview"
        />
        <Box
          bgcolor={statusInfo.status === 'error' ? 'red' : 'green'}
          sx={{
            borderRadius: '10px',
            padding: '10px',
            width: isTabletScreen ? '90vw' : '450px',
            boxSizing: 'border-box',
            position: 'fixed',
            top: `${statusInfo.open ? '-80px' : '-500px'}`,
            transition: 'ease-in-out .3s',
          }}
          className={`status_info_box ${
            statusInfo.status === 'error' ? 'error' : 'success'
          }`}
        >
          <Box
            sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button
              sx={{ marginRight: '-20px' }}
              onClick={() =>
                setStatusInfo({
                  open: false,
                  message: '',
                  status: '',
                  timeout: 5000,
                })
              }
            >
              <CloseIcon sx={{ fill: 'white', color: 'white' }} />
            </Button>
          </Box>
          <Box sx={{ paddingBottom: '20px', px: '20px' }}>
            <Box>
              <Typography>{statusInfo.message}</Typography>
            </Box>
          </Box>
        </Box>
        {/* <Box id="savePreviewImage" /> */}
        {!openNewTokenSet && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <div className={styles.save_modal_input_container}>
              <Input
                value={realm?.name}
                className={`save_modal_input ${styles.save_modal_input} ${styles.save_modal_input__realmName} ${styles[theme]}`}
                sx={{ mb: 2 }}
                disabled
              />
              <RiLock2Fill />
            </div>

            <SelectTokenOptions
              tokenSets={tokenSets?.token_sets as Token_Set[]}
              selectedTokenSet={selectedTokenSet}
              setSelectedTokenSet={(tokenSet) => setSelectedTokenSet(tokenSet)}
              openNewTokenSet={() => setOpenNewTokenSet(true)}
              selectLabel={label}
              setSelectLabel={(value) => handleSetSelectLabel(value)}
            />

            <Input
              value={token?.data?.name ?? ''}
              onChange={handleTokenTitleChange}
              className={`save_modal_input ${styles.save_modal_input} ${styles[theme]}`}
              sx={{ mb: 2 }}
            />

            <Button
              type="button"
              disabled={
                selectedTokenSet === '' ||
                token?.data?.name === '' ||
                savingTokenStatus === 'loading'
              }
              className={`save_modal_save_button ${styles.save_modal_save_button}`}
              onClick={handleSaveToken}
            >
              {savingTokenStatus === 'loading' ? (
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
                >
                  <p>Saving</p>
                  <SpinnerMini />
                </div>
              ) : (
                'Save'
              )}
            </Button>
          </Box>
        )}

        {/* Create new token set */}
        {openNewTokenSet && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <NewTokenSetForm
              setSelectedTokenSet={(tokenSet) => setSelectedTokenSet(tokenSet)}
              setSelectLabel={(value) => handleSetSelectLabel(value)}
              newTokenSet={newTokenSet}
              // handleEditNewTokenSetDescription={
              //   handleEditNewTokenSetDescription
              // }
              handleEditNewTokenSetName={handleEditNewTokenSetName}
              // handleEditNewTokenSetThumbnailUrl={
              //   handleEditNewTokenSetThumbnailUrl
              // }
              realmID={realm?._id}
              closeNewTokenSetForm={() => setOpenNewTokenSet(false)}
              appendNewTokenSet={(tokenSet: Token_Set) =>
                tokenSets?.token_sets.push(tokenSet)
              }
              showStatus={(open, message, status, timeout) =>
                setStatusInfo({
                  open,
                  message,
                  status,
                  timeout,
                })
              }
              // onSubmitNewTokenSet={onSubmitNewTokenSet}
            />
          </Box>
        )}
      </Grid>
    </Grid>
  );
}
