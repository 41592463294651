/* eslint-disable no-nested-ternary */
/* eslint-disable object-curly-newline */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable operator-linebreak */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable no-unsafe-optional-chaining */
import React from 'react';
import { Button, Stack, Tooltip, Zoom, useMediaQuery } from '@mui/material';
import {
  GenerateDiceImg,
  HelpImg,
  SectionHeadingText,
} from '../../styledComps/styledComps';
import HelpIcon from '../../../images/help.webp';
import DiceIcon from '../../../images/dice.webp';
import CharacterAbility from './CharacterAbility';
import {
  CharacterAbility as CharAbility,
  GetUserCharacterGenerationQuery,
} from '../../../__generated__/graphql';
import generateCharacterAbilities from './utilities/GenerateCharacterAbilities';
import appStore from '../../../store/AppStore';

interface Props {
  variant?:
    | 'characterGen'
    | 'characterSummary'
    | 'characterWizard1'
    | 'characterWizard2';
  abilities?: CharAbility | undefined | null;
  setCharacterAbilities: (ab: CharAbility) => void;
}

function CharacterAbilitiesGen({
  variant,
  abilities,
  setCharacterAbilities,
}: Props) {
  // const limit = 100;

  const isTabletScreen = useMediaQuery('(max-width:865px)');
  const isMobileScreen = useMediaQuery('(max-width:530px)');

  const viewMood = appStore((state) => state.characterSheetMood);
  const selectedCharacter = appStore((state) => state.selectedCharacter);
  const createCharacterView = appStore((state) => state.createCharacterView);
  const userCharacterGeneration:
    | GetUserCharacterGenerationQuery
    | null
    | undefined = appStore((state) => state.userCharacterGeneration);
  React.useEffect(() => {
    if (viewMood === 'edit' && createCharacterView === 'sheet') {
      if (selectedCharacter !== null) {
        setCharacterAbilities({
          strength: selectedCharacter.abilities?.strength,
          dexterity: selectedCharacter.abilities?.dexterity,
          consitution: selectedCharacter.abilities?.consitution,
          intelligence: selectedCharacter.abilities?.intelligence,
          wisdom: selectedCharacter.abilities?.wisdom,
          charisma: selectedCharacter.abilities?.charisma,
        });
      }
    }
  }, []);
  React.useEffect(() => {
    console.log(userCharacterGeneration);
    if (userCharacterGeneration !== null) {
      const randomAbilities = generateCharacterAbilities();
      setCharacterAbilities({
        strength: randomAbilities[0].toString(),
        dexterity: randomAbilities[1],
        consitution: randomAbilities[2],
        intelligence: randomAbilities[3],
        wisdom: randomAbilities[4],
        charisma: randomAbilities[5],
      });
    }
  }, [userCharacterGeneration]);
  return (
    <Stack gap="22px">
      {variant !== 'characterWizard1' && variant !== 'characterWizard2' && (
        <Stack
          gap="7px"
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <SectionHeadingText>Abilities</SectionHeadingText>
          {variant === 'characterGen' && (
            <>
              <Tooltip
                title="Abilities of the character you are about to create are as follows "
                arrow
                placement="bottom"
                TransitionComponent={Zoom}
                componentsProps={{
                  tooltip: {
                    sx: {
                      fontFamily: 'Passero One',
                      fontWeight: '400px',
                      fontSize: '14px',
                      padding: '10px',
                      bgcolor: '#3c3c3ce6',
                      '& .MuiTooltip-arrow': {
                        color: '#3c3c3ce6',
                      },
                      maxWidth: '250px',
                    },
                  },
                }}
              >
                <HelpImg src={HelpIcon} />
              </Tooltip>
              <Button
                sx={{
                  width: '33px',
                  minWidth: '0px',
                  height: '33px',
                  borderRadius: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: '#FFF',
                  '&:hover': {
                    background: '#FFF',
                  },
                }}
                onClick={() => {
                  const randomAbilities = generateCharacterAbilities();
                  setCharacterAbilities({
                    strength: randomAbilities[0].toString(),
                    dexterity: randomAbilities[1],
                    consitution: randomAbilities[2],
                    intelligence: randomAbilities[3],
                    wisdom: randomAbilities[4],
                    charisma: randomAbilities[5],
                  });
                }}
              >
                <GenerateDiceImg src={DiceIcon} />
              </Button>
            </>
          )}
        </Stack>
      )}
      <Stack
        gap={
          variant === 'characterGen'
            ? isTabletScreen
              ? '10px'
              : '68px'
            : '28px'
        }
        direction={
          variant === 'characterGen' ||
          variant === 'characterWizard1' ||
          variant === 'characterWizard2'
            ? 'row'
            : 'column'
        }
        flexWrap={
          variant === 'characterWizard1' || variant === 'characterWizard2'
            ? 'wrap'
            : isTabletScreen
            ? 'wrap'
            : 'nowrap'
        }
        justifyContent="center"
        alignItems="center"
      >
        <CharacterAbility
          title="str"
          variant={variant}
          value={
            abilities?.strength !== undefined
              ? parseInt(abilities?.strength ?? '0', 10)
              : 0
          }
          tip="Strength of the character"
          onIncrease={() => {
            if (abilities?.strength !== undefined) {
              const newValue = parseInt(abilities?.strength ?? '0', 10) + 2;
              if (newValue <= 18) {
                setCharacterAbilities({
                  ...abilities,
                  strength: newValue.toString(),
                });
              }
              // const total = abilities?.charisma! + abilities?.consitution!
              // + abilities?.dexterity! + abilities?.intelligence!
              // + abilities?.wisdom! + newValue;
              // if (total <= limit) {
              // }
            }
          }}
          onChange={(val: any) => {
            if (abilities?.strength !== undefined) {
              const newValue = val;
              if (newValue <= 18) {
                setCharacterAbilities({
                  ...abilities,
                  strength: newValue.toString(),
                });
              }
              // const total = abilities?.charisma! + abilities?.consitution!
              // + abilities?.dexterity! + abilities?.intelligence!
              // + abilities?.wisdom! + newValue;
              // if (total <= limit) {
              // }
            }
          }}
        />
        <CharacterAbility
          title="dex"
          variant={variant}
          value={
            abilities?.dexterity !== undefined ? abilities?.dexterity ?? 0 : 0
          }
          tip="Dexterity of the character"
          onIncrease={() => {
            if (
              abilities?.dexterity !== null &&
              abilities?.dexterity !== undefined
            ) {
              const newValue = abilities?.dexterity + 2;
              if (newValue <= 18) {
                setCharacterAbilities({
                  ...abilities,
                  dexterity: newValue,
                });
              }
              // const total = abilities?.charisma! + abilities?.consitution!
              // + parseInt(abilities?.strength!, 10) + abilities?.intelligence!
              // + abilities?.wisdom! + newValue;
              // if (total <= limit) {
              // }
            }
          }}
          onChange={(val: any) => {
            if (
              abilities?.dexterity !== null &&
              abilities?.dexterity !== undefined
            ) {
              const newValue = val;
              if (newValue <= 18) {
                setCharacterAbilities({
                  ...abilities,
                  dexterity: newValue,
                });
              }
              // const total = abilities?.charisma! + abilities?.consitution!
              // + parseInt(abilities?.strength!, 10) + abilities?.intelligence!
              // + abilities?.wisdom! + newValue;
              // if (total <= limit) {
              // }
            }
          }}
        />
        <CharacterAbility
          title="con"
          variant={variant}
          value={
            abilities?.consitution !== undefined
              ? abilities?.consitution ?? 0
              : 0
          }
          tip="Constitution of the character"
          onIncrease={() => {
            if (
              abilities?.consitution !== null &&
              abilities?.consitution !== undefined
            ) {
              const newValue = abilities?.consitution + 2;
              if (newValue <= 18) {
                setCharacterAbilities({
                  ...abilities,
                  consitution: newValue,
                });
              }
              // const total = abilities?.charisma! + abilities?.dexterity!
              // + parseInt(abilities?.strength!, 10) + abilities?.intelligence!
              // + abilities?.wisdom! + newValue;
              // if (total <= limit) {
              // }
            }
          }}
          onChange={(val: any) => {
            if (
              abilities?.consitution !== null &&
              abilities?.consitution !== undefined
            ) {
              const newValue = val;
              if (newValue <= 18) {
                setCharacterAbilities({
                  ...abilities,
                  consitution: newValue,
                });
              }
              // const total = abilities?.charisma! + abilities?.dexterity!
              // + parseInt(abilities?.strength!, 10) + abilities?.intelligence!
              // + abilities?.wisdom! + newValue;
              // if (total <= limit) {
              // }
            }
          }}
        />
        <CharacterAbility
          title="int"
          variant={variant}
          value={
            abilities?.intelligence !== undefined
              ? abilities?.intelligence ?? 0
              : 0
          }
          tip="Intelligence of the character"
          onIncrease={() => {
            if (
              abilities?.intelligence !== null &&
              abilities?.intelligence !== undefined
            ) {
              const newValue = abilities?.intelligence + 2;
              if (newValue <= 18) {
                setCharacterAbilities({
                  ...abilities,
                  intelligence: newValue,
                });
              }
              // const total = abilities?.charisma! + abilities?.consitution!
              // + parseInt(abilities?.strength!, 10) + abilities?.dexterity!
              // + abilities?.wisdom! + newValue;
              // if (total <= limit) {
              // }
            }
          }}
          onChange={(val: any) => {
            if (
              abilities?.intelligence !== null &&
              abilities?.intelligence !== undefined
            ) {
              const newValue = val;
              if (newValue <= 18) {
                setCharacterAbilities({
                  ...abilities,
                  intelligence: newValue,
                });
              }
              // const total = abilities?.charisma! + abilities?.consitution!
              // + parseInt(abilities?.strength!, 10) + abilities?.dexterity!
              // + abilities?.wisdom! + newValue;
              // if (total <= limit) {
              // }
            }
          }}
        />
        <CharacterAbility
          title="wis"
          variant={variant}
          value={abilities?.wisdom !== undefined ? abilities?.wisdom ?? 0 : 0}
          tip="Wisdom of the character"
          onIncrease={() => {
            if (abilities?.wisdom !== null && abilities?.wisdom !== undefined) {
              const newValue = abilities?.wisdom + 2;
              if (newValue <= 18) {
                setCharacterAbilities({
                  ...abilities,
                  wisdom: newValue,
                });
              }
              // const total = abilities?.charisma! + abilities?.consitution!
              // + parseInt(abilities?.strength!, 10) + abilities?.intelligence!
              // + abilities?.dexterity! + newValue;
              // if (total <= limit) {
              // }
            }
          }}
          onChange={(val: any) => {
            if (abilities?.wisdom !== null && abilities?.wisdom !== undefined) {
              const newValue = val;
              if (newValue <= 18) {
                setCharacterAbilities({
                  ...abilities,
                  wisdom: newValue,
                });
              }
              // const total = abilities?.charisma! + abilities?.consitution!
              // + parseInt(abilities?.strength!, 10) + abilities?.intelligence!
              // + abilities?.dexterity! + newValue;
              // if (total <= limit) {
              // }
            }
          }}
        />
        <CharacterAbility
          title="cha"
          variant={variant}
          value={
            abilities?.charisma !== undefined ? abilities?.charisma ?? 0 : 0
          }
          tip="Charisma of the character"
          onIncrease={() => {
            if (
              abilities?.charisma !== null &&
              abilities?.charisma !== undefined
            ) {
              const newValue = abilities?.charisma + 2;
              if (newValue <= 18) {
                setCharacterAbilities({
                  ...abilities,
                  charisma: newValue,
                });
              }
              // const total = abilities?.dexterity! + abilities?.consitution!
              // + parseInt(abilities?.strength!, 10) + abilities?.intelligence!
              // + abilities?.wisdom! + newValue;
              // if (total <= limit) {
              // }
            }
          }}
          onChange={(val: any) => {
            if (
              abilities?.charisma !== null &&
              abilities?.charisma !== undefined
            ) {
              const newValue = val;
              if (newValue <= 18) {
                setCharacterAbilities({
                  ...abilities,
                  charisma: newValue,
                });
              }
              // const total = abilities?.dexterity! + abilities?.consitution!
              // + parseInt(abilities?.strength!, 10) + abilities?.intelligence!
              // + abilities?.wisdom! + newValue;
              // if (total <= limit) {
              // }
            }
          }}
        />
      </Stack>
    </Stack>
  );
}

CharacterAbilitiesGen.defaultProps = {
  variant: 'characterGen',
  abilities: null,
};

export default CharacterAbilitiesGen;
