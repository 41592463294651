/* eslint-disable object-curly-newline */
import styled from '@emotion/styled';
import { Box, Button, InputBase, Stack, Typography } from '@mui/material';
import DPBackground from '../../images/characterDPBackground.webp';
import CraftStartOptionBackground from '../../images/textured-background-1@3x.webp';

export const BookmarkImg = styled('img')({
  width: '360px',
  height: 'auto',
  flexShrink: 0,
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  position: 'absolute',

  '@media (max-width: 530px)': {
    width: '230px',
    height: '60px',
  },
});

export const BookmarkTitleImg = styled('img')({
  width: 'auto',
  height: '33px',
  flexShrink: 0,

  '@media (max-width: 530px)': {
    height: '20px',
  },
});

// for Character AI Gen Area
export const OptionsStack = styled(Stack)({
  background: '#FFF',
  borderRadius: '5px',
  overflow: 'hidden',
  width: '100%',
  height: '96px',
  flexWrap: 'nowrap',
  flexDirection: 'row',
  cursor: 'pointer',

  '@media (max-width: 530px)': {
    height: '76px',
  },
});

export const OptionsStackIconContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#7A3900',
  width: '96px',

  '@media (max-width: 530px)': {
    width: '76px',
  },
});

export const OptionsStackIcon = styled('img')({
  height: '40px',
  width: '40px',
  objectFit: 'contain',
});

export const OptionsStackTextContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 'calc(100% - 96px)',

  '@media (max-width: 530px)': {
    width: 'calc(100% - 76px)',
  },
});

export const OptionsStackText = styled(Typography)({
  color: '#250503',
  textAlign: 'center',
  fontFamily: 'DM Sans',
  fontSize: '22px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '28px',

  '@media (max-width: 530px)': {
    fontSize: '18px',
  },
});

export const SmallHeadingText = styled(Typography)({
  color: '#49454F',
  fontFamily: 'DM Sans',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '19px' /* 133.333% */,
});

export const SectionHeadingText = styled(Typography)({
  color: '#250503',
  fontFamily: 'Passero One',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '20px' /* 150% */,
  letterSpacing: '.1px',
});

export const AttributeWrapper = styled(Box)({
  borderRadius: '4px',
  background: '#E3D5BC',
  padding: '9px 12px',
  boxSizing: 'border-box',
});

export const AttributeText = styled(Typography)({
  color: '#1D1B20',
  fontFamily: 'DM Sans',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '24px' /* 150% */,
  letterSpacing: '.5px',
  display: 'inline',
});

export const AttributeTextField = styled(InputBase)({
  border: 'none',
  outline: 'none',
  textarea: {
    color: '#1D1B20',
    fontFamily: 'DM Sans',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px' /* 150% */,
    letterSpacing: '0.5px',
  },
  input: {
    color: '#1D1B20',
    fontFamily: 'DM Sans',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px' /* 150% */,
    letterSpacing: '0.5px',
  },
  'MuiInputBase-root': {
    padding: 0,
    paddingTop: '10px',
    outline: 'none!important',
    border: 'none!important',
  },
});

export const HelpImg = styled('img')({
  height: '12px',
  width: 'auto',
});

export const GenerateDiceImg = styled('img')({
  width: '21px',
  height: 'auto',
});

// ____________________________________________________________________________

export const EditorImg = styled('img')({
  height: '20px',
  width: 'auto',
  cursor: 'pointer',
});

export const AIButton = styled(Button)({
  display: 'flex',
  padding: '10px 14px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  borderRadius: '4px',
  color: '#ffffffcc',
  background: '#DA16A2',
  '&:hover': {
    background: '#DA16A2',
  },

  '@media (max-width: 530px)': {
    padding: '5px 7px',
    gap: '4px',
  },
});

export const AIButtonImg = styled('img')({
  height: '16px',
  width: '16px',
  objectFit: 'contain',
});

export const AIButtonText = styled(Typography)({
  color: 'white',
  fontFamily: 'Passero One',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '16px' /* 150% */,
  letterSpacing: '.1px',
  textTransform: 'capitalize',

  '@media (max-width: 530px)': {
    fontSize: '14px',
  },
});

export const CoinImg = styled('img')({
  height: '24px',
  width: 'auto',
});

export const ImageBackgroundWrapper = styled(Box)({
  height: '264px',
  width: '270px',
  backgroundSize: 'contain',
  backgroundImage: `url(${DPBackground})`,
  backgroundPosition: 'center center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transform: 'translateY(-50%)',
  position: 'absolute',
  top: '50%',
  left: '5px',
});

export const CharacterDPImage = styled('img')({
  height: '232px',
  width: '232px',
  border: '1.5px solid #29370D',
  borderRadius: '50%',
  objectFit: 'cover',
  objectPosition: 'top center',
});

export const CustomScrollWrapper = styled(Box)({
  boxSizing: 'border-box',
  paddingRight: '6px',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    width: '8px',
    backgroundColor: '#FFF',
    borderRadius: '8px',
  },
  '&.scrollbar': {
    scrollbarWidth: 'thin',
    scrollbarColor: '#FFF',
    backgroundColor: '#FFF',
    borderRadius: '8px',
  },
  '&::-webkit-scrollbar-thumb, &.scrollbar-thumb, &::-webkit-scrollbar-thumb:hover, &.scrollbar-thumb:hover':
    {
      backgroundColor: '#250503',
      borderRadius: '8px',
    },
});

export const BackgroundContentWrapper = styled(Box)({
  borderRadius: '4px',
  boxSizing: 'border-box',
  border: '1px solid #BCAD93',
  paddingRight: '6px',
  background: '#F7F2E9',
});

export const GoalContentWrapper = styled(Box)({
  borderRadius: '4px',
  boxSizing: 'border-box',
  border: '1px solid #BCAD93',
  borderTop: 'none',
  paddingRight: '6px',
});

export const ShieldImg = styled('img')({
  width: '40px',
  height: '40px',
  flexShrink: 0,
});

export const FilterImg = styled('img')({
  width: '30px',
  height: '30px',
  flexShrink: 0,

  '@media (max-width: 530px)': {
    width: '20px',
    height: '20px',
  },
});

export const DownloadImg = styled('img')({
  width: '23px',
  height: '23px',
  objectFit: 'contain',
  objectPosition: 'center center',
});

export const TokenBalanceWrapper = styled(Stack)({
  backgroundColor: 'white',
  borderRadius: '8px',
  boxSizing: 'border-box',
  height: '25px',
  padding: '0px 4px',
  columnGap: '10px',
  alignItems: 'center',
  flexDirection: 'row',
  border: '1px solid #41521F',
});

export const CPCreateButton = styled(Button)({
  borderRadius: '5px',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  display: 'block',
  border: 'none',
  outline: 'none',
  padding: '7px 10px',
  cursor: 'pointer',
  color: 'white',
});

export const CPCreateButtonText = styled(Typography)({
  color: 'white',
  fontFamily: 'Passero One',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  textTransform: 'none',
});

export const FlexibleHeightImg = styled('img')({
  height: 'auto',
});

export const CraftStartOptionWrapper = styled(Box)({
  backgroundImage: `url(${CraftStartOptionBackground})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  boxSizing: 'border-box',
  border: '2px solid white',
  borderRadius: '5px',
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  minHeight: '100px',
  overflow: 'hidden',
  '&.realm-default': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    height: '577px',
    position: 'relative',
    backgroundSize: 'cover!important',
    backgroundPosition: 'center!important',
    '::before': {
      content: '""',
      width: '100%',
      height: '100%',
      background: '#25050380',
      position: 'absolute',
    },
  },
});

export const CraftStartOptionHeadingImg = styled('img')({
  height: '182px',
  width: '100%',
  objectFit: 'cover',
});

export const WizardNavigationButton = styled(Button)({
  height: '40px',
  padding: '8px 12px',
  boxSizing: 'border-box',
  borderRadius: '45px',
  background: '#41521F',
  color: 'white',
  '&:hover': {
    background: '#41521F',
  },
});

export const WizardNavigationButtonText = styled(Typography)({
  color: 'white',
  fontFamily: 'DM Sans',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '20px',
  textTransform: 'uppercase',
  letterSpacing: '.1px',
});

export const SubHeadingText = styled(Typography)({
  color: '#250503',
  fontFamily: 'DM Sans',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '30px',
  letterSpacing: '0.1px',

  '@media (max-width: 530px)': {
    fontSize: '16px',
  },
});

export const SummaryModalFnButton = styled(Button)({
  display: 'flex',
  padding: '0',
  color: 'white',
  height: '33px',
  width: '33px',
  borderRadius: '50%',
  minWidth: '1px',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'white',
  '&:hover': {
    background: 'white',
  },
  '&.active': {
    background: '#41521F',
    '&:hover': {
      background: '#41521F',
    },
  },
});

export const SummaryModalFnButtonImg = styled('img')({
  height: '21px',
  width: 'auto',
});
