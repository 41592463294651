import React, { useContext } from 'react';
import { Tooltip, Zoom } from '@mui/material';
import { ThemeContext } from '../../context/ThemeContext';
import styles from './icons.module.scss';

interface Props {
  onClick: () => void;
  active: boolean;
}

function NotesIcon({ onClick, active } : Props) {
  const { theme } = useContext(ThemeContext);
  return (
    <Tooltip
      title="Notes"
      arrow
      placement="bottom"
      TransitionComponent={Zoom}
      componentsProps={{
        tooltip: {
          sx: {
            fontFamily: 'Passero One',
            fontWeight: '400px',
            fontSize: '16px',
            padding: '10px',
            bgcolor: '#3c3c3ce6',
            '& .MuiTooltip-arrow': {
              color: '#3c3c3ce6',
            },
            maxWidth: '250px',
          },
        },
      }}
    >
      <button
        onClick={onClick}
        type="button"
        className={`${styles.icon} ${styles.notes_icon} ${styles[theme]} ${active && styles.active}`}
        aria-label="notes icon"
      >
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 26 30"
          xmlSpace="preserve"
        >
          <path d="M7.7,1.7v4 M18.3,1.7v4 M7.7,13.7h10.7 M7.7,20.3H13 M25,10.3v11.3c0,4-2,6.7-6.7,6.7H7.7C3,28.3,1,25.7,1,21.7 V10.3c0-4,2-6.7,6.7-6.7h10.7C23,3.7,25,6.3,25,10.3z" />
        </svg>
      </button>
    </Tooltip>
  );
}

export default NotesIcon;
