/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable react/no-unused-prop-types */

import * as React from 'react';
import { Button, Grid, Typography, Box, Modal } from '@mui/material';
import useNotification from '../../hooks/useNotification';
import {
  Token_Set,
  UpdateOneTokenSetDocument,
} from '../../__generated__/graphql';
import useGraphQLMutation from '../../hooks/useGraphQLMutation';
import appStore from '../../store/AppStore';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '600px',
  color: 'white',
  p: 4,
  outline: 'none',
};

type RWModalProps = {
  open: boolean;
  onClose: () => void;
  setOpen?: (v: boolean) => void;
  onConfirm: () => void;
} & React.PropsWithChildren;

export default function UpdateTokenSetModal({
  open,
  onClose,
  setOpen,
}: RWModalProps) {
  const handleClose = () => {
    onClose?.();
    setOpen?.(false);
  };

  const { displayNotification } = useNotification();

  const selectedTokenSet: Token_Set | null = appStore(
    (state) => state.selectedTokenSet,
  );

  const [selectedTokenSetName, setSelectedTokenSetName] = React.useState(
    selectedTokenSet?.name ?? '',
  );

  React.useEffect(() => {
    setSelectedTokenSetName(selectedTokenSet?.name ?? '');
  }, [selectedTokenSet]);

  const setupdateTokenSetModalOpen = (bool: boolean) => {
    appStore.setState({
      updateTokenSetModalOpen: bool,
    });
  };

  const { mutate: updateTokenSetName, status: updateTokenSetNameStatus } =
    useGraphQLMutation(UpdateOneTokenSetDocument);

  React.useEffect(() => {
    if (updateTokenSetNameStatus === 'loading') {
      displayNotification({
        message: 'Updating token set name ...',
        type: 'info',
      });
    }
    if (updateTokenSetNameStatus === 'error') {
      displayNotification({
        message: 'Failed to update token set name',
        type: 'error',
      });
    }
    if (updateTokenSetNameStatus === 'success') {
      setupdateTokenSetModalOpen(false);
      displayNotification({
        message: 'Token set name updated successfully',
        type: 'success',
      });
    }
  }, [updateTokenSetNameStatus]);

  return (
    <div>
      <Modal
        open={open}
        onClose={() => handleClose()}
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              backdropFilter: 'blur(4px)',
            },
          },
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ zIndex: 999 }}
      >
        <Box sx={style}>
          <Grid container>
            <Grid
              item
              xs={12}
              mb={2}
              p={2}
              py={3}
              sx={{
                backgroundColor: 'rgba(217, 217, 217, 0.2)',
                border: '1px solid #FFFFFF',
                borderRadius: '10px',
                height: 'fit-content',
                maxHeight: 'fit-content',
                boxSizing: 'border-box',
                justifyContent: 'center',
              }}
            >
              <Box
                sx={{
                  //   display: 'flex',
                  //   justifyContent: 'space-between',
                  boxSizing: 'border-box',
                  width: '100%',
                }}
              >
                {/* <img
                  src={selectedTokenSet?.thumbnail_url ?? ''}
                  alt={selectedTokenSet?.name ?? ''}
                  style={{
                    height: '200px',
                    width: '200px',
                    objectFit: 'contain',
                    display: 'block',
                  }}
                /> */}
                <Box
                  sx={{
                    width: '100%',
                    boxSizing: 'border-box',
                  }}
                >
                  <Typography
                    sx={{
                      color: 'white',
                      fontFamily: 'Passero One',
                      fontSize: '25px',
                      fontWeight: 400,
                      paddingBottom: '10px',
                    }}
                  >
                    Update Token Set Name
                  </Typography>
                  <textarea
                    style={{
                      width: '100%',
                      boxSizing: 'border-box',
                      border: '1px solid white',
                      padding: '10px 20px',
                      color: 'white',
                      borderRadius: '5px',
                      fontFamily: 'Passero One',
                      fontSize: '20px',
                      fontWeight: 400,
                      outline: 'none',
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                      marginBottom: '10px',
                    }}
                    value={selectedTokenSetName}
                    onChange={(
                      event: React.ChangeEvent<HTMLTextAreaElement>,
                    ) => {
                      setSelectedTokenSetName(event.target.value);
                    }}
                  />
                  <Button
                    type="button"
                    sx={{
                      display: 'block',
                      fontFamily: 'Passero One',
                      fontSize: '16px',
                      fontWeight: 400,
                      padding: '10px 15px',
                      borderRadius: '5px',
                      cursor: 'pointer',
                      backgroundColor: '#FF6746',
                      color: 'white',
                      width: '100%',
                      '&:hover': {
                        backgroundColor: '#FF6746',
                        color: 'white',
                      },
                    }}
                    disabled={selectedTokenSetName === ''}
                    onClick={() => {
                      updateTokenSetName({
                        set: {
                          name: selectedTokenSetName,
                        },
                        query: {
                          _id: selectedTokenSet?._id,
                        },
                      });
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}

UpdateTokenSetModal.defaultProps = {
  setOpen: () => {},
};
