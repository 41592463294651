import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Stack, Typography } from '@mui/material';
import RecommendedCard from '../../../RecommendedCharacter/RecommendedCharacter';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  outline: 'none',
};

type RWModalProps = {
  open: boolean,
  onClose: (e: Event, r: 'backdropClick' | 'escapeKeyDown') => void,
  setOpen: (v: boolean) => void,
};

export default function EmptyCartModal({
  open, onClose, setOpen,
}: RWModalProps) {
  const handleClose = (e: Event, r: 'backdropClick' | 'escapeKeyDown') => { onClose?.(e, r); setOpen(false); };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        slotProps={{ backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.7)', backdropFilter: 'blur(4px)' } } }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              width: '1080px',
              height: 'fit-content',
              background: '#fff',
              borderRadius: 4,
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                width: '1080px',
                height: '50px',
                background: '#41521F',
              }}
            />
            <Box
              display="flex"
              flexDirection="column"
              sx={{
                textAlign: 'center',
                paddingTop: '85px',
              }}
            >
              <Typography
                sx={{
                  textAlign: 'center',
                  fontFamily: 'Passero One',
                  fontSize: '36px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '20px',
                  letterSpacing: '0.1px',
                }}
              >
                Your Shopping Cart is Empty

              </Typography>
              {' '}
&nbsp;
              <Typography
                sx={{
                  textAlign: 'center',
                  fontFamily: 'DM Sans',
                  fontSize: '24px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '30px',
                  letterSpacing: '0.1px',
                }}
              >
                Want some recommendations?
                {' '}

              </Typography>
            </Box>
            <Box
              display="flex"
              sx={{
                mt: 5,
                mb: 2,
                paddingInline: '44px',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Passero One',
                  fontSize: '25px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '20px',
                  letterSpacing: '0.1px',
                }}
              >
                Recommended for you

              </Typography>
            </Box>
            <Stack
              flexDirection="row"
              sx={{
                mb: 2,
                paddingInline: '44px',
                paddingBottom: '50px',
              }}
              gap="30px"
              flexWrap="nowrap"
            >
              {RecommendedCard}
            </Stack>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
