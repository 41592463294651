/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-cycle */

import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import s from './Settings.module.scss';
import DashboardHeader from '../DashboardHeader/DashboardHeader';
import Sidebar from './Sidebar';
import AccountManagement from './AccountManagement';
import Subscription from './Subscription';
import Notification from '../../components/notifications/Notification';
import RWSettings from '../../views/Dashboard/RWSettings/RWSettings';
import Credits from './Credits';

export type TabButtonType = 'Account' | 'Subscription' | 'Credits' | 'Devtools';

const Settings = () => {
  const [selectedTab, setSelectedTab] = useState<TabButtonType>('Account');
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(true);

  const isTabletOrBelow = useMediaQuery({ query: '(max-width: 768px)' });

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  return (
    <div className={s.layout}>
      <DashboardHeader />
      <Notification />
      <main className={s.main}>
        {isTabletOrBelow ? (
          <>
            <Sidebar
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              isCollapsed={isSidebarCollapsed}
              toggleSidebar={toggleSidebar}
            />
            <div
              className={`${s.main__container} ${
                isSidebarCollapsed ? '' : s.expanded
              }`}
            >
              {selectedTab === 'Account' && <AccountManagement />}
              {selectedTab === 'Subscription' && <Subscription />}
              {selectedTab === 'Credits' && <Credits />}
              {selectedTab === 'Devtools' && <RWSettings />}
            </div>
          </>
        ) : (
          <>
            <Sidebar
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
            <div className={s.main__container}>
              {selectedTab === 'Account' && <AccountManagement />}
              {selectedTab === 'Subscription' && <Subscription />}
              {selectedTab === 'Credits' && <Credits />}
              {selectedTab === 'Devtools' && <RWSettings />}
            </div>
          </>
        )}
      </main>
    </div>
  );
};

export default Settings;
