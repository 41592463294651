/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Box, Typography } from '@mui/material';
import s from './ToggleSlider.module.scss';

type ToggleSliderProps = {
  isDarkMode: boolean;
  handleToggle: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const ToggleSlider: React.FC<ToggleSliderProps> = ({ isDarkMode, handleToggle }) => (
  <Box>
    <Box sx={{ marginTop: 3 }}>
      <Typography variant="body1">
        {isDarkMode ? 'Dark Mode ON' : 'Dark Mode OFF'}
      </Typography>
    </Box>
    <div className={s.toggleSlider}>

      <Box>
        <input
          type="checkbox"
          id="toggle"
          checked={isDarkMode}
          onChange={handleToggle}
          className={s.toggleSlider__input}
        />
        <label htmlFor="toggle" className={s.slider} />
      </Box>
    </div>
  </Box>

);

export default ToggleSlider;
