/* eslint-disable operator-linebreak */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable nonblock-statement-body-position */
import React from 'react';
import { Stack, useMediaQuery } from '@mui/material';
import WhiteDice from '../../../images/dice-white.webp';
import {
  AIButton,
  AIButtonImg,
  AIButtonText,
  SubHeadingText,
} from '../../styledComps/styledComps';
import {
  Body_Shape,
  CharacterAbility,
  Class,
  Eye,
  Gender,
  GetBodyShapesAttributesDocument,
  GetClassesAttributesDocument,
  GetEyesAttributesDocument,
  GetGendersAttributesDocument,
  GetPersonalitiesAttributesDocument,
  GetRacesAttributesDocument,
  GetSkinsAttributesDocument,
  Personality,
  Race,
  Skin,
} from '../../../__generated__/graphql';
import useGraphQL from '../../../hooks/useGraphQL';
import {
  AlignmentOptions,
  HairColorOptions,
  HairStyleOptions,
  LAEOptions,
  MotivationOptions,
  ProfessionOptions,
  PronounOptions,
  RelationshipOptions,
} from './CharacterStaticOptions';
import getRandomValue from '../../../util/getRandomValue';
// import getRandomItems from '../../../util/getRandomItemsAsArray';
import pickThreeRandomItems from '../../../util/getRandomItemsAsArray';
import generateCharacterName from './utilities/GenerateCharacterName';
import generateCharacterAbilities from './utilities/GenerateCharacterAbilities';
import CharacterWarningModal from '../../modals/CharacterWarningModal';

interface Props {
  setCharacterName: (char: string) => void;
  setCharacterAge: (char: number) => void;
  setCharacterPronouns: (char: string) => void;
  setCharacterGender: (char: Gender) => void;
  setCharacterRace: (char: Race) => void;
  setCharacterClass: (char: Class) => void;
  setCharacterAlignment: (char: string) => void;
  setCharacterPersonalityType: (char: Personality) => void;
  setCharacterMotivations: (char: string[]) => void;
  setCharacterAbilities: (ab: CharacterAbility) => void;
  setCharacterBodyType: (char: Body_Shape) => void;
  setCharacterSkinColor: (char: Skin) => void;
  setCharacterHairColor: (char: string) => void;
  setCharacterHairStyle: (char: string) => void;
  setCharacterEyeColor: (char: Eye) => void;
  setCharacterProfession: (char: string) => void;
  setCharacterRelationship: (char: string) => void;
  setCharacterLifeAlteringEvent: (char: string) => void;
}

function CharacterSheetRandomizer({
  setCharacterName,
  setCharacterAge,
  setCharacterPronouns,
  setCharacterGender,
  setCharacterRace,
  setCharacterClass,
  setCharacterAlignment,
  setCharacterPersonalityType,
  setCharacterMotivations,
  setCharacterAbilities,
  setCharacterBodyType,
  setCharacterSkinColor,
  setCharacterHairColor,
  setCharacterHairStyle,
  setCharacterEyeColor,
  setCharacterProfession,
  setCharacterRelationship,
  setCharacterLifeAlteringEvent,
}: Props) {
  const [openCharacterWarningModal, setOpenCharacterWarningModal] =
    React.useState(false);
  const { data: genders } = useGraphQL(GetGendersAttributesDocument, {});
  const { data: races } = useGraphQL(GetRacesAttributesDocument, {});
  const { data: classes } = useGraphQL(GetClassesAttributesDocument, {});
  const { data: personalities } = useGraphQL(
    GetPersonalitiesAttributesDocument,
    {},
  );
  const { data: bodyTypes } = useGraphQL(GetBodyShapesAttributesDocument, {});
  const { data: skinColor } = useGraphQL(GetSkinsAttributesDocument, {});
  const { data: eyeColor } = useGraphQL(GetEyesAttributesDocument, {});

  function getRandomNumber(): number {
    return Math.floor(Math.random() * 100) + 1;
  }

  const handleRandomizeSheet = () => {
    setCharacterName(generateCharacterName());
    setCharacterAge(getRandomNumber());
    setCharacterPronouns(getRandomValue(PronounOptions));
    const GenderOptions = genders?.genders;
    if (GenderOptions !== undefined) {
      setCharacterGender(getRandomValue(GenderOptions));
    }
    const RaceOptions = races?.races;
    if (RaceOptions !== undefined) {
      setCharacterRace(getRandomValue(RaceOptions));
    }
    const ClassOptions = classes?.classes;
    if (ClassOptions !== undefined) {
      setCharacterClass(getRandomValue(ClassOptions));
    }
    setCharacterAlignment(getRandomValue(AlignmentOptions));
    const PersonalityOptions = personalities?.personalities;
    if (PersonalityOptions !== undefined) {
      setCharacterPersonalityType(getRandomValue(PersonalityOptions));
    }
    setCharacterMotivations(pickThreeRandomItems(MotivationOptions));
    // console.log(pickThreeRandomItems(MotivationOptions));
    const randomAbilities = generateCharacterAbilities();
    setCharacterAbilities({
      strength: randomAbilities[0].toString(),
      dexterity: randomAbilities[1],
      consitution: randomAbilities[2],
      intelligence: randomAbilities[3],
      wisdom: randomAbilities[4],
      charisma: randomAbilities[5],
    });
    const BodyShapeOptions = bodyTypes?.body_shapes;
    if (BodyShapeOptions !== undefined) {
      setCharacterBodyType(getRandomValue(BodyShapeOptions));
    }
    const SkinColorOptions = skinColor?.skins;
    if (SkinColorOptions !== undefined) {
      setCharacterSkinColor(getRandomValue(SkinColorOptions));
    }
    const EyeColorOptions = eyeColor?.eyes;
    if (EyeColorOptions !== undefined) {
      setCharacterEyeColor(getRandomValue(EyeColorOptions));
    }
    setCharacterHairColor(getRandomValue(HairColorOptions));
    setCharacterHairStyle(getRandomValue(HairStyleOptions));
    setCharacterProfession(getRandomValue(ProfessionOptions));
    setCharacterRelationship(getRandomValue(RelationshipOptions));
    setCharacterLifeAlteringEvent(getRandomValue(LAEOptions));
  };

  const isTabletScreen = useMediaQuery('(max-width:865px)');
  const isMobileScreen = useMediaQuery('(max-width:530px)');

  return (
    <>
      <Stack
        direction="row"
        gap="23px"
        paddingTop={isMobileScreen ? '25px' : '0px'}
        display={isMobileScreen ? 'none' : 'block'}
      >
        <SubHeadingText>Lorem ipsum dolor sit?</SubHeadingText>
        <AIButton
          sx={{ background: '#7A3900!important' }}
          onClick={() => setOpenCharacterWarningModal(true)}
        >
          <AIButtonImg src={WhiteDice} />
          <AIButtonText>Randomize Your Sheet</AIButtonText>
        </AIButton>
      </Stack>
      <CharacterWarningModal
        open={openCharacterWarningModal}
        onClose={() => {}}
        onConfirm={() => {
          handleRandomizeSheet();
          setOpenCharacterWarningModal(false);
        }}
        setOpen={setOpenCharacterWarningModal}
        message="Using Randomize Sheet will delete your current input."
        variant="randomize"
        hideable
      />
    </>
  );
}

export default CharacterSheetRandomizer;
