/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import Konva from 'konva';
import { Filter, KonvaEventObject } from 'konva/lib/Node';
import React, {
  RefObject, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { Image as KonvaImage } from 'react-konva';
import useItem, { OverrideItemProps } from '../../hooks/useItem';
import { StageData } from '../../redux/currentStageData';
import decimalUpToSeven from '../../util/decimalUpToSeven';

export type ImageItemKind = {
  dataItemType: string;
  id: string;
  name: string;
  src: string;
  image: typeof Image;
};

export type ImageItemProps = OverrideItemProps<{
  data: StageData;
  e?: DragEvent;
}>;

const OpacityFilter: Filter = (imageData: ImageData) => {
  // make all pixels opaque 100%
  const nPixels = imageData.data.length;
  for (let i = 0; i < nPixels - 4; i += 4) {
    if (imageData.data[i] === 0
            && imageData.data[i + 1] === 0
            && imageData.data[i + 2] === 0) {
      imageData.data[i + 3] = 0;
    }
  }
};

const MaskWhiteFilter: Filter = (imageData: ImageData) => {
  // make all pixels opaque 100%
  const nPixels = imageData.data.length;
  for (let i = 0; i < nPixels - 4; i += 4) {
    const threshold = 200;
    if (imageData.data[i] > threshold
            && imageData.data[i + 1] > threshold
            && imageData.data[i + 2] > threshold) {
      imageData.data[i + 3] = 0;
    }
  }
};

export const filterMap: { [name: string]: Filter } = {
  Brighten: Konva.Filters.Brighten,
  Grayscale: Konva.Filters.Grayscale,
  RGB: Konva.Filters.RGB,
  HSL: Konva.Filters.HSL,
  Invert: Konva.Filters.Invert,
  ClipMask: OpacityFilter,
  WhiteMask: MaskWhiteFilter,
};

function ImageItem({ data, onSelect, onDragEnd }: { data: any, onSelect: any, onDragEnd: any }) {
  const { attrs } = data;
  const imageRef = useRef() as RefObject<Konva.Image>;
  const [imageSrc, setImageSrc] = useState<CanvasImageSource>(new Image());
  const { updateItem } = useItem();

  const filters = useMemo(() => {
    if (!data.attrs.filters) {
      return [Konva.Filters.Brighten];
    }
    return data.attrs.filters.map((filterName: string) => filterMap[filterName]);
  }, [data.attrs]);

  useEffect(() => {
    const newImage = new Image();
    newImage.onload = () => {
      setImageSrc(newImage);
    };
    newImage.crossOrigin = 'Anonymous';
    let source;
    if (attrs.src.startsWith('find:')) {
      source = attrs.src;
    } else {
      source = attrs.src;
    }
    if (source.startsWith('data:')) {
      Konva.Image.fromURL(source, (imageNode: Konva.Image) => {
        let width;
        let height;
        if (imageNode.width() > imageNode.height()) {
          width = decimalUpToSeven(512);
          height = decimalUpToSeven(width * (imageNode.height() / imageNode.width()));
        } else {
          height = decimalUpToSeven(512);
          width = decimalUpToSeven(height * (imageNode.width() / imageNode.height()));
        }
        imageNode.width(width);
        imageNode.height(height);
        const newBase64 = imageNode.toDataURL({
          x: 0,
          y: 0,
          width,
          height,
          pixelRatio: 5,
        });
        newImage.src = newBase64;
      });
      return;
    }
    newImage.src = source;
  }, [attrs.src]);

  const onDragMoveFrame = useCallback((e: KonvaEventObject<DragEvent>) => {
    e.target.getLayer()?.batchDraw();
  }, []);

  const onDragEndFrame = useCallback(
    (e: KonvaEventObject<DragEvent>) => {
      e.evt.preventDefault();
      e.evt.stopPropagation();
      onDragEnd?.();
      updateItem(e.target.id(), (prevData: any) => ({
        ...e.target.attrs,
        filters: prevData.attrs.filters,
      }));
      e.target.getLayer()?.batchDraw();
    },
    [data],
  );

  useEffect(() => {
    if (imageRef.current) {
      imageRef.current.brightness(data.attrs.brightness);
      imageRef.current.cache();
      // imageRef.current.getParent().cache();
    }
  }, [imageSrc, data]);

  useEffect(() => {
    imageRef.current!.cache();
    // imageRef.current!.getParent().cache();
    imageRef.current!.getLayer()?.batchDraw();
  }, []);

  return (
    <KonvaImage
      ref={imageRef}
      image={imageSrc}
      onClick={onSelect}
      name="label-target"
      dataItemType="image"
      dataFrameType="image"
      id={data.id}
      x={attrs.x}
      y={attrs.y}
      red={attrs.red}
      green={attrs.green}
      blue={attrs.blue}
      hue={attrs.hue}
      saturation={attrs.saturation}
      luminance={attrs.luminance}
      width={attrs.width}
      height={attrs.height}
      scaleX={attrs.scaleX}
      scaleY={attrs.scaleY}
      fill={attrs.fill ?? 'transparent'}
      opacity={attrs.opacity ?? 1}
      rotation={attrs.rotation ?? 0}
      filters={[...filters]}
      draggable={attrs.draggable}
      listening={attrs.draggable}
      onDragMove={onDragMoveFrame}
      onDragEnd={onDragEndFrame}
    />
  );
}

export default ImageItem;
