/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Stack, useMediaQuery } from '@mui/material';
import { SectionHeadingText } from '../../styledComps/styledComps';
import CharacterFilterDropdown from '../../fixedFilter/filterDropDown/CharacterFilterDropdown';
import {
  Body_Shape,
  Eye,
  GetBodyShapesAttributesDocument,
  GetEyesAttributesDocument,
  GetSkinsAttributesDocument,
  GetUserCharacterGenerationQuery,
  Skin,
} from '../../../__generated__/graphql';
import useGraphQL from '../../../hooks/useGraphQL';
import { HairColorOptions, HairStyleOptions } from './CharacterStaticOptions';
import appStore from '../../../store/AppStore';
import getAttributeValue from '../../../util/aiGetCharAttribute';

interface Props {
  characterBodyType: Body_Shape | null;
  setCharacterBodyType: (char: Body_Shape) => void;
  characterSkinColor: Skin | null;
  setCharacterSkinColor: (char: Skin) => void;
  characterHairColor: string;
  setCharacterHairColor: (char: string) => void;
  characterHairStyle: string;
  setCharacterHairStyle: (char: string) => void;
  characterEyeColor: Eye | null;
  setCharacterEyeColor: (char: Eye) => void;
}

function CharacterPhysicalProfile({
  characterBodyType,
  setCharacterBodyType,
  characterSkinColor,
  setCharacterSkinColor,
  characterHairColor,
  setCharacterHairColor,
  characterHairStyle,
  setCharacterHairStyle,
  characterEyeColor,
  setCharacterEyeColor,
}: Props) {
  const viewMood = appStore((state) => state.characterSheetMood);
  const userCharacterGeneration:
    | GetUserCharacterGenerationQuery
    | null
    | undefined = appStore((state) => state.userCharacterGeneration);
  const selectedCharacter = appStore((state) => state.selectedCharacter);
  const createCharacterView = appStore((state) => state.createCharacterView);
  const { data: bodyTypes } = useGraphQL(GetBodyShapesAttributesDocument, {});
  const { data: skinColor } = useGraphQL(GetSkinsAttributesDocument, {});
  const { data: eyeColor } = useGraphQL(GetEyesAttributesDocument, {});
  React.useEffect(() => {
    if (viewMood === 'edit' && createCharacterView === 'sheet') {
      if (selectedCharacter !== null) {
        const selectedBodyType = bodyTypes?.body_shapes.find(
          (body) =>
            body?.name === selectedCharacter?.physical_profile?.body_type,
        );
        if (selectedBodyType !== null && selectedBodyType !== undefined) {
          setCharacterBodyType(selectedBodyType);
        } else {
          setCharacterBodyType({
            _id: '',
            name: selectedCharacter?.physical_profile?.body_type,
          });
        }
        const selectedSkinColor = skinColor?.skins.find(
          (skin) =>
            skin?.name === selectedCharacter?.physical_profile?.skin_color,
        );
        if (selectedSkinColor !== null && selectedSkinColor !== undefined) {
          setCharacterSkinColor(selectedSkinColor);
        } else {
          setCharacterSkinColor({
            _id: '',
            name: selectedCharacter?.physical_profile?.skin_color,
          });
        }
        setCharacterHairColor(
          selectedCharacter?.physical_profile?.hair_color as string,
        );
        setCharacterHairStyle(
          selectedCharacter?.physical_profile?.hair_style as string,
        );
        const selectedEyeColor = eyeColor?.eyes.find(
          (eye) => eye?.name === selectedCharacter?.physical_profile?.eye_color,
        );
        if (selectedEyeColor !== null && selectedEyeColor !== undefined) {
          setCharacterEyeColor(selectedEyeColor);
        } else {
          setCharacterEyeColor({
            _id: '',
            name: selectedCharacter?.physical_profile?.eye_color,
          });
        }
      }
    }
  }, []);
  React.useEffect(() => {
    if (userCharacterGeneration !== (null || undefined)) {
      const character =
        userCharacterGeneration?.user_character_generation?.attributes;
      if (character) {
        const charSkinColor = getAttributeValue(character, 'skin_color');
        if (charSkinColor !== undefined) {
          const selectedSkinColor = skinColor?.skins.find(
            (skin) =>
              skin?.name?.toLocaleLowerCase() ===
              charSkinColor.toLocaleLowerCase(),
          );
          if (selectedSkinColor !== null && selectedSkinColor !== undefined) {
            setCharacterSkinColor(selectedSkinColor);
          } else {
            setCharacterSkinColor({
              _id: skinColor?.skins[0]?._id,
              name: charSkinColor,
            });
          }
        }
        const charHairColor = getAttributeValue(character, 'hair_color');
        if (charHairColor !== undefined) {
          setCharacterHairColor(charHairColor);
        }
        const charHairStyle = getAttributeValue(character, 'hair_style');
        if (charHairStyle !== undefined) {
          setCharacterHairStyle(charHairStyle);
        }
      }
    }
  }, [userCharacterGeneration]);

  const isTabletScreen = useMediaQuery('(max-width:865px)');
  const isMobileScreen = useMediaQuery('(max-width:530px)');

  return (
    <Stack gap="22px">
      <Stack
        gap="7px"
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <SectionHeadingText>Physical Profile</SectionHeadingText>
      </Stack>
      <Stack
        direction={isTabletScreen ? 'column' : 'row'}
        flexWrap="nowrap"
        gap="28px"
      >
        {isTabletScreen && (
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <CharacterFilterDropdown
              width={isTabletScreen ? '50%' : '20%'}
              title="Body Type"
              options={bodyTypes?.body_shapes}
              value={characterBodyType?.name}
              onChange={setCharacterBodyType}
            />
            <CharacterFilterDropdown
              width={isTabletScreen ? '50%' : '20%'}
              title="Skin Color"
              options={skinColor?.skins}
              value={characterSkinColor?.name}
              onChange={setCharacterSkinColor}
            />
          </div>
        )}
        {!isTabletScreen && (
          <>
            <CharacterFilterDropdown
              width={isTabletScreen ? '50%' : '20%'}
              title="Body Type"
              options={bodyTypes?.body_shapes}
              value={characterBodyType?.name}
              onChange={setCharacterBodyType}
            />
            <CharacterFilterDropdown
              width={isTabletScreen ? '50%' : '20%'}
              title="Skin Color"
              options={skinColor?.skins}
              value={characterSkinColor?.name}
              onChange={setCharacterSkinColor}
            />
          </>
        )}
        {isTabletScreen && (
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <CharacterFilterDropdown
              width={isTabletScreen ? '100%' : '20%'}
              title="Hair Color"
              options={HairColorOptions}
              value={characterHairColor}
              onChange={setCharacterHairColor}
            />
            <CharacterFilterDropdown
              width={isTabletScreen ? '100%' : '20%'}
              title="Hair Style"
              options={HairStyleOptions}
              value={characterHairStyle}
              onChange={setCharacterHairStyle}
            />
          </div>
        )}

        {!isTabletScreen && (
          <>
            <CharacterFilterDropdown
              width={isTabletScreen ? '100%' : '20%'}
              title="Hair Color"
              options={HairColorOptions}
              value={characterHairColor}
              onChange={setCharacterHairColor}
            />
            <CharacterFilterDropdown
              width={isTabletScreen ? '100%' : '20%'}
              title="Hair Style"
              options={HairStyleOptions}
              value={characterHairStyle}
              onChange={setCharacterHairStyle}
            />
          </>
        )}
        <CharacterFilterDropdown
          width={isTabletScreen ? '100%' : '20%'}
          title="Eye Color"
          options={eyeColor?.eyes}
          value={characterEyeColor?.name}
          onChange={setCharacterEyeColor}
        />
      </Stack>
    </Stack>
  );
}

export default CharacterPhysicalProfile;
