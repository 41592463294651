import React from 'react';
import {
  Card,
  Box,
  CardContent,
  Typography,
  Chip,
  Stack,
  CardHeader,
  CardMedia,
} from '@mui/material';
import appStore from '../../store/AppStore';
// import { GetRealmQuery } from '../../__generated__/graphql';

interface RealmSliderProps {
  currentRealmId: string;
}

/**
 * Realm slider component.
 *
 * @component
 * @example
 * // Example usage of RealmSlider component
 * <RealmSlider />
 *
 * @returns {JSX.Element} React element representing the realm slider.
 */
const RealmSlider: React.FC<RealmSliderProps> = () => (
  appStore((state) => state.selectedRealm) ? (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '40%',
        borderRadius: '0px 5px 5px 0px',
        zIndex: 500,
        transition: 'width 0.5s',
      }}
    >
      <Card
        sx={{
          backgroundColor: 'tertiary.main',
          borderRadius: '0px 5px 5px 0px',
          boxShadow: '2px 2px 6px 3px rgba(0, 0, 0, 0.3)',
        }}
      >
        <CardHeader
          title={(
            <Typography
              sx={{
                fontSize: '24px',
                fontFamily: 'Passero One, cursive, small-caps',
                fontWeight: 'bold',
              }}
            >
              {appStore((state) => state.selectedRealm)?.name}
            </Typography>
          )}
          subheader={appStore((state) => state.selectedRealm)?.description}
        />
        <CardContent>
          <Stack spacing={2}>
            {appStore((state) => state.selectedRealm)?.tags ? (
              <Stack direction="row" spacing={1}>
                {appStore((state) => state.selectedRealm)?.tags?.map((tag) => (
                  <Chip
                    variant="outlined"
                    key={tag}
                    label={tag}
                    sx={{
                      background: '#FFF',
                      color: '#000',
                    }}
                  />
                ))}
              </Stack>
            ) : null}
          </Stack>
        </CardContent>
        <CardMedia
          component="img"
          height="60%"
          image={appStore((state) => state.selectedRealm)?.thumbnail_url || ''}
          alt={appStore((state) => state.selectedRealm)?.thumbnail_url || ''}
          style={{
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        />
      </Card>
    </Box>
  ) : <> </>
);

export default RealmSlider;
