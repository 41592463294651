/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import * as Realm from 'realm-web';
import {
  useState, useEffect, useContext, createContext,
} from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import {
  signInWithRedirect,
  signInWithCredential,
  getRedirectResult,
  signInWithPopup,
  getAdditionalUserInfo,
  UserCredential,
  AuthCredential,
  GoogleAuthProvider,
} from 'firebase/auth';
import RealmWorldsService from '../services/realmWorldsService';

const TENANT_ID = 'realm-worlds-1pwl0';
// Add your Firebase credentials
firebase.initializeApp({
  apiKey: 'AIzaSyC-jRsLqBFQ9VbIgjlvLPEk8dhXxdYAZsI',
  authDomain: 'smartai-dev-poc-image-gen.firebaseapp.com',
  projectId: 'smartai-dev-poc-image-gen',
  appId: '1:500182862859:web:c555abc8aa8d35eeb00b0e',
});
const actionCodeSettings = {
  url: `https://${process.env.WEB_URL}/confirmEmail`,
  handleCodeInApp: false,
  dynamicLinkDomain: `${process.env.WEB_DOMAIN}`,
};
const authContext = createContext<ProvideAuthProps | null>(null);
const googleProvider = new firebase.auth.GoogleAuthProvider();
firebase.auth().useDeviceLanguage();
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }: { children: JSX.Element }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

const app = new Realm.App({ id: `${process.env.REACT_APP_MONGO_ATLAS_SEARCH_APP}` });
// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => useContext(authContext);
// export const useGoogleProvider = () => useContext(googleProvider);
// Provider hook that creates auth object and handles state
interface ProvideAuthProps {
  user: firebase.User | null,
  realmUser: Realm.User | null,
  signin: Function,
  signup: Function,
  signout: Function,
  sendPasswordResetEmail: Function,
  confirmPasswordReset: Function,
  changePassword: Function,
  signInWithRedirectFlow: Function,
  signInWithCredentialFlow: Function,
  getRedirectResultFlow: Function,
  signInWithPopupFlow: Function,
  sendEmailConfirmation: Function,
  confirmEmail: Function,
  getAdditionalUserInfoFlow: Function,
  updateUserProfile: Function,
  getCurrentUserTokens: Function,
  refreshToken: Function,
}
function useProvideAuth() {
  const [user, setUser] = useState<firebase.User | null>(null);
  const [realmUser, setRealmUser] = useState<Realm.User | null>(null);
  const [currentUserToken, setCurrentUserToken] = useState<string | undefined>(undefined);
  const auth = firebase.auth();
  auth.tenantId = TENANT_ID;
  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const getAdditionalUserInfoFlow = (result: UserCredential) => getAdditionalUserInfo(result);
  const signin = (email: string, password: string) => auth
    .signInWithEmailAndPassword(email, password)
    .then(async (response: firebase.auth.UserCredential) => {
      setUser(response.user as firebase.User);
      const token = await response.user?.getIdToken();
      await app.logIn(Realm.Credentials.jwt(token || ''));
      setRealmUser(app.currentUser);
      setCurrentUserToken(token);
      localStorage.setItem('token', token || '');
      localStorage.setItem('realmToken', app.currentUser?.accessToken || '');
      return response.user;
    });
  const signInWithCredentialFlow = async (credential: AuthCredential) => signInWithCredential(
    auth,
    credential,
  )
    .then(async (result: UserCredential) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      // const credential = GoogleAuthProvider.credentialFromResult(result);
      // const token = credential.accessToken;
      // The signed-in user info.
      setUser(result.user as firebase.User);
      const token = await result.user?.getIdToken();
      setCurrentUserToken(token);
      // IdP data available using getAdditionalUserInfo(result)
      return result;
    });

  const changePassword = async (
    oldPassword: string,
    newPassword: string,
  // eslint-disable-next-line no-async-promise-executor
  ) => new Promise<void>(async (resolve, reject) => {
    try {
      const reauthUser = await auth?.currentUser?.reauthenticateWithCredential(
        firebase.auth.EmailAuthProvider.credential(auth?.currentUser?.email || '', oldPassword),
      );

      await auth?.currentUser?.updatePassword(newPassword);
      resolve();
    } catch (error) {
      reject(error);
    }
  });
  const signInWithPopupFlow = async () => signInWithPopup(auth, googleProvider)
    .then(async (result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      // const credential = GoogleAuthProvider.credentialFromResult(result);
      // const token = credential?.accessToken;
      // The signed-in user info.
      setUser(result.user as firebase.User);
      const token = await result.user?.getIdToken();
      setCurrentUserToken(token);
      // IdP data available using getAdditionalUserInfo(result)
      return result;
    });
  const signInWithRedirectFlow = async () => signInWithRedirect(auth, googleProvider)
    .then(async (result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      // const credential = GoogleAuthProvider.credentialFromResult(result);
      // const token = credential.accessToken;
      // The signed-in user info.
      const { user } = result;
      setUser(user as firebase.User);
      // const token = await result.user.getIdToken();
      // setCurrentUserToken(token);
      // IdP data available using getAdditionalUserInfo(result)
      // ...
    });
  const getRedirectResultFlow = async () => getRedirectResult(auth)
    .then(async (result) => {
      // This gives you a Google Access Token. You can use it to access Google APIs.
      // const credential = GoogleAuthProvider.credentialFromResult(result);
      // const token = credential.accessToken;

      // The signed-in user info.
      setUser(result?.user as firebase.User);
      const token = await result?.user?.getIdToken();
      setCurrentUserToken(token);
      // IdP data available using getAdditionalUserInfo(result)
      // ...
    });
  const signup = async ({ email, password, displayName }:
  { email: string, password: string, displayName: string }) => RealmWorldsService
    .CreateNewUserAccount({ email, password, displayName });
  const updateUserProfile = async ({ userInfo }
  : { userInfo: { displayName: any; photoURL: any; } }) => {
    const user = firebase.auth().currentUser;
    user?.updateProfile({
      displayName: userInfo.displayName,
      photoURL: userInfo.photoURL,
    });
    const userNew = firebase.auth().currentUser;
    setUser(userNew as firebase.User);
    const token = await userNew?.getIdToken();
    setCurrentUserToken(token);
  };
  const signout = () => auth
    .signOut()
    .then(() => {
      setUser(null);
      setCurrentUserToken(undefined);
      localStorage.clear();
    });
  const sendPasswordResetEmail = (email: string) => auth
    .sendPasswordResetEmail(email)
    .then(() => true);
  const confirmPasswordReset = (code: string, password: string) => auth
    .confirmPasswordReset(code, password)
    .then(() => true);
  const sendEmailConfirmation = () => auth?.currentUser?.sendEmailVerification(actionCodeSettings)
    .then(() => true);

  const refreshToken = async () => auth
    ?.currentUser?.getIdToken(true).then(async (idToken) => {
      setCurrentUserToken(idToken);
      await app.logIn(Realm.Credentials.jwt(idToken || ''));
      setRealmUser(app.currentUser);
      localStorage.setItem('rwUserId', auth.currentUser?.uid || '');
      localStorage.setItem('token', idToken);
      localStorage.setItem('realmToken', app.currentUser?.accessToken || '');
    });

  const confirmEmail = (code: string) => auth
    .applyActionCode(code);

  async function getValidAccessToken(user: any) {
    // An already logged in user's access token might be stale. To
    // guarantee that the token is valid, refresh it if necessary.
    await user.refreshAccessToken();
    return user.accessToken;
  }

  const getCurrentUserTokens = async () => {
    try {
      const token = await auth?.currentUser?.getIdToken();
      setCurrentUserToken(token);
      await app.logIn(Realm.Credentials.jwt(token || ''));
      setRealmUser(app.currentUser);
      localStorage.setItem('rwUserId', auth.currentUser?.uid || '');
      localStorage.setItem('token', token || '');
      localStorage.setItem('realmToken', app.currentUser?.accessToken || '');
      return { fb: token, realm: await getValidAccessToken(app.currentUser) };
    } catch (error) {
      return { fb: undefined, realm: undefined };
    }
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      console.log('state changed: ', user);
      if (user) {
        setUser(user as firebase.User);
        const token = await user?.getIdToken();
        console.log('subscribe token: ', token);
        setCurrentUserToken(token);

        const res = await app.logIn(Realm.Credentials.jwt(token || ''));
        console.log('subscribe realm user: ', res);
        console.log('setting realm user: ', app.currentUser);
        setRealmUser(app.currentUser);
        localStorage.setItem('token', token);
      } else {
        setUser(null);
        setCurrentUserToken(undefined);
        localStorage.getItem('token');
      }
    });
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);
  // Return the user object and auth methods
  return {
    user,
    realmUser,
    signin,
    signup,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset,
    changePassword,
    signInWithRedirectFlow,
    signInWithCredentialFlow,
    getRedirectResultFlow,
    sendEmailConfirmation,
    signInWithPopupFlow,
    confirmEmail,
    getAdditionalUserInfoFlow,
    updateUserProfile,
    getCurrentUserTokens,
    refreshToken,
  };
}
