export const PronounOptions = [
  'She/Her',
  'He/Him',
  'Other',
  'They/Them',
];

export const AlignmentOptions = [
  'Lawful Good',
  'Neutral Good',
  'Chaotic Good',
  'Lawful Neutral',
  'True Neutral',
  'Chaotic Neutral',
  'Lawful Evil',
  'Neutral Evil',
  'Chaotic Evil',
];

export const MotivationOptions = [
  'Explorer',
  'Adventurer',
  'Seeker of Riches',
  'Heroism Survivor',
  'Amass Power',
  'Be Free',
  'Love',
  'Fun Seeker',
  'Andrenaline Junkie',
];

export const HairColorOptions = [
  'Black',
  'Blonde',
  'Brown',
  'Red',
  'Blue',
  'Green',
  'Purple',
  'Pink',
  'Silver',
  'Golden',
];

export const HairStyleOptions = [
  'Straight',
  'Wavy',
  'Curly',
  'Short',
  'Long',
  'Buzzed',
  'Bop',
  'Afro',
  'Braided',
  'Bald',
];

export const ProfessionOptions = [
  'BlackSmith',
  'GoldSmith',
  'Sailor',
  'Stable Boy',
  'Jester',
  'Farmer',
  'Miner',
];

export const RelationshipOptions = [
  'Single',
  'Married',
  'Player',
  'Divorcee',
  'Widow',
];

// life altering event (LAE)
export const LAEOptions = [
  'Death',
  'Disaster',
  'Love',
  'Divorce',
  'Zen',
];
