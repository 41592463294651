/* eslint-disable object-curly-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/require-default-props */

import React, { useContext, useState, useEffect } from 'react';
import { Box, Collapse, Stack, Typography, useMediaQuery } from '@mui/material';
import styled from '@emotion/styled';
import { ThemeContext } from '../../context/ThemeContext';
import styles from './panels.module.scss';
import {
  CircleIcon,
  RectangleIcon,
  ForegroundIcon,
  TextIcon,
  NotesIcon,
  LeftArrowIcon,
  RightArrowIcon,
  // ClockArrowIcon,
  FantasyIcon,
  RealmWorldIcon,
  HamburgerIcon,
  ShoppingCartIcon,
} from '../toolbar_icons';
import PanelItem from './PanelItem/PanelItem';
import Menu from './Menu';
import appStore, { ActiveTab } from '../../store/AppStore';

export interface PanelProps {
  onStageChange: (changeName: string) => void;
  goToPast?: () => void;
  gotToFuture?: () => void;
  handleHistory?: () => void;
  historyOpened?: boolean;
  // handleOpenAssetViewer?: () => void;
  // handleSave?: () => void;
  // saveDisabled?: boolean;
  // handleSaveAs?: () => void;
  handleStageClear: () => void;
}

function Panels({
  onStageChange,
  goToPast,
  gotToFuture,
  handleHistory,
  historyOpened,
  // handleSave,
  // saveDisabled,
  // handleSaveAs,
  handleStageClear,
}: PanelProps) {
  const { theme } = useContext(ThemeContext);
  const [opened, setOpened] = useState(false);
  const [menuOpened, setMenuOpened] = useState(false);

  const activeTab: ActiveTab = appStore((state) => state.activeTab);

  const MenuText = styled(Typography)({
    textAlign: 'center',
    fontFamily: 'DM Sans',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '20px',
    color: 'white',
  });

  const setActiveTab = (tab: ActiveTab) =>
    appStore.setState({
      activeTab: tab,
    });
  const setNewPanel = (value: ActiveTab) => {
    setOpened((prev) => {
      let opened1 = true;

      if (activeTab === value) {
        opened1 = !prev;
      } else {
        setActiveTab(value);
      }

      if (opened1) {
        setMenuOpened(false);
        if (historyOpened) {
          if (handleHistory !== undefined) {
            handleHistory();
          }
        }
      } else {
        setActiveTab('none');
      }
      return opened1;
    });
  };
  const toggleViewMenu = () => {
    setMenuOpened(!menuOpened);
    if (historyOpened) {
      if (handleHistory !== undefined) {
        handleHistory();
      }
    }
    setOpened(false);
    setActiveTab('none');
  };
  const localCartObject: string = appStore((state) => state.localCartObject);

  const localCartArray: any[] =
    localCartObject === '' ? [] : JSON.parse(localCartObject);

  useEffect(() => {
    if (historyOpened) {
      setOpened(false);
      setActiveTab('none');
      setMenuOpened(false);
    }
  }, [historyOpened]);

  useEffect(() => {
    if (activeTab === 'aigenerate') {
      setOpened(true);
    }
  }, [activeTab]);

  const isTabletScreen = useMediaQuery('(max-width:865px)');
  // const isMobileScreen = useMediaQuery('(max-width:530px)');

  return (
    <>
      <div className={`${styles.toolkit} ${styles[theme]}`}>
        {!isTabletScreen ? (
          <HamburgerIcon
            onClick={toggleViewMenu}
            active={menuOpened && activeTab === 'none'}
          />
        ) : (
          <Stack direction="column" alignItems="center">
            <HamburgerIcon
              onClick={toggleViewMenu}
              active={menuOpened && activeTab === 'none'}
            />
            <MenuText>Menu</MenuText>
          </Stack>
        )}
        <div className={styles.toolkit_divider_wrapper}>
          <span />
        </div>
        {!isTabletScreen ? (
          <CircleIcon
            onClick={() => setNewPanel('frame')}
            active={activeTab === 'frame'}
          />
        ) : (
          <Stack direction="column" alignItems="center">
            <CircleIcon
              onClick={() => setNewPanel('frame')}
              active={activeTab === 'frame'}
            />
            <MenuText>Border</MenuText>
          </Stack>
        )}
        {!isTabletScreen ? (
          <RectangleIcon
            onClick={() => setNewPanel('background')}
            active={activeTab === 'background'}
          />
        ) : (
          <Stack direction="column" alignItems="center">
            <RectangleIcon
              onClick={() => setNewPanel('background')}
              active={activeTab === 'background'}
            />
            <MenuText>BG</MenuText>
          </Stack>
        )}
        {!isTabletScreen ? (
          <ForegroundIcon
            onClick={() => setNewPanel('overlay')}
            active={activeTab === 'overlay'}
          />
        ) : (
          <Stack direction="column" alignItems="center">
            <ForegroundIcon
              onClick={() => setNewPanel('overlay')}
              active={activeTab === 'overlay'}
            />
            <MenuText>Foreground</MenuText>
          </Stack>
        )}
        {!isTabletScreen ? (
          <TextIcon
            onClick={() => setNewPanel('text')}
            active={activeTab === 'text'}
          />
        ) : (
          <Stack direction="column" alignItems="center">
            <TextIcon
              onClick={() => setNewPanel('text')}
              active={activeTab === 'text'}
            />
            <MenuText>Text</MenuText>
          </Stack>
        )}
        {!isTabletScreen ? (
          <NotesIcon
            onClick={() => setNewPanel('notes')}
            active={activeTab === 'notes'}
          />
        ) : (
          <Stack direction="column" alignItems="center">
            <NotesIcon
              onClick={() => setNewPanel('notes')}
              active={activeTab === 'notes'}
            />
            <MenuText>Notes</MenuText>
          </Stack>
        )}
        <div className={styles.toolkit_divider_wrapper}>
          <span />
        </div>
        {!isTabletScreen ? (
          <LeftArrowIcon onClick={goToPast} />
        ) : (
          <Stack direction="column" alignItems="center">
            <LeftArrowIcon onClick={goToPast} />
            <MenuText>Undo</MenuText>
          </Stack>
        )}
        {!isTabletScreen ? (
          <RightArrowIcon onClick={gotToFuture} />
        ) : (
          <Stack direction="column" alignItems="center">
            <RightArrowIcon onClick={gotToFuture} />
            <MenuText>Redo</MenuText>
          </Stack>
        )}
        {/* <ClockArrowIcon
          onClick={handleHistory}
          active={historyOpened === true}
        /> */}
        <div className={styles.toolkit_divider_wrapper}>
          <span />
        </div>
        {!isTabletScreen ? (
          <FantasyIcon
            onClick={() => setNewPanel('aigenerate')}
            active={activeTab === 'aigenerate'}
          />
        ) : (
          <Stack direction="column" alignItems="center">
            <FantasyIcon
              onClick={() => setNewPanel('aigenerate')}
              active={activeTab === 'aigenerate'}
            />
            <MenuText>AI Gen</MenuText>
          </Stack>
        )}
        {!isTabletScreen ? (
          <RealmWorldIcon
            onClick={() => setNewPanel('library')}
            active={activeTab === 'library'}
          />
        ) : (
          <Stack direction="column" alignItems="center">
            <RealmWorldIcon
              onClick={() => setNewPanel('library')}
              active={activeTab === 'library'}
            />
            <MenuText>Library</MenuText>
          </Stack>
        )}
        {!isTabletScreen ? (
          <ShoppingCartIcon
            onClick={() => {
              if (localCartArray.length > 0) {
                appStore.setState({ openShoppingCartModal: true });
              } else {
                appStore.setState({ openEmptyCartModal: true });
              }
            }}
            active={activeTab === 'shoppingcart'}
          />
        ) : (
          <Stack direction="column" alignItems="center">
            <ShoppingCartIcon
              onClick={() => {
                if (localCartArray.length > 0) {
                  appStore.setState({ openShoppingCartModal: true });
                } else {
                  appStore.setState({ openEmptyCartModal: true });
                }
              }}
              active={activeTab === 'shoppingcart'}
            />
            <MenuText>Cart</MenuText>
          </Stack>
        )}
      </div>
      {menuOpened && activeTab === 'none' && (
        <Menu
          closeMenu={() => setMenuOpened(false)}
          // handleSave={() => handleSave}
          // handleSaveAs={() => handleSaveAs}
          handleStageClear={handleStageClear}
          // saveDisabled={saveDisabled}
          openMyCollection={() => setNewPanel('mycollection')}
          activeTab={activeTab}
        />
      )}
      <Box
        sx={{ display: 'flex', flexDirection: 'row', pointerEvents: 'none' }}
      >
        <Collapse
          className={styles.panel_collapse}
          in={opened}
          orientation="horizontal"
        >
          <PanelItem activeTab={activeTab} onStageChange={onStageChange} />
        </Collapse>
        {/* <Slide
          direction="right"
          in={opened}
          container={drawerRef.current}
          mountOnEnter
          unmountOnExit
        >
          <PanelItem activeTab={activeTab} />
        </Slide> */}
      </Box>
    </>
  );
}

export default Panels;
