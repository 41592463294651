import React from 'react';
import {
  Box, Button, Stack, Typography,
} from '@mui/material';
import {
  AttributeTextField, AttributeWrapper, FlexibleHeightImg, GenerateDiceImg, SmallHeadingText,
} from '../../styledComps/styledComps';
import FancyUnderline from '../../../images/fancy-underline-one.webp';
import CharacterFilterDropdown from '../../fixedFilter/filterDropDown/CharacterFilterDropdown';
import DiceIcon from '../../../images/dice.webp';
import RacePlaceholder from '../../../images/character-placeholder.webp';
import CharacterSectionWithHelp from './CharacterSectionWithHelp';
import useGraphQL from '../../../hooks/useGraphQL';
import {
  Class, Gender, GetClassesAttributesDocument, GetGendersAttributesDocument,
  GetPronounsDocument,
  GetRacesAttributesDocument,
} from '../../../__generated__/graphql';
import generateCharacterName from './utilities/GenerateCharacterName';

interface Props {
  characterName: string;
  setCharacterName: (char: string) => void;
  characterAge: number;
  setCharacterAge: (char: number) => void;
  characterPronouns: string;
  setCharacterPronouns: (char: string) => void;
  characterGender: Gender | null;
  setCharacterGender: (char: Gender) => void;
  characterRace: string;
  setCharacterRace: (char: string) => void;
  characterClass: Class | null;
  setCharacterClass: (char: Class) => void;
}

type Race = { name: string, _id: string, thumbnail_url: string };

const PronounOptions = [
  'She/Her',
  'He/Him',
  'Other',
  'They/Them',
];

// const GenderOptions = [
//   'Non-Binary',
//   'Agender',
//   'Other',
//   'Male',
//   'Female',
//   'Genderqueer',
//   'Genderfluid',
//   'Transgender',
//   'Bigender',
//   'Two-Spirit',
// ];

// const ClassOptions = [
//   'Paladin',
//   'Cleric',
//   'Ranger',
//   'Warrior',
//   'Bard',
//   'Mage',
//   'Rogue',
//   'Warlock',
//   'Druid',
//   'Monk',
// ];

const Races: Race[] = [
  {
    name: 'Dragonborn',
    _id: 'Dragonborn',
    thumbnail_url: RacePlaceholder,
  },
  {
    name: 'Dwarf',
    _id: 'Dwarf',
    thumbnail_url: RacePlaceholder,
  },
  {
    name: 'Elf',
    _id: 'Elf',
    thumbnail_url: RacePlaceholder,
  },
  {
    name: 'Aarokocra',
    _id: 'Aarokocra',
    thumbnail_url: RacePlaceholder,
  },
  {
    name: 'Gnome',
    _id: 'Gnome',
    thumbnail_url: RacePlaceholder,
  },
  {
    name: 'Halfling',
    _id: 'Halfling',
    thumbnail_url: RacePlaceholder,
  },
  {
    name: 'Half-Orc',
    _id: 'Half-Orc',
    thumbnail_url: RacePlaceholder,
  },
  {
    name: 'Human',
    _id: 'Human',
    thumbnail_url: RacePlaceholder,
  },
  {
    name: 'Tiefling',
    _id: 'Tiefling',
    thumbnail_url: RacePlaceholder,
  },
  {
    name: 'Goblin',
    _id: 'Goblin',
    thumbnail_url: RacePlaceholder,
  },
];

function CharacterRaceAndClass({
  characterName,
  setCharacterName,
  characterAge,
  setCharacterAge,
  characterPronouns,
  setCharacterPronouns,
  characterGender,
  setCharacterGender,
  characterRace,
  setCharacterRace,
  characterClass,
  setCharacterClass,
}: Props) {
  const { data: genders } = useGraphQL(
    GetGendersAttributesDocument,
    {},
  );
  console.log(genders?.genders);
  const { data: pronouns } = useGraphQL(
    GetPronounsDocument,
    {},
  );
  console.log(pronouns?.pronouns);
  const { data: classes } = useGraphQL(
    GetClassesAttributesDocument,
    {},
  );
  console.log(classes?.classes);
  const { data: races } = useGraphQL(
    GetRacesAttributesDocument,
    {},
  );
  console.log(races?.races);

  return (
    <Stack gap="33px">
      <Stack gap="21px">
        <Stack justifyContent="center" alignItems="center" gap="14px">
          <Typography
            sx={{
              color: '#250503',
              fontFamily: 'Passero One',
              fontSize: '36px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '50px',
              letterSpacing: '.1px',
            }}
          >
            Race & Class
          </Typography>
          <FlexibleHeightImg width="102px" src={FancyUnderline} />
        </Stack>
        <Stack flexDirection="row" gap="23px" flexWrap="wrap">
          <AttributeWrapper width="100%">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack width="92%">
                <SmallHeadingText>Name</SmallHeadingText>
                {/* <AttributeText>Enter Character Name</AttributeText> */}
                <AttributeTextField
                  placeholder="Enter Character Name"
                  value={characterName}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setCharacterName(event.target.value);
                  }}
                  fullWidth
                />
              </Stack>
              <Button
                sx={{
                  width: '33px',
                  minWidth: '0px',
                  height: '33px',
                  borderRadius: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: '#FFF',
                  '&:hover': {
                    background: '#FFF',
                  },
                }}
                onClick={() => setCharacterName(generateCharacterName())}
              >
                <GenerateDiceImg src={DiceIcon} />
              </Button>
            </Stack>
          </AttributeWrapper>
          <AttributeWrapper width="20%">
            <Box>
              <SmallHeadingText>Age</SmallHeadingText>
              <AttributeTextField
                placeholder="Insert Age"
                type="number"
                value={characterAge}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCharacterAge(parseInt(event.target.value, 10));
                }}
                fullWidth
                inputProps={{
                  min: 0,
                }}
              />
            </Box>
          </AttributeWrapper>
          <CharacterFilterDropdown
            width="calc(45% - 23px)"
            title="Pronouns"
            options={PronounOptions}
            value={characterPronouns}
            onChange={setCharacterPronouns}
          />
          <CharacterFilterDropdown
            width="calc(35% - 23px)"
            title="Gender"
            options={genders?.genders}
            value={characterGender?.name}
            onChange={setCharacterGender}
          />
        </Stack>
      </Stack>
      <Box>
        <CharacterSectionWithHelp
          title="Select a Race"
          message="lorem ipsum dolor"
        />
        <Stack direction="row" gap="15px" flexWrap="wrap">
          {Races.map((race) => (
            <Box
              key={race._id}
              onClick={() => setCharacterRace(race._id)}
              sx={{
                // boxSizing: 'border-box',
                width: '99px',
                height: '125px',
                padding: '2px',
                borderRadius: '5px',
                border: race._id === characterRace ? 'solid 5px #41521F' : 'none',
                boxShadow: race._id === characterRace ? '0px 4px 10px 0px #41521F' : 'none',
                cursor: 'pointer',
                background: 'white',
              }}
            >
              <img
                src={race.thumbnail_url}
                style={{
                  height: '95px',
                  width: '100%',
                  objectFit: 'cover',
                  borderRadius: '3px',
                }}
                alt={race.name}
              />
              <Typography
                sx={{
                  fontFamily: 'Passero One',
                  fontSize: '18px',
                  fontWeight: '400',
                  fontStyle: 'normal',
                  lineHeight: 'normal',
                  color: '#250503',
                  textAlign: 'center',
                }}
              >
                {race.name}
              </Typography>
            </Box>
          ))}
        </Stack>
      </Box>
      {/* Character Class */}
      <Box>
        <CharacterSectionWithHelp
          title="Select a Class"
          message="lorem ipsum dolor"
        />
        <CharacterFilterDropdown
          width="100%"
          title="Class"
          options={classes?.classes}
          value={characterClass?.name}
          onChange={setCharacterClass}
        />
      </Box>
    </Stack>
  );
}

export default CharacterRaceAndClass;
