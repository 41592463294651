/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoMdSettings } from 'react-icons/io';
import EmptyFavoriteStar from '../../assets/images/empty-star.png';
import FullFavoriteStar from '../../assets/images/full-star.png';
import errorPlaceholder from '../../assets/images/NewDashboard/image_realm.jpg';
import useGraphQL from '../../hooks/useGraphQL';
import s from './DetailedRealms.module.scss';
import { GetCharactersSetsDocument, GetTokenSetsDocument } from '../../__generated__/graphql';
import appStore, { RealmDefaultViewOptions } from '../../store/AppStore';

interface RealmProps {
  id: string;
  thumbnail_url: any;
  tags: any;
  realmName: string;
  isFavorited: boolean;
  description: any;
}

/**
 * A detailed representation of an individual realm with additional information.
 *
 * @component
 * @example
 * // Example usage of DetailedRealms component
 * <DetailedRealms
 *   id="exampleRealmId"
 *   thumbnail_url="exampleImageUrl"
 *   tags={['tag1', 'tag2', 'tag3']}
 *   realmName="Example Realm"
 *   isFavorited={false}
 *   description="A detailed description of the realm."
 * />
 *
 * @param {Object} props - The properties of the component.
 * @param {string} props.id - The unique identifier of the realm.
 * @param {string} props.thumbnail_url - The URL of the realm's thumbnail image.
 * @param {string[]} props.tags - An array of tags associated with the realm.
 * @param {string} props.realmName - The name of the realm.
 * @param {boolean} props.isFavorited - Indicates whether the realm is favorited.
 * @param {string} props.description - A detailed description of the realm.
 *
 * @returns {JSX.Element} React element representing a detailed realm.
 */

const DetailedRealms = ({
  id,
  thumbnail_url,
  tags,
  realmName,
  isFavorited,
  description,
}: RealmProps) => {
  const {
    data: tokens,
    error: tokenError,
    isLoading,
  } = useGraphQL(GetTokenSetsDocument, {
    query: {
      realm: {
        _id: id,
      },
    },
  });

  const {
    data: characterSets,
    error: characterError,
  } = useGraphQL(GetCharactersSetsDocument, {
    query: {
      _id: id,
    },
  });

  // console.log(id, tokens?.token_sets[0]?.tokens);

  const navigate = useNavigate();
  const [brokenImages, setBrokenImages] = useState<string[]>([]);

  const handleImageError = (realmId: string) => {
    setBrokenImages((prevBrokenImages) => [...prevBrokenImages, realmId]);
  };

  const hasTokens = tokens?.token_sets[0]?.tokens;
  const hasCharacters = characterSets?.character_sets[0]?.characters;

  const realmDefaultView: RealmDefaultViewOptions = appStore(
    (state) => state.realmDefaultView,
  );
  console.log(realmDefaultView);

  return (
    <div key={id} className={s.detail}>
      <div className={s.detail__background}>
        {brokenImages.includes(id) || !thumbnail_url ? (
          <img
            className={s.detail__background__image}
            src={errorPlaceholder}
            alt="This is a placeholder"
            onError={() => handleImageError(id)}
          />
        ) : (
          <img
            className={s.detail__background__image}
            src={thumbnail_url}
            alt="realm image"
            onError={() => handleImageError(id)}
          />
        )}
        <img
          src={isFavorited ? FullFavoriteStar : EmptyFavoriteStar}
          alt="favourite icon"
          className={s.detail__background__star}
        />
      </div>
      <div className={s.detail__info}>
        <p className={s.detail__info__heading}>{realmName}</p>
        <div className={s.detail__info__bottom}>
          {tags?.slice(0, 3)?.map((tag: string, index: number) => (
            <div
              key={index}
              className={
                tag?.split(' ').length === 1
                  ? s.detail__info__tag
                  : s.detail__info__longTag
              }
            >
              { tag.toLowerCase().substring(0, 12) }
              { tag.length > 12 ? '...' : '' }
            </div>
          ))}
        </div>
        <div className={s.detail__description}>
          { description.substring(0, 128) }
          { description.length > 128 ? '...' : '' }
        </div>
      </div>
      <div className={s.detail__tokenContainer}>
        {hasTokens ? (
          tokens?.token_sets[0]?.tokens
            ?.slice(-5)
            .map((token: any, index) => (
              <img
                key={index}
                src={token.thumbnail_url}
                alt="token"
                className={s.detail__tokenContainer__token}
              />
            ))
        ) : isLoading ? (
          <p className={s.detail__tokenContainer__text}>
            Loading tokens...please wait
          </p>
        ) : (
          <p className={s.detail__tokenContainer__text}>
            You have no tokens, Enter realm to create one.
          </p>
        )}
      </div>
      <div className={s.detail__tokenContainer}>
        {hasTokens ? (
          characterSets?.character_sets[0]?.characters
            ?.slice(-5)
            .map((character: any, index) => (
              <img
                key={index}
                src={character.thumbnail_url}
                alt="character"
                className={s.detail__tokenContainer__token}
              />
            ))
        ) : isLoading ? (
          <p className={s.detail__tokenContainer__text}>
            Loading characters...please wait
          </p>
        ) : (
          <p className={s.detail__tokenContainer__text}>
            You have no characters, Enter realm to create one.
          </p>
        )}
      </div>
      <div className={s.detail__actions}>
        <button
          type="button"
          className={s.detail__enterButton}
          onClick={() => navigate(`/realm/${id}/${realmDefaultView}`)}
        >
          Enter Realm
        </button>

        <div className={s.detail__settingsContainer}>
          <IoMdSettings />
        </div>
      </div>
    </div>
  );
};

export default DetailedRealms;
