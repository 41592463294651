/* eslint-disable object-curly-newline */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
/* eslint-disable max-len */
import * as React from 'react';
import { nanoid } from '@reduxjs/toolkit';
import Konva from 'konva';
import { Filter } from 'konva/lib/Node';
// import { InstantSearch } from 'react-instantsearch-hooks-web';
import {
  useRef,
  useMemo,
  useEffect,
  MutableRefObject,
  Fragment,
  forwardRef,
  useImperativeHandle,
  useContext,
} from 'react';
// import { Form } from 'react-router-dom';
import { Stage, Layer, Group } from 'react-konva';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import useItem from '../../hooks/useItem';
import { StageData } from '../../redux/currentStageData';
import ImageItem, { ImageItemProps } from '../konvaItem/ImageItem';
// import ExportImageModal from '../modals/ExportImageModal';
import TextItem, { TextItemProps } from '../konvaItem/TextItem';
import useTransformer from '../../hooks/useTransformer';
import { canvasHeight, canvasWidth } from '../CanvasConstants';
import { useAuth } from '../../hooks/use-auth';
import useToken from '../../hooks/useToken';
import ShowInToolModal from '../modals/ShowInToolModal';
import useModal from '../../hooks/useModal';
import { ThemeContext } from '../../context/ThemeContext';
import styles from './preview.module.scss';
import './previewmodifier.scss';
import {
  GetUserConfigDocument,
  GetRealmQuery,
} from '../../__generated__/graphql';
import useGraphQL from '../../hooks/useGraphQL';
import SaveTokenModal from '../modals/SaveTokenModal';
import SaveTokenForm from './saveToken/SaveTokenForm';
import CarretIcon from '../../assets/images/SVG/left-carrat.svg';
// import { IInsertOneTokenResult } from '../../__generated__/realmWorldsApiSchemas';

const WIDTH = 260;
const HEIGHT = 260;

export interface PreviewRef {
  savePreview: () => void;
  exportPreview: () => void;
  stage: Konva.Stage;
}

export interface PreviewProps {
  /* eslint-disable */
  onSave?: () => void;
  realm: GetRealmQuery['realm'];
}

export const Preview = forwardRef<PreviewRef, PreviewProps>((props, ref) => {
  const auth = useAuth();
  const [openSaveTokenModal, setOpenSaveTokenModal] = React.useState(false);
  const { modals, openModal, closeModal } = useModal();
  const { stageData } = useItem();
  const stageRef = useRef() as MutableRefObject<Konva.Stage>;

  const { updateToken } = useToken();
  const [ showPreview, setShowPreview ] = React.useState(false);

  const exportPreview = () => {
    const link = document.createElement('a');
    const stage = stageRef.current;
    const uri = stage.toDataURL({
      x: 0,
      y: 0,
      width: WIDTH,
      height: HEIGHT,
      pixelRatio: 1,
    });
    if (uri) {
      link.download = `realm-worlds-project1-${nanoid()}.png`;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const savePreview = () => {
    console.log('saving');
    const image = document.createElement('img');
    const stage = stageRef.current;
    stage.toBlob({
      x: 0,
      y: 0,
      width: WIDTH,
      height: HEIGHT,
      pixelRatio: 1,
      callback: (blob) => {
        image.src = stage.toDataURL({
          x: 0,
          y: 0,
          width: WIDTH,
          height: HEIGHT,
          pixelRatio: 1,
        });

        updateToken((prevData) => ({
          finalImageBlob: blob,
          finalImageSrc: image.src,
          edits: stageData,
          edited: prevData.data?.edited,
        }));
        openModal('export-modal');
      },
    });
  };

  const sortedStageData = useMemo(() => {
    if (stageData.length === 0) {
      return [];
    }

    let secondItem =
      stageData.find((x) => x.id === 'clip-mask') ??
      stageData.find((x) => x.id === 'border-background');

    const dataCopy = [...stageData];
    if (secondItem) {
      if (secondItem.id === 'border-background') {
        const attrs = {
          ...secondItem.attrs,
          filters: ['WhiteMask'],
          zIndex: 11,
          brightness: 0,
          opacity: 1.0,
        };
        secondItem = { ...secondItem, attrs, id: 'clip-mask' };
        dataCopy.push(secondItem);
      }
    }

    return dataCopy.sort((a, b) => {
      if (a.attrs.zIndex === b.attrs.zIndex) {
        if (a.attrs.zIndex < 0) {
          return b.attrs.updatedAt - a.attrs.updatedAt;
        }
        return a.attrs.updatedAt - b.attrs.updatedAt;
      }
      return a.attrs.zIndex - b.attrs.zIndex;
    });
  }, [stageData]);

  const tempTransfomer = useTransformer();

  const renderObject = (item: StageData) => {
    switch (item.attrs.dataItemType) {
      case 'image':
        return (
          <ImageItem
            key={`image-${item.id}`}
            data={item as ImageItemProps['data']}
            onDragEnd={() => {}}
            onSelect={() => {
              console.log('grabed');
            }}
          />
        );
      case 'text':
        return (
          <TextItem
            key={`image-${item.id}`}
            data={item as TextItemProps['data']}
            transformer={tempTransfomer}
            onDragEnd={() => {}}
            onSelect={() => {
              console.log('grabed');
            }}
          />
        );
      default:
        return null;
    }
  };

  const OpacityFilter: Filter = (imageData: ImageData) => {
    const nPixels = imageData.data.length;
    const threshold = 1;
    for (let i = 0; i < nPixels - 4; i += 4) {
      if (
        imageData.data[i] < threshold &&
        imageData.data[i + 1] < threshold &&
        imageData.data[i + 2] < threshold
      ) {
        imageData.data[i + 3] = 0;
      }
    }
  };

  const groupRef = useRef() as MutableRefObject<Konva.Group>;

  useEffect(() => {
    if (!groupRef.current) return;

    const interval = setInterval(() => {
      if (stageData.length === 0) {
        groupRef.current.clearCache();
        return;
      }
      groupRef.current.filters([OpacityFilter]);
      groupRef.current.cache();
    }, 100);

    return () => clearInterval(interval);
  }, [groupRef, stageData]);

  useEffect(() => {
    if (!stageRef.current || !stageRef.current.container().parentElement) {
      return;
    }

    stageRef.current.container().style.backgroundImage = `url(${process.env.PUBLIC_URL}/images/transparency_bg.png)`;
  }, [stageRef]);

  const {
    // data: getUserConfig,
    error: getUserConfigError,
    isLoading: getUserConfigIsLoading,
  } = useGraphQL(GetUserConfigDocument, {
    query: {
      _id: auth?.realmUser?.id,
    },
  });

  if (getUserConfigError)
    console.log('error getting user config: ', getUserConfigError);
  if (getUserConfigIsLoading) console.log('loading user config');

  /*   const onExportSave = (value: IInsertOneTokenResult | null) => {
    closeModal('export-modal');
    props.onSave?.();
    if (value && !getUserConfig?.user?.config?.showInToolByDefault) {
      openModal('show-in-tool-modal');
    }
  }; */

  const { theme } = useContext(ThemeContext);

  useImperativeHandle(ref, () => ({
    savePreview,
    exportPreview,
    stage: stageRef.current,
  }));

  const { realm } = props;

  const tokenSets = realm?.token_sets;
  useEffect(() => {
    console.log('this realm: ', realm);
    console.log('this tokenSets: ', tokenSets);
  }, []);

  const isTabletScreen = useMediaQuery('(max-width:865px)');
  // const isMobileScreen = useMediaQuery('(max-width:530px)');

  return (
    <>
      <Box sx={{ marginRight: showPreview && isTabletScreen ? 0 : !isTabletScreen ? 0 : '-313px'}}>
        <Box className={`${styles.preview_title_wrapper} ${styles[theme]}`}>
          <Typography
            className={styles.preview_title}
            align="center"
            fontSize={24}
          >
            Preview
          </Typography>
        </Box>
        {isTabletScreen && (
          <Box onClick={() => {setShowPreview(!showPreview)}} sx={{ position: 'absolute', top: '0', left: '-16px', width: '16px', height: '98px', borderRadius: '100px 0 0 100px', backgroundColor: '#181818', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <img src={CarretIcon} alt="carret" style={{ height: '15px', width: 'auto'}} />
          </Box>
        )}
        <Box sx={{ boxSizing: 'border-box', border: isTabletScreen ? 'solid 5px' : 'none' }}>
          <Stage ref={stageRef} width={WIDTH} height={HEIGHT}>
            <Layer listening={false} draggable={false}>
              <Group
                x= {-((canvasWidth - WIDTH * 2) / 2) * 0.5}
                y= {-((canvasHeight - HEIGHT * 2) / 2) * 0.5}
                scale={{ x: 0.5, y: 0.5 }}
              >
                <Group
                  // globalCompositeOperation={"destination-out"}
                  filters={[OpacityFilter]}
                  ref={groupRef}
                >
                  {stageData.length
                    ? sortedStageData.map((item) => renderObject(item))
                    : null}
                </Group>
                {stageData.length
                  ? renderObject(stageData[stageData.length - 1])
                  : null}
              </Group>
            </Layer>
          </Stage>
        </Box>
        <Button
          className={`${styles.preview_export_button} ${styles[theme]}`}
          sx={{ backgroundColor: '#181818', borderRadius: 0 }}
          fullWidth
          variant="contained"
          onClick={exportPreview}
        >
          {isTabletScreen ? 'PREVIEW IMAGE' : 'EXPORT IMAGE'}
        </Button>
        <Button
          className={`${styles.preview_save_button} ${styles[theme]}`}
          fullWidth
          variant="contained"
          // onClick={savePreview}
          onClick={() => setOpenSaveTokenModal(true)}
          // disabled={auth?.user == null}
        >
          Save Image
        </Button>
        {auth?.user && (
          <div>
            {/* <ExportImageModal
              open={!!modals['export-modal']}
              onClose={() => { closeModal('export-modal'); }}
              onConfirm={onExportSave}
            /> */}
            <ShowInToolModal
              open={!!modals['show-in-tool-modal']}
              onClose={() => {
                closeModal('show-in-tool-modal');
              }}
            />
          </div>
        )}
        <SaveTokenModal
          open={openSaveTokenModal}
          onClose={() => {}}
          onConfirm={() => {}}
          setOpen={setOpenSaveTokenModal}
        >
          <SaveTokenForm
            realm={realm}
            currentStage={stageRef.current}
            closeSaveTokenModal={() => setOpenSaveTokenModal(false)}
          />
        </SaveTokenModal>
      </Box>
    </>
  );
});

Preview.defaultProps = {
  onSave: () => {},
  realm: {
    __typename: undefined,
    _id: '',
    name: '',
    description: '',
    tags: undefined,
    thumbnail_url: undefined,
    is_favorited: false,
    is_public: false,
  },
};
