import React, { useContext, SVGProps } from 'react';
import { Tooltip, Zoom } from '@mui/material';
import { ThemeContext } from '../../context/ThemeContext';
import styles from './icons.module.scss';

interface Props {
  onClick: () => void;
  active: boolean;
}

function CircleIcon({ onClick, active } : Props) {
  const { theme } = useContext(ThemeContext);

  const circleProps: SVGProps<SVGCircleElement> = {
    cx: 24,
    cy: 24,
    r: 14,
  };

  return (
    <Tooltip
      title="Border"
      arrow
      placement="bottom"
      TransitionComponent={Zoom}
      componentsProps={{
        tooltip: {
          sx: {
            fontFamily: 'Passero One',
            fontWeight: '400px',
            fontSize: '16px',
            padding: '10px',
            bgcolor: '#3c3c3ce6',
            '& .MuiTooltip-arrow': {
              color: '#3c3c3ce6',
            },
            maxWidth: '250px',
          },
        },
      }}
    >
      <button
        onClick={onClick}
        type="button"
        className={`${styles.icon} ${styles.circle_icon} ${styles[theme]} ${active && styles.active}`}
        aria-label="circle icon"
      >
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 48 48"
        >
          <circle {...circleProps} className={styles.circle} />
        </svg>
      </button>
    </Tooltip>
  );
}

export default CircleIcon;
