import React, { useContext } from 'react';
import { Tooltip, Zoom } from '@mui/material';
import { ThemeContext } from '../../context/ThemeContext';
import styles from './icons.module.scss';

interface Props {
  onClick: () => void;
  active: boolean;
}

function CreateIcon({ onClick, active } : Props) {
  const { theme } = useContext(ThemeContext);
  return (
    <Tooltip
      title="Character Creator"
      arrow
      placement="bottom"
      TransitionComponent={Zoom}
      componentsProps={{
        tooltip: {
          sx: {
            fontFamily: 'Passero One',
            fontWeight: '400px',
            fontSize: '16px',
            padding: '10px',
            bgcolor: '#3c3c3ce6',
            '& .MuiTooltip-arrow': {
              color: '#3c3c3ce6',
            },
            maxWidth: '250px',
          },
        },
      }}
    >
      <button
        onClick={onClick}
        type="button"
        className={`${styles.icon} ${styles.create_icon} ${styles[theme]} ${active && styles.active}`}
        aria-label="create"
      >
        <svg
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 25 25"
        >
          <rect className={styles.create_cls_1} width="25" height="25" />
          <path className={styles.create_cls_2} d="m21.74,9.35v10.44c0,1.08-.87,1.95-1.95,1.95H5.18c-1.08,0-1.95-.87-1.95-1.95V5.18c0-1.08.87-1.95,1.95-1.95h13.85l-5.01,5.01H5.01v11.71h14.94v-8.82l1.78-1.78Z" />
          <polygon className={styles.create_cls_2} points="22.96 5.76 14.12 14.61 11.73 14.94 12.06 12.55 20.91 3.71 22.96 5.76" />
          <path className={styles.create_cls_2} d="m24.53,4.01l-.53.53-2.05-2.05.53-.53c.55-.55,1.46-.54,2.03.02h0c.57.57.58,1.48.02,2.03Z" />
        </svg>
      </button>
    </Tooltip>
  );
}

export default CreateIcon;
