/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import styled from '@emotion/styled';
import CharacterStartBGImg from '../../images/realm-default-view-background.webp';
import TokenMakerBGImg from '../../images/token-maker-card-background.webp';
import CharacterCatalogBGImg from '../../images/character-catalog-card-background.webp';
import RealmAdventureBGImg from '../../images/realm-adventures-card-background.webp';
import {
  CraftStartOptionWrapper,
  FlexibleHeightImg,
} from '../styledComps/styledComps';
import DecorativeLineImage from '../../images/decorative-line.webp';
import GladiatorImage from '../../images/gladiator.webp';
import FantasyImage from '../../images/fantasy-pink.webp';
import TokenStoreImage from '../../images/token-store.webp';
import ManageCharacterImage from '../../images/manage-character.webp';
import CartImage from '../../images/cart-2.webp';
import appStore, { RealmDefaultViewOptions } from '../../store/AppStore';

const PasseroFont = styled(Typography)({
  fontFamily: 'Passero One',
  fontWeight: '400',
  '&.title': {
    color: 'white',
    textAlign: 'center',
    fontSize: '20px',
    lineHeight: 'normal',
  },
  '&.buttonText': {
    color: '#250503',
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    textTransform: 'none',
  },
});

const BodyCopyText = styled(Typography)({
  fontFamily: 'DM Sans',
  fontWeight: '400',
  color: 'white',
  fontSize: '12px',
  lineHeight: 'normal',
});

const OptionButton = styled(Button)({
  borderRadius: '5px',
  display: 'block',
  border: 'none',
  outline: 'none',
  padding: '10px 24px',
  cursor: 'pointer',
  color: '#250503',
});

const BodyStack = styled(Stack)({
  flexDirection: 'row',
  gap: '17px',
  width: '238px',
  mx: 'auto',
});

const BodyStackImg = styled('img')({
  height: '37px',
  width: 'auto',
});

function RealmDefaultView() {
  const [hideCraftStart, setHideCraftStart] = React.useState(false);
  const setRealmDefaultView = (view: RealmDefaultViewOptions) =>
    appStore.setState({
      realmDefaultView: view,
    });
  // const setDefaultCreateCharacterView = (view: CreateCharacterViewOptions) => appStore.setState({
  //   defaultCreateCharacterView: view,
  // });
  const handleHideRealmDefault = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setHideCraftStart(event.target.checked);
  };
  // const handleGoToView = (view: CreateCharacterViewOptions) => {
  //   setCreateCharacterView(view);
  //   if (hideCraftStart) setDefaultCreateCharacterView(view);
  // };
  const navigate = useNavigate();

  const isTabletScreen = useMediaQuery('(max-width:865px)');

  return (
    <Stack
      sx={{
        minHeight: '100%',
        background: `url(${CharacterStartBGImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        boxSizing: 'border-box',
      }}
      gap="30px"
      pt="100px"
    >
      {/* Title */}
      <Stack gap="10px" justifyContent="center" alignItems="center">
        <Typography
          sx={{
            color: '#FFF',
            textAlign: 'center',
            fontFamily: 'Passero One',
            fontSize: '57px',
            fontStyle: 'normal',
            textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            fontWeight: 400,
            lineHeight: '64px',
            letterSpacing: '-.25px',
          }}
        >
          Start Your Journey
        </Typography>
        <FlexibleHeightImg width="102px" src={DecorativeLineImage} />
      </Stack>

      {/* options */}
      <Stack
        direction={isTabletScreen ? 'column' : 'row'}
        gap="50px"
        justifyContent="center"
        margin="0 auto"
      >
        <CraftStartOptionWrapper
          width="350px"
          height="557px"
          pb="50px"
          className="realm-default"
          sx={{
            background: `url(${TokenMakerBGImg})!important`,
          }}
        >
          <Stack
            gap="14px"
            position="relative"
            alignItems="center"
            justifyContent="center"
            zIndex={10}
          >
            <PasseroFont className="title" color="white">
              Your Gateway to Character Tokens
            </PasseroFont>
            <Stack gap="15px">
              <BodyStack>
                <BodyStackImg src={GladiatorImage} />
                <BodyCopyText>
                  Customize your heroes and villains with a click.
                </BodyCopyText>
              </BodyStack>
              <BodyStack>
                <BodyStackImg src={FantasyImage} />
                <BodyCopyText>
                  Detailed AI Image Generator to capture your character vision.
                </BodyCopyText>
              </BodyStack>
              <BodyStack>
                <BodyStackImg src={TokenStoreImage} />
                <BodyCopyText>
                  Acquire all the assets you need in the RW Token Store.
                </BodyCopyText>
              </BodyStack>
            </Stack>
            <OptionButton
              sx={{
                background: 'white',
                mt: '10px',
                '&:hover': {
                  background: 'white',
                },
              }}
              onClick={() => {
                if (hideCraftStart) setRealmDefaultView('tokenmaker');
                navigate('../tokenmaker');
              }}
            >
              <PasseroFont className="buttonText">Token Maker</PasseroFont>
            </OptionButton>
          </Stack>
        </CraftStartOptionWrapper>
        <CraftStartOptionWrapper
          width="350px"
          height="557px"
          pb="50px"
          className="realm-default"
          sx={{
            background: `url(${CharacterCatalogBGImg})!important`,
          }}
        >
          <Stack
            gap="14px"
            position="relative"
            alignItems="center"
            justifyContent="center"
            zIndex={10}
          >
            <PasseroFont className="title" color="white">
              Craft & Control Your D&D Legends
            </PasseroFont>
            <Stack gap="15px">
              <BodyStack>
                <BodyStackImg src={ManageCharacterImage} />
                <BodyCopyText>
                  Comprehensive list of characters to manage & organize.
                </BodyCopyText>
              </BodyStack>
              <BodyStack>
                <BodyStackImg src={FantasyImage} />
                <BodyCopyText>
                  Detailed AI Image Generator to capture your character vision.
                </BodyCopyText>
              </BodyStack>
              <BodyStack>
                <BodyStackImg src={CartImage} />
                <BodyCopyText>
                  Acquire all the assets you need in the RW Character Store.
                </BodyCopyText>
              </BodyStack>
            </Stack>
            <OptionButton
              sx={{
                background: 'white',
                mt: '10px',
                '&:hover': {
                  background: 'white',
                },
              }}
              onClick={() => {
                if (hideCraftStart) setRealmDefaultView('character');
                navigate('../character');
              }}
            >
              <PasseroFont className="buttonText">Character Module</PasseroFont>
            </OptionButton>
          </Stack>
        </CraftStartOptionWrapper>
        <CraftStartOptionWrapper
          width="350px"
          height="557px"
          pb="50px"
          className="realm-default"
          sx={{
            background: `url(${RealmAdventureBGImg})!important`,
          }}
        >
          <Stack
            gap="14px"
            position="relative"
            alignItems="center"
            justifyContent="center"
            zIndex={10}
          >
            <PasseroFont className="title" color="white">
              Your Realm, Your Adventures
            </PasseroFont>
            <BodyCopyText textAlign="center">
              Craft and oversee D&D campaigns effortlessly.
              <br />
              Mold your worlds, weave your stories, and guide heroes on their
              epic journeys!
            </BodyCopyText>
            <OptionButton
              sx={{
                background: 'white',
                mt: '10px',
                '&:hover': {
                  background: 'white',
                },
              }}
              onClick={() => {
                if (hideCraftStart) setRealmDefaultView('campaign');
                navigate('../campaign');
              }}
            >
              <PasseroFont className="buttonText">Campaigns</PasseroFont>
            </OptionButton>
          </Stack>
        </CraftStartOptionWrapper>
      </Stack>

      {/* Don't show this again */}
      <Stack justifyContent="center" alignItems="center">
        <FormControlLabel
          control={
            <Checkbox
              inputProps={{ 'aria-label': 'Do not show create start again' }}
              onChange={handleHideRealmDefault}
              sx={{
                color: '#E9DCC3',
                boxSizing: 'border-box',
                borderRadius: '4px',
                '&.Mui-checked': {
                  color: '#41521F',
                },
              }}
            />
          }
          label="Do not show this again."
          sx={{
            '.MuiTypography-root': {
              color: '#FFF',
              fontFamily: 'DM Sans',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '24px',
              letterSpacing: '.5px',
            },
          }}
        />
      </Stack>
    </Stack>
  );
}

export default RealmDefaultView;
