/**
 * Generated by @openapi-codegen
 *
 * @version v1
 */
import * as reactQuery from "@tanstack/react-query";
import {
  useRealmWorldsApiContext,
  RealmWorldsApiContext,
} from "./realmWorldsApiContext";
import type * as Fetcher from "./realmWorldsApiFetcher";
import { realmWorldsApiFetch } from "./realmWorldsApiFetcher";
import type * as Schemas from "./realmWorldsApiSchemas";

export type DownloadCharacterPathParams = {
  characterId: string;
};

export type DownloadCharacterError = Fetcher.ErrorWrapper<undefined>;

export type DownloadCharacterVariables = {
  pathParams: DownloadCharacterPathParams;
} & RealmWorldsApiContext["fetcherOptions"];

export const fetchDownloadCharacter = (
  variables: DownloadCharacterVariables,
  signal?: AbortSignal
) =>
  realmWorldsApiFetch<
    Schemas.CharacterDownloadResponse,
    DownloadCharacterError,
    undefined,
    {},
    {},
    DownloadCharacterPathParams
  >({
    url: "/Characters/{characterId}/Download",
    method: "get",
    ...variables,
    signal,
  });

export const useDownloadCharacter = <TData = Schemas.CharacterDownloadResponse>(
  variables: DownloadCharacterVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.CharacterDownloadResponse,
      DownloadCharacterError,
      TData
    >,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useRealmWorldsApiContext(options);
  return reactQuery.useQuery<
    Schemas.CharacterDownloadResponse,
    DownloadCharacterError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/Characters/{characterId}/Download",
      operationId: "downloadCharacter",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchDownloadCharacter({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type CreateCharacterFormDataPathParams = {
  characterId: string;
};

export type CreateCharacterFormDataError = Fetcher.ErrorWrapper<undefined>;

export type CreateCharacterFormDataRequestBody = {
  /**
   * @format binary
   */
  AvatarImage?: Blob;
  /**
   * @format binary
   */
  CharacterImage?: Blob;
};

export type CreateCharacterFormDataVariables = {
  body?: CreateCharacterFormDataRequestBody;
  pathParams: CreateCharacterFormDataPathParams;
} & RealmWorldsApiContext["fetcherOptions"];

export const fetchCreateCharacterFormData = (
  variables: CreateCharacterFormDataVariables,
  signal?: AbortSignal
) =>
  realmWorldsApiFetch<
    Schemas.IGetCharacterResult,
    CreateCharacterFormDataError,
    CreateCharacterFormDataRequestBody,
    {},
    {},
    CreateCharacterFormDataPathParams
  >({
    url: "/Characters/{characterId}/Images",
    method: "post",
    ...variables,
    signal,
  });

export const useCreateCharacterFormData = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.IGetCharacterResult,
      CreateCharacterFormDataError,
      CreateCharacterFormDataVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useRealmWorldsApiContext();
  return reactQuery.useMutation<
    Schemas.IGetCharacterResult,
    CreateCharacterFormDataError,
    CreateCharacterFormDataVariables
  >({
    mutationFn: (variables: CreateCharacterFormDataVariables) =>
      fetchCreateCharacterFormData({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CreateCharacterSetFormDataPathParams = {
  characterSetId: string;
};

export type CreateCharacterSetFormDataError = Fetcher.ErrorWrapper<undefined>;

export type CreateCharacterSetFormDataRequestBody = {
  /**
   * @format binary
   */
  BackgroundImage?: Blob;
  /**
   * @format binary
   */
  OriginalImage?: Blob;
};

export type CreateCharacterSetFormDataVariables = {
  body?: CreateCharacterSetFormDataRequestBody;
  pathParams: CreateCharacterSetFormDataPathParams;
} & RealmWorldsApiContext["fetcherOptions"];

export const fetchCreateCharacterSetFormData = (
  variables: CreateCharacterSetFormDataVariables,
  signal?: AbortSignal
) =>
  realmWorldsApiFetch<
    Schemas.IUpdateCharacterSetMutation,
    CreateCharacterSetFormDataError,
    CreateCharacterSetFormDataRequestBody,
    {},
    {},
    CreateCharacterSetFormDataPathParams
  >({
    url: "/CharacterSet/{characterSetId}/Images",
    method: "post",
    ...variables,
    signal,
  });

export const useCreateCharacterSetFormData = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.IUpdateCharacterSetMutation,
      CreateCharacterSetFormDataError,
      CreateCharacterSetFormDataVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useRealmWorldsApiContext();
  return reactQuery.useMutation<
    Schemas.IUpdateCharacterSetMutation,
    CreateCharacterSetFormDataError,
    CreateCharacterSetFormDataVariables
  >({
    mutationFn: (variables: CreateCharacterSetFormDataVariables) =>
      fetchCreateCharacterSetFormData({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetWorkflowPathParams = {
  /**
   * @format int32
   */
  workflowId: number;
};

export type GetWorkflowError = Fetcher.ErrorWrapper<undefined>;

export type GetWorkflowVariables = {
  pathParams: GetWorkflowPathParams;
} & RealmWorldsApiContext["fetcherOptions"];

export const fetchGetWorkflow = (
  variables: GetWorkflowVariables,
  signal?: AbortSignal
) =>
  realmWorldsApiFetch<
    Schemas.RWUserWorkflowResponse,
    GetWorkflowError,
    undefined,
    {},
    {},
    GetWorkflowPathParams
  >({
    url: "/Generate/Workflow/{workflowId}",
    method: "get",
    ...variables,
    signal,
  });

export const useGetWorkflow = <TData = Schemas.RWUserWorkflowResponse>(
  variables: GetWorkflowVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.RWUserWorkflowResponse,
      GetWorkflowError,
      TData
    >,
    "queryKey" | "queryFn"
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } =
    useRealmWorldsApiContext(options);
  return reactQuery.useQuery<
    Schemas.RWUserWorkflowResponse,
    GetWorkflowError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/Generate/Workflow/{workflowId}",
      operationId: "getWorkflow",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetWorkflow({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GenerationNewImagesError = Fetcher.ErrorWrapper<undefined>;

export type GenerationNewImagesVariables = {
  body?: Schemas.ImageGenerationWorkflowRequest;
} & RealmWorldsApiContext["fetcherOptions"];

export const fetchGenerationNewImages = (
  variables: GenerationNewImagesVariables,
  signal?: AbortSignal
) =>
  realmWorldsApiFetch<
    Schemas.ImageGenerationWorkflowResponse,
    GenerationNewImagesError,
    Schemas.ImageGenerationWorkflowRequest,
    {},
    {},
    {}
  >({ url: "/Generate/Images", method: "post", ...variables, signal });

export const useGenerationNewImages = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ImageGenerationWorkflowResponse,
      GenerationNewImagesError,
      GenerationNewImagesVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useRealmWorldsApiContext();
  return reactQuery.useMutation<
    Schemas.ImageGenerationWorkflowResponse,
    GenerationNewImagesError,
    GenerationNewImagesVariables
  >({
    mutationFn: (variables: GenerationNewImagesVariables) =>
      fetchGenerationNewImages({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GenerateNewCharacterTextError = Fetcher.ErrorWrapper<undefined>;

export type GenerateNewCharacterTextVariables = {
  body?: Schemas.CharacterTextGenerationRequest;
} & RealmWorldsApiContext["fetcherOptions"];

export const fetchGenerateNewCharacterText = (
  variables: GenerateNewCharacterTextVariables,
  signal?: AbortSignal
) =>
  realmWorldsApiFetch<
    Schemas.CharacterGenerationWorkflowRequest,
    GenerateNewCharacterTextError,
    Schemas.CharacterTextGenerationRequest,
    {},
    {},
    {}
  >({ url: "/Generate/Character/Text", method: "post", ...variables, signal });

export const useGenerateNewCharacterText = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.CharacterGenerationWorkflowRequest,
      GenerateNewCharacterTextError,
      GenerateNewCharacterTextVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useRealmWorldsApiContext();
  return reactQuery.useMutation<
    Schemas.CharacterGenerationWorkflowRequest,
    GenerateNewCharacterTextError,
    GenerateNewCharacterTextVariables
  >({
    mutationFn: (variables: GenerateNewCharacterTextVariables) =>
      fetchGenerateNewCharacterText({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GenerateNewCharacterResultError = Fetcher.ErrorWrapper<undefined>;

export type GenerateNewCharacterResultVariables = {
  body?: Schemas.CharacterResultGenerationRequest;
} & RealmWorldsApiContext["fetcherOptions"];

export const fetchGenerateNewCharacterResult = (
  variables: GenerateNewCharacterResultVariables,
  signal?: AbortSignal
) =>
  realmWorldsApiFetch<
    Schemas.CharacterResult,
    GenerateNewCharacterResultError,
    Schemas.CharacterResultGenerationRequest,
    {},
    {},
    {}
  >({
    url: "/Generate/Character/Result",
    method: "post",
    ...variables,
    signal,
  });

export const useGenerateNewCharacterResult = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.CharacterResult,
      GenerateNewCharacterResultError,
      GenerateNewCharacterResultVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useRealmWorldsApiContext();
  return reactQuery.useMutation<
    Schemas.CharacterResult,
    GenerateNewCharacterResultError,
    GenerateNewCharacterResultVariables
  >({
    mutationFn: (variables: GenerateNewCharacterResultVariables) =>
      fetchGenerateNewCharacterResult({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CreateRealmTokenFormDataPathParams = {
  realmId: string;
};

export type CreateRealmTokenFormDataError = Fetcher.ErrorWrapper<undefined>;

export type CreateRealmTokenFormDataRequestBody = {
  /**
   * Custom Token Name
   */
  TokenName?: string;
  /**
   * Custom Token Description
   */
  TokenDescription?: string;
  /**
   * Custom Token Edits that created the Token
   */
  TokenEdits?: Schemas.TokenEdits[];
  /**
   * Orignal Image that was uploaded to create the token
   *
   * @format binary
   */
  OriginalImage?: Blob;
  /**
   * Final Image that is the Token
   *
   * @format binary
   */
  Token?: Blob;
  /**
   * Is a Public Realm
   */
  IsPublic?: boolean;
  /**
   * Is the Realm Visible in the Tool
   */
  IsVisibleInTool?: boolean;
  /**
   * Is the Realm Visible in the Tool
   */
  IsFavorited?: boolean;
  /**
   * List of Notes for a Token
   */
  Notes?: Schemas.Note[];
  /**
   * Token Set ID
   */
  TokenSetId?: string;
};

export type CreateRealmTokenFormDataVariables = {
  body?: CreateRealmTokenFormDataRequestBody;
  pathParams: CreateRealmTokenFormDataPathParams;
} & RealmWorldsApiContext["fetcherOptions"];

export const fetchCreateRealmTokenFormData = (
  variables: CreateRealmTokenFormDataVariables,
  signal?: AbortSignal
) =>
  realmWorldsApiFetch<
    Schemas.IInsertOneTokenResult,
    CreateRealmTokenFormDataError,
    CreateRealmTokenFormDataRequestBody,
    {},
    {},
    CreateRealmTokenFormDataPathParams
  >({ url: "/Realms/{realmId}/Tokens", method: "post", ...variables, signal });

export const useCreateRealmTokenFormData = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.IInsertOneTokenResult,
      CreateRealmTokenFormDataError,
      CreateRealmTokenFormDataVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useRealmWorldsApiContext();
  return reactQuery.useMutation<
    Schemas.IInsertOneTokenResult,
    CreateRealmTokenFormDataError,
    CreateRealmTokenFormDataVariables
  >({
    mutationFn: (variables: CreateRealmTokenFormDataVariables) =>
      fetchCreateRealmTokenFormData({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdateRealmTokenFormDataPathParams = {
  realmId: string;
  tokenId: string;
};

export type UpdateRealmTokenFormDataError = Fetcher.ErrorWrapper<undefined>;

export type UpdateRealmTokenFormDataRequestBody = {
  /**
   * Custom Token Name
   */
  TokenName?: string;
  /**
   * Custom Token Description
   */
  TokenDescription?: string;
  /**
   * Custom Token Edits that created the Token
   */
  TokenEdits?: Schemas.TokenEdits[];
  /**
   * Orignal Image that was uploaded to create the token
   *
   * @format binary
   */
  OriginalImage?: Blob;
  /**
   * Final Image that is the Token
   *
   * @format binary
   */
  Token?: Blob;
  /**
   * Is a Public Realm
   */
  IsPublic?: boolean;
  /**
   * Is the Realm Visible in the Tool
   */
  IsVisibleInTool?: boolean;
  /**
   * Is the Realm Visible in the Tool
   */
  IsFavorited?: boolean;
  /**
   * List of Notes for a Token
   */
  Notes?: Schemas.Note[];
  /**
   * Token Set ID
   */
  TokenSetId?: string;
};

export type UpdateRealmTokenFormDataVariables = {
  body?: UpdateRealmTokenFormDataRequestBody;
  pathParams: UpdateRealmTokenFormDataPathParams;
} & RealmWorldsApiContext["fetcherOptions"];

export const fetchUpdateRealmTokenFormData = (
  variables: UpdateRealmTokenFormDataVariables,
  signal?: AbortSignal
) =>
  realmWorldsApiFetch<
    Schemas.IUpdateOneTokenResult,
    UpdateRealmTokenFormDataError,
    UpdateRealmTokenFormDataRequestBody,
    {},
    {},
    UpdateRealmTokenFormDataPathParams
  >({
    url: "/Realms/{realmId}/Tokens/{tokenId}",
    method: "put",
    ...variables,
    signal,
  });

export const useUpdateRealmTokenFormData = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.IUpdateOneTokenResult,
      UpdateRealmTokenFormDataError,
      UpdateRealmTokenFormDataVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useRealmWorldsApiContext();
  return reactQuery.useMutation<
    Schemas.IUpdateOneTokenResult,
    UpdateRealmTokenFormDataError,
    UpdateRealmTokenFormDataVariables
  >({
    mutationFn: (variables: UpdateRealmTokenFormDataVariables) =>
      fetchUpdateRealmTokenFormData({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CreateRealmMediaAssetFormDataPathParams = {
  realmId: string;
};

export type CreateRealmMediaAssetFormDataError =
  Fetcher.ErrorWrapper<undefined>;

export type CreateRealmMediaAssetFormDataRequestBody = {
  /**
   * Asset Name
   */
  Name?: string;
  /**
   * Asset Category (UserLibrary, RWLibrary, Public)
   */
  AssetCategoryType?: string;
  /**
   * Asset Type (Image, Video, Audio, Document)
   */
  AssetType?: string;
  /**
   * Data Asset to be uploaded
   *
   * @format binary
   */
  Data?: Blob;
  /**
   * Is a Public Realm
   */
  Tags?: string[];
};

export type CreateRealmMediaAssetFormDataVariables = {
  body?: CreateRealmMediaAssetFormDataRequestBody;
  pathParams: CreateRealmMediaAssetFormDataPathParams;
} & RealmWorldsApiContext["fetcherOptions"];

export const fetchCreateRealmMediaAssetFormData = (
  variables: CreateRealmMediaAssetFormDataVariables,
  signal?: AbortSignal
) =>
  realmWorldsApiFetch<
    Schemas.IInsertOneTokenResult,
    CreateRealmMediaAssetFormDataError,
    CreateRealmMediaAssetFormDataRequestBody,
    {},
    {},
    CreateRealmMediaAssetFormDataPathParams
  >({
    url: "/Realms/{realmId}/MediaAsset",
    method: "post",
    ...variables,
    signal,
  });

export const useCreateRealmMediaAssetFormData = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.IInsertOneTokenResult,
      CreateRealmMediaAssetFormDataError,
      CreateRealmMediaAssetFormDataVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useRealmWorldsApiContext();
  return reactQuery.useMutation<
    Schemas.IInsertOneTokenResult,
    CreateRealmMediaAssetFormDataError,
    CreateRealmMediaAssetFormDataVariables
  >({
    mutationFn: (variables: CreateRealmMediaAssetFormDataVariables) =>
      fetchCreateRealmMediaAssetFormData({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type StripeSubscriptionWebhookError = Fetcher.ErrorWrapper<undefined>;

export type StripeSubscriptionWebhookVariables =
  RealmWorldsApiContext["fetcherOptions"];

export const fetchStripeSubscriptionWebhook = (
  variables: StripeSubscriptionWebhookVariables,
  signal?: AbortSignal
) =>
  realmWorldsApiFetch<
    undefined,
    StripeSubscriptionWebhookError,
    undefined,
    {},
    {},
    {}
  >({
    url: "/Stripe/Subscription/Event",
    method: "post",
    ...variables,
    signal,
  });

export const useStripeSubscriptionWebhook = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      StripeSubscriptionWebhookError,
      StripeSubscriptionWebhookVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useRealmWorldsApiContext();
  return reactQuery.useMutation<
    undefined,
    StripeSubscriptionWebhookError,
    StripeSubscriptionWebhookVariables
  >({
    mutationFn: (variables: StripeSubscriptionWebhookVariables) =>
      fetchStripeSubscriptionWebhook({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CreateRealmWorldsUserError = Fetcher.ErrorWrapper<undefined>;

export type CreateRealmWorldsUserVariables = {
  body?: Schemas.RealmWorldsNewUserRequest;
} & RealmWorldsApiContext["fetcherOptions"];

export const fetchCreateRealmWorldsUser = (
  variables: CreateRealmWorldsUserVariables,
  signal?: AbortSignal
) =>
  realmWorldsApiFetch<
    Schemas.RealmWorldsUserResponse,
    CreateRealmWorldsUserError,
    Schemas.RealmWorldsNewUserRequest,
    {},
    {},
    {}
  >({ url: "/Users/Signup", method: "post", ...variables, signal });

export const useCreateRealmWorldsUser = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.RealmWorldsUserResponse,
      CreateRealmWorldsUserError,
      CreateRealmWorldsUserVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useRealmWorldsApiContext();
  return reactQuery.useMutation<
    Schemas.RealmWorldsUserResponse,
    CreateRealmWorldsUserError,
    CreateRealmWorldsUserVariables
  >({
    mutationFn: (variables: CreateRealmWorldsUserVariables) =>
      fetchCreateRealmWorldsUser({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpgradeRealmWorldUserSubscriptionError =
  Fetcher.ErrorWrapper<undefined>;

export type UpgradeRealmWorldUserSubscriptionVariables = {
  body?: Schemas.RealmWorldsUserSubscriptionUpgradeRequest;
} & RealmWorldsApiContext["fetcherOptions"];

export const fetchUpgradeRealmWorldUserSubscription = (
  variables: UpgradeRealmWorldUserSubscriptionVariables,
  signal?: AbortSignal
) =>
  realmWorldsApiFetch<
    Schemas.SmartAISSGPAdminUserSubscription,
    UpgradeRealmWorldUserSubscriptionError,
    Schemas.RealmWorldsUserSubscriptionUpgradeRequest,
    {},
    {},
    {}
  >({
    url: "/Users/Subscription/Upgrade",
    method: "put",
    ...variables,
    signal,
  });

export const useUpgradeRealmWorldUserSubscription = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.SmartAISSGPAdminUserSubscription,
      UpgradeRealmWorldUserSubscriptionError,
      UpgradeRealmWorldUserSubscriptionVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useRealmWorldsApiContext();
  return reactQuery.useMutation<
    Schemas.SmartAISSGPAdminUserSubscription,
    UpgradeRealmWorldUserSubscriptionError,
    UpgradeRealmWorldUserSubscriptionVariables
  >({
    mutationFn: (variables: UpgradeRealmWorldUserSubscriptionVariables) =>
      fetchUpgradeRealmWorldUserSubscription({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type UpdateUserProfilePathParams = {
  userId: string;
};

export type UpdateUserProfileError = Fetcher.ErrorWrapper<undefined>;

export type UpdateUserProfileRequestBody = {
  /**
   * Profile Image
   *
   * @format binary
   */
  ProfileImage?: Blob;
};

export type UpdateUserProfileVariables = {
  body?: UpdateUserProfileRequestBody;
  pathParams: UpdateUserProfilePathParams;
} & RealmWorldsApiContext["fetcherOptions"];

export const fetchUpdateUserProfile = (
  variables: UpdateUserProfileVariables,
  signal?: AbortSignal
) =>
  realmWorldsApiFetch<
    undefined,
    UpdateUserProfileError,
    UpdateUserProfileRequestBody,
    {},
    {},
    UpdateUserProfilePathParams
  >({
    url: "/Users/{userId}/Profile/Image",
    method: "put",
    ...variables,
    signal,
  });

export const useUpdateUserProfile = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpdateUserProfileError,
      UpdateUserProfileVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useRealmWorldsApiContext();
  return reactQuery.useMutation<
    undefined,
    UpdateUserProfileError,
    UpdateUserProfileVariables
  >({
    mutationFn: (variables: UpdateUserProfileVariables) =>
      fetchUpdateUserProfile({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CreateMediaAssetFormDataPathParams = {
  realmId: string;
};

export type CreateMediaAssetFormDataError = Fetcher.ErrorWrapper<undefined>;

export type CreateMediaAssetFormDataRequestBody = {
  /**
   * Asset Name
   */
  Name?: string;
  /**
   * Asset Category (UserLibrary, RWLibrary, Public)
   */
  AssetCategoryType?: string;
  /**
   * Asset Type (Image, Video, Audio, Document)
   */
  AssetType?: string;
  /**
   * Data Asset to be uploaded
   *
   * @format binary
   */
  Data?: Blob;
  /**
   * Is a Public Realm
   */
  Tags?: string[];
};

export type CreateMediaAssetFormDataVariables = {
  body?: CreateMediaAssetFormDataRequestBody;
  pathParams: CreateMediaAssetFormDataPathParams;
} & RealmWorldsApiContext["fetcherOptions"];

export const fetchCreateMediaAssetFormData = (
  variables: CreateMediaAssetFormDataVariables,
  signal?: AbortSignal
) =>
  realmWorldsApiFetch<
    Schemas.IInsertOneTokenResult,
    CreateMediaAssetFormDataError,
    CreateMediaAssetFormDataRequestBody,
    {},
    {},
    CreateMediaAssetFormDataPathParams
  >({ url: "/Users/MediaAsset", method: "post", ...variables, signal });

export const useCreateMediaAssetFormData = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.IInsertOneTokenResult,
      CreateMediaAssetFormDataError,
      CreateMediaAssetFormDataVariables
    >,
    "mutationFn"
  >
) => {
  const { fetcherOptions } = useRealmWorldsApiContext();
  return reactQuery.useMutation<
    Schemas.IInsertOneTokenResult,
    CreateMediaAssetFormDataError,
    CreateMediaAssetFormDataVariables
  >({
    mutationFn: (variables: CreateMediaAssetFormDataVariables) =>
      fetchCreateMediaAssetFormData({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type QueryOperation =
  | {
      path: "/Characters/{characterId}/Download";
      operationId: "downloadCharacter";
      variables: DownloadCharacterVariables;
    }
  | {
      path: "/Generate/Workflow/{workflowId}";
      operationId: "getWorkflow";
      variables: GetWorkflowVariables;
    };
