/* eslint-disable react/require-default-props */
import React, { ReactNode, useContext } from 'react';
import { ThemeContext } from '../../context/ThemeContext';
import styles from './floatingmenu.module.scss';

interface Props {
  children: ReactNode;
  onClick?: (event: React.SyntheticEvent) => void;
  disabled?: boolean;
  active?: boolean;
  type?: string;
}

function FloatingMenuItem({
  children,
  onClick,
  disabled,
  active,
  type,
}: Props) {
  const { theme } = useContext(ThemeContext);

  return (
    <button
      type="button"
      onClick={onClick}
      className={` ${styles[theme]} ${
        type === 'history'
          ? styles.floating_menu_item_history
          : styles.floating_menu_item
      } ${active ? styles.active : ''}`}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

export default FloatingMenuItem;
