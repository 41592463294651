/* eslint-disable max-len */
import { KonvaEventObject, Node, NodeConfig } from 'konva/lib/Node';
import { useState } from 'react';
import { ITEMS_CONTEXT } from './useItem';
import useTransformer from './useTransformer';

const useSelection = (transformer: ReturnType<typeof useTransformer>) => {
  const [selectedItems, setSelectedItems] = useState<ITEMS_CONTEXT['selectedItems']>(
    [] as ITEMS_CONTEXT['selectedItems'],
  );

  const getEventPosition = (e: MouseEvent | TouchEvent) => {
    if (e instanceof MouseEvent) {
      return {
        clientX: e.clientX,
        clientY: e.clientY,
        pageX: e.pageX,
        pageY: e.pageY,
        screenX: e.screenX,
        screenY: e.screenY,
        shiftKey: e.shiftKey, // MouseEvent has shiftKey
      };
    }
    if (e instanceof TouchEvent && e.touches.length > 0) {
      const touch = e.touches[0];
      return {
        clientX: touch.clientX,
        clientY: touch.clientY,
        pageX: touch.pageX,
        pageY: touch.pageY,
        screenX: touch.screenX,
        screenY: touch.screenY,
        shiftKey: false, // TouchEvent does not have shiftKey
      };
    }
    return {
      clientX: 0,
      clientY: 0,
      pageX: 0,
      pageY: 0,
      screenX: 0,
      screenY: 0,
      shiftKey: false, // Default value
    };
  };

  const onSelectItem = (e?: KonvaEventObject<MouseEvent | TouchEvent>, itemList?: Node<NodeConfig>[]) => {
    if (!transformer?.transformerRef.current) {
      console.error('transformer is not given');
      return;
    }

    if (itemList) {
      transformer.transformerRef.current.nodes(itemList);
      transformer.setTransformerConfig(transformer.transformerRef.current);
      setSelectedItems(itemList);
      return;
    }

    if (!e) {
      return;
    }

    const eventPosition = getEventPosition(e.evt);

    if (e.target.getType() === 'Stage') {
      transformer.transformerRef.current.nodes([]);
      transformer.setTransformerConfig(transformer.transformerRef.current);
      setSelectedItems([]);
      return;
    }

    let newItemList = [] as ITEMS_CONTEXT['selectedItems'];
    const targetItem = e.target.name() === 'label-text'
      ? e.target.getParent().getParent().findOne('.label-target')
      : e.target;

    if (!targetItem.draggable()) {
      return;
    }

    if (!eventPosition.shiftKey) {
      newItemList = [targetItem];
    } else if (selectedItems.find((item) => item.id() === targetItem.id())) {
      newItemList = selectedItems.filter((item) => item.id() !== targetItem.id());
    } else {
      newItemList = [...selectedItems, targetItem];
    }
    transformer.transformerRef.current.nodes(newItemList);
    transformer.setTransformerConfig(transformer.transformerRef.current);
    setSelectedItems(newItemList);
  };

  const clearSelection = () => {
    if (transformer.transformerRef.current) {
      transformer.transformerRef.current.nodes([]);
      transformer.setTransformerConfig(transformer.transformerRef.current);
    }
    setSelectedItems([]);
  };

  return {
    selectedItems,
    setSelectedItems,
    onSelectItem,
    clearSelection,
  };
};

export default useSelection;
