/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react/require-default-props */
/* eslint-disable object-curly-newline */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useContext, useEffect, useRef, useState } from 'react';
import { HiXMark } from 'react-icons/hi2';
import {
  Box,
  Button,
  Paper,
  Stack,
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
} from '@mui/material';
import s from './CreateRealmModal.module.scss';
import fantasy from '../../images/fantasy.webp';
import library from '../../images/library.webp';
import uploadImage from '../../images/upload-image.webp';
import dice from '../../images/dice.webp';
import background from '../../assets/images/NewDashboard/green_banner.png';
import bookmark from '../../images/bookmark.webp';
import realm from '../../images/realms-icon-active.svg';
import { useAuth } from '../../hooks/use-auth';
import useNotification from '../../hooks/useNotification';
import { ModalContext } from './Modal';
import useGraphQLMutation from '../../hooks/useGraphQLMutation';
import {
  CreateRealmDocument,
  RealmInsertInput,
} from '../../__generated__/graphql';
import ChooseFromLibraryModal from '../../components/modals/ChooseFromLibrary';
import CharacterLibraryView from '../../components/realms/Character/CharacterLibraryView';

interface RealmProps {
  updateApp?: () => void;
  updateState?: () => void;
}

/**
 * A component for creating a new realm with user-defined details.
 *
 * @component
 * @example
 * // Example usage of CreateRealmModal component
 * <CreateRealmModal />
 *
 * @returns {JSX.Element} React element representing the create realm modal.
 */

const CreateRealmModal = ({ updateApp, updateState }: RealmProps) => {
  const modalContext = useContext(ModalContext);
  const [activeInput, setActiveInput] = useState(null);
  const [openChooseFromLibraryModal, setOpenChooseFromLibraryModal] =
    useState(false);

  const handleFocus = (inputId: any) => {
    setActiveInput(inputId);
  };

  const handleBlur = () => {
    setActiveInput(null);
  };

  // form states
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedFileBlob, setSelectedFileBlob] = useState<Blob | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [nameInput, setNameInput] = useState('');
  const [tagsInput, setTagsInput] = useState<any>('');
  const [descriptionInput, setDescriptionInput] = useState('');
  const tagsInputRef = useRef<HTMLInputElement>(null);
  const tagsContainerRef = useRef<HTMLDivElement>(null);

  const [tags, setTags] = useState<string[]>([]);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    console.log(e.key);
    if (
      (e.key === 'Enter' || e.key === 'Tab' || e.key === ' ') &&
      tagsInput.trim() !== ''
    ) {
      setTags((prevTags) => [...prevTags, tagsInput.trim()]);
      setTagsInput('');
    }
  };

  const removeTag = (index: number) => {
    setTags((prevTags) => prevTags.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (tagsInputRef.current && tagsContainerRef.current) {
      const totalTagsWidth = tagsContainerRef.current.offsetWidth;
    }
  }, [tags]);

  const auth = useAuth();
  const { displayNotification } = useNotification();

  const {
    mutate: createRealm,
    isLoading: isRealmLoading,
    isError: isCreateRealmError,
    isSuccess: isRealmSuccess,
    error: createRealmError,
    data: createdRealm,
    status: createRealmStatus,
  } = useGraphQLMutation(CreateRealmDocument);

  React.useEffect(() => {
    if (isRealmLoading) {
      displayNotification({
        message: 'Creating realm...',
        type: 'success',
      });
    }

    if (isRealmSuccess) {
      console.log('createRealm Success: ', createdRealm);
      displayNotification({
        message: `Realm ${createdRealm.insertOneRealm?.name} was successfully created!`,
        type: 'success',
      });

      if (modalContext) {
        modalContext.close();
      }

      updateApp && updateApp();
      updateState && updateState();
    }

    if (isCreateRealmError) {
      console.error('createRealm Error: ', createRealmError);
      displayNotification({ message: 'Error creating Realm', type: 'error' });
    }
  }, [createRealmStatus]);

  const convertFileToBlob = (file: File) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const blob = new Blob([reader.result as ArrayBuffer], {
        type: file.type,
      });
      setSelectedFileBlob(blob);
    };

    reader.readAsArrayBuffer(file);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      console.log(URL.createObjectURL(file));
      setSelectedFile(file);
      convertFileToBlob(file);
      setPreviewUrl(URL.createObjectURL(file));
      console.log(URL.createObjectURL(file));
    }
  };

  console.log([tagsInput]);

  const handleSaveRealm = () => {
    const newRealm: RealmInsertInput = {
      name: nameInput,
      description: descriptionInput,
      is_public: true,
      is_favorited: false,
      tags,
      thumbnail_url:
        'https://storage.googleapis.com/smartai-stockimage-dev-storage-watermark-na/Fantasy/20230804/719bc992-67c8-4226-afc0-19f828bf5faa.png',
      owner: {
        link: auth?.realmUser?.id,
      },
      createdAt: new Date(),
    };
    createRealm({
      data: newRealm,
    });
  };

  return (
    <Paper className={s.modal}>
      {/* Gradients

      <div className={s.modal__topGradient} />
      <div className={s.modal__midGradient} />
      <div className={s.modal__extraGradient} />
      <div className={s.modal__newGradient} />
      <div className={s.modal__lastGradient} />
      <div className={s.modal__bottomGradient} />
      */}

      <img className={s.modal__bookmarkImg} src={bookmark} />
      <div className={s.modal__container}>
        <img className={s.modal__container__realmImage} src={realm} />
        <Typography className={s.modal__container__text}>
          Realm Creator
        </Typography>
      </div>
      <div className={s.textContainer}>
        <Typography className={s.textContainer__title}>
          👋 Welcome, Realm Creator,
        </Typography>
        <Typography className={s.textContainer__info}>
          You hold the power to shape a world of fantasy and adventure, you are
          about to breathe life into your very own realm, a world where epic
          quests and legendary tales will unfold.
        </Typography>
        <Typography className={s.textContainer__info}>
          Are you ready? 🧙 🔮
        </Typography>
      </div>
      <div className={s.actionsContainer}>
        <Card className={s.characterActions}>
          <CardHeader
            title={
              <Typography
                sx={{
                  fontSize: '20px',
                  fontFamily: 'Passero One, cursive, small-caps',
                  fontWeight: '500',
                  color: '#7a3900',
                  textAlign: 'center',
                }}
              >
                Character Image
              </Typography>
            }
          />
          <CardContent>
            {previewUrl === null && (
              <div className={s.characterActions__container}>
                <label htmlFor="fileInput" style={{ width: '100%' }}>
                  <div className={s.characterActions__box}>
                    <div className={s.characterActions__boxImage}>
                      <img
                        className={s.characterActions__boxImage__icon}
                        src={uploadImage}
                        alt="upload icon"
                      />
                    </div>
                    <div className={s.characterActions__boxFeature}>
                      <Typography
                        className={s.characterActions__boxFeature__text}
                      >
                        Upload image
                      </Typography>
                    </div>
                  </div>
                </label>
                <input
                  type="file"
                  accept=".jpg,.png,.webp"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                  id="fileInput"
                />

                <Box
                  className={s.characterActions__box}
                  onClick={() => {
                    setOpenChooseFromLibraryModal(true);
                  }}
                >
                  <div className={s.characterActions__boxImage}>
                    <img
                      className={s.characterActions__boxImage__icon}
                      src={library}
                      alt="library icon"
                    />
                  </div>
                  <div className={s.characterActions__boxFeature}>
                    <Typography
                      className={s.characterActions__boxFeature__text}
                    >
                      My Library
                    </Typography>
                  </div>
                </Box>

                <div className={s.characterActions__box}>
                  <div
                    className={`${s.characterActions__boxImage} ${s.characterActions__boxImagePink}`}
                  >
                    <img
                      className={`${s.characterActions__boxImage__icon} ${s.characterActions__boxImage__iconPink}`}
                      src={fantasy}
                      alt="fantasy icon"
                    />
                  </div>
                  <div className={s.characterActions__boxFeature}>
                    <Typography
                      className={s.characterActions__boxFeature__text}
                    >
                      AI Generate
                    </Typography>
                  </div>
                </div>
              </div>
            )}
            {previewUrl !== null && (
              <div className={s.replaceImageContainer}>
                <img
                  alt="realm preview"
                  src={previewUrl}
                  style={{
                    height: '100%',
                    marginTop: '10px',
                    width: '100%',
                    objectFit: 'cover',
                    objectPosition: 'center',
                  }}
                />
                <div className={s.replaceImage}>
                  <label htmlFor="fileInput" style={{ width: 'fit-content' }}>
                    <div
                      className={s.characterActions__box}
                      style={{ width: 'fit-content' }}
                    >
                      <div className={s.characterActions__boxImage}>
                        <img
                          className={s.characterActions__boxImage__icon}
                          src={uploadImage}
                          alt="upload icon"
                        />
                      </div>
                    </div>
                  </label>
                  <input
                    type="file"
                    accept=".jpg,.png,.webp"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                    id="fileInput"
                  />
                  <Box
                    className={s.characterActions__box}
                    style={{ width: 'fit-content' }}
                    onClick={() => {
                      setOpenChooseFromLibraryModal(true);
                    }}
                  >
                    <div className={s.characterActions__boxImage}>
                      <img
                        className={s.characterActions__boxImage__icon}
                        src={library}
                        alt="library icon"
                      />
                    </div>
                  </Box>
                </div>
              </div>
            )}
          </CardContent>
        </Card>
        <form className={s.form}>
          <div className={s.form__box}>
            <label htmlFor="nameInput" className={s.form__box__label}>
              Name
            </label>
            {/* <div className={s.form__box__diceDiv}>
              <img src={dice} alt="dice icon" className={s.form__box__dice} />
            </div> */}
            <input
              className={s.form__box__input}
              placeholder=""
              type="text"
              id="nameInput"
              onFocus={() => handleFocus('nameInput')}
              onBlur={handleBlur}
              onChange={(e) => setNameInput(e.target.value)}
              value={nameInput}
              required
            />
          </div>

          <div className={s.form__box}>
            <label htmlFor="tagsInput" className={s.form__box__label}>
              Tags
            </label>
            <div className={s.form__box__tags} ref={tagsContainerRef}>
              {tags.map((tag, index) => (
                <div key={index} className={s.form__box__tag}>
                  <p className={s.form__box__tagText}>{tag}</p>
                  <HiXMark onClick={() => removeTag(index)} />
                </div>
              ))}
            </div>
            <input
              ref={tagsInputRef}
              className={s.form__box__input}
              placeholder=""
              type="text"
              id="tagsInput"
              onFocus={() => handleFocus('tagsInput')}
              onBlur={handleBlur}
              onChange={(e) => setTagsInput(e.target.value)}
              value={tagsInput}
              onKeyDown={handleKeyPress}
              required
            />
          </div>

          <div className={s.form__box}>
            <label htmlFor="descriptionInput" className={s.form__box__label}>
              Description
            </label>
            <input
              className={`${s.form__box__input} ${s.form__box__inputDescription}`}
              placeholder=""
              type="text"
              id="descriptionInput"
              onFocus={() => handleFocus('descriptionInput')}
              onBlur={handleBlur}
              onChange={(e) => setDescriptionInput(e.target.value)}
              value={descriptionInput}
              required
            />
          </div>

          <button
            className={s.form__button}
            type="button"
            disabled={isRealmLoading}
            onClick={handleSaveRealm}
          >
            Create Realm
          </button>
        </form>
      </div>
      {openChooseFromLibraryModal && (
        <Box
          sx={{
            position: 'absolute',
            top: '30%',
            left: '50%',
            transform: 'translate(-50%, -30%)',
            width: '70%',
            outline: 'none',
            // overflow: 'hidden',
            zIndex: 999,
          }}
        >
          {/* heading and close button */}
          <Box
            sx={{
              width: '100%',
              borderRadius: '20px 20px 0px 0px',
              background: '#41521F',
              padding: '12px 20px',
              boxSizing: 'border-box',
              display: 'flex',
              alignItems: 'center',
              gap: '35px',
            }}
          >
            <button
              type="button"
              onClick={() => setOpenChooseFromLibraryModal(false)}
              style={{
                display: 'block',
                background: 'transparent',
                border: 'none',
                outline: 'none',
                filter: 'none',
                cursor: 'pointer',
              }}
              aria-label="close"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 10 10"
                width={10}
              >
                <polygon
                  style={{ fill: '#fff', strokeWidth: 0 }}
                  points="6.03 5 10 8.97 9.49 9.49 8.97 10 5 6.03 1.03 10 .51 9.49 0 8.97 3.97 5 0 1.03 .51 .51 1.03 0 5 3.97 8.97 0 9.49 .51 10 1.03 6.03 5"
                />
              </svg>
            </button>
            <Typography
              sx={{
                color: 'white',
                fontFamily: 'Passero One',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '26px' /* 130% */,
                letterSpacing: '0.46px',
                textTransform: 'uppercase',
              }}
            >
              Choose from my library
            </Typography>
          </Box>
          {/* character set name and thumnbail options */}
          <Box
            sx={{
              width: '100%',
              borderRadius: '0px 0px 20px 20px',
              background: '#FFF',
              padding: '45px 65px',
              boxSizing: 'border-box',
              maxHeight: '800px',
              boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
            }}
          >
            <CharacterLibraryView
              handleCloseLibraryView={() => {}}
              previewBackground={previewUrl!}
              handleLibraryImageClick={(link: string) => setPreviewUrl(link)}
              variant="general"
            />
            <Stack direction="row" justifyContent="flex-end">
              <Button
                onClick={() => setOpenChooseFromLibraryModal(false)}
                sx={{
                  background: '#7A3900',
                  borderRadius: '4px',
                  color: 'white',
                  fontSize: '20px',
                  fontFamily: 'Passero One',
                  fontWeight: '400',
                  textTransform: 'capitalize',
                  paddingInline: '24px',
                  '&:hover': {
                    background: '#7A3900',
                  },
                }}
              >
                Apply
              </Button>
            </Stack>
          </Box>
          {/* Preview */}
          {/* <CharacterSetModalPreview
            background={previewBackground}
            handleStage={(stage: Stage) => {
              setCurrentStage(stage);
            }}
            onConfirm={onConfirm}
            variant="general"
          /> */}
        </Box>
      )}
    </Paper>
  );
};

export default CreateRealmModal;
