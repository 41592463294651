/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
  Box,
  Button,
  Input,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import UploadIcon from '../../../images/upload-image.webp';
import LibraryIcon from '../../../images/library.webp';
import SelectColorIcon from '../../../images/select-color.webp';
import appStore, {
  CharacterSheetMoodOptions,
  ThumbnailSource,
} from '../../../store/AppStore';
import CharacterWarningModal from '../../modals/CharacterWarningModal';

interface Props {
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleLibraryView: () => void;
  handleColorView: () => void;
  handleSaveSet: () => void;
  handleDeleteSet: () => void;
  disableSave: boolean;
  characterSetTitle: string;
  setCharacterTitle: (value: string) => void;
}

/**
 * Represents a React component for the general view of the create Character Set Modal.
 *
 * @component
 * @example
 * // Example usage of CharacterGeneralView component
 * <CharacterGeneralView
 *   handleFileChange={handleFileChangeFunction}
 *   handleLibraryView={handleLibraryViewFunction}
 *   handleColorView={handleColorViewFunction}
 * />
 * @date 11/22/2023 - 11:56:14 AM
 *
 * @param {Props} param0
 * @param {(event: React.ChangeEvent<HTMLInputElement>) => void} param0.handleFileChange
 * @param {() => void} param0.handleLibraryView
 * @param {() => void} param0.handleColorView
 * @returns {*}
 */
function CharacterGeneralView({
  handleFileChange,
  handleLibraryView,
  handleColorView,
  handleSaveSet,
  handleDeleteSet,
  disableSave,
  characterSetTitle,
  setCharacterTitle,
}: Props) {
  /**
   * The selected thumbnail source from the application store.
   *
   * @type {ThumbnailSource}
   */
  const selectedThumbnailSource: ThumbnailSource = appStore(
    (state) => state.previewThumbnailSource,
  );
  const characterSetMood: CharacterSheetMoodOptions = appStore(
    (state) => state.characterSetMood,
  );
  const [openCharacterWarningModal, setOpenCharacterWarningModal] =
    React.useState(false);

  const isTabletScreen = useMediaQuery('(max-width:865px)');
  const isMobileScreen = useMediaQuery('(max-width:530px)');

  return (
    <>
      {/* Character Set Name */}
      <Box>
        <Typography
          sx={{
            color: '#250503',
            fontFamily: 'DM Sans',
            fontSize: '13px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: 'normal',
          }}
        >
          Character Set Name
        </Typography>
        <Input
          placeholder="Lorem ipsum dolor sit"
          value={characterSetTitle}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setCharacterTitle(event.target.value)
          }
          autoFocus
          fullWidth
          sx={{
            borderRadius: '6px',
            border: '2px solid #E0E0E0',
            p: '10px 12px',
            outline: 'none',
            filter: 'none',
            mt: '10px',
            boxShadow: 'none',
            '&::before, &::after': {
              outline: 'none!important',
              filter: 'none!important',
              boxShadow: 'none!important',
              border: 'none!important',
            },
            color: '#250503',
            fontFamily: 'DM Sans',
            fontSize: '13px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: 'normal',
          }}
        />
      </Box>
      {/* Select a thumbnail */}
      <Box mt={isMobileScreen ? '35px' : '45px'}>
        <Typography
          sx={{
            color: '#250503',
            fontFamily: 'DM Sans',
            fontSize: '13px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: 'normal',
          }}
        >
          Select a Thumbnail
        </Typography>
        <Stack
          spacing={isMobileScreen ? '25px' : '50px'}
          mt="10px"
          direction={isMobileScreen ? 'column' : 'row'}
        >
          {/* upload image */}
          <Stack
            spacing="6px"
            alignItems="center"
            direction={isMobileScreen ? 'row' : 'column'}
            gap={isMobileScreen ? '10px' : '0px'}
          >
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="fileInput">
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '5px',
                  backgroundColor:
                    selectedThumbnailSource === 'upload'
                      ? '#41521F'
                      : '#7A3900',
                  minHeight: '60px',
                  minWidth: '60px',
                  cursor: 'pointer',
                }}
              >
                <img
                  src={UploadIcon}
                  alt="upload"
                  style={{
                    height: 'auto',
                    width: '30px',
                  }}
                />
              </Box>
            </label>
            <input
              type="file"
              accept=".jpg,.png,.webp"
              style={{ display: 'none' }}
              onChange={handleFileChange}
              id="fileInput"
            />
            <Typography
              sx={{
                color: '#250503',
                fontFamily: 'DM Sans',
                fontSize: '13px',
                fontStyle: 'normal',
                fontWeight: '500',
              }}
            >
              Upload an Image
            </Typography>
          </Stack>
          {/* library */}
          <Stack
            spacing="6px"
            alignItems="center"
            direction={isMobileScreen ? 'row' : 'column'}
            gap={isMobileScreen ? '10px' : '0px'}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '5px',
                backgroundColor:
                  selectedThumbnailSource === 'library' ? '#41521F' : '#7A3900',
                minHeight: '60px',
                minWidth: '60px',
                cursor: 'pointer',
              }}
              onClick={handleLibraryView}
            >
              <img
                src={LibraryIcon}
                alt="upload"
                style={{
                  height: 'auto',
                  width: '30px',
                }}
              />
            </Box>
            <Typography
              sx={{
                color: '#250503',
                fontFamily: 'DM Sans',
                fontSize: '13px',
                fontStyle: 'normal',
                fontWeight: '500',
              }}
            >
              Choose from Library
            </Typography>
          </Stack>
          {/* color */}
          <Stack
            spacing="6px"
            alignItems="center"
            direction={isMobileScreen ? 'row' : 'column'}
            gap={isMobileScreen ? '10px' : '0px'}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '5px',
                backgroundColor:
                  selectedThumbnailSource === 'color' ? '#41521F' : '#7A3900',
                minHeight: '60px',
                minWidth: '60px',
                cursor: 'pointer',
              }}
              onClick={handleColorView}
            >
              <img
                src={SelectColorIcon}
                alt="upload"
                style={{
                  height: 'auto',
                  width: '30px',
                }}
              />
            </Box>
            <Typography
              sx={{
                color: '#250503',
                fontFamily: 'DM Sans',
                fontSize: '13px',
                fontStyle: 'normal',
                fontWeight: '500',
              }}
            >
              Select Color
            </Typography>
          </Stack>
        </Stack>
      </Box>
      {/* Save Set Button */}
      <Box
        mt="50px"
        width="100%"
        display="flex"
        justifyContent={
          characterSetMood === 'create' ? 'flex-end' : 'space-between'
        }
      >
        {characterSetMood === 'edit' && (
          <Button
            variant="contained"
            sx={{
              borderRadius: '4px',
              background: 'red',
              boxShadow:
                '0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
              color: '#FFF',
              fontFamily: 'Passero One',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '22px' /* 137.5% */,
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              '&:hover': {
                background: 'red',
              },
            }}
            onClick={() => setOpenCharacterWarningModal(true)}
          >
            Delete Set
          </Button>
        )}
        <Button
          variant="contained"
          sx={{
            borderRadius: '4px',
            background: '#41521F',
            boxShadow:
              '0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
            color: '#FFF',
            fontFamily: 'Passero One',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '22px' /* 137.5% */,
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            '&:hover': {
              background: '#41521F',
            },
          }}
          onClick={handleSaveSet}
          disabled={disableSave}
        >
          {characterSetMood === 'create' ? 'Save Set' : 'Update Set'}
        </Button>
      </Box>
      {/* Delete Character */}
      <CharacterWarningModal
        open={openCharacterWarningModal}
        onClose={() => {}}
        onConfirm={() => {
          handleDeleteSet();
          setOpenCharacterWarningModal(false);
        }}
        setOpen={setOpenCharacterWarningModal}
        message="Deleting a character set is irreversible, do you wish to proceed?"
        variant="delete"
      />
    </>
  );
}

export default CharacterGeneralView;
