/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable object-curly-newline */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoMdSettings } from 'react-icons/io';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Stack,
  Typography,
} from '@mui/material';
import s from './AllRealms.module.scss';
// import favoriteStar from '../../assets/images/empty-star.png';
// import settingsIcon from '../../images/settings-icon-active.svg';
import EmptyFavoriteStar from '../../assets/images/empty-star.png';
import FullFavoriteStar from '../../assets/images/full-star.png';
import errorPlaceholder from '../../assets/images/NewDashboard/image_realm.jpg';
import appStore, { RealmDefaultViewOptions } from '../../store/AppStore';
import useGraphQLMutation from '../../hooks/useGraphQLMutation';
import { UpdateOneRealmDocument } from '../../__generated__/graphql';
import useNotification from '../../hooks/useNotification';

interface RealmProps {
  id: string;
  thumbnail_url: any;
  tags: any;
  realmName: string;
  isFavorited: boolean;
  refetchFavoritedReams: () => void;
}

/**
 * A component representing an individual realm in a list of all realms.
 *
 * @component
 * @example
 * // Example usage of AllRealms component
 * <AllRealms
 *   id="exampleRealmId"
 *   thumbnail_url="exampleImageUrl"
 *   tags={['tag1', 'tag2', 'tag3']}
 *   realmName="Example Realm"
 *   isFavorited={false}
 * />
 *
 * @param {Object} props - The properties of the component.
 * @param {string} props.id - The unique identifier of the realm.
 * @param {string} props.thumbnail_url - The URL of the realm's thumbnail image.
 * @param {string[]} props.tags - An array of tags associated with the realm.
 * @param {string} props.realmName - The name of the realm.
 * @param {boolean} props.isFavorited - Indicates whether the realm is favorited.
 *
 * @returns {JSX.Element} React element representing an individual realm.
 */

const AllRealms = ({
  id,
  thumbnail_url,
  tags,
  realmName,
  isFavorited,
  refetchFavoritedReams,
}: RealmProps) => {
  const navigate = useNavigate();
  const { displayNotification } = useNotification();
  const [brokenImages, setBrokenImages] = useState<string[]>([]);
  const [realmIsFavorited, setRealmIsFavorited] = useState<boolean>(isFavorited);
  const {
    mutate: updateRealmFav,
    status: updateRealmFavStatus,
  } = useGraphQLMutation(UpdateOneRealmDocument);

  const handleImageError = (realmId: string) => {
    setBrokenImages((prevBrokenImages) => [...prevBrokenImages, realmId]);
  };

  const handleFavorite = () => {
    console.log('working on it');
    updateRealmFav({
      set: {
        is_favorited: !realmIsFavorited,
      },
      query: {
        _id: id,
      },
    });
  };

  const realmDefaultView: RealmDefaultViewOptions = appStore(
    (state) => state.realmDefaultView,
  );

  React.useEffect(() => {
    if (updateRealmFavStatus === 'loading') {
      displayNotification({ message: `${realmIsFavorited ? 'Unfavoriting' : 'Favoriting'} ${realmName} realm ...`, type: 'info' });
    }
    if (updateRealmFavStatus === 'error') {
      displayNotification({ message: `Failed to ${realmIsFavorited ? 'unfavorite' : 'favorite'} ${realmName} realm`, type: 'error' });
    }
    if (updateRealmFavStatus === 'success') {
      displayNotification({ message: `${realmIsFavorited ? 'Unfavorite' : 'Favorited'} ${realmName} realm successfully`, type: 'success' });
      setRealmIsFavorited(!realmIsFavorited);
      refetchFavoritedReams();
    }
  }, [updateRealmFavStatus]);

  return (
    <div key={id} className={s.realm}>
      <div>
        <Card
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.6)',
            ':hover > .MuiCardMedia-root': {
              transform: 'scale(1.1)',
              transition: 'transform 0.5s',
            },
          }}
          onClick={() => navigate(`/realm/${id}/${realmDefaultView}`)}
        >
          <CardHeader
            sx={{
              color: '#7a3900',
            }}
            title={(
              <Typography
                sx={{
                  fontSize: '20px',
                  fontFamily: 'Passero One, cursive, small-caps',
                  fontWeight: '500',
                  color: '#7a3900',
                }}
              >
                {realmName}
              </Typography>
            )}
            action={(
              <Box>
                <Button
                  onClick={handleFavorite}
                  style={{
                    border: 'none',
                    background: 'none',
                    cursor: 'pointer',
                  }}
                >
                  <img
                    src={realmIsFavorited ? FullFavoriteStar : EmptyFavoriteStar}
                    alt="Favorite"
                  />
                </Button>
                <IoMdSettings
                  className={s.realm__settingsIcon}
                  onClick={() => navigate(`/realm/${id}/settings`)}
                />
              </Box>
            )}
          />
          <CardMedia
            className={s.realm__background}
            component="img"
            height="100%"
            image={brokenImages.includes(id) || !thumbnail_url ? (errorPlaceholder) : thumbnail_url}
            alt={thumbnail_url}
            style={{
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
            onError={() => handleImageError(id)}
          />
        </Card>
      </div>
      <div>
        <div>
          <div className={s.realm__info__tags}>
            {tags?.slice(0, 3)?.map((tag: string, index: number) => (
              <div
                key={index}
                className={
                    tag?.split(' ').length === 1
                      ? s.realm__info__tag
                      : s.realm__info__longTag
                  }
              >
                { tag.toLowerCase().substring(0, 12) }
                { tag.length > 12 ? '...' : '' }
              </div>
            ))}
          </div>

          { /* <button
            type="button"
            className={s.realm__info__button}
            onClick={() => navigate(`/realm/${id}/${realmDefaultView}`)}
          >
            Enter Realm
          </button> */ }
        </div>
      </div>
    </div>
  );
};

export default AllRealms;
