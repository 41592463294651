/* eslint-disable no-nested-ternary */
/* eslint-disable object-curly-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { ArrowDropDown } from '@mui/icons-material';
import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import EditIcon from '../../../images/edit.webp';
import CustomBorder from '../../../images/custom-border-image-1.webp';
import './expansion.scss';
import appStore, {
  CharacterViewOptions,
  CreateCharacterViewOptions,
  ViewTypeOptions,
} from '../../../store/AppStore';
import CharacterCatalogCPExpansionItem from './CharacterCatalogCPExpansionItem';
import backgroundImage from '../../../images/gray-orange-abstract-texture.webp';
import PlusIcon from '../../../images/plus-math.svg';
import KindredIcon from '../../../images/kindred.svg';
import FantasyIcon from '../../../images/fantasy-icon.png';
import RWLogoIcon from '../../../images/Realm_worlds_logo.png';
import { CharacterQueryInput } from '../../../__generated__/graphql';
import {
  CPCreateButton,
  CPCreateButtonText,
  FlexibleHeightImg,
  SectionHeadingText,
} from '../../styledComps/styledComps';
import { setCreateZip } from '../../../util/zipUtil';
// import {
//   DownloadCharacterVariables, fetchDownloadCharacter,
// } from '../../../__generated__/realmWorldsApiComponents';

interface Props {
  title: string | null | undefined;
  setID: string;
  backgroundUrl: string | null | undefined;
  characters: (CharacterQueryInput | null)[];
  editCharacterSet: () => void;
}

/**
  * A new expansion component specifically for the character catalog - character section
  * It is different from the token maker expansion component in the following ways:
    - It is expected to have a background image
    - It doesn't have a seperate header component
    - It has a custom image border
  * It must have the child component `CharacterCatalogCPExpansionItem`
*/
function CharacterCatalogCPExpansion({
  title,
  setID,
  backgroundUrl,
  characters,
  editCharacterSet,
}: Props) {
  const isTabletScreen = useMediaQuery('(max-width:865px)');
  const isMobileScreen = useMediaQuery('(max-width:530px)');

  const [isExpanded, setIsExpanded] = React.useState(false);
  const [cpSlice, setCpSlice] = React.useState(6);
  const [viewportWidth, setViewportWidth] = React.useState(window.innerWidth);
  const viewType: ViewTypeOptions = appStore((state) => state.viewType);

  const setSelectedView = (view: CharacterViewOptions) => {
    appStore.setState({
      selectedCharacterView: view,
    });
  };
  const createCharacterView: CreateCharacterViewOptions = appStore(
    (state) => state.createCharacterView,
  );
  const setCreateCharacterView = (view: CreateCharacterViewOptions) => {
    if (view !== createCharacterView) {
      appStore.setState({
        createCharacterView: view,
      });
    }
  };
  const defaultCreateCharacterView: CreateCharacterViewOptions = appStore(
    (state) => state.defaultCreateCharacterView,
  );
  const setOpenAICharacterGenModal = (val: boolean) => {
    appStore.setState({
      openAICharacterGenModal: val,
    });
  };
  async function handleDownloadCharacterSet() {
    try {
      // Fetch the data from the URL
      const url = backgroundUrl!;
      const response = await fetch(url);
      const blober = await fetch(url).then((r) => r.blob());
      // Check if the request was successful
      if (!response.ok) {
        throw new Error(`Failed to fetch data from ${url}`);
      }
      const characterSetObject = {
        name: title,
        backgroundUrl,
      };
      return setCreateZip(
        characterSetObject,
        blober,
        `${title!}_Character Set`,
        characters,
      );
      // return blober;
    } catch (error) {
      console.error(error);
      // Handle the error appropriately
      return null;
    }
  }

  React.useEffect(() => {
    if (viewportWidth <= 1600) {
      setCpSlice(4);
    } else {
      setCpSlice(6);
    }
  }, [viewportWidth]);

  React.useEffect(() => {
    function handleResize() {
      setViewportWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          minHeight: '200px',
          boxSizing: 'border-box',
          position: 'absolute',
          top: 0,
          left: 0,
          objectFit: 'cover',
          backgroundSize: 'cover',
          backgroundImage: `url(${
            backgroundUrl && backgroundUrl !== undefined
              ? backgroundUrl
              : backgroundImage
          })`,
          backgroundPosition: 'center center',
          backgroundColor: 'rgba(208, 177, 122, 0.50)',
          backgroundRepeat: 'none',
          zIndex: '1',
        }}
      />
      <Box
        sx={{
          width: '100%',
          minHeight: '200px',
          boxSizing: 'border-box',
          padding: isMobileScreen ? '9px 9px' : '18px 30px',
          border: '8px solid #68583D',
          borderImageSource: `url(${CustomBorder})`,
          borderImageSlice: '17',
          borderImageRepeat: 'round',
          backgroundColor: '#D0B17AAA',
          position: 'relative',
          zIndex: '5',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '26px',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Passero One',
                fontSize: isMobileScreen ? '22px' : '28px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: isMobileScreen ? '22px' : '16px' /* 66.667% */,
                letterSpacing: '0.1px',
                color: '#250503',
              }}
            >
              {title}
            </Typography>
            <Stack direction="row" gap="15px">
              <Box onClick={editCharacterSet} sx={{ cursor: 'pointer' }}>
                <img src={EditIcon} alt="edit" />
              </Box>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 21.28 25"
                style={{
                  height: '21px',
                  width: 'auto',
                  cursor: 'pointer',
                }}
                onClick={handleDownloadCharacterSet}
              >
                <path
                  style={{ fill: '#260404', strokeWidth: '0px' }}
                  d="m21.28,6.3v17.23c0,.81-.66,1.47-1.46,1.47H1.46c-.8,0-1.46-.66-1.46-1.47V6.3c0-.81.66-1.47,1.46-1.47h7.94v11.51l-2.31-2.31c-.49-.49-1.27-.49-1.76,0-.49.49-.49,1.27,0,1.76l4.41,4.41c.24.24.57.36.88.36s.63-.12.88-.36l4.41-4.41c.49-.49.49-1.27,0-1.76-.49-.49-1.27-.49-1.76,0l-2.26,2.27V4.83h7.94c.8,0,1.46.66,1.46,1.47Z"
                />
                <path
                  style={{ fill: '#260404', strokeWidth: '0px' }}
                  d="m11.88,1.24v3.59h-2.49V1.24c0-.69.56-1.24,1.24-1.24s1.24.55,1.24,1.24Z"
                />
              </svg>
            </Stack>
          </Box>

          {characters.length > cpSlice && (
            <ArrowDropDown
              onClick={() => setIsExpanded(!isExpanded)}
              className={`expansionArrow cp ${isExpanded ? 'rotated' : ''}`}
            />
          )}
        </Box>
        {/* content */}
        {characters.length > 0 ? (
          <Box
            sx={{
              display: 'flex',
              gap:
                viewType === 'grid'
                  ? isMobileScreen
                    ? '10px!important'
                    : '50px'
                  : '25px',
              justifyContent: isMobileScreen ? 'center' : 'flex-start',
              flexWrap: 'wrap',
              paddingTop: '14px',
            }}
          >
            {characters.slice(0, cpSlice).map((character) => (
              <CharacterCatalogCPExpansionItem
                key={character?._id}
                character={character}
                setID={setID}
              />
            ))}
            {isExpanded &&
              characters
                .slice(cpSlice)
                .map((character) => (
                  <CharacterCatalogCPExpansionItem
                    key={character?._id}
                    character={character}
                    setID={setID}
                  />
                ))}
          </Box>
        ) : (
          <Stack
            pt="34px"
            alignItems="center"
            justifyContent="center"
            gap="16px"
          >
            <SectionHeadingText
              sx={{
                fontSize: isMobileScreen ? '24px!important' : '36px!important',
                lineHeight: 'normal!important',
              }}
            >
              Your Character Set is Empty
            </SectionHeadingText>
            <Stack
              gap={isTabletScreen ? '14px' : '33px'}
              direction={isMobileScreen ? 'column' : 'row'}
              alignItems={isTabletScreen ? 'stretch' : 'center'}
              justifyContent="center"
            >
              <CPCreateButton
                sx={{
                  background: '#41521F',
                  '&:hover': {
                    background: '#41521F',
                  },
                }}
                onClick={() => {
                  setSelectedView('creator');
                  setCreateCharacterView(defaultCreateCharacterView);
                }}
              >
                <Stack
                  direction="row"
                  gap="5px"
                  alignItems="center"
                  justifyContent="center"
                  width={isMobileScreen ? '180px' : '100%'}
                >
                  <FlexibleHeightImg src={PlusIcon} width="8px" />
                  <FlexibleHeightImg src={KindredIcon} width="25px" />
                  <CPCreateButtonText>Create a Character</CPCreateButtonText>
                </Stack>
              </CPCreateButton>
              <CPCreateButton
                sx={{
                  background: '#DA16A2',
                  '&:hover': {
                    background: '#DA16A2',
                  },
                }}
                onClick={() => setOpenAICharacterGenModal(true)}
              >
                <Stack
                  direction="row"
                  gap="5px"
                  alignItems="center"
                  justifyContent="center"
                  width={isMobileScreen ? '180px' : '100%'}
                >
                  <FlexibleHeightImg src={PlusIcon} width="8px" />
                  <FlexibleHeightImg src={FantasyIcon} width="25px" />
                  <CPCreateButtonText>AI Generate Character</CPCreateButtonText>
                </Stack>
              </CPCreateButton>
              <CPCreateButton
                sx={{
                  background: '#41521F',
                  '&:hover': {
                    background: '#41521F',
                  },
                }}
                onClick={() => setSelectedView('store')}
              >
                <Stack
                  direction="row"
                  gap="5px"
                  alignItems="center"
                  justifyContent="center"
                  width={isMobileScreen ? '180px' : '100%'}
                >
                  <FlexibleHeightImg src={RWLogoIcon} width="25px" />
                  <CPCreateButtonText>RW Store</CPCreateButtonText>
                </Stack>
              </CPCreateButton>
            </Stack>
          </Stack>
        )}
      </Box>
    </Box>
  );
}

export default CharacterCatalogCPExpansion;
