import React, { useContext } from 'react';
import { Tooltip, Zoom } from '@mui/material';
import { ThemeContext } from '../../context/ThemeContext';
import styles from './icons.module.scss';

interface Props {
  onClick: () => void;
  active: boolean;
}

function QueryIcon({ onClick, active } : Props) {
  const { theme } = useContext(ThemeContext);
  return (
    <Tooltip
      title="Help"
      arrow
      placement="bottom"
      TransitionComponent={Zoom}
      componentsProps={{
        tooltip: {
          sx: {
            fontFamily: 'Passero One',
            fontWeight: '400px',
            fontSize: '16px',
            padding: '10px',
            bgcolor: '#3c3c3ce6',
            '& .MuiTooltip-arrow': {
              color: '#3c3c3ce6',
            },
            maxWidth: '250px',
          },
        },
      }}
    >
      <button
        onClick={onClick}
        type="button"
        className={`${styles.icon} ${styles.query_icon} ${styles[theme]} ${active && styles.active}`}
        aria-label="query icon"
      >
        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 25 25"
        >
          <path className={styles.query_cls_2} d="m12.5,2.52c-8.46,0-9.23,1.5-9.23,2.32v5.42c0,6.42,6.67,12.2,9.23,12.2s9.23-5.78,9.23-12.2v-5.42c0-.82-.78-2.32-9.23-2.32Zm0,15.39c-.7,0-1.26-.56-1.26-1.26s.56-1.26,1.26-1.26,1.26.56,1.26,1.26-.56,1.26-1.26,1.26Zm3.24-8.48c-.02.18-.05.35-.09.52-.12.46-.32.89-.61,1.26-.3.4-.69.68-1.07,1-.3.25-.6.59-.6,1.01h-1.57c0-1.55,1.4-2.2,2.21-3.3.85-1.15-.26-2.46-1.51-2.46-1.49,0-1.72,1.56-1.72,1.56h-1.5c-.09,0,.22-1.09.27-1.19.49-1.15,1.65-2.04,2.93-2.04.87,0,1.75.35,2.38.96.7.68.98,1.73.89,2.68Z" />
          <rect className={styles.query_cls_1} width="25" height="25" />
        </svg>
      </button>
    </Tooltip>
  );
}

export default QueryIcon;
