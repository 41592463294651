/* eslint-disable object-curly-newline */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import { Box, Button, Stack, Typography, useMediaQuery } from '@mui/material';
import styled from '@emotion/styled';
import {
  ReactiveBase,
  ReactiveList,
  SearchBox,
} from '@appbaseio/reactivesearch';
import BackArrow from '../../../images/back.webp';
import FilterIcon from '../../../images/filter-slider.webp';
import CharacterLibraryExpansionWrapper from './CharacterLibraryExpansionWrapper';

interface Props {
  handleCloseLibraryView: () => void;
  previewBackground: string;
  handleLibraryImageClick: (link: string) => void;
  variant?: 'general' | 'character_set';
}

// const isTabletScreen = useMediaQuery('(max-width:865px)');
const FilterImg = styled('img')({
  width: '30px',
  height: '30px',
  flexShrink: 0,
});

/**
 * Represents a React component for the library view of the Create Character Set Modal - thumbnails.
 *
 * @component
 * @example
 * // Example usage of CharacterLibraryView component
 * <CharacterLibraryView
 *   handleCloseLibraryView={handleCloseLibraryViewFunction}
 *   SampleLibraryImages={sampleLibraryImagesArray}
 *   previewBackground="backgroundImageUrl"
 *   handleLibraryImageClick={handleLibraryImageClickFunction}
 * />
 *
 * @param {Props} param0 - The component properties.
 * @param {() => void} param0.handleCloseLibraryView - Callback for closing the library view.
 * @param {{}} param0.SampleLibraryImages - Array of sample library images.
 * @param {string} param0.previewBackground - URL of the preview background.
 * @param {(link: string) => void} param0.handleLibraryImageClick
 * - Callback for handling library image click.
 * @returns {*}
 */
function CharacterLibraryView({
  handleCloseLibraryView,
  previewBackground,
  handleLibraryImageClick,
  variant,
}: Props) {
  const isTabletScreen = useMediaQuery('(max-width:865px)');
  const isMobileScreen = useMediaQuery('(max-width:530px)');
  return (
    <>
      <Stack spacing="15px" direction="row" alignItems="center">
        {variant === 'character_set' && (
          <>
            <Button
              variant="contained"
              sx={{
                p: '0',
                background: 'transparent',
                boxShadow: 'none',
                outline: 'none',
                '&:hover': {
                  background: 'transparent',
                  boxShadow: 'none',
                },
              }}
              onClick={handleCloseLibraryView}
            >
              <img
                src={BackArrow}
                alt="back arrow"
                style={{ width: '50px', height: 'auto' }}
              />
            </Button>
            <Typography
              sx={{
                color: '#250503',
                fontFamily: 'DM Sans',
                fontSize: '13px',
                fontStyle: 'normal',
                fontWeight: '700',
                lineHeight: 'normal',
              }}
            >
              Select a Thumbnail
            </Typography>
          </>
        )}
      </Stack>
      <ReactiveBase
        app="library_content_packs_index"
        url={process.env.REACT_APP_MONGO_ATLAS_SEARCH_URL as string}
        transformRequest={(request: any) => ({
          ...request,
          headers: {
            ...request.headers,
            Authorization: `Bearer ${localStorage.getItem('realmToken')}`,
          },
        })}
        // @ts-ignore
        mongodb={{
          collection: 'library_content_packs',
          db: 'realm_worlds',
        }}
        analytics={false}
        credentials="none"
      >
        <Stack
          direction="row"
          gap="30px"
          alignItems="center"
          flexWrap="nowrap"
          width="100%"
        >
          <SearchBox
            componentId="mycharacterpackslibrary-searchbox"
            iconPosition="right"
            dataField={['name']}
            includeFields={['*']}
            autosuggest={false}
            AIConfig={{}}
            AIUIConfig={{}}
            type="search"
            compoundClause="must"
            enableIndexSuggestions={false}
            enterButton={false}
            className="my_custom_searchbox cpp"
            style={{ width: isMobileScreen ? '100%' : 'auto' }}
          />
          {!isTabletScreen ? (
            <Button
              sx={{
                background: '#41521F',
                padding: '10px',
                boxSizing: 'border-box',
                color: '#ffffffcc',
                minWidth: isTabletScreen ? '0px' : '30px',
                '&:hover': {
                  background: '#41521F',
                },
              }}
            >
              <FilterImg src={FilterIcon} />
            </Button>
          ) : null}
        </Stack>
        <ReactiveList
          componentId="mycharacterpackslibrary-results"
          dataField="_score"
          size={10}
          pagination
          innerClass={{
            pagination: 'list_pagination',
            resultStats: 'list_resultStats',
            resultsInfo: 'list_resultsInfo',
          }}
          className="dashboard_reactive_list cpp"
          compoundClause="must"
          react={{
            and: ['discover-searchbox'],
          }}
          onError={(err) => console.log(err)}
          sortOptions={[
            {
              dataField: 'updated_at',
              sortBy: 'desc',
              label: 'Recent',
            },
            {
              dataField: 'updated_at',
              sortBy: 'asc',
              label: 'Oldest',
            },
            {
              dataField: 'name',
              sortBy: 'asc',
              label: 'Name - A-Z',
            },
            {
              dataField: 'name',
              sortBy: 'desc',
              label: 'Name - Z-A',
            },
          ]}
          showResultStats={false}
          render={({ data }: any) => (
            <Box
              sx={{
                height: isTabletScreen ? '300px' : '400px',
                maxHeight: isTabletScreen ? '300px' : '400px',
                overflowY: 'scroll',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                gap: isMobileScreen ? '0px' : '40px',
                '&::-webkit-scrollbar': {
                  width: '5px',
                  backgroundColor: '#CFCAAD',
                  borderRadius: '5px',
                },
                '&.scrollbar': {
                  scrollbarWidth: 'thin',
                  scrollbarColor: '#CFCAAD',
                  backgroundColor: '#CFCAAD',
                  borderRadius: '5px',
                },
                '&::-webkit-scrollbar-thumb, &.scrollbar-thumb, &::-webkit-scrollbar-thumb:hover, &.scrollbar-thumb:hover':
                  {
                    backgroundColor: '#41521F',
                    borderRadius: '5px',
                  },
              }}
            >
              {data &&
                data.map((pack: any) => (
                  <CharacterLibraryExpansionWrapper
                    key={`${pack.name}__pack`}
                    pack={pack}
                    previewBackground={previewBackground}
                    handleLibraryImageClick={(link: string) =>
                      handleLibraryImageClick(link)
                    }
                  />
                ))}
            </Box>
          )}
        />
      </ReactiveBase>
    </>
  );
}

CharacterLibraryView.defaultProps = {
  variant: 'character_set',
};

export default CharacterLibraryView;
