/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable operator-linebreak */
/* eslint-disable arrow-body-style */

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ReactiveBase,
  ReactiveList,
  SearchBox,
} from '@appbaseio/reactivesearch';
import { Typography } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import { IoMdSettings } from 'react-icons/io';
import s from './DashboardView.module.scss';
import allRealmStyles from '../Realms/AllRealms.module.scss';
import detailedRealmStyles from '../Realms/DetailedRealms.module.scss';
import RealmsIcon from '../../images/Realm_worlds_logo.png';
import warriorIcon from '../../assets/images/NewDashboard/warrior_token.png';
import favoritesIcon from '../../images/favorites-icon.svg';
import realmIcon from '../../images/realms-icon.svg';
import realmIconActive from '../../images/realms-icon-active.svg';
import plusIcon from '../../images/plus-icon.png';
import filterIcon from '../../images/filter-slider.webp';
import detailedView from '../../images/detailed-view.svg';
import gridView from '../../images/grid-view.svg';
import SwiperComponent from '../SwiperComponent/SwiperComponent';
import Modal from '../Modal/Modal';
import CreateRealmModal from '../Modal/CreateRealmModal';
import {
  GetRealmsDocument,
  GetTokenSetsDocument,
  GetCharactersSetsDocument,
  GetRealmsQuery,
  Realm,
  RealmSortByInput,
  GetUserProfileDocument,
  GetUserWalletDocument,
} from '../../__generated__/graphql';
import '../../views/Realm/Characters/charactergenerator.scss';
import AllRealms from '../Realms/AllRealms';
import DetailedRealms from '../Realms/DetailedRealms';
import Spinner from '../Spinners/Spinner';
import { useAuth } from '../../hooks/use-auth';
import useGraphQL from '../../hooks/useGraphQL';

import token from '../../assets/images/azamel_Coin.png';
import plus from '../../assets/images/Add.png';
import appStore from '../../store/AppStore';

import { getAvaliableCredits } from '../../views/Dashboard/Dashboard';

/**
 * Represents a component for managing and displaying realms in a dashboard view.
 *
 * @component
 * @example
 * // Example usage of DashboardView component
 * <DashboardView />
 *
 * @returns {JSX.Element} React element representing the dashboard view for managing realms.
 */

export type ViewButtonType = 'gridView' | 'detailedView';

// const RealmsHeader = () => (
//   <div className={s.layout__background}>
//     <img
//       className={s.layout__background__logo}
//       src={RealmsIcon}
//       alt="realm world logo"
//     />
//     <h1 className={s.layout__background__text}>My Realms</h1>
//     <div className={s.layout__background__gradient} />
//   </div>
// );

const RenderRealmSearch = (
  showFilter: boolean,
  selectedButton: ViewButtonType,
  setSelectedButton: React.Dispatch<React.SetStateAction<ViewButtonType>>,
  setShowFilter: React.Dispatch<React.SetStateAction<boolean>>,
  searchBoxValue: string,
  setSearchBoxValue: React.Dispatch<React.SetStateAction<string>>,
  updateHandler: (() => void) | undefined,
) => {
  return (
    <div className={s.layout__main__buttonWrapper}>
      <div className={s.layout__main__container}>
        <div className={s.layout__main__header}>
          <img className={s.layout__main__image} src={realmIcon} alt="realm" />
          <p className={s.layout__main__text}>Realm Library</p>
        </div>
        <Modal>
          <Modal.Open opensWindowName="createRealm">
            <button type="button" className={s.layout__main__button}>
              <img
                src={plusIcon}
                alt="add icon"
                className={s.layout__main__buttonAdd}
              />
              <img
                src={realmIconActive}
                alt="active realm"
                className={s.layout__main__buttonRealm}
              />
              <p className={s.layout__main__buttonText}>Create Realm</p>
            </button>
          </Modal.Open>

          <Modal.Window name="createRealm">
            <CreateRealmModal updateApp={updateHandler} />
          </Modal.Window>
        </Modal>
      </div>

      <div className={s.layout__main__actions}>
        <div className={s.layout__main__actionsSearch}>
          <SearchBox
            componentId="realms-searchbox"
            iconPosition="right"
            dataField={['name']}
            includeFields={['name', 'owner']}
            autosuggest={false}
            AIConfig={{}}
            AIUIConfig={{}}
            type="search"
            compoundClause="must"
            enableIndexSuggestions={false}
            enterButton={false}
            className="my_custom_searchbox realm"
            defaultValue={searchBoxValue}
            onValueChange={setSearchBoxValue}
          />
        </div>
        <div className={s.layout__main__actionsView}>
          <button
            className={s.layout__main__actionsButton}
            type="button"
            onClick={() => setShowFilter(!showFilter)}
          >
            <img
              src={filterIcon}
              alt="filter icon"
              className={s.layout__main__actionsIcon}
            />
          </button>

          <button
            className={`${s.layout__main__actionsButton} ${
              selectedButton === 'detailedView' ? s.active : ''
            }`}
            type="button"
            onClick={() => {
              setSelectedButton('detailedView');
            }}
          >
            <img
              src={detailedView}
              alt="detailed view"
              className={`${s.layout__main__actionsIcon} ${
                selectedButton === 'detailedView' ? s.active : ''
              }`}
            />
          </button>
          <button
            className={`${s.layout__main__actionsButton} ${
              selectedButton === 'gridView' ? s.active : ''
            }`}
            type="button"
            onClick={() => {
              setSelectedButton('gridView');
            }}
          >
            <img
              src={gridView}
              alt="grid view"
              className={`${s.layout__main__actionsIcon} ${
                selectedButton === 'gridView' ? s.active : ''
              }`}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

/*
  USAGE: {renderFavorites(
         favoriteRealms,
         realmsError,
         isLoading,
         defaultRealmView,
      )}
*/
const renderFavorites = (
  favoriteRealms: GetRealmsQuery | undefined,
  realmsError: unknown,
  isLoading: boolean,
  defaultRealmView: any,
) => {
  return (
    <div className={s.layout__main__favorites}>
      <div className={s.layout__main__header}>
        <img
          className={s.layout__main__image}
          src={favoritesIcon}
          alt="favorites"
        />
        <p className={s.layout__main__text}>Favorite Realms</p>
      </div>

      <SwiperComponent
        favoriteRealms={favoriteRealms}
        realmsError={realmsError}
        isLoading={isLoading}
        defaultRealmView={defaultRealmView}
      />
    </div>
  );
};

const DashboardView = () => {
  const [selectedButton, setSelectedButton] =
    useState<ViewButtonType>('gridView');

  // State to track the value of the search box
  const [searchBoxValue, setSearchBoxValue] = useState('');

  const [showFilter, setShowFilter] = useState(false);

  // const [realmsUpdate, setRealmsUpdate] = useState('realms');
  const [appUpdate, setAppUpdate] = useState('realms_index');
  const [updateRender, setUpdateRender] = useState(false);

  const updateApp = () => {
    setAppUpdate('');
    setTimeout(() => {
      setAppUpdate('realms_index');
    }, 200);
  };

  const updateState = () => {
    setTimeout(() => {
      setUpdateRender((prevValue) => !prevValue);
    }, 200);
  };

  // Function to handle changes in the search box valu
  /*
  const handleSearchBoxChange = (value: any) => {
    setSearchBoxValue(value);
  };
*/

  const navigate = useNavigate();

  const auth = useAuth();

  const userProfile = auth?.user?.uid
    ? useGraphQL(GetUserProfileDocument, {
        query: {
          _id: auth.user.uid,
        },
      }).data
    : null;

  const userWallet = auth?.user?.uid
    ? useGraphQL(GetUserWalletDocument, {
        query: {
          _id: auth.user.uid,
        },
      }).data
    : null;

  appStore.setState({
    userName:
      userProfile?.user?.username ?? (auth?.user?.displayName as string),
    userCredits: getAvaliableCredits(userWallet ?? {}),
  });

  const userCredits: number = appStore((state) => state.userCredits);

  const userName: string = appStore((state) => state.userName as string);

  const userPhoto: string | null = appStore(
    (state) => state.userProfilePhoto as string,
  );

  const defaultRealmView = appStore((state) => state.realmDefaultView);

  // console.log('Search value', searchBoxValue);

  const {
    data: favoriteRealms,
    error: realmsError,
    isLoading,
    refetch: refetchFavoriteRealms,
  } = useGraphQL(GetRealmsDocument, {
    limit: 100,
    sortBy: RealmSortByInput.CreatedatDesc,
    query: {
      is_favorited: true,
    },
  });

  return (
    <div className={s.layout}>
      <div className={s.layout__background}>
        <img
          className={s.layout__background__logo}
          src={RealmsIcon}
          alt="realm world logo"
        />
        <div className={s.token_wrapper} onClick={() => navigate('/settings')}>
          <img
            className={s.token}
            src={token}
            alt="token"
            onClick={() => navigate('/settings')}
          />
          <p>{userCredits !== null ? userCredits : 'loading...'}</p>
          <button type="button" className={s.addButton}>
            <img src={plus} alt="token" />
          </button>
        </div>
        <h1 className={s.layout__background__text}>My Realms</h1>
        <div className={s.layout__background__gradient} />
      </div>

      <ReactiveBase
        app={appUpdate}
        url={process.env.REACT_APP_MONGO_ATLAS_SEARCH_URL as string}
        transformRequest={(request: any) => ({
          ...request,
          headers: {
            ...request.headers,
            Authorization: `Bearer ${localStorage.getItem('realmToken')}`,
          },
        })}
        // @ts-ignore
        mongodb={{
          collection: 'realms',
          db: 'realm_worlds',
        }}
        style={{ minHeight: '100%', height: 'fit-content' }}
        analytics={false}
        credentials="none"
      >
        <div className={s.layout__main} style={{ height: '100%' }}>
          {RenderRealmSearch(
            showFilter,
            selectedButton,
            setSelectedButton,
            setShowFilter,
            searchBoxValue,
            setSearchBoxValue,
            updateState,
          )}

          <ReactiveList
            componentId="realms-results"
            dataField="_score"
            size={12}
            pagination
            innerClass={{
              pagination: 'list_pagination',
              resultStats: 'list_resultStats',
              resultsInfo: `list_resultsInfo ${!showFilter && 'hide'}`,
              noResults: 'list_noResults',
            }}
            className="dashboard_reactive_list realm"
            compoundClause="must"
            react={{
              and: ['realms-searchbox'],
            }}
            onError={(err) => console.log(err)}
            sortOptions={[
              {
                dataField: 'createdAt',
                sortBy: 'desc',
                label: 'Recent',
              },
              {
                dataField: 'createdAt',
                sortBy: 'asc',
                label: 'Oldest',
              },
              {
                dataField: 'name',
                sortBy: 'asc',
                label: 'Name - A-Z',
              },
              {
                dataField: 'name',
                sortBy: 'desc',
                label: 'Name - Z-A',
              },
            ]}
            showResultStats={false}
            render={({ data, loading }: any) => {
              if (loading) return <Spinner />;

              return selectedButton === 'gridView' ? (
                <div className={allRealmStyles.realmGridContainer}>
                  {/* <div className={allRealmStyles.realmGridWrapper}> */}
                  <div className={allRealmStyles.realmGrid}>
                    {data ? (
                      data.map((realm: Realm) => (
                        <AllRealms
                          key={realm._id}
                          id={realm._id}
                          thumbnail_url={realm.thumbnail_url}
                          realmName={realm.name}
                          tags={realm.tags}
                          isFavorited={realm.is_favorited}
                          refetchFavoritedReams={refetchFavoriteRealms}
                        />
                      ))
                    ) : (
                      <div className={allRealmStyles.error}>
                        Nothing to see here... You don’t have anything in your
                        library yet.
                      </div>
                    )}
                  </div>
                  {/* </div> */}
                </div>
              ) : (
                <div className={detailedRealmStyles.main}>
                  <div className={detailedRealmStyles.main__header}>
                    <Typography
                      className={detailedRealmStyles.detail__info}
                      variant="h6"
                    >
                      Thumbnail
                    </Typography>
                    <Typography
                      className={detailedRealmStyles.detail__info}
                      variant="h6"
                    >
                      Realm Metadata
                    </Typography>
                    <Typography
                      className={detailedRealmStyles.detail__info}
                      variant="h6"
                    >
                      Tokens
                    </Typography>
                    <Typography
                      className={detailedRealmStyles.detail__info}
                      variant="h6"
                    >
                      Characters
                    </Typography>
                    <Typography
                      className={detailedRealmStyles.detail__info}
                      variant="h6"
                    >
                      Actions
                    </Typography>

                    <div
                      className={detailedRealmStyles.main__header__actionsEmpty}
                    />
                  </div>
                  {data ? (
                    data.map((realm: Realm) => (
                      <DetailedRealms
                        key={realm._id}
                        id={realm._id}
                        thumbnail_url={realm.thumbnail_url}
                        realmName={realm.name}
                        tags={realm.tags}
                        isFavorited={realm.is_favorited}
                        description={realm.description}
                      />
                    ))
                  ) : (
                    <div className={allRealmStyles.error}>
                      Nothing to see here... You don&apos;t have anything in
                      your library yet.
                    </div>
                  )}
                </div>
              );
            }}
          />
        </div>
      </ReactiveBase>
    </div>
  );
};

export default DashboardView;
