/* eslint-disable implicit-arrow-linebreak */
import { create } from 'zustand';
import { NavString } from '../interfaces/navitems';
import { RWLibraryString } from '../interfaces/rwlibrary';
import { MyLibraryString } from '../interfaces/mylibrary';
import { MyCollectionString } from '../interfaces/mycollection';
import {
  Character,
  Character_Set,
  GetRealmQuery,
  GetUserCharacterGenerationQuery,
  Token,
  Token_Set,
  Campaign,
} from '../__generated__/graphql';
import cookieUtil from '../util/cookieUtil';

/**
 * @type {ActiveTab} Represents the possible active tabs, in the filter dropdown in token maker.
 */
export type ActiveTab =
  | 'none'
  | 'frame'
  | 'background'
  | 'overlay'
  | 'text'
  | 'notes'
  | 'library'
  | 'mycollection'
  | 'aigenerate'
  | 'shoppingcart';
/**
 * @type {ViewTypeOptions} Represents the possible viewing options for Character Catalog.
 */
export type ViewTypeOptions = 'detail' | 'grid';
/**
 * @type {ThumbnailSource} Represents the possible sources for thumbnail in creating Character Set.
 * - used in CharacterSetModalPreview component
 */
export type ThumbnailSource = 'upload' | 'library' | 'color' | '';
/**
 * @type {ExpansionVariant} Represents the variant for the Expansion & Expansion Header components.
 */
export type ExpansionVariant = 'character' | 'token-maker';
/**
 * To aid reusability with slight variations in the UI.
 * @type {SampleNote} Temporary type for sample notes until integration for Character Catalog.
 */
export type SampleNote = {
  _id: string;
  title: string;
  date: string;
  content: string;
};

export type CreateCharacterViewOptions = 'start' | 'sheet' | 'wizard';

export type CharacterSheetMoodOptions = 'create' | 'edit';

export type RealmDefaultViewOptions = 'default' | 'character' | 'tokenmaker' | 'campaign';

export type CharacterViewOptions = 'creator' | 'catalog' | 'store';

export type CharacterWizardViewOptions =
  | 'race'
  | 'characteristics'
  | 'motives'
  | 'background'
  | 'summary';

interface IAppStore {
  activeNavItem: NavString | null;
  activeRWLibraryFilter: RWLibraryString | null;
  activeMyLibraryFilter: MyLibraryString | null;
  imageDropped: boolean;
  recentChangeName: string;
  /**
   * aiWorkFlowId - @type {number} - Represents the workflowID for image generation in Token Maker.
   */
  aiWorkFlowId: number;
  /**
   * aiIsLoading - @type {boolean} - Tells if AI image is being generated in Token Maker.
   */
  aiIsLoading: boolean;
  /**
   * aiIsLoaded - @type {boolean} - Tells if AI image is done generating in Token Maker.
   */
  aiIsLoaded: boolean;
  /**
   * activeMyCollectionFilter - @type {MyCollectionString | null}
   * - Represents active filter in My Collection in Token Maker.
   * selectedToken - @type {Token | null} - Represents selected Token in Token Maker.
   */
  activeMyCollectionFilter: MyCollectionString | null;
  reset: () => void;
  selectedToken: Token | null;
  selectedTokenSet: Token_Set | null;
  /**
   * Represents the state structure of the application store.
   * updateTokenModalOpen - @type {boolean}
   * - Tells if Update Token Modal should be open in Token Maker.
   * deleteTokenModalOpen - @type {boolean}
   */
  updateTokenModalOpen: boolean;

  updateTokenSetModalOpen: boolean;
  /**
   * Tells if Delete Token Modal should be open in Token Maker.
   */
  deleteTokenModalOpen: boolean;
  /**
   * activetab - @type {ActiveTab} - Dictates which panel is open in Token Maker.
   */
  activeTab: ActiveTab;
  /**
   * userName - @type {string | null} - Stores the user's username.
   */
  userName: string | null;
  /**
   * userCredits - @type {number} - Stores the user's usercredits.
   */
  userCredits: number;
  /**
   * selectedRealm - @type {GetRealmQuery['realm'] | null} - Stores selected realm from Dashboard.
   */
  selectedRealm: GetRealmQuery['realm'] | null;
  /**
   * viewType - @type {ViewTypeOptions} - Stores selected view type for Character Catalog.
   */
  viewType: ViewTypeOptions;
  /**
   * previewThumbnailSource - @type {ThumbnailSource}
   * - Stores selected preview thumbnail type for Character Set creation.
   */
  previewThumbnailSource: ThumbnailSource;
  /**
   * openCharacterSummaryModal - @type {boolean}
   * - Tells if Character Summary Modal should be open in Character Catalog.
   * openPackPurchaseSummaryModal - @type {boolean}
   */
  openCharacterSummaryModal: boolean;
  /**
   * Tells if Pack Purchase Summary Modal should be open in Character Store.
   */
  openPackPurchaseSummaryModal: boolean;
  /**
   * Tells if Pack Purchase Summary Modal should be open in Token Store.
   */
  openTokenPackPurchaseSummaryModal: boolean;
  TokenPackPurchaseSummary: any;
  /**
   * This is an array list of objects currently in the RW store cart
   *
   * @default []
   */
  rwStoreCartItems: Array<[]>;
  /**
   * This is an array list of objects currently in the Token store cart
   *
   * @default []
   */
  tokenStoreCartItems: Array<[]>;
  selectedCharacter: Character | null;
  /**
   * @type {CreateCharacterViewOptions}
   * - Dictates the selected view in the Create Character Page.
   */
  createCharacterView: CreateCharacterViewOptions;
  defaultCreateCharacterView: CreateCharacterViewOptions;
  selectedCharacterView: CharacterViewOptions;
  characterSheetMood: CharacterSheetMoodOptions;
  characterSetMood: CharacterSheetMoodOptions;
  selectedCharacterWizardView: CharacterWizardViewOptions;
  selectedCharacterSet: any | Character_Set | null;
  realmDefaultView: RealmDefaultViewOptions;
  selectedCharacterPack: any | null;
  localCartObject: string;
  openShoppingCartModal: boolean;
  openEmptyCartModal: boolean;
  selectedRealmDetail: any | undefined;
  userProfilePhoto: string | null;
  userCharacterGeneration: GetUserCharacterGenerationQuery | null | undefined;
  openAICharacterGenModal: boolean;
  selectedCampaign: Campaign | null;
}

const storeDefaults = {
  activeNavItem: null,
  activeRWLibraryFilter: null,
  activeMyLibraryFilter: null,
  imageDropped: false,
  recentChangeName: 'Initial',
  aiWorkFlowId: -1,
  aiIsLoading: false,
  aiIsLoaded: false,
  activeMyCollectionFilter: 'Recent' as 'Recent',
  selectedToken: null,
  selectedTokenSet: null,
  updateTokenModalOpen: false,
  deleteTokenModalOpen: false,
  updateTokenSetModalOpen: false,
  activeTab: 'none' as 'none',
  userName: null,
  userCredits: 0,
  selectedRealm: null,
  viewType: 'grid' as 'grid',
  previewThumbnailSource: '' as '',
  openCharacterSummaryModal: false,
  openPackPurchaseSummaryModal: false,
  openTokenPackPurchaseSummaryModal: false,
  TokenPackPurchaseSummary: {},
  rwStoreCartItems: [],
  tokenStoreCartItems: [],
  selectedCharacter: null,
  createCharacterView: 'start' as 'start',
  defaultCreateCharacterView: 'start' as 'start',
  selectedCharacterView: 'catalog' as 'catalog',
  characterSheetMood: 'create' as 'create',
  characterSetMood: 'create' as 'create',
  selectedCharacterWizardView: 'race' as 'race',
  selectedCharacterSet: null,
  realmDefaultView: 'default' as 'default',
  selectedCharacterPack: null,
  localCartObject: cookieUtil.getCookie('userCartObject'),
  openShoppingCartModal: false,
  openEmptyCartModal: false,
  selectedRealmDetail: undefined,
  userProfilePhoto: null,
  userCharacterGeneration: null,
  openAICharacterGenModal: false,
  selectedCampaign: null,
};

const appStore = create(
  (set: any): IAppStore => ({
    ...storeDefaults,
    // Reset function to reset store values to default
    reset: () =>
      set({
        ...storeDefaults,
      }),
  }),
);

export default appStore;
