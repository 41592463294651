/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Stack, useMediaQuery } from '@mui/material';
import useGraphQL from '../../../hooks/useGraphQL';
import { GetManyMediaAssetsDocument } from '../../../__generated__/graphql';
import CharacterLibraryExpansionItem from './CharacterLibraryExpansionItem';

interface Props {
  mediaAssetIds: string[] | undefined;
  isExpanded: boolean;
  previewBackground: any;
  handleLibraryImageClick: (link: string) => void;
}

function CharacterLibraryExpansionItemWrapper({
  mediaAssetIds,
  isExpanded,
  previewBackground,
  handleLibraryImageClick,
}: Props) {
  const { data: mediaAssets, error: mediaAssetsError } = useGraphQL(
    GetManyMediaAssetsDocument,
    {
      query: {
        _id_in: mediaAssetIds ?? [],
      },
    },
  );
  // const isTabletScreen = useMediaQuery('(max-width:865px)');
  const isMobileScreen = useMediaQuery('(max-width:530px)');
  const sliceBy = isMobileScreen ? 6 : 4;

  if (mediaAssetsError) console.log(mediaAssetsError);

  return (
    <Stack gap={isMobileScreen ? '5px' : '15px'} direction="row" alignItems="center" flexWrap="wrap">
      {mediaAssets !== undefined && (
        <>
          {mediaAssets.media_assets.slice(0, sliceBy).map((asset, index) => (
            <CharacterLibraryExpansionItem
              key={`${asset?.name}_${index}`}
              previewUrl={previewBackground}
              displayUrl={asset?.thumbnail_url!}
              imageUrl={asset?.asset_url!}
              handleLibraryImageClick={handleLibraryImageClick}
            />
          ))}
          {isExpanded && mediaAssets.media_assets.slice(sliceBy).map((asset, index) => (
            <CharacterLibraryExpansionItem
              key={`${asset?.name}_${index}`}
              previewUrl={previewBackground}
              displayUrl={asset?.thumbnail_url!}
              imageUrl={asset?.asset_url!}
              handleLibraryImageClick={handleLibraryImageClick}
            />
          ))}
        </>
      )}
    </Stack>
  );
}

export default CharacterLibraryExpansionItemWrapper;
