function setCookie(cookieName: string, cookieValue: string, exdays: number): void {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cookieName}=${cookieValue};${expires};path=/`;
}

function getCookie(cookieName: string): string {
  const name = `${cookieName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

function deleteCookie(cookieName: string): void {
  const expires = 'expires=Thu, 01 Jan 1970 00:00:00 UTC';
  document.cookie = `${cookieName}=;${expires};path=/`;
}

const cookieUtil = {
  setCookie,
  getCookie,
  deleteCookie,
};

export default cookieUtil;
