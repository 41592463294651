/* eslint-disable object-curly-newline */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/comma-dangle */
/* eslint-disable operator-linebreak */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
/* eslint-disable @typescript-eslint/indent */
import React, { useContext, useMemo, useState, useEffect } from 'react';
import { ChromePicker, ColorResult, RGBColor, SketchPicker } from 'react-color';
import {
  ImageList,
  ImageListItem,
  CardActionArea,
  Box,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { StageData } from '../../../redux/currentStageData';
import useItem from '../../../hooks/useItem';
import { StyledTab, StyledTabs, setTabId } from '../StyledPanel/StyledPanel';
import TabPanel from '../../tab/TabPanel';
import { canvasWidth, canvasHeight } from '../../CanvasConstants';
import borderList from '../../../config/borders.json';
import RWColorPicker from '../../colorPicker/RWColorPicker';
import PanelItemNew from '../PanelItem/PanelItemNew';
import { ThemeContext } from '../../../context/ThemeContext';
import '../eachpanelmodifier.scss';
// import { Grid } from '@mui/material';

export interface PanelItemProps {
  onStageChange: (changeName: string) => void;
}

export default function FramePanel({ onStageChange }: PanelItemProps) {
  const colorToRGB = (
    rgb: string,
  ): { red: number; green: number; blue: number; alpha: number } => {
    const result =
      rgb.length > 0 ? rgb.replace(/[^\d,.]/g, '').split(',') : null;
    return result
      ? {
          red: parseInt(result[0], 10),
          green: parseInt(result[1], 10),
          blue: parseInt(result[2], 10),
          alpha: parseFloat(result[3] ?? '1.0'),
        }
      : {
          red: 0,
          green: 0,
          blue: 0,
          alpha: 0,
        };
  };
  const { createItem, removeItem, updateItem, stageData } = useItem();
  const targetItemBorder = useMemo(
    () =>
      (stageData as StageData[]).find(
        (data) => data.id === 'border' || data.attrs.id === 'border',
      ),
    [stageData],
  );

  const [color, setColor] = React.useState<RGBColor>(() =>
    targetItemBorder?.attrs?.color
      ? targetItemBorder.attrs.color
      : {
          r: 255,
          g: 255,
          b: 255,
          a: 1.0,
        },
  );

  const handleColorChange = (color: any) => {
    setColor(color.rgb);
  };

  const handleColorChangeCompleted = (color: ColorResult) => {
    if (targetItemBorder) {
      const { r, b, g, a } = color.rgb;

      onStageChange('Updated Border Color');
      updateItem(targetItemBorder.id, (prevData) => ({
        ...targetItemBorder.attrs,
        red: r,
        green: g,
        blue: b,
        opacity: a,
        color: color.rgb,
        updatedAt: Date.now(),
      }));

      setColor(color.rgb);
    }
  };

  const isTabletScreen = useMediaQuery('(max-width:865px)');

  const insertImage = (data: { [key: string]: any }) => {
    const imageSrc = new Image();
    let imageSource = `${process.env.PUBLIC_URL}/images/borders/${data.imageSrc}`;
    const { r, b, g, a } = color;
    removeItem('border');
    removeItem('border-background');
    imageSrc.onload = () => {
      const width = isTabletScreen ? data.imageWidth / 1.5 : data.imageWidth;
      const height = isTabletScreen ? data.imageHeight / 1.5 : data.imageHeight;
      const newBorder: StageData = {
        id: 'border',
        attrs: {
          name: 'label-target',
          dataItemType: 'image',
          x: (canvasWidth - width) / 2,
          y: (canvasHeight - height) / 2,
          width,
          height,
          red: r,
          green: g,
          blue: b,
          opacity: a,
          src: imageSource,
          zIndex: 10,
          draggable: false,
          brightness: 0,
          color: { color },
          filters: ['RGB'],
          updatedAt: Date.now(),
        },
      };

      imageSource = `${process.env.PUBLIC_URL}/images/masks/${data.maskSrc}`;
      const maskWidth = isTabletScreen ? data.maskWidth / 1.5 : data.maskWidth;
      const maskHeight = isTabletScreen ? data.maskHeight / 1.5 : data.maskHeight;
      const newBackground: StageData = {
        id: 'border-background',
        attrs: {
          name: 'label-target',
          dataItemType: 'image',
          x: (canvasWidth - maskWidth) / 2,
          y: (canvasHeight - maskHeight) / 2,
          width: maskWidth,
          height: maskHeight,
          red: 255,
          green: 255,
          blue: 255,
          src: imageSource,
          zIndex: 0,
          draggable: false,
          brightness: 0,
          color: { color },
          filters: ['RGB', 'ClipMask'],
          updatedAt: Date.now(),
        },
      };

      onStageChange('Updated Border');
      createItem(newBorder);
      onStageChange('Updated Border Background');
      createItem(newBackground);
    };

    imageSrc.src = `${process.env.PUBLIC_URL}/images/borders/${data.imageSrc}`;
  };

  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const { theme } = useContext(ThemeContext);

  const [active, setActive] = useState('');
  const localActive = localStorage.getItem('activeFrame');

  useEffect(() => {
    if (localActive !== null) {
      setActive(JSON.parse(localActive));
    } else {
      setActive('');
    }
  }, []);

  // set localstorage for active item
  useEffect(() => {
    localStorage.setItem('activeFrame', JSON.stringify(active));
  }, [active]);

  return (
    <>
      <StyledTabs value={tabValue} onChange={handleTabChange}>
        <StyledTab label="Border" {...setTabId(0)} />
        <StyledTab label="Colors" {...setTabId(0)} />
      </StyledTabs>
      <TabPanel value={tabValue} index={0}>
        <Grid container>
          <Grid item xs={12} display="flex" flexWrap="wrap">
            <ImageList
              cols={2}
              className={
                theme === 'light' ? 'imageList light' : 'imageList dark'
              }
            >
              <PanelItemNew
                onClick={() => {
                  setActive('');
                  removeItem('border');
                  removeItem('border-background');
                }}
              >
                <ImageListItem>
                  <Typography textAlign="center">None</Typography>
                </ImageListItem>
              </PanelItemNew>

              {/* <button
                type="button"
                className="panelCard"
                onClick={() => {
                  removeItem('border');
                  removeItem('border-background');
                }}
              >
                <div className="panelCard__item">
                  <Typography textAlign="center">None</Typography>
                </div>
                <span className="background" />
              </button> */}
              {/* <CardActionArea
                // className="panelCard"
                onClick={() => {
                  removeItem('border');
                  removeItem('border-background');
                }}
              >
                <ImageListItem className="panelCard__item">
                  <Typography textAlign="center">None</Typography>
                </ImageListItem>
                <span className="background" />
              </CardActionArea> */}
              {borderList.map((item, index) => (
                <PanelItemNew
                  key={index}
                  onClick={() => {
                    insertImage(item);
                    setActive(`${item.imageSrc}`);
                  }}
                  active={active === item.imageSrc}
                >
                  <ImageListItem key={index} sx={{ margin: 1.65 }}>
                    <img
                      key={index}
                      src={`${process.env.PUBLIC_URL}/images/borders/${item.imageSrc}`}
                      srcSet={`${process.env.PUBLIC_URL}/images/borders/${item.imageSrc}`}
                      alt={item.id}
                      loading="lazy"
                    />
                    <span className="background" />
                  </ImageListItem>
                </PanelItemNew>
                // <CardActionArea
                //   // className="panelCard"
                //   key={index}
                //   onClick={() => {
                //     insertImage(item);
                //   }}
                // >
                //   <ImageListItem
                //     // className="panelCard__item"
                //     key={index}
                //     sx={{ margin: 3 }}
                //   >
                //     <img
                //       key={index}
                //       src={`${process.env.PUBLIC_URL}/images/borders/${item.imageSrc}`}
                //       srcSet={`${process.env.PUBLIC_URL}/images/borders/${item.imageSrc}`}
                //       alt={item.id}
                //       loading="lazy"
                //     />
                //     <span className="background" />
                //   </ImageListItem>
                // </CardActionArea>
              ))}
            </ImageList>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Box>
          <RWColorPicker
            color={color}
            onChange={handleColorChange}
            onChangeComplete={handleColorChangeCompleted}
          />
          {/* <Grid container mt={2}>
            <Grid item xs={12} mb={2} display="flex" flexWrap="wrap">
            </Grid>
          </Grid> */}
        </Box>
      </TabPanel>
    </>
  );
}
