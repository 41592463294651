import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import styled from '@emotion/styled';
import { FlexibleHeightImg } from '../styledComps/styledComps';
import RWMascott from '../../images/rwmascott-warning.webp';
import FantasyIcon from '../../images/fantasy.webp';
import WhiteDiceIcon from '../../images/dice-white.webp';
import WhiteTrashIcon from '../../images/trash-white.webp';

/**
 * Represents the props for the `CreateCharacterSetModal` component.
 *
 * @interface
 * @property {boolean} open - Controls the visibility of the modal.
 * @property {() => void} onClose - Callback function when the modal is closed.
 * @property {() => void} [setOpen] - Callback function to set the modal visibility.
 * @property {() => void} onConfirm - Callback function when the user confirms the action.
 */
type RWModalProps = {
  open: boolean;
  onClose: () => void;
  setOpen?: (v: boolean) => void;
  onConfirm: () => void;
  variant: 'ai' | 'randomize' | 'delete';
  message: string;
  hideable?: boolean;
} & React.PropsWithChildren;

const OptionButton = styled(Button)({
  borderRadius: '5px',
  display: 'block',
  border: 'none',
  outline: 'none',
  padding: '10px 24px',
  cursor: 'pointer',
  color: 'white',
  background: '#41521F',
  '&:hover': {
    background: '#41521F',
  },
  '&.ai': {
    background: '#DA16A2',
    '&:hover': {
      background: '#DA16A2',
    },
  },
  '&.randomize': {
    background: '#7A3900',
    '&:hover': {
      background: '#7A3900',
    },
  },
  '&.delete': {
    background: 'red',
    '&:hover': {
      background: 'red',
    },
  },
});

const PasseroFont = styled(Typography)({
  fontFamily: 'Passero One',
  fontWeight: '400',
  '&.title': {
    color: '#250503',
    fontSize: '36px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '42px',
  },
  '&.buttonText': {
    color: '#fff',
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    textTransform: 'none',
  },
});

export default function CreateCharacterSetModal({
  open,
  onClose,
  setOpen,
  onConfirm,
  variant,
  message,
  hideable,
}: RWModalProps) {
  const handleClose = () => {
    onClose?.();
    setOpen?.(false);
  };

  const confirmIcon = () => {
    if (variant === 'ai') return FantasyIcon;
    if (variant === 'delete') return WhiteTrashIcon;
    return WhiteDiceIcon;
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => handleClose()}
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              backdropFilter: 'blur(4px)',
            },
          },
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ zIndex: 999 }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '30%',
            left: '50%',
            transform: 'translate(-50%, -30%)',
            width: '718px',
            outline: 'none',
            overflow: 'hidden',
          }}
        >
          {/* heading and close button */}
          <Box
            sx={{
              width: '100%',
              borderRadius: '20px 20px 0px 0px',
              background: '#41521F',
              padding: '12px 20px',
              boxSizing: 'border-box',
              display: 'flex',
              alignItems: 'center',
              gap: '35px',
            }}
          >
            <button
              type="button"
              onClick={handleClose}
              style={{
                display: 'block',
                background: 'transparent',
                border: 'none',
                outline: 'none',
                filter: 'none',
                cursor: 'pointer',
              }}
              aria-label="close"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 10 10"
                width={10}
              >
                <polygon
                  style={{ fill: '#fff', strokeWidth: 0 }}
                  points="6.03 5 10 8.97 9.49 9.49 8.97 10 5 6.03 1.03 10 .51 9.49 0 8.97 3.97 5 0 1.03 .51 .51 1.03 0 5 3.97 8.97 0 9.49 .51 10 1.03 6.03 5"
                />
              </svg>
            </button>
          </Box>
          <Box
            sx={{
              width: '100%',
              borderRadius: '0px 0px 20px 20px',
              background: '#FFF',
              padding: '45px 65px',
              boxSizing: 'border-box',
              maxHeight: '600px',
            }}
          >
            <Stack direction="row" gap="24px">
              <FlexibleHeightImg width="253px" src={RWMascott} />
              <Stack gap="23px">
                <Box>
                  <PasseroFont className="title">Heads up</PasseroFont>
                  <Typography
                    sx={{
                      color: '#250503',
                      fontFamily: 'DM Sans',
                      fontSize: '24px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '30px',
                      letterSpacing: '.1px',
                    }}
                    pt="3px"
                  >
                    {message}
                  </Typography>
                </Box>
                <Stack direction="row" gap="30px">
                  <OptionButton onClick={handleClose}>
                    <PasseroFont className="buttonText">Cancel</PasseroFont>
                  </OptionButton>
                  <OptionButton className={variant} onClick={onConfirm}>
                    <Stack direction="row" justifyContent="center" gap="8px" alignItems="center">
                      <FlexibleHeightImg width="20px" src={confirmIcon()} />
                      <PasseroFont className="buttonText">Confirm</PasseroFont>
                    </Stack>
                  </OptionButton>
                </Stack>
                {/* Don't show this again */}
                {hideable && (
                  <FormControlLabel
                    control={(
                      <Checkbox
                        inputProps={{ 'aria-label': 'Do not show this again' }}
                        // onChange={handleHideCreateStart}
                        sx={{
                          color: '#E9DCC3',
                          boxSizing: 'border-box',
                          borderRadius: '4px',
                          '&.Mui-checked': {
                            color: '#41521F',
                          },
                        }}
                      />
                    )}
                    label="Do not show this again."
                    sx={{
                      '.MuiTypography-root': {
                        color: '#1D1B20',
                        fontFamily: 'DM Sans',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '24px',
                        letterSpacing: '.5px',
                      },
                    }}
                  />
                )}
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

/**
 * Default props for the `CreateCharacterSetModal` component.
 */
CreateCharacterSetModal.defaultProps = {
  setOpen: () => {},
  hideable: false,
};
