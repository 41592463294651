import JSZip from 'jszip';
import { saveAs } from 'file-saver';
// import useGraphQL from '../hooks/useGraphQL';
// import { GetCharactersDocument } from '../__generated__/graphql';

const createZip = (object: any, image: Blob, zipname: string): any => {
  const zip = new JSZip();

  const jsonName = object.name;
  const jsonString = JSON.stringify(object);
  zip.file(`${jsonName}.json`, jsonString);
  zip.file(`${jsonName}.txt`, jsonString);
  zip.file(`${jsonName}.png`, image);

  zip.generateAsync({ type: 'blob' }).then((content: Blob) => {
    saveAs(content, zipname);
  });
};

// eslint-disable-next-line max-len
async function setCharacter(zip: JSZip, character: any, zipname: string, counter: number, total: number) {
  // const { data: fetchedCharacter } = useGraphQL(
  //   GetCharactersDocument,
  //   {
  //     query: {
  //       _id: character?._id,
  //     },
  //   },
  // );
  if (total && total > 0 && counter < total) {
    try {
      const finalDetails = {
        name: character?.name,
        abilities: {
          charisma: character?.abilities?.charisma,
          constitution: character?.abilities?.consitution,
          dexterity: character?.abilities?.dexterity,
          intelligence: character?.abilities?.intelligence,
          strength: parseInt(character?.abilities?.strength!, 10),
          wisdom: character?.abilities?.wisdom,
        },
        alignment: character?.alignment,
        birthstory: character?.backstory,
        class: character?.class,
        gender: character?.gender,
        height: character?.height,
        home: character?.home,
        life_altering_event: character?.life_altering_event,
        long_term_goals: character?.long_term_goals,
        motivations: character?.motivations,
        notes: character?.notes?.map(
          (note: any) => ({
            title: note?.title,
            content: note?.content,
          }),
        ),
        physical_profile: character?.physical_profile,
        profession: character?.profession,
        pronoun: character?.pronoun,
        race: character?.race,
        realms: character?.realms,
        relationship_status: character?.relationship_status,
        short_term_goals: character?.short_term_goals,
        weight: character?.weight,
      };
      const characterContent = JSON.stringify(finalDetails);
      const contentName = character?.name;
      console.log(counter);
      zip.file(`${contentName}_${counter + 1}.json`, characterContent);
      zip.file(`${contentName}_${counter + 1}.txt`, characterContent);
      const url = character?.avatar_url!;
      const response = await fetch(url);
      const blober = await fetch(url).then((r) => r.blob());
      if (!response.ok) {
        throw new Error(`Failed to fetch data from ${url}`);
      }
      zip.file(`${contentName}_${counter + 1}.png`, blober);
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  if (counter === (total - 1) || total === 0) {
    zip.generateAsync({ type: 'blob' }).then((content: Blob) => {
      saveAs(content, zipname);
    });
  }
  return null;
}

export const setCreateZip = (set: any, image: Blob, zipname: string, characters: any[]): any => {
  const zip = new JSZip();
  console.log(set, image, zipname, characters);
  let counter = 0;

  zip.file('_characterset_backgroundImage.png', image);
  // const jsonName = set.name;
  for (let i = 0; i <= characters.length; i += 1) {
    setCharacter(zip, characters[i], zipname, counter, characters.length);
    console.log(characters[i]);
    counter += 1;
  }
};

export default createZip;
