import React from 'react';
import Expansion from '../../panel/Expansion/Expansion';
import CharacterLibraryExpansionItemWrapper from './CharacterLibraryExpansionItemWrapper';

interface Props {
  pack: any;
  previewBackground:any;
  handleLibraryImageClick: (link: string) => void;
}

function CharacterLibraryExpansionWrapper({
  pack,
  previewBackground,
  handleLibraryImageClick,
}: Props) {
  const [isExpanded, setIsExpanded] = React.useState(false);
  return (
    <Expansion
      title={pack.name}
      isExpanded={isExpanded}
      setIsExpanded={() => setIsExpanded(!isExpanded)}
      variant="character"
    >
      <CharacterLibraryExpansionItemWrapper
        mediaAssetIds={pack.media_assets}
        isExpanded={isExpanded}
        previewBackground={previewBackground}
        handleLibraryImageClick={handleLibraryImageClick}
      />
    </Expansion>
  );
}

export default CharacterLibraryExpansionWrapper;
