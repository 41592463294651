/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable arrow-body-style */
import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import coin from '../../images/azamel_Coin.png';
import s from './Credits.module.scss';
import SubscriptionPopup from '../../components/modals/SubscriptionPopupLight/SubscriptionPopupLight';
import {
  GetUserWalletDocument,
  GetUserWalletQuery,
} from '../../__generated__/graphql';
import useGraphQL from '../../hooks/useGraphQL';
import { useAuth } from '../../hooks/use-auth';
import useNotification from '../../hooks/useNotification';

const Credits = () => {
  const auth = useAuth();
  const { displayNotification } = useNotification();
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);
  const { data: userProfile, error: userProfileError } = useGraphQL(
    GetUserWalletDocument,
    {
      query: {
        _id: auth?.user?.uid,
      },
    },
  );
  if (userProfileError) {
    displayNotification({
      message: 'Error loading user profile',
      type: 'error',
    });
  }

  const getAvaliableCreidts = (query: GetUserWalletQuery) => {
    let credits = 0;
    if (query?.user?.wallet) {
      credits += query.user.wallet?.ssgpCreditPackCreditsRemaining ?? 0;
      credits += query.user.wallet?.ssgpSubscriptionCreditsRemaining ?? 0;
      credits -= query.user.wallet?.ssgpPendingCredits ?? 0;
    }
    return credits;
  };
  return (
    <div className={s.layout}>
      <p className={s.layout__heading}>Subscription & Billing</p>
      <div className={s.box}>
        <div className={s.wrapper}>
          <p className={s.wrapper__heading}>
            Hello{' '}
            {userProfile?.user?.username ?? userProfile?.user?.email ?? 'ERROR'}
            ,
            <br />
            Thanks for being a{' '}
            {userProfile?.user?.user_subscription?.subscription_tier?.name}{' '}
            member!
          </p>
          <div className={s.wrapper__box}>
            <p className={s.wrapper__subscription}>Subscription Credits</p>
            <div className={s.wrapper__container}>
              <div className={s.wrapper__content}>
                <div className={s.wrapper__package}>
                  <div className={s.wrapper__package__subscriptionContent}>
                    <p className={s.wrapper__package__name}>Wallet</p>
                  </div>
                </div>
                <Box marginTop={4} />
                <div className={s.wrapper__package__actionsBox}>
                  <p className={s.wrapper__package__text}>Remaining Credits:</p>
                  <Typography
                    variant="h4"
                    component="div"
                    gutterBottom
                    marginLeft={0}
                    marginRight={0}
                  >
                    {userProfile ? getAvaliableCreidts(userProfile) : '0'}
                  </Typography>
                  <Button
                    sx={{
                      display: 'block',
                      padding: '4px 10px',
                      borderRadius: '4px',
                      background: '$realm-green',
                      boxShadow:
                        '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                      border: 'none',
                      outline: 'none',
                      width: '81px',
                      color: '$white-2',
                      fontFamily: 'Passero One',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '22px',
                      letterSpacing: '0.46px',
                      textTransform: 'uppercase',
                    }}
                    type="button"
                    onClick={() => setOpenSubscriptionModal(true)}
                    disabled
                  >
                    Buy Credits
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Credits;
