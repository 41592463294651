/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react/require-default-props */
/* eslint-disable consistent-return */
/* eslint-disable consistent-return */
import React, { MouseEventHandler } from 'react';
import { Box, Tooltip, Zoom } from '@mui/material';
import './expansion.scss';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import fullStar from '../../../assets/images/full-star.png';
import emptyStar from '../../../assets/images/empty-star.png';
import {
  GetTokenDocument,
  Token,
  UpdateOneTokenDocument,
} from '../../../__generated__/graphql';
import useGraphQL from '../../../hooks/useGraphQL';
import useNotification from '../../../hooks/useNotification';
import useGraphQLMutation from '../../../hooks/useGraphQLMutation';
import { FloatingMenu, FloatingMenuItem } from '../../floatingMenu';
import appStore from '../../../store/AppStore';

interface Props {
  token: Token;
  updateApp?: () => void;
}

interface MenuPosition {
  top: string;
  left: string;
}

function ExpansionTokenItem({ token, updateApp }: Props) {
  const [favorited, setFavorited] = React.useState(
    token?.is_favorited && token?.is_favorited !== undefined
      ? token.is_favorited
      : false,
  );
  const [openMenu, setOpenMenu] = React.useState(false);
  const { data: gqlToken } = useGraphQL(GetTokenDocument, {
    query: {
      _id: token._id,
    },
  });

  const tokenRef = React.useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (e: MouseEvent) => {
    if (tokenRef.current && !tokenRef.current.contains(e.target as Node)) {
      setOpenMenu(false);
    }
  };

  const [menuPosition, setMenuPosition] = React.useState<MenuPosition>({
    top: '0',
    left: '0',
  });

  const handleContextMenu: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    console.log('token: ', gqlToken?.token);
    const top = `${e.clientY}px`;
    const left = `${e.clientX}px`;
    setMenuPosition({ top, left });
    setOpenMenu(true);
    setOpenMenu(!openMenu);
    appStore.setState({
      selectedToken: token,
    });
  };

  React.useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const { mutate: updateToken, status: updateTokenStatus } = useGraphQLMutation(
    UpdateOneTokenDocument,
  );

  const { displayNotification } = useNotification();

  const setTokenFavoriteValue = async (
    tokenId: string,
    isFavorited: boolean,
  ) => {
    // setLoading(true);
    if (isFavorited !== undefined) {
      displayNotification({
        message: !isFavorited
          ? 'Marking token as a favorite...'
          : 'Unfavoriting token...',
        type: 'info',
      });
      setFavorited(!isFavorited);
      updateToken({
        set: {
          is_favorited: !isFavorited,
        },
        query: {
          _id: tokenId,
        },
      });
    } else {
      return null;
    }
  };

  React.useEffect(() => {
    if (updateTokenStatus === 'error') {
      displayNotification({
        message: token.is_favorited
          ? 'Failed to unfavorite token'
          : 'Failed to mark token as favorite',
        type: 'error',
      });
      setFavorited(token.is_favorited);
    }
    if (updateTokenStatus === 'success') {
      displayNotification({
        message: token.is_favorited
          ? 'Unfavorited token successfully'
          : 'Successfully marked token as favorite',
        type: 'success',
      });
      updateApp && updateApp();
    }
  }, [updateTokenStatus]);

  const handleDownloadToken = async () => {
    try {
      const name = token.name ?? 'unnamed-token';
      const tokenUrl = token.token_url ?? '';
      if (!tokenUrl) {
        console.error('No tokenUrl found');
        return;
      }
      // const response = await fetch(tokenUrl.replace('https://storage.googleapis.com', `${REACT_APP_URL}/tokens`));
      const response = await fetch(tokenUrl);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = name?.endsWith('.png') ? name : `${name}.png`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(blobUrl);
      displayNotification({
        message: `${token.name} downloaded successfully`,
        type: 'success',
      });
    } catch (error) {
      console.error('Failed to download file', error);
      displayNotification({
        message: `Failed to download the ${token.name} token`,
        type: 'error',
      });
    }
  };

  return (
    <Box sx={{ position: 'relative', width: 100, height: 100 }} ref={tokenRef}>
      {token?.thumbnail_url && token?.thumbnail_url !== undefined ? (
        <Tooltip
          title={token.name}
          arrow
          placement="bottom"
          TransitionComponent={Zoom}
          followCursor
          componentsProps={{
            tooltip: {
              sx: {
                fontFamily: 'Passero One',
                fontWeight: '400px',
                fontSize: '18px',
                padding: '10px',
                bgcolor: '#41521Fe6',
                '& .MuiTooltip-arrow': {
                  color: '#41521Fe6',
                },
              },
            },
          }}
        >
          <Box onClick={handleContextMenu}>
            <img
              src={token.thumbnail_url}
              alt={token.name}
              className="expansionTokenItemImage"
            />
          </Box>
        </Tooltip>
      ) : (
        <span>No thumbnail available</span>
      )}
      <Box
        className="expansionTokenItemStar"
        onClick={() => {
          setTokenFavoriteValue(token._id, token.is_favorited);
        }}
      >
        <Tooltip
          title={`${
            token?.is_favorited ? 'Click to Unfavorite' : 'Click to Favorite'
          }`}
          arrow
          placement="bottom"
          TransitionComponent={Zoom}
          componentsProps={{
            tooltip: {
              sx: {
                fontFamily: 'Passero One',
                fontWeight: '400px',
                fontSize: '14px',
                padding: '10px',
                bgcolor: '#3c3c3ce6',
                '& .MuiTooltip-arrow': {
                  color: '#3c3c3ce6',
                },
              },
            },
          }}
        >
          {favorited ? (
            <StarIcon
              sx={{
                color: 'white',
                fontSize: '30px',
                cursor: `url(${emptyStar}),auto`,
              }}
            />
          ) : (
            <StarOutlineIcon
              sx={{
                color: 'white',
                fontSize: '30px',
                cursor: `url(${fullStar}),auto`,
              }}
            />
          )}
        </Tooltip>
      </Box>
      {openMenu && (
        <Box
          sx={{
            position: 'fixed',
            zIndex: '10',
            top: menuPosition.top,
            left: menuPosition.left,
          }}
        >
          <FloatingMenu>
            <FloatingMenuItem
              onClick={() => {
                appStore.setState({
                  updateTokenModalOpen: true,
                  activeTab: 'none',
                });
                setOpenMenu(false);
              }}
            >
              Update Token
            </FloatingMenuItem>
            <FloatingMenuItem
              onClick={() => {
                appStore.setState({
                  deleteTokenModalOpen: true,
                  activeTab: 'none',
                });
                setOpenMenu(false);
              }}
            >
              Delete Token
            </FloatingMenuItem>
            <FloatingMenuItem
              onClick={() => {
                handleDownloadToken();
                setOpenMenu(false);
              }}
            >
              Download Token
            </FloatingMenuItem>
          </FloatingMenu>
        </Box>
      )}
    </Box>
  );
}

export default ExpansionTokenItem;
