/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable arrow-body-style */
import React from 'react';
import { Box, Modal } from '@mui/material';
import avatar from '../../assets/images/NewDashboard/avatar.webp';
import s from './AccountManagement.module.scss';
import ToggleSlider from '../ToggleSlider/ToggleSlider';
import { useAuth } from '../../hooks/use-auth';
import { useUpdateUserProfile } from '../../__generated__/realmWorldsApiComponents';
import useNotification from '../../hooks/useNotification';
import useGraphQL from '../../hooks/useGraphQL';
import { GetUserProfileDocument, UpdateUserDocument } from '../../__generated__/graphql';
import useGraphQLMutation from '../../hooks/useGraphQLMutation';
import loadingGif from '../../assets/images/loading.gif';
import Spinner from '../Spinners/Spinner';
import PasswordReset from './PasswordReset';
import appStore from '../../store/AppStore';

const AccountManagement = () => {
  const auth = useAuth();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [newUserName, setNewUserName] = React.useState<string>('');
  const { displayNotification } = useNotification();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const updateUserProfileImage = useUpdateUserProfile({
    onSuccess: () => {
      displayNotification({
        message: 'Profile Image updated successfully',
        type: 'success',
      });
    },
    onError: () => {
      displayNotification({
        message: 'There was an error updating your profile image. Please try again later.',
        type: 'error',
      });
    },
  });

  const {
    data: userProfile,
    status: userProfileFetchStatus,
    error: userProfileFetchError,
    refetch: refetchUserProfile,
  } = useGraphQL(
    GetUserProfileDocument,
    {
      query: {
        _id: auth?.user?.uid,
      },
    },
  );
  // console.log('userProfile: ', userProfile);
  // console.log('userProfileFetchStatus: ', userProfileFetchStatus);
  // console.log('userProfileFetchError: ', userProfileFetchError);
  if (userProfileFetchError) console.log('userProfileFetchError: ', userProfileFetchError);
  const {
    mutate: updateUserProfile,
    status: updateUserProfileStatus,
    error: updateUserProfileError,
  } = useGraphQLMutation(UpdateUserDocument);
  // console.log('updatedUserProfile: ', updatedUserProfile);
  // console.log('updateUserProfileStatus: ', updateUserProfileStatus);
  if (updateUserProfileError) console.log('updateUserProfileError: ', updateUserProfileError);
  console.log('updateUserProfileStatus: ', updateUserProfileStatus);

  const handleUploadImage = () => {
    console.log('handleUploadImage');
    // Open the file picker
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (event) => {
      const formData = new FormData();
      // check that the file is an image and not null
      if (!event.target || !(event.target as HTMLInputElement).files) {
        displayNotification({
          message: 'Please select a valid image file',
          type: 'error',
        });
      } else {
        const { files } = (event.target as HTMLInputElement);
        if (files && files.length > 0) {
          formData.append('ProfileImage', files[0]);
          // Upload the file to the server
          updateUserProfileImage.mutate({
            pathParams: {
              userId: auth?.user?.uid || '',
            },
            // @ts-ignore
            body: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }, {
            onSuccess: () => {
              refetchUserProfile();
            },
          });
        }
      }
    };
    input.click();
  };

  const handleUpdateUserProfile = () => {
    console.log('handleUpdateUserProfile');
    updateUserProfile({
      data: {
        username: newUserName,
      },
      query: {
        _id: auth?.user?.uid,
      },
    });
    appStore.setState({
      userName: newUserName,
    });
  };

  React.useEffect(() => {
    if (userProfile?.user) {
      appStore.setState({
        userName: userProfile.user.username,
        userProfilePhoto: userProfile.user.photo_url,
      });
    }
  }, [userProfile]);

  const handleThemeToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log('handleThemeToggle');
    console.log('event.target.checked: ', event.target.checked);
    // Update the user profile with the new theme
    updateUserProfile({
      data: {
        config: {
          defaultTheme: event.target.checked ? 'dark' : 'light',
        },
      },
      query: {
        _id: auth?.user?.uid,
      },
    }, {
      onSuccess: () => {
        refetchUserProfile();
      },
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleLogout = () => {
    console.log('handleLogout');
    auth?.signout();
    // redirect to the home page
    window.location.href = '/';
  };

  return (
    <>
      <Modal
        open={updateUserProfileStatus === 'loading'}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spinner realm />
      </Modal>
      {(!userProfileFetchStatus || userProfileFetchStatus === 'loading' || updateUserProfileImage.isLoading) && (
      <Box className={s.cards_wrapper}>
        <img src={loadingGif} className={s.loading_gif} alt="loading gif" />
      </Box>
      )}
      {userProfileFetchStatus === 'success' && (

      <div className={s.main}>
        <div className={s.wrapper}>
          <div className={s.wrapper__heading}>
            <p className={s.wrapper__heading__text}>Account Management</p>
          </div>

          <div className={s.wrapper__box}>
            <div className={s.wrapper__box__preview}>
              <p className={s.wrapper__box__previewText}>Preview</p>
            </div>
            <div className={s.wrapper__box__imageBox}>
              <img className={s.wrapper__box__image} src={userProfile?.user?.photo_url || avatar} alt="avatar" />
            </div>
            <div className={s.wrapper__box__upload} onClick={handleUploadImage}>
              {' '}
              <p className={s.wrapper__box__uploadText}>Upload Image</p>
            </div>
            <div className={s.wrapper__box__remove}>
              <p className={s.wrapper__box__removeText}>Remove Image</p>
            </div>
          </div>
        </div>
        <div className={s.theme}>
          <div className={s.theme__appearance}>
            <p className={s.theme__appearance__title}>Theme</p>
            <p className={s.theme__appearance__text}>
              Choose how you’d like Realm Worlds to appear. Switch between light
              and dark mode.
            </p>
            <ToggleSlider
              isDarkMode={userProfile?.user?.config?.defaultTheme === 'dark' || false}
              handleToggle={handleThemeToggle}
            />
          </div>
          <div className={s.theme__details}>
            <form className={s.theme__form}>
              <label className={s.theme__form__label}>Update Username</label>
              {/* Set the user name if changed */}
              <input
                className={s.theme__form__input}
                type="text"
                id="username"
                value={newUserName}
                placeholder={userProfile?.user?.username || 'Enter your Username'}
                onChange={(event) => setNewUserName(event.target.value)}
              />
              <button type="button" className={s.theme__form__button} onClick={handleUpdateUserProfile}>
                Update
              </button>
            </form>
          </div>

          {/* <div className={s.theme__details}>
          <form className={s.theme__form}>
            <label className={s.theme__form__label}>Update Email</label>
            <input className={s.theme__form__input} type="email" id="email" />
            <button type="button" className={s.theme__form__button}>
              Update
            </button>
          </form>
        </div> */}
          {/* If this is a google account, then don't show the update password section */}
          { auth?.user?.providerData[0]?.providerId !== 'google.com' && (
            <PasswordReset />
          )}
          <div className={s.theme__deactivate}>
            <p className={s.theme__deactivate__heading}>Logout</p>
            {/* <p className={s.theme__deactivate__text}>
              This deactivates your account permanently!
            </p> */}
            <button type="button" className={s.theme__deactivate__button} onClick={handleLogout}>
              Logout
            </button>
          </div>
        </div>
      </div>
      )}
    </>
  );
};

export default AccountManagement;
