import React, { useContext } from 'react';
import { Tooltip, Zoom } from '@mui/material';
import { ThemeContext } from '../../context/ThemeContext';
import styles from './icons.module.scss';

interface Props {
  onClick: () => void;
  active: boolean;
}

function CharacterWizardIcon({ onClick, active } : Props) {
  const { theme } = useContext(ThemeContext);
  return (
    <Tooltip
      title="Character Wizard"
      arrow
      placement="bottom"
      TransitionComponent={Zoom}
      componentsProps={{
        tooltip: {
          sx: {
            fontFamily: 'Passero One',
            fontWeight: '400px',
            fontSize: '16px',
            padding: '10px',
            bgcolor: '#3c3c3ce6',
            '& .MuiTooltip-arrow': {
              color: '#3c3c3ce6',
            },
            maxWidth: '250px',
          },
        },
      }}
    >
      <button
        onClick={onClick}
        type="button"
        className={`${styles.icon} ${styles.charactersheet_icon} ${styles[theme]} ${active && styles.active}`}
        aria-label="character wizard"
      >
        <svg
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24.4 25"
        >
          <path className={styles.charactersheet_cls_1} d="m20.25,18.11c-5.84-1.01-11.67-1.03-17.54-.26.04-.12.08-.22.12-.32.39-.98.77-1.96,1.16-2.94.04-.09.08-.14.19-.15.35-.05.69-.11,1.04-.18.1-.02.16,0,.21.1.24.47.49.93.73,1.39.05.09.1.12.2.1.11-.02.23-.04.34-.05.08,0,.1-.04.12-.12.09-.54.19-1.08.28-1.61.02-.1.05-.13.15-.14.53-.06,1.06-.12,1.59-.18.08,0,.11-.04.12-.12.01-.11.03-.22.06-.33.03-.1,0-.14-.09-.18-.48-.24-.96-.49-1.44-.73-.08-.04-.11-.08-.09-.17.1-.52.18-1.04.27-1.56.02-.1,0-.17-.1-.21-.07-.03-.14-.07-.22-.11-.19-.09-.18-.09-.34.05-.38.36-.76.71-1.14,1.07-.07.06-.12.08-.21.03-.12-.07-.24-.12-.37-.18.02-.05.03-.09.04-.13.68-1.72,1.35-3.45,2.03-5.17.03-.07.07-.14.12-.18.7-.6,1.41-1.19,2.12-1.79.08-.07.14-.08.21,0,.06.06.13.12.21.17.14.08.13.17.08.3-.18.43-.34.86-.51,1.29-.04.1-.03.16.07.22.09.06.18.12.26.19.07.06.12.05.19,0,.44-.29.89-.56,1.32-.85.08-.06.14-.05.22,0,.44.31.89.62,1.33.93.08.05.13.06.21,0,.12-.09.3-.16.34-.28.04-.11-.07-.27-.11-.41-.13-.4-.25-.8-.38-1.19-.03-.09-.02-.14.06-.2.44-.31.87-.63,1.31-.94.07-.05.09-.1.06-.18-.03-.06-.05-.13-.07-.2-.08-.24-.08-.23-.33-.22-.47.02-.94.03-1.41.05-.11,0-.16-.03-.19-.13-.06-.19-.12-.38-.19-.57-.02-.07-.02-.11.04-.16.85-.61,1.69-1.21,2.53-1.82.08-.06.15-.06.24-.03,1.05.28,2.05.7,3.03,1.18,2.26,1.11,4.31,2.54,6.21,4.19.02.02.05.03.08.05-.14.15-.26.28-.39.4-.15.15-.32.3-.47.46-.06.06-.13.08-.22.06-1.15-.19-2.31-.29-3.48-.28-1.19.02-2.36.14-3.48.56-.12.04-.24.1-.36.15-.07.03-.1.06-.08.14.05.23.11.46.14.69.02.11.08.12.16.12.43.02.86.03,1.29.05.21,0,.42.02.63.02.1,0,.13.04.14.13.13.7.27,1.4.41,2.11.27,1.41.55,2.82.82,4.24.23,1.16.45,2.33.67,3.49.02.11.04.21.06.34Zm-3.25-5.18c-1.14.39-2.18.27-3.05-.55-.89-.83-1.05-1.86-.64-2.99-1.37.62-2.01,2.24-1.29,3.61.8,1.53,2.85,1.95,4.22.87.32-.25.57-.55.76-.94Z" />
          <path className={styles.charactersheet_cls_1} d="m10.78,18.63c1.23.05,2.45.08,3.68.15,1.45.08,2.9.25,4.33.46,1.27.19,2.52.43,3.77.73.1.02.15.07.17.17.19.9.25,1.8,0,2.69-.21.72-.63,1.29-1.29,1.66-.09.05-.19.08-.3.07-.6-.05-1.2-.09-1.8-.11-1.27-.05-2.55-.12-3.82-.14-1.42-.03-2.84-.02-4.26-.01-.83,0-1.66.04-2.49.08-1.08.06-2.16.12-3.23.2-1.03.08-2.05.19-3.08.3-.36.04-.71.09-1.07.13-.06,0-.14-.02-.19-.06-.62-.61-1.01-1.35-1.15-2.21-.16-1.07.13-2.04.63-2.97.09-.16.19-.24.37-.27.97-.14,1.93-.31,2.9-.43,1.02-.12,2.04-.21,3.07-.29.69-.05,1.39-.08,2.08-.09.56-.01,1.13,0,1.69,0,0-.02,0-.04,0-.06Z" />
        </svg>
      </button>
    </Tooltip>
  );
}

export default CharacterWizardIcon;
