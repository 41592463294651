import React from 'react';
import { Stack, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { SubHeadingText } from '../../styledComps/styledComps';

interface Props {
  title: string;
  imageSource: string;
  message: string;
}

const TitBitImg = styled('img')({
  width: 'auto',
  height: '28px',
});

function CharacterTitBit({
  title,
  imageSource,
  message,
}: Props) {
  return (
    <Stack direction="row" gap="15px" width="100%">
      <TitBitImg src={imageSource} />
      <Stack>
        <SubHeadingText>{title}</SubHeadingText>
        <Typography
          sx={{
            color: '#250503',
            fontFamily: 'DM Sans',
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '30px', /* 125% */
            letterSpacing: '0.1px',
          }}
        >
          {message}
        </Typography>
      </Stack>
    </Stack>
  );
}

export default CharacterTitBit;
