/* eslint-disable object-curly-newline */
export default function getRealmCampaigns() {
  return [
    { id: '1',
      name: 'Dragons Rise',
      description: 'Dragons Rise - the land of Arkana has been ruled by the Dragonborn for centuries. The Warlocks of the West have always been a thorn in their side, but now they have formed an alliance. The Dragonborn are in danger of losing their power.',
      image: 'https://storage.googleapis.com/smartai-stockimage-dev-storage-watermark-na/Fantasy/20240502/01f2cc32-b08a-4f8a-8b22-8b4b5d522ba6.png',
      tags: ['fantasy', 'political intrigue', 'dragons', 'magic', 'warlocks', 'dragonborn'],
      calendar: 'Gregorian',
      epoch: 'Medieval',
      history: 'The land of Arkana has been ruled by the Dragonborn for centuries. The Warlocks of the West have always been a thorn in their side, but now they have formed an alliance. The Dragonborn are in danger of losing their power.',
      campaignCalendarStart: '1/1/1432',
      campaignCalendarEnd: '12/1/1434',
      acts: [
        { id: '1',
          name: 'Act 1 - The Dragonborn Debates',
          description: 'The Dragonborn debate the Warlock alliance',
          actCalendarStart: '1/1/1432',
          actCalendarEnd: '2/1/1434',
          image: 'https://storage.googleapis.com/smartai-stockimage-dev-storage-watermark-na/Fantasy/20240214/3dd9fed1-24ee-46c9-8e8c-447170ec3b17.png',
          plots: [
            { id: '8888888-213123-2132',
              name: 'Plot Point #1',
              description: 'Description 1',
              image: 'https://via.placeholder.com/150',
              plotline: 'the debates over the Warlock alliance',
              outcomes: [
                { id: '8888888-213123-2132-01', name: 'Outcome #1', description: 'Outcome 1 Description', image: 'https://via.placeholder.com/150', result: 'the Dragonborn agree to the Warlock alliance' },
                { id: '8888888-213123-2132-02', name: 'Outcome #2', description: 'Outcome 2 Description', image: 'https://via.placeholder.com/150', result: 'the Dragonborn reject the Warlock alliance' },
                { id: '8888888-213123-2132-03', name: 'Outcome #3', description: 'Outcome 3 Description', image: 'https://via.placeholder.com/150', result: 'the Dragonborn are divided over the Warlock alliance' },
              ] },
            { id: '4433122-213123-2132',
              name: 'Plot Point #2',
              description: 'Description 1',
              image: 'https://via.placeholder.com/150',
              plotline: 'the Warlocks form an alliance',
              outcomes: [
                { id: '4433122-213123-2132-01', name: 'Outcome #1', description: 'Outcome 1 Description', image: 'https://via.placeholder.com/150', result: 'the Warlocks send spies to the Dragonborn' },
                { id: '4433122-213123-2132-02', name: 'Outcome #2', description: 'Outcome 2 Description', image: 'https://via.placeholder.com/150', result: 'the Warlocks send assassins to the Dragonborn' },
                { id: '4433122-213123-2132-03', name: 'Outcome #3', description: 'Outcome 3 Description', image: 'https://via.placeholder.com/150', result: 'the Warlocks send diplomats to the Dragonborn' },
              ] },
          ],
          characters: [
            { id: '444441-213123-2132', name: 'Sinisterus Devious', description: 'Character Description 1', image: 'https://storage.googleapis.com/smartai-stockimage-dev-storage-watermark-na/Fantasy/20231026/613f055d-76e6-4f20-bd6b-565a99cb031b.png', role: 'mastermind behind the coup, a cunning court vizier' },
            { id: '77777-213123-2132', name: 'Heroine Saveus', description: 'Character Description 2', image: 'https://storage.googleapis.com/smartai-stockimage-dev-storage-watermark-na/Fantasy/20240110/383e9fbc-74e6-4ef1-8d57-eac4cf41a1d7.png', role: 'vigilante hero' },
          ],
          quests: [
            { id: '123123-213123-2132', name: 'Quest #1', description: 'Discover the motivations behind the coup', image: 'https://via.placeholder.com/150', type: 'main' },
            { id: '123123-213123-2132', name: 'Quest #2', description: 'Stop the coup', image: 'https://via.placeholder.com/150', type: 'main' },
          ],
          encounters: [
            {
              id: 'enc-1001',
              name: 'Ambush at Elderwood Crossing',
              description: 'A tactical combat encounter where players are ambushed by goblins in a foggy forest. Visibility is limited, and the terrain is challenging.',
              type: 'Combat',
              difficulty: 'Moderate',
              environment: {
                name: 'Elderwood Forest',
                description: 'A dense forest with heavy fog reducing visibility. Known for its narrow paths and thick underbrush.',
                weather: 'Foggy',
                terrain: 'Forest',
                image: 'https://via.placeholder.com/150',
                location: { id: '6655777-213123-2132' },
              },
              outcomes: [
                { id: '4433122-213123-2132-01', name: 'Victory', description: 'Players defeat the goblins, gaining loot and information about the goblin tribe\'s activities in the area', image: 'https://via.placeholder.com/150', result: 'Players defeat the goblins' },
                { id: '4433122-213123-2132-02', name: 'Negotiation', description: 'Players negotiate with the goblin shaman, potentially gaining an ally or avoiding combat through a trade of goods', image: 'https://via.placeholder.com/150', result: 'Players negotiate with the goblin shaman' },
                { id: '4433122-213123-2132-03', name: 'Retreat', description: 'Players are overwhelmed and must retreat, possibly leading to a later encounter or a temporary setback in their quest', image: 'https://via.placeholder.com/150', result: 'Players are overwhelmed and must retreat' },
              ],
              characters: [
                { id: '444441-213123-2132', name: 'Sinisterus Devious', description: 'Character Description 1', image: 'https://storage.googleapis.com/smartai-stockimage-dev-storage-watermark-na/Fantasy/20231026/613f055d-76e6-4f20-bd6b-565a99cb031b.png', role: 'mastermind behind the coup, a cunning court vizier' },
                { id: '77777-213123-2132', name: 'Heroine Saveus', description: 'Character Description 2', image: 'https://storage.googleapis.com/smartai-stockimage-dev-storage-watermark-na/Fantasy/20240110/383e9fbc-74e6-4ef1-8d57-eac4cf41a1d7.png', role: 'vigilante hero' },
              ],
            },
          ],
        },
        { id: '2',
          name: 'Act 2 - The Warlock Alliance',
          description: 'The Warlocks form an alliance',
          image: 'https://storage.googleapis.com/smartai-stockimage-dev-storage-watermark-na/Fantasy/20231028/8333cf3b-ba48-4d69-acec-2287287e7491.png',
          plots: [
            { id: '4433122-213123-2132',
              name: 'Plot Point #1',
              description: 'The Warlocks form an alliance - but with Who?',
              image: 'https://storage.googleapis.com/smartai-stockimage-dev-storage-watermark-na/Fantasy/20231028/8333cf3b-ba48-4d69-acec-2287287e7491.png',
              plotline: 'The Warlocks form an alliance - but with Who?',
              outcomes: [
                { id: '4433122-213123-2132-01', name: 'Outcome #1', description: 'Outcome 1 Description', image: 'https://via.placeholder.com/150', result: 'the Warlocks form an alliance with the Dragonborn and peace is restored' },
                { id: '4433122-213123-2132-02', name: 'Outcome #2', description: 'Outcome 2 Description', image: 'https://via.placeholder.com/150', result: 'the Warlocks form an alliance with the Dragonborn and betray them' },
                { id: '4433122-213123-2132-03', name: 'Outcome #3', description: 'Outcome 3 Description', image: 'https://via.placeholder.com/150', result: 'the Warlocks form an alliance with the Dragonborn and are destroyed' },
                { id: '4433122-213123-2132-04', name: 'Outcome #4', description: 'Outcome 4 Description', image: 'https://via.placeholder.com/150', result: 'the Warlocks fail to form an alliance with the Dragonborn and are destroyed' },
                { id: '4433122-213123-2132-05', name: 'Outcome #5', description: 'Outcome 5 Description', image: 'https://via.placeholder.com/150', result: 'the Warlocks form an alliance with the enemy of the Dragonborn' },
              ] },
          ],
          characters: [
            { id: '444441-213123-2132', name: 'Sinisterus Devious', description: 'Character Description 1', image: 'https://storage.googleapis.com/smartai-stockimage-dev-storage-watermark-na/Fantasy/20231026/613f055d-76e6-4f20-bd6b-565a99cb031b.png', role: 'mastermind behind the coup, a cunning court vizier' },
            { id: '77777-213123-2132', name: 'Heroine Saveus', description: 'Character Description 2', image: 'https://storage.googleapis.com/smartai-stockimage-dev-storage-watermark-na/Fantasy/20240110/383e9fbc-74e6-4ef1-8d57-eac4cf41a1d7.png', role: 'vigilante hero' },
          ],
          quests: [
            { id: '123123-213123-2132', name: 'Quest #1', description: 'Discover Warlock motivations', image: 'https://via.placeholder.com/150', type: 'main' },
            { id: '123123-213123-2132', name: 'Quest #2', description: 'Stop the Warlock alliance', image: 'https://via.placeholder.com/150', type: 'main' },
          ],
          encounters: [],
        },
        { id: '3',
          name: 'Act 3 - The Dragonborn Fall',
          description: 'The Dragonborn fall to the Warlocks',
          image: 'https://storage.googleapis.com/smartai-stockimage-dev-storage-watermark-na/Fantasy/20240110/a807b168-e11d-45c4-a925-1228f11c921c.png',
          plots: [
            { id: '4433122-213123-2132',
              name: 'Plot Point #1',
              description: 'Description 1',
              image: 'https://via.placeholder.com/150',
              plotline: 'the Warlocks formed an alliance with the Dragonborn but betray them',
              outcomes: [
                { id: '4433122-213123-2132-01', name: 'Outcome #1', description: 'Outcome 1 Description', image: 'https://via.placeholder.com/150', result: 'the Warlocks betray the Dragonborn and take over the kingdom' },
                { id: '4433122-213123-2132-02', name: 'Outcome #2', description: 'Outcome 2 Description', image: 'https://via.placeholder.com/150', result: 'the Warlocks betray the Dragonborn and form a secret alliance with the enemy of the Dragonborn' },
                { id: '4433122-213123-2132-03', name: 'Outcome #3', description: 'Outcome 3 Description', image: 'https://via.placeholder.com/150', result: 'the Warlocks betray the Dragonborn and are destroyed' },
              ] },
          ],
          quests: [],
          characters: [],
          encounters: [],
        },
      ],
      locations: [
        { id: '6655777-213123-2132', name: 'Arkana', description: 'Arkana Description 1', image: 'https://storage.googleapis.com/smartai-stockimage-dev-storage-watermark-na/Cinema/20240405/3e60affa-8d59-4c0f-8c88-8a17eb135aeb.png', type: 'village' },
        { id: '987565-213123-2132', name: 'Sithon', description: 'Sithon Description 1', image: 'https://storage.googleapis.com/smartai-stockimage-dev-storage-watermark-na/Fantasy/20240505/3cd1f67a-1d50-48a7-b5ef-ae502ec93cc0.png', type: 'forest' },
      ],
    },
    { id: '2',
      name: 'The Darkening',
      description: 'The Darkening - the land of Arkana has been plunged into darkness. The skies have turned black, and the people are afraid. The evil forces that have caused this are unknown, but they must be stopped. The fate of the world hangs in the balance.',
      image: 'https://storage.googleapis.com/smartai-stockimage-dev-storage-watermark-na/Fantasy/20231028/cd6a4493-17e0-403c-ba01-9243f6457a17.png',
      tags: ['fantasy', 'magic', 'darkness', 'evil', 'unknown forces'],
      calendar: 'Gregorian',
      epoch: 'Medieval',
      history: 'An unnatural darkening of the skies has fallen over the land of Arkana. The people are afraid, and the evil forces behind this must be stopped. The fate of the world hangs in the balance.',
      campaignCalendarStart: '1/1/1200',
      campaignCalendarEnd: '1/1/1210',
      acts: [
        { id: '1',
          name: 'Act 1 - The Darkening',
          description: 'Campaign Act 1 Description',
          image: 'https://storage.googleapis.com/smartai-stockimage-dev-storage-watermark-na/Fantasy/20231016/12768eec-3ffd-4d1a-b51f-cc2a6a4cb68d.png',
          plots: [
            { id: '8888888-213123-2132',
              name: 'Plot Point #1',
              description: 'Description 1',
              image: 'https://via.placeholder.com/150',
              plotline: 'the political coup has installed an evil dictator',
              outcomes: [
                { id: '8888888-213123-2132-01', name: 'Outcome #1', description: 'Outcome 1 Description', image: 'https://via.placeholder.com/150', result: 'the evil dictator is overthrown' },
                { id: '8888888-213123-2132-02', name: 'Outcome #2', description: 'Outcome 2 Description', image: 'https://via.placeholder.com/150', result: 'the evil dictator is successful and grows stronger' },
                { id: '8888888-213123-2132-03', name: 'Outcome #3', description: 'Outcome 3 Description', image: 'https://via.placeholder.com/150', result: 'the evil dictators power is neutered by adverserial forces in the kingdom' },
              ],
            },
            { id: '4433122-213123-2132',
              name: 'Plot Point #2',
              description: 'Description 1',
              image: 'https://via.placeholder.com/150',
              plotline: 'villages near the borderlands are being pillaged',
              outcomes: [
                { id: '4433122-213123-2132-01', name: 'Outcome #1', description: 'Outcome 1 Description', image: 'https://via.placeholder.com/150', result: 'the pillaging stops' },
                { id: '4433122-213123-2132-02', name: 'Outcome #2', description: 'Outcome 2 Description', image: 'https://via.placeholder.com/150', result: 'the pillaging continues and spreads' },
                { id: '4433122-213123-2132-03', name: 'Outcome #3', description: 'Outcome 3 Description', image: 'https://via.placeholder.com/150', result: 'the pillaging stops but the pillagers are not caught' },
              ],
            },
          ],
          quests: [],
          characters: [
            { id: '444441-213123-2132', name: 'Drakonis Mavus', description: 'Character Description 1', image: 'https://storage.googleapis.com/smartai-stockimage-dev-storage-watermark-na/Fantasy/20231028/474ec09d-8457-41c6-b36e-352ae0c2307e.png', role: 'evil dictator' },
            { id: '77777-213123-2132', name: 'Leafus Badis', description: 'Character Description 2', image: 'https://storage.googleapis.com/smartai-stockimage-dev-storage-watermark-na/Comics/20240427/43de8a28-8aff-4f75-98ab-308bc6cf02e7.png', role: 'vigilante hero' },
          ],
          encounters: [],
        },
        { id: '2',
          name: 'Act 2 - The Shadows',
          description: 'The shadows have grown long and dark, and the people are afraid. The evil forces that have caused this must be stopped. The fate of the world hangs in the balance.',
          image: 'https://storage.googleapis.com/smartai-stockimage-dev-storage-watermark-na/Cinema/20240403/40630127-7cf1-4652-983d-ab8877fe0605.png',
          plots: [
            { id: '8888888-213123-2132',
              name: 'Plot Point #1',
              description: 'Description 1',
              image: 'https://via.placeholder.com/150',
              plotline: 'the people are being led to believe the Elves magic are causing the darkness',
              outcomes: [
                { id: '8888888-213123-2132-01', name: 'Outcome #1', description: 'Outcome 1 Description', image: 'https://via.placeholder.com/150', result: 'the people are convinced and the Elves are exiled' },
                { id: '8888888-213123-2132-02', name: 'Outcome #2', description: 'Outcome 2 Description', image: 'https://via.placeholder.com/150', result: 'the people are convinced and the Elves are executed' },
                { id: '8888888-213123-2132-03', name: 'Outcome #3', description: 'Outcome 3 Description', image: 'https://via.placeholder.com/150', result: 'the people are convinced and the Elves are imprisoned' },
              ],
            },
            { id: '4433122-213123-2132',
              name: 'Plot Point #2',
              description: 'Description 1',
              image: 'https://via.placeholder.com/150',
              plotline: 'the practice of magic is being blamed for the darkness and Barbarian hordes are attacking the cities',
              outcomes: [
                { id: '4433122-213123-2132-01', name: 'Outcome #1', description: 'Outcome 1 Description', image: 'https://via.placeholder.com/150', result: 'the practice of magic is outlawed and the Barbarians are defeated' },
                { id: '4433122-213123-2132-02', name: 'Outcome #2', description: 'Outcome 2 Description', image: 'https://via.placeholder.com/150', result: 'the practice of magic is outlawed and the Barbarians are not defeated' },
                { id: '4433122-213123-2132-03', name: 'Outcome #3', description: 'Outcome 3 Description', image: 'https://via.placeholder.com/150', result: 'the practice of magic is not outlawed and the Barbarians are defeated' },
              ],
            },
          ],
          quests: [],
          characters: [
            { id: '444441-213123-2132', name: 'Sylvar Stormfront', description: 'Character Description 1', image: 'https://cdn.discordapp.com/attachments/1100891040547881101/1206081263404974080/39211a7a-bdc7-11ee-95ae-0242ac110002-0.png?ex=663d9635&is=663c44b5&hm=fa82d8a341d8ce2a78ca5d7b154bcf7eb6e9d33364b542d28a282a4f6d1c157a&', role: 'Elven mage' },
            { id: '77777-213123-2132', name: 'Gorak the Barbarian', description: 'Character Description 2', image: 'https://cdn.discordapp.com/attachments/1100891040547881101/1206078772953092176/e01b4f6e-bdc0-11ee-95ae-0242ac110002-0.png?ex=663d93e3&is=663c4263&hm=c0fd0b16de2c343251e4992aa136568e1be07ada687d540eead5cfb0b87b63cd&', role: 'Barbarian warlord' },
          ],
          encounters: [],
        },
        { id: '3',
          name: 'Act 3 - The Light',
          description: 'Campaign Act 3 Description',
          image: 'https://storage.googleapis.com/smartai-stockimage-dev-storage-watermark-na/Nature/20240417/fac01e75-ff15-4016-bcae-7a78719118f2.png',
          plots: [],
          quests: [],
          characters: [],
          encounters: [],
        },
      ],
      locations: [
        { id: '6655777-213123-2132', name: 'Arkana', description: 'Arkana Description 1', image: 'https://via.placeholder.com/150', type: 'city' },
        { id: '987565-213123-2132', name: 'Sithon', description: 'Sithon Description 1', image: 'https://via.placeholder.com/150', type: 'city' },
        { id: '987565-213123-2132', name: 'Drakton', description: 'Drakton Description 1', image: 'https://via.placeholder.com/150', type: 'village' },
      ],
    },
    { id: '3',
      name: 'The Elven Wars',
      description: 'During the Elven Wars, the Elves of the North fought against the Dwarves of the South. The Elves were victorious, but at a great cost. The Dwarves were forced to retreat to the mountains, and the Elves were left to rebuild their kingdom.',
      image: 'https://storage.googleapis.com/smartai-stockimage-dev-storage-watermark-na/Cinema/20240102/2b72fe50-78e5-4e37-941a-53f2a35a0ceb.png',
      tags: ['fantasy', 'war', 'elves', 'dwarves'],
      calendar: 'Elronian',
      epoch: 'The Enlightenment',
      history: 'The Elven Wars were a time of great strife and turmoil. The Elves of the North fought against the Dwarves of the South, and the land was torn asunder. The Elves were victorious, but at a great cost. The Dwarves were forced to retreat to the mountains, and the Elves were left to rebuild their kingdom.',
      campaignCalendarStart: '1/1/1000',
      campaignCalendarEnd: '1/1/1005',
      acts: [
        { id: '1',
          name: 'Act 1 - The Golden BattleAxe',
          description: 'Campaign Act 1 Description',
          image: 'https://cdn.discordapp.com/attachments/1100891040547881101/1203586935969419295/f7b5f0ba-c325-11ee-8c4b-0242ac110002-1.png?ex=663d14ef&is=663bc36f&hm=975cd3d78268a04a9418d68e7c63187f83fcf8547e8168f7b4f2526f3aa9388a&',
          plots: [
            { id: '8888888-213123-2132',
              name: 'Plot Point #1',
              description: 'PP1 - Description 1',
              image: 'https://via.placeholder.com/150',
              plotline: 'the Dwarves are planning a counterattack',
              outcomes: [],
            },
            { id: '4433122-213123-2132', name: 'Plot Point #2', description: 'PP2 - Description 1', image: 'https://via.placeholder.com/150', plotline: 'the Elves are searching for a lost artifact' },
          ],
          quests: [],
          characters: [
            { id: '444441-213123-2132', name: 'Elrond the Wise', description: 'Character Description 1', image: 'https://storage.googleapis.com/smartai-stockimage-dev-storage-watermark-na/Fantasy/20230430/88db9d6a-149a-427d-b708-18a9c994b3de.png', role: 'Elven king' },
            { id: '77777-213123-2132', name: 'Gimli the Brave', description: 'Character Description 2', image: 'https://storage.googleapis.com/smartai-stockimage-dev-storage-watermark-na/Fantasy/20230430/ae6655ee-b622-4b7f-8bce-2c99f766992c.png', role: 'Dwarven warrior' },
          ],
          encounters: [],
        },
        { id: '2',
          name: 'Act 2 - The Silver Bow',
          description: 'Campaign Act 2 Description',
          image: 'https://storage.googleapis.com/smartai-stockimage-dev-storage-watermark-na/Fantasy/20230822/08c92dec-931b-4e45-bb1e-c6265f406857.png',
          plots: [],
          quests: [],
          characters: [],
        },
        { id: '3',
          name: 'Act 3 - The Bronze Shield',
          description: 'Campaign Act 3 Description',
          image: 'https://storage.googleapis.com/smartai-stockimage-dev-storage-watermark-na/Character%20Design/20240321/73cfa2b4-e6b1-4c39-804f-6a7af66def63.png',
          plots: [],
          quests: [],
          characters: [],
          encounters: [],
        },
      ],
      locations: [
        { id: '6655777-213123-2132', name: 'Elvenwood', description: 'Elvenwood Description 1', image: 'https://via.placeholder.com/150', type: 'forest' },
        { id: '987565-213123-2132', name: 'Dwarvenhold', description: 'Dwarvenhold Description 1', image: 'https://via.placeholder.com/150', type: 'mountain' },
      ],
    },
  ];
}
