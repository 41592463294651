/* eslint-disable react/react-in-jsx-scope */
import {
  Box,
  Typography,
  Button,
  Card,
  CardMedia,
  CardContent,
  useMediaQuery,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DownloadIcon from '@mui/icons-material/Download';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useEffect, useState } from 'react';
import DashboardHeader from '../DashboardHeader/DashboardHeader';
import BackgroundImage from '../../images/character-catalog.webp';
import getGalleryCharacters from './Community.data';

type Character = ReturnType<typeof getGalleryCharacters>[0];

function getGalleryImages() {
  return [
    'https://storage.googleapis.com/ssgp-stockimage/m9so2Sl0WnZ45rj6B0AWckIaS2L2/13ade538-d5be-4bf7-9040-41a7dfdb1abf/e1b68b8b-bb6e-4656-b35b-f60466946d61/202404/19018-0.png',
    'https://storage.googleapis.com/ssgp-stockimage/m9so2Sl0WnZ45rj6B0AWckIaS2L2/13ade538-d5be-4bf7-9040-41a7dfdb1abf/e1b68b8b-bb6e-4656-b35b-f60466946d61/202404/19017-3.png',
    'https://storage.googleapis.com/ssgp-stockimage/m9so2Sl0WnZ45rj6B0AWckIaS2L2/13ade538-d5be-4bf7-9040-41a7dfdb1abf/e1b68b8b-bb6e-4656-b35b-f60466946d61/202404/19015-3.png',
    'https://storage.googleapis.com/ssgp-stockimage/m9so2Sl0WnZ45rj6B0AWckIaS2L2/13ade538-d5be-4bf7-9040-41a7dfdb1abf/e1b68b8b-bb6e-4656-b35b-f60466946d61/202404/19018-3.png',
    'https://storage.googleapis.com/ssgp-stockimage/m9so2Sl0WnZ45rj6B0AWckIaS2L2/13ade538-d5be-4bf7-9040-41a7dfdb1abf/e1b68b8b-bb6e-4656-b35b-f60466946d61/202404/19018-1.png',
  ];
}

function CommunityView() {
  useEffect(() => {
  }, []);

  const [currentTab, setCurrentTab] = useState('characters');
  const isMobileScreen = useMediaQuery('(max-width:500px)');
  const isTabletScreen = useMediaQuery('(max-width:865px)');
  const fontSize = () => {
    if (isMobileScreen) {
      return '40px';
    }
    if (isTabletScreen) {
      return '50px';
    }
    return '100px';
  };

  const background = () => (
    <Box
      sx={{
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        pt: 15,
        width: '100vw',
        height: '100%',
        position: 'relative',
        boxSizing: 'border-box',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          height: '100%',
          width: '100%',
          background: 'linear-gradient(180deg, #ffffff00 30%, #E9DCC3 100%)',
          zIndex: 2,
        }}
      />
      <Typography sx={{
        color: '#FFF',
        textAlign: 'center',
        fontFamily: 'Passero One',
        fontSize: fontSize(),
        fontStyle: 'normal',
        textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        fontWeight: 400,
        lineHeight: 'normal',
        paddingBottom: 5,
        zIndex: 3,
      }}
      >
        Community Page
      </Typography>
    </Box>
  );

  const showCommunityGallery = () => (
    <Box>
      <div
        style={{
          display: 'flex', gap: '20px', flexWrap: 'wrap', justifyContent: 'center',
        }}
      >
        {getGalleryImages().map((url, index) => (
          // eslint-disable-next-line jsx-a11y/img-redundant-alt
          <img
            style={{
              width: '150px',
              display: 'inline-block',
              // make image cards professional with borders, accents, and padding
              padding: '10px',
              border: '1px solid black',
              borderRadius: '10px',
            }}
            // eslint-disable-next-line react/no-array-index-key
            key={`image${index}`}
            alt="Image"
            src={url}
          />
        ))}
      </div>
    </Box>
  );

  const openImage = (url: string) => {
    window.open(url, '_blank');
  };

  const downloadImage = (url: string) => {
    const a = document.createElement('a');
    a.href = url;
    a.download = 'download.jpg'; // The name of the downloaded file
    a.click();
  };

  const showCommunityCharacters = () => (
    <Box>
      <div
        style={{
          display: 'flex',
          gap: '20px',
          flexWrap: 'wrap',
          justifyContent: 'center',
          paddingInline: isMobileScreen ? '0' : '2rem',
          paddingBottom: '2rem',
        }}
      >
        {getGalleryCharacters().map((c: Character) => (
          // eslint-disable-next-line jsx-a11y/img-redundant-alt
          <Card
            onClick={() => openImage(c.ImageUrls[0])}
            style={{
              width: isMobileScreen ? '100%' : '250px',
              display: 'inline-block',
              padding: '10px',
              border: '1px solid black',
              borderRadius: '10px',
              position: 'relative',
            }}
          >
            <CardMedia
              component="img"
              height={isMobileScreen ? '250' : '200'}
              image={c.ImageUrls[0]}
              alt="Character"
              sx={{
                objectPosition: isMobileScreen ? 'top' : 'center',
                objectFit: 'cover',
              }}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div" sx={{ fontFamily: 'Passero One', fontSize: '24px' }}>
                {c.Name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {`${c.Race} ${c.Class}`}
              </Typography>
            </CardContent>
            <div
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                display: 'none',
                backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: add a semi-transparent background
                borderRadius: '5px', // Optional: round the corners
                padding: '5px', // Optional: add some padding
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.display = 'block';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.display = 'none';
              }}
            >
              <Tooltip title="Download">
                <IconButton onClick={() => downloadImage(c.ImageUrls[0])}>
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Generate PDF">
                <IconButton onClick={() => openImage(c.ImageUrls[0])}>
                  <PictureAsPdfIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Card>
        ))}
      </div>
    </Box>
  );

  const showTokenMaker = () => (
    <Box>
      <div>
        <iframe title="Community Token Maker" src="https://realmworlds.ai" width="100%" height="1000px" />
      </div>
    </Box>
  );

  return (
    <div
      style={{
        overflowX: 'hidden',
        overflowY: 'hidden',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
        backgroundColor: '#d0b17a',
        width: '100%',
        maxWidth: '100%',
        minHeight: '100vh',
      }}
    >
      <DashboardHeader />
      {background()}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: isMobileScreen ? 'stretch' : 'center',
          // flexWrap: 'wrap',
          p: isMobileScreen ? 2 : 5,
          margin: '5px',
          width: '100%',
          boxSizing: 'border-box',
        }}
      >
        <Button
          sx={{
            margin: '5px', backgroundColor: '#41521f!important', fontFamily: 'Passero One', color: '#fff',
          }}
          variant="contained"
          onClick={() => setCurrentTab('tokenMaker')}
        >
          Token Maker
        </Button>
        <Button
          sx={{
            margin: '5px', backgroundColor: '#41521f!important', fontFamily: 'Passero One', color: '#fff',
          }}
          variant="contained"
          color="primary"
          onClick={() => setCurrentTab('gallery')}
        >
          Community Gallery
        </Button>
        <Button
          sx={{
            margin: '5px', backgroundColor: '#41521f!important', fontFamily: 'Passero One', color: '#fff',
          }}
          variant="contained"
          color="primary"
          onClick={() => setCurrentTab('characters')}
        >
          Community Characters
        </Button>
        <Button
          sx={{
            margin: '5px', backgroundColor: '#41521f!important', fontFamily: 'Passero One', color: '#fff',
          }}
          variant="contained"
          color="primary"
          onClick={() => window.open('https://www.bonfire.com/store/smartai-studio/', '_blank')}
        >
          Merch Store
        </Button>
      </Box>
      <Box sx={{ px: isMobileScreen ? 2 : 'auto' }}>
        {currentTab === 'gallery' && showCommunityGallery()}
        {currentTab === 'characters' && showCommunityCharacters()}
        {currentTab === 'tokenMaker' && showTokenMaker()}
      </Box>
    </div>
  );
}

export default CommunityView;
