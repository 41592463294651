/* eslint-disable object-curly-newline */
import React from 'react';
import {
  Box,
  Button,
  Slider,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Konva from 'konva';
import { Stage } from 'konva/lib/Stage';
import appStore, { ThumbnailSource } from '../../../store/AppStore';
import CustomBorder from '../../../images/custom-border-image-1.webp';
import PlaceHolderImage from '../../../images/preview-image-placeholder.webp';
import IncreaseIcon from '../../../images/increase.webp';
import DecreaseIcon from '../../../images/decrease.webp';
import PanIcon from '../../../images/pan.webp';

interface Props {
  background: string,
  handleStage: (stage: Stage) => void,
  variant?: 'general' | 'character_set',
  onConfirm: (url: string | null) => void,
}

/**
 * Represents a React component to preview the selected thumbnail for Create Character Set modal.
 *
 * @component
 * @example
 * // Example usage of CharacterSetModalPreview component
 * <CharacterSetModalPreview background="backgroundImageUrl" />
 *
 * @date 11/22/2023 - 10:59:53 AM
 *
 * @param {Props} param0
 * @param {string} param0.background
 * @param {(stage: Stage) => void} param0.handleStage
 * - Function to handle the stage of the Konva canvas.
 * @returns {*}
 */
function CharacterSetModalPreview({
  background, handleStage, variant, onConfirm,
}: Props) {
  /**
   * The selected thumbnail source from the application store.
   *
   * @type {ThumbnailSource}
   */
  const selectedThumbnailSource: ThumbnailSource = appStore(
    (state) => state.previewThumbnailSource,
  );
  const isTabletScreen = useMediaQuery('(max-width:865px)');

  /**
   * State to manage the zoom value of the character set preview.
   *
   * @type {number}
   */
  const [zoomValue, setZoomValue] = React.useState<number>(0);
  const [imagePostion, setImagePosition] = React.useState({ x: 0, y: 0 });
  const [draggableImage, setDraggableImage] = React.useState(false);
  const canvasWidth = isTabletScreen ? window.innerWidth * 0.9 : 600;
  console.log(canvasWidth);

  /**
   * Handles the change of the zoom value.
   *
   * @param {Event} event - The change event.
   * @param {number | number[]} newValue - The new value of the zoom.
   */
  const handleZoomChange = (event: Event, newValue: number | number[]) => {
    setZoomValue(newValue as number);
  };

  /**
   * Extracts the URL from a string if it starts with 'url('.
   *
   * @param {string} input - The input string.
   * @returns {string | null} - The extracted URL or null if the input does not start with 'url('.
   */
  function extractUrl(input: string): string | null {
    const prefix = 'url(';
    if (input.startsWith(prefix)) {
      // Remove 'url(' at the start and ')' at the end
      return input.slice(prefix.length, -1);
    }
    return null;
  }

  /**
   * The URL extracted from the background.
   *
   * @type {string | null}
   */
  const previewUrl = extractUrl(background);

  /**
   * A reference to the HTML canvas element.
   *
   * @type {React.RefObject<HTMLCanvasElement>}
   */
  const canvasRef = React.useRef<HTMLCanvasElement>(null);

  const handleImageDragEnd = (e: Konva.KonvaEventObject<DragEvent>) => {
    setImagePosition({
      x: e.target.x(),
      y: e.target.y(),
    });
  };

  /**
   * Draws an image on the preview canvas.
   *
   * @param {HTMLImageElement} imageObj - The image to be drawn.
   */
  const drawPreviewCanvasImg = (imageObj: HTMLImageElement) => {
    // const imageWidth = stage.width() * (zoomValue > 0 ? (zoomValue / 100) * 2 : 1);
    const prevCanvas = document.getElementById('modalPreviewCanvas');

    if (prevCanvas !== null) {
      const stage = new Konva.Stage({
        container: 'modalPreviewCanvas',
        width: canvasWidth,
        height: 250,
      });
      stage.remove();
      const imageWidth = zoomValue > 0
        ? stage.width() + (stage.width() * (zoomValue / 100) * 2)
        : stage.width();
      const imageHeight = (imageObj.naturalHeight / imageObj.naturalWidth) * imageWidth;
      const previewLayer = new Konva.Layer();
      const previewImage = new Konva.Image({
        image: imageObj,
        x: imagePostion.x,
        y: imagePostion.y,
        width: imageWidth,
        height: imageHeight,
        draggable: draggableImage,
      });
      previewImage.on('mouseover', () => {
        if (draggableImage) {
          document.body.style.cursor = `url(${PanIcon}),auto`;
        }
      });
      previewImage.on('mouseout', () => {
        document.body.style.cursor = 'default';
      });
      previewImage.on('dragmove', () => {
        handleStage(stage);
      });
      previewImage.on('dragend', (e) => {
        handleImageDragEnd(e);
      });
      previewLayer.add(previewImage);
      stage.add(previewLayer);
      handleStage(stage);
    }
  };

  /**
   * Draws a color on the preview canvas.
   *
   * @param {string} hexCode - The hex code of the color to be drawn.
   */
  const drawPreviewCanvasColor = (hexCode: string) => {
    const prevCanvas = document.getElementById('modalPreviewCanvas');

    if (prevCanvas !== null) {
      const stage = new Konva.Stage({
        container: 'modalPreviewCanvas',
        width: canvasWidth,
        height: 250,
      });
      stage.remove();
      const previewLayer = new Konva.Layer();
      const previewColor = new Konva.Rect({
        x: (stage.width() / 2) - (canvasWidth / 2),
        y: (stage.height() / 2) - (250 / 2),
        width: canvasWidth,
        height: 250,
        fill: hexCode,
      });
      previewLayer.add(previewColor);
      stage.add(previewLayer);
      handleStage(stage);
    }
  };

  React.useEffect(() => {
    if (selectedThumbnailSource === 'upload' || selectedThumbnailSource === 'library') {
      const imageObj = new Image();
      imageObj.onload = () => {
        drawPreviewCanvasImg(imageObj);
      };
      if (previewUrl) {
        imageObj.src = previewUrl;
      }
    } else if (selectedThumbnailSource === 'color') {
      drawPreviewCanvasColor(background);
    }
  }, [previewUrl, zoomValue, draggableImage, background]);

  return (
    <>
      {/* Preview Heading */}
      <Box
        sx={{
          width: '100%',
          borderRadius: '0px',
          background: '#41521F',
          padding: '12px 20px',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          gap: '35px',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          sx={{
            color: 'white',
            fontFamily: 'Passero One',
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '26px' /* 130% */,
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
          }}
        >
          Preview
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '15px',
          }}
        >
          <button
            type="button"
            style={{
              display: 'block',
              outline: 'none',
              border: 'none',
              padding: 0,
              background: 'transparent',
              height: '18px',
              cursor: 'pointer',
            }}
            onClick={() => setDraggableImage(!draggableImage)}
          >
            <img
              src={PanIcon}
              alt="decrease"
              style={{
                height: '18px',
                width: 'auto',
              }}
            />
          </button>
          <Stack spacing="10px" direction="row" alignItems="center">
            <img
              src={DecreaseIcon}
              alt="decrease"
              style={{
                height: '18px',
                width: 'auto',
              }}
            />
            <Slider
              aria-label="zoom"
              value={zoomValue}
              onChange={handleZoomChange}
              sx={{
                width: isTabletScreen ? '100px' : '200px',
                color: '#D0B17A',
                '.MuiSlider-rail': {
                  color: 'white',
                },
                '.MuiSlider-thumb': {
                  width: '15px',
                  height: '15px',
                },
                '.MuiSlider-thumb:hover': {
                  boxShadow: '0px 0px 0px 8px #D0B17A3C',
                },
              }}
              min={0}
              max={100}
            />
            <img
              src={IncreaseIcon}
              alt="increase"
              style={{
                height: '18px',
                width: 'auto',
              }}
            />
          </Stack>
        </Box>
      </Box>
      {/* Preview container */}
      <Box
        sx={{
          width: '100%',
          borderRadius: '0px 0px 20px 20px',
          transition: 'ease-in-out',
          minHeight: '200px',
          boxSizing: 'border-box',
          overflow: 'hidden',
          position: 'relative',
          backgroundColor: variant === 'general' ? 'black' : 'transparent',
        }}
      >
        <Box id="modalPreviewCanvas" ref={canvasRef} height={250} width={canvasWidth} />
        <Box
          sx={{
            border: '2px solid #FFF',
            background: variant === 'character_set' ? 'rgba(208, 177, 122, 0.50)' : 'transparent',
            borderImageSource: variant === 'character_set' ? `url(${CustomBorder})` : 'none',
            borderImageSlice: '17',
            borderImageRepeat: 'round',
            width: variant === 'character_set' ? '100%' : '166.5px',
            minHeight: variant === 'character_set' ? '150px' : '210px',
            boxSizing: 'border-box',
            px: variant === 'character_set' ? '24px' : '0',
            pt: variant === 'character_set' ? '48px' : '0',
            pb: variant === 'character_set' ? '15px' : '0',
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
            position: 'absolute',
            top: variant === 'character_set' ? '45px' : '50%',
            left: variant === 'character_set' ? 0 : '50%',
            transform: variant === 'general' ? 'translate(-50%, -50%)' : 'none',
          }}
        >
          {variant === 'character_set' && (
          <>
            <img
              src={PlaceHolderImage}
              alt="placeholder"
              style={{
                height: 'auto',
                width: 'calc(100% / 7)',
              }}
            />
            <img
              src={PlaceHolderImage}
              alt="placeholder"
              style={{
                height: 'auto',
                width: 'calc(100% / 7)',
              }}
            />
            <img
              src={PlaceHolderImage}
              alt="placeholder"
              style={{
                height: 'auto',
                width: 'calc(100% / 7)',
              }}
            />
            <img
              src={PlaceHolderImage}
              alt="placeholder"
              style={{
                height: 'auto',
                width: 'calc(100% / 7)',
              }}
            />
            <img
              src={PlaceHolderImage}
              alt="placeholder"
              style={{
                height: 'auto',
                width: 'calc(100% / 7)',
              }}
            />
            <img
              src={PlaceHolderImage}
              alt="placeholder"
              style={{
                height: 'auto',
                width: 'calc(100% / 7)',
              }}
            />
          </>
          )}
        </Box>
        {variant === 'general' && (
          <Button onClick={() => onConfirm(previewUrl)}>Apply</Button>
        )}
      </Box>
    </>
  );
}

CharacterSetModalPreview.defaultProps = {
  variant: 'character_set',
};

export default CharacterSetModalPreview;
