/* eslint-disable object-curly-newline */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable @typescript-eslint/indent */
import React from 'react';
import { Box, Button, Stack, useMediaQuery } from '@mui/material';
import {
  AttributeWrapper,
  SmallHeadingText,
  GenerateDiceImg,
  AttributeTextField,
} from '../../styledComps/styledComps';
import CharacterFilterDropdown from '../../fixedFilter/filterDropDown/CharacterFilterDropdown';
import DiceIcon from '../../../images/dice.webp';
import {
  Class,
  Gender,
  GetClassesAttributesDocument,
  GetGendersAttributesDocument,
  GetPersonalitiesAttributesDocument,
  GetPronounsDocument,
  GetRacesAttributesDocument,
  GetUserCharacterGenerationQuery,
  Personality,
  Race,
} from '../../../__generated__/graphql';
import useGraphQL from '../../../hooks/useGraphQL';
import generateCharacterName from './utilities/GenerateCharacterName';
import {
  AlignmentOptions,
  MotivationOptions,
  PronounOptions,
} from './CharacterStaticOptions';
import appStore from '../../../store/AppStore';
import getAttributeValue from '../../../util/aiGetCharAttribute';

interface Props {
  characterName: string;
  setCharacterName: (char: string) => void;
  characterAge: number;
  setCharacterAge: (char: number) => void;
  characterPronouns: string;
  setCharacterPronouns: (char: string) => void;
  characterGender: Gender | null;
  setCharacterGender: (char: Gender) => void;
  characterRace: Race | null;
  setCharacterRace: (char: Race) => void;
  characterClass: Class | null;
  setCharacterClass: (char: Class) => void;
  characterAlignment: string;
  setCharacterAlignment: (char: string) => void;
  characterPersonalityType: Personality | null;
  setCharacterPersonalityType: (char: Personality) => void;
  characterMotivations: string[];
  setCharacterMotivations: (char: string[]) => void;
}

/**
 * Represents a component for character attributes.
 *
 * @component
 * @example
 * // Example usage of CharacterAttributes component
 * <CharacterAttributes />
 *
 * @returns {JSX.Element} React element representing character attributes.
 */
function CharacterAttributes({
  characterName,
  setCharacterName,
  characterAge,
  setCharacterAge,
  characterPronouns,
  setCharacterPronouns,
  characterGender,
  setCharacterGender,
  characterRace,
  setCharacterRace,
  characterClass,
  setCharacterClass,
  characterAlignment,
  setCharacterAlignment,
  characterPersonalityType,
  setCharacterPersonalityType,
  characterMotivations,
  setCharacterMotivations,
}: Props) {
  const viewMood = appStore((state) => state.characterSheetMood);
  const userCharacterGeneration:
    | GetUserCharacterGenerationQuery
    | null
    | undefined = appStore((state) => state.userCharacterGeneration);
  const selectedCharacter = appStore((state) => state.selectedCharacter);
  const createCharacterView = appStore((state) => state.createCharacterView);

  const { data: genders } = useGraphQL(GetGendersAttributesDocument, {});
  const { data: races } = useGraphQL(GetRacesAttributesDocument, {});
  const { data: classes } = useGraphQL(GetClassesAttributesDocument, {});
  const { data: pronouns } = useGraphQL(GetPronounsDocument, {});
  console.log(pronouns?.pronouns);
  const { data: personalities } = useGraphQL(
    GetPersonalitiesAttributesDocument,
    {},
  );

  React.useEffect(() => {
    if (viewMood === 'edit' && createCharacterView === 'sheet') {
      if (selectedCharacter !== null) {
        setCharacterName(selectedCharacter?.name);
        // setCharacterAge(selectedCharacter?.age ?? 0);
        setCharacterAge(0);
        setCharacterPronouns(selectedCharacter?.pronoun as string);
        const selectedGender = genders?.genders.find(
          (gender) => gender?.name === selectedCharacter?.gender,
        );
        if (selectedGender !== null && selectedGender !== undefined) {
          setCharacterGender(selectedGender);
        } else {
          setCharacterGender({
            _id: '',
            name: selectedCharacter.gender,
          });
        }
        const selectedRace = races?.races.find(
          (race) => race?.name === selectedCharacter?.race,
        );
        if (selectedRace !== null && selectedRace !== undefined) {
          setCharacterRace(selectedRace);
        } else {
          setCharacterRace({
            _id: '',
            name: selectedCharacter.race,
          });
        }
        const selectedClass = classes?.classes.find(
          (c) => c?.name === selectedCharacter?.class,
        );
        if (selectedClass !== null && selectedClass !== undefined) {
          setCharacterClass(selectedClass);
        } else {
          setCharacterClass({
            _id: '',
            name: selectedCharacter.class,
          });
        }
        setCharacterAlignment(selectedCharacter?.alignment as string);
        setCharacterMotivations(selectedCharacter.motivations as string[]);
        // const selectedPersonality = personalities?.personalities
        //   .find((p) => p?.name === selectedCharacter?.personality);
        // if (selectedPersonality !== null && selectedPersonality !== undefined) {
        //   setCharacterPersonalityType(selectedPersonality);
        // }
      }
    }
  }, []);

  React.useEffect(() => {
    if (userCharacterGeneration !== (null || undefined)) {
      const character =
        userCharacterGeneration?.user_character_generation?.attributes;
      if (character) {
        const charName = getAttributeValue(character, 'character_name');
        if (charName !== undefined) {
          setCharacterName(charName);
        }
        const charGender = getAttributeValue(character, 'gender');
        if (charGender !== undefined) {
          const selectedGender = genders?.genders.find(
            (gender) => gender?.name === charGender,
          );
          if (selectedGender !== null && selectedGender !== undefined) {
            setCharacterGender(selectedGender);
          }
        }
        const charPronouns = getAttributeValue(character, 'pronouns');
        if (charPronouns !== undefined) {
          setCharacterPronouns(charPronouns);
        }
        const charRace = getAttributeValue(character, 'race');
        if (charRace !== undefined) {
          const selectedRace = races?.races.find(
            (race) =>
              race?.name?.toLocaleLowerCase() === charRace.toLocaleLowerCase(),
          );
          if (selectedRace !== null && selectedRace !== undefined) {
            setCharacterRace(selectedRace);
          } else {
            setCharacterRace({
              _id: races?.races[0]?._id,
              name: charRace,
            });
          }
        }
        const charClass = getAttributeValue(character, 'class');
        if (charClass !== undefined) {
          const selectedClass = classes?.classes.find(
            (c) =>
              c?.name?.toLocaleLowerCase() === charClass.toLocaleLowerCase(),
          );
          if (selectedClass !== null && selectedClass !== undefined) {
            setCharacterClass(selectedClass);
          } else {
            setCharacterClass({
              _id: classes?.classes[0]?._id,
              name: charClass,
            });
          }
        }
        const charAlignment = getAttributeValue(character, 'alignment');
        if (charAlignment !== undefined) {
          setCharacterAlignment(charAlignment);
        }
        const charMotivations = getAttributeValue(character, 'motivations');
        if (charMotivations !== undefined) {
          setCharacterMotivations([charMotivations]);
        }
        const charPersonalityType = getAttributeValue(
          character,
          'personality_type',
        );
        if (charPersonalityType !== undefined) {
          const selectedPersonalityType = personalities?.personalities.find(
            (p) =>
              p?.name?.toLocaleLowerCase() ===
              charPersonalityType.toLocaleLowerCase(),
          );
          if (
            selectedPersonalityType !== null &&
            selectedPersonalityType !== undefined
          ) {
            setCharacterPersonalityType(selectedPersonalityType);
          } else {
            setCharacterPersonalityType({
              _id: personalities?.personalities[0]?._id,
              name: charPersonalityType,
            });
          }
        }
        // setCharacterAge(character[1]?.value)
      }
    }
  }, [userCharacterGeneration]);

  const isTabletScreen = useMediaQuery('(max-width:865px)');
  const isMobileScreen = useMediaQuery('(max-width:530px)');

  return (
    <Stack
      direction={isTabletScreen ? 'column' : 'row'}
      flexWrap="wrap"
      gap="28px"
      width={isTabletScreen ? '100%' : '896px'}
      paddingTop="9px"
      // overflowX={isTabletScreen ? 'hidden' : 'auto'}
    >
      {/* note:
       * 280px is 33.333% adjusted for flex gap
       * 434px is 50% adjusted for flex gap
       */}
      <AttributeWrapper width={isTabletScreen ? '100%!important' : '588px'}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack width="540px">
            <SmallHeadingText>Name</SmallHeadingText>
            {/* <AttributeText>Enter Character Name</AttributeText> */}
            <AttributeTextField
              placeholder="Enter Character Name"
              value={characterName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setCharacterName(event.target.value);
              }}
              fullWidth
            />
          </Stack>
          <Button
            sx={{
              width: '33px',
              minWidth: '0px',
              height: '33px',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: '#FFF',
              '&:hover': {
                background: '#FFF',
              },
            }}
            onClick={() => setCharacterName(generateCharacterName())}
          >
            <GenerateDiceImg src={DiceIcon} />
          </Button>
        </Stack>
      </AttributeWrapper>

      {!isTabletScreen && (
        <AttributeWrapper width={isTabletScreen ? '50%!important' : '280px'}>
          <Box>
            <SmallHeadingText>Age</SmallHeadingText>
            {/* <AttributeText>Insert Age</AttributeText> */}
            <AttributeTextField
              placeholder="Insert Age"
              type="number"
              value={characterAge}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setCharacterAge(parseInt(event.target.value, 10));
              }}
              fullWidth
              inputProps={{
                min: 0,
              }}
            />
          </Box>
        </AttributeWrapper>
      )}

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: isTabletScreen ? '10px' : '28px',
        }}
      >
        <CharacterFilterDropdown
          width={isTabletScreen ? '50%!important' : '434px'}
          title="Pronouns"
          options={PronounOptions}
          value={characterPronouns}
          onChange={setCharacterPronouns}
        />
        <CharacterFilterDropdown
          width={isTabletScreen ? '50%!important' : '434px'}
          title="Gender"
          options={genders?.genders}
          value={characterGender?.name}
          onChange={setCharacterGender}
        />
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: isTabletScreen ? '10px' : '28px',
        }}
      >
        <CharacterFilterDropdown
          width={isTabletScreen ? '50%!important' : '280px'}
          title="Race"
          options={races?.races}
          tooltip
          toolTipMessage="Choose the race you wish to apply to the character."
          value={characterRace?.name}
          onChange={setCharacterRace}
        />
        <CharacterFilterDropdown
          width={isTabletScreen ? '50%!important' : '280px'}
          title="Class"
          options={classes?.classes}
          tooltip
          toolTipMessage="Choose the class you wish to apply to the character."
          value={characterClass?.name}
          onChange={setCharacterClass}
        />

        {!isTabletScreen && (
          <CharacterFilterDropdown
            width={isTabletScreen ? '50%!important' : '280px'}
            title="Alignment"
            options={AlignmentOptions}
            tooltip
            toolTipMessage="Choose the alignment you wish to apply to the character."
            value={characterAlignment}
            onChange={setCharacterAlignment}
          />
        )}
      </div>
      <div
        style={{
          display: isTabletScreen ? 'flex' : 'none',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        {isTabletScreen && (
          <AttributeWrapper width={isTabletScreen ? '50%!important' : '280px'}>
            <Box>
              <SmallHeadingText>Age</SmallHeadingText>
              {/* <AttributeText>Insert Age</AttributeText> */}
              <AttributeTextField
                placeholder="Insert Age"
                type="number"
                value={characterAge}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCharacterAge(parseInt(event.target.value, 10));
                }}
                fullWidth
                inputProps={{
                  min: 0,
                }}
              />
            </Box>
          </AttributeWrapper>
        )}
        {isTabletScreen && (
          <CharacterFilterDropdown
            width={isTabletScreen ? '50%!important' : '280px'}
            title="Alignment"
            options={AlignmentOptions}
            tooltip
            toolTipMessage="Choose the alignment you wish to apply to the character."
            value={characterAlignment}
            onChange={setCharacterAlignment}
          />
        )}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: isTabletScreen ? 'column' : 'row',
          alignItems: 'center',
          gap: isTabletScreen ? '30px' : '30px',
        }}
      >
        <CharacterFilterDropdown
          width={isTabletScreen ? '100%!important' : '434px'}
          title="Motivations"
          options={MotivationOptions}
          tooltip
          toolTipMessage="Choose the motivations you wish to apply to the character."
          multipleSelect
          multipleSelectLowerLimit={1}
          multipleSelectUpperLimit={3}
          value={characterMotivations}
          onChange={setCharacterMotivations}
        />
        <CharacterFilterDropdown
          width={isTabletScreen ? '100%!important' : '434px'}
          title="Personality Type"
          options={personalities?.personalities}
          tooltip
          toolTipMessage="Choose the personality type you wish to apply to the character."
          value={characterPersonalityType?.name}
          onChange={setCharacterPersonalityType}
        />
      </div>
    </Stack>
  );
}

export default CharacterAttributes;
