/* eslint-disable arrow-body-style */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Typography,
} from '@mui/material';
import { GetUserWalletDocument } from '../../__generated__/graphql';
import useGraphQL from '../../hooks/useGraphQL';
import { useAuth } from '../../hooks/use-auth';
import useNotification from '../../hooks/useNotification';
import SubscriptionCheckout from '../../views/Subscription/SubscriptionExt';
import s from './Subscription.module.scss';

const SubscriptionPayment = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { displayNotification } = useNotification();
  const { data: userProfile, error: userProfileError } = useGraphQL(GetUserWalletDocument, {
    query: {
      _id: auth?.user?.uid,
    },
  });
  if (userProfileError) {
    displayNotification({ message: 'Error loading user profile', type: 'error' });
  }

  console.log('userProfile', userProfile);

  return (
    <div className={s.layout}>
      <Box
        sx={{
          display: 'flow',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 5,
          margin: 5,
          backgroundColor: '#D0B17A',
        }}
      >
        <Typography
          variant="h3"
          sx={{
            display: 'flow',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 2,
            margin: 2,
            fontFamily: 'Passero One',
          }}
        >
          Realm Worlds Subscriptions
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{
            display: 'flow',
            justifyContent: 'center',
            alignItems: 'center',
            height: 50,
            padding: 2,
            margin: 2,
          }}
          onClick={() => {
            navigate('/settings');
          }}
        >
          Continue with current subscription
        </Button>
        <SubscriptionCheckout />
      </Box>
    </div>
  );
};

export default SubscriptionPayment;
