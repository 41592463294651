/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {
  Box,
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CharacterSheetBGImg from '../../../images/treebg.webp';
import TexturedBackground from '../../../images/flowerbg2.webp';
import CustomBorder from '../../../images/custom-border-image-1.webp';
import FloweredBorder from '../../../images/flowered-outline-square.webp';
import CharacterRaceAndClass from './CharacterRaceAndClass';
import CharacterCharacteristics from './CharacterCharacteristics';
import {
  Body_Shape,
  CharacterAbility,
  CharacterInsertInput,
  CharacterLong_Term_Goal,
  CharacterShort_Term_Goal,
  Character_Set,
  Class,
  CreateCharacterDocument,
  Eye,
  Gender,
  GetCharactersSetsDocument,
  Personality,
  Skin,
  UpdateCharacterSetDocument,
} from '../../../__generated__/graphql';
import CharacterMotiveandGoals from './CharacterMotiveandGoals';
import CharacterBackgroundInformation from './CharacterBackgroundInformation';
import CharacterWizardViewToggle from './CharacterWizardViewToggle';
import RaceImage from '../../../images/wizard-race-view.webp';
import CharacteristicsImage from '../../../images/wizard-characteristics-view.webp';
import MotiveImage from '../../../images/wizard-motives-view.webp';
import BackgroundInfoImage from '../../../images/wizard-background-view.webp';
import SummaryImage from '../../../images/wizard-summary-view.webp';
import UploadIcon from '../../../images/upload-image.webp';
import LibraryIcon from '../../../images/library.webp';
import CharacterNameRibbon from '../../../images/character-name-ribbon.webp';
import FlowerLeft from '../../../images/flower2.png';
import FlowerRight from '../../../images/flower1.png';
import RwMascottCongrats from '../../../images/rwmascott-congratulations.webp';
import appStore, {
  CharacterViewOptions,
  CharacterWizardViewOptions,
} from '../../../store/AppStore';
import {
  AttributeText,
  AttributeTextField,
  AttributeWrapper,
  BackgroundContentWrapper,
  CustomScrollWrapper,
  FlexibleHeightImg,
  GoalContentWrapper,
  OptionsStack,
  OptionsStackIcon,
  OptionsStackIconContainer,
  OptionsStackText,
  OptionsStackTextContainer,
  SectionHeadingText,
  SmallHeadingText,
  WizardNavigationButton,
  WizardNavigationButtonText,
} from '../../styledComps/styledComps';
import CharacterNoteView from '../../notes/CharacterNoteView';
import sampleNotes from '../../notes/characternotesample.json';
import CharacterAbilitiesGen from './CharacterAbilitiesGen';
import CharacterWizardSummary from './CharacterWizardSummary';
import { useAuth } from '../../../hooks/use-auth';
import useGraphQL from '../../../hooks/useGraphQL';
import useGraphQLMutation from '../../../hooks/useGraphQLMutation';
import useNotification from '../../../hooks/useNotification';
import ChooseFromLibraryModal from '../../modals/ChooseFromLibrary';

function CharacterWizard() {
  const [characterName, setCharacterName] = React.useState('');
  const [characterAge, setCharacterAge] = React.useState<number>(0);
  const [characterPronouns, setCharacterPronouns] = React.useState('');
  const [characterGender, setCharacterGender] = React.useState<Gender | null>(
    null,
  );
  const [characterRace, setCharacterRace] = React.useState('');
  const [characterClass, setCharacterClass] = React.useState<Class | null>(
    null,
  );
  const [characterAbilities, setCharacterAbilities] =
    React.useState<CharacterAbility>({
      strength: '0',
      dexterity: 0,
      consitution: 0,
      intelligence: 0,
      wisdom: 0,
      charisma: 0,
    });
  const [characterBodyType, setCharacterBodyType] =
    React.useState<Body_Shape | null>(null);
  const [characterSkinColor, setCharacterSkinColor] =
    React.useState<Skin | null>(null);
  const [characterHairColor, setCharacterHairColor] = React.useState('');
  const [characterHairStyle, setCharacterHairStyle] = React.useState('');
  const [characterEyeColor, setCharacterEyeColor] = React.useState<Eye | null>(
    null,
  );
  const [characterAlignment, setCharacterAlignment] = React.useState('');
  const [characterPersonalityType, setCharacterPersonalityType] =
    React.useState<Personality | null>(null);
  const [characterMotivations, setCharacterMotivations] = React.useState<
    string[]
  >([]);
  const [characterProfession, setCharacterProfession] = React.useState('');
  const [characterLifeAlteringEvent, setCharacterLifeAlteringEvent] =
    React.useState('');
  const [characterBackStory, setCharacterBackStory] = React.useState('');
  const [characterRelationship, setCharacterRelationship] = React.useState('');
  const [characterLineage, setCharacterLineage] = React.useState('');
  const [characterShortTermGoals, setCharacterShortTermGoals] = React.useState<
    CharacterShort_Term_Goal[]
  >([]);
  const [characterLongTermGoals, setCharacterLongTermGoals] = React.useState<
    CharacterLong_Term_Goal[]
  >([]);

  const selectedView: CharacterWizardViewOptions = appStore(
    (state) => state.selectedCharacterWizardView,
  );
  const setSelectedView = (v: CharacterWizardViewOptions) => {
    appStore.setState({
      selectedCharacterWizardView: v,
    });
  };
  const setSelectedCharacterView = (view: CharacterViewOptions) => {
    appStore.setState({
      selectedCharacterView: view,
    });
  };

  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const [selectedFileBlob, setSelectedFileBlob] = React.useState<Blob | null>(
    null,
  );
  const [previewUrl, setPreviewUrl] = React.useState<string | null>(null);
  const [showMascott, setShowMascott] = React.useState(false);
  const [openChooseFromLibraryModal, setOpenChooseFromLibraryModal] =
    React.useState(false);

  const auth = useAuth();
  const { id: realmId } = useParams();
  const { displayNotification } = useNotification();

  const { data: charactersSets } = useGraphQL(GetCharactersSetsDocument, {
    query: {
      owner: {
        _id: auth?.user?.uid,
      },
    },
  });

  const convertFileToBlob = (file: File) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const blob = new Blob([reader.result as ArrayBuffer], {
        type: file.type,
      });
      setSelectedFileBlob(blob);
    };

    reader.readAsArrayBuffer(file);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      console.log(URL.createObjectURL(file));
      setSelectedFile(file);
      convertFileToBlob(file);
      setPreviewUrl(URL.createObjectURL(file));
      console.log(URL.createObjectURL(file));
    }
  };

  const handleBottomNavigation = (dir: 'Back' | 'Forward') => {
    if (dir === 'Back') {
      if (selectedView === 'race') {
        setSelectedCharacterView('catalog');
      } else if (selectedView === 'characteristics') {
        setSelectedView('race');
      } else if (selectedView === 'motives') {
        setSelectedView('characteristics');
      } else if (selectedView === 'background') {
        setSelectedView('motives');
      } else if (selectedView === 'summary') {
        setSelectedView('background');
      }
    } else if (dir === 'Forward') {
      if (selectedView === 'race') {
        setSelectedView('characteristics');
      } else if (selectedView === 'characteristics') {
        setSelectedView('motives');
      } else if (selectedView === 'motives') {
        setSelectedView('background');
      } else if (selectedView === 'background') {
        setSelectedView('summary');
      }
    }
  };
  const [charactersCharacterSet, setCharactersCharacterSet] = React.useState({
    name: 'Select a Character Set',
    _id: '',
  });

  const [readyToSave, setReadyToSave] = React.useState(false);

  const {
    data: createdCharacter,
    mutate: createCharacter,
    status: createdCharacterStatus,
  } = useGraphQLMutation(CreateCharacterDocument);

  const { mutate: updateCharacterSet, status: updateCharacterSetStatus } =
    useGraphQLMutation(UpdateCharacterSetDocument);

  const handleSaveCharacter = () => {
    const character: CharacterInsertInput = {
      name: characterName,
      avatar_url: previewUrl,
      character_image_thumbnail_url: previewUrl,
      owner: {
        link: auth?.realmUser?.id,
      },
      realms: {
        link: [realmId],
      },
      race: characterRace,
      class: characterClass?.name,
      gender: characterGender?.name,
      pronoun: characterPronouns,
      motivations: characterMotivations,
      abilities: characterAbilities,
      alignment: characterAlignment,
      physical_profile: {
        body_type: characterBodyType?.name,
        skin_color: characterSkinColor?.name,
        hair_color: characterHairColor,
        hair_style: characterHairStyle,
        eye_color: characterEyeColor?.name,
      },
      profession: characterProfession,
      relationship_status: characterRelationship,
      life_altering_event: characterLifeAlteringEvent,
      backstory: characterBackStory,
      short_term_goals: characterShortTermGoals,
      long_term_goals: characterLongTermGoals,
    };
    console.log(character);
    createCharacter({
      data: character,
    });
  };

  const handleUploadCharacterImage = (id: string) => {
    const formData = new FormData();
    formData.append('AvatarImage', selectedFileBlob!);
    formData.append('CharacterImage', selectedFileBlob!);
    displayNotification({
      message: 'Uploading character image ...',
      type: 'info',
    });
    fetch(`${process.env.REACT_APP_API_URL}/Characters/${id}/Images`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          displayNotification({
            message: 'Failed to upload character image',
            type: 'error',
          });
        }
        const contentType = response.headers.get('Content-Type');
        if (contentType && contentType.includes('application/json')) {
          return response.json();
        }
        return null;
      })
      .then((result) => {
        console.log(result);
        // handleClose();
        displayNotification({
          message: 'Character image uploaded successfully',
          type: 'success',
        });
        setShowMascott(true);
        setTimeout(() => {
          appStore.setState({
            selectedCharacterView: 'catalog',
          });
        }, 5000);
        // refetch();
      })
      .catch((error) => {
        console.error('There was a problem: ', error);
        displayNotification({
          message: 'Failed to upload character image',
          type: 'error',
        });
      });
  };

  React.useEffect(() => {
    if (createdCharacterStatus === 'loading') {
      displayNotification({ message: 'Creating character ...', type: 'info' });
    }
    if (createdCharacterStatus === 'error') {
      displayNotification({
        message: 'Failed to create character ...',
        type: 'error',
      });
    }
    if (createdCharacterStatus === 'success') {
      displayNotification({
        message: 'Character created successfully ...',
        type: 'success',
      });
      handleUploadCharacterImage(createdCharacter.insertOneCharacter?._id);
      const oldCharacterIDs =
        charactersSets?.character_sets
          .find(
            (characterset) => characterset?._id === charactersCharacterSet._id,
          )
          ?.characters?.map((characters) => characters?._id) ?? [];
      console.log([
        ...oldCharacterIDs,
        createdCharacter.insertOneCharacter?._id,
      ]);
      updateCharacterSet({
        set: {
          characters: {
            link: [
              ...oldCharacterIDs,
              createdCharacter.insertOneCharacter?._id,
            ],
          },
        },
        query: {
          _id: charactersCharacterSet._id,
        },
      });
    }
  }, [createdCharacterStatus]);

  React.useEffect(() => {
    if (updateCharacterSetStatus === 'loading') {
      displayNotification({
        message: 'Adding character to character set ...',
        type: 'info',
      });
    }
    if (updateCharacterSetStatus === 'error') {
      displayNotification({
        message: 'Failed to add character to a character set',
        type: 'error',
      });
    }
    if (updateCharacterSetStatus === 'success') {
      displayNotification({
        message: 'Successfully added character to character set',
        type: 'success',
      });
    }
  }, [updateCharacterSetStatus]);

  React.useEffect(() => {
    if (
      previewUrl !== null &&
      charactersCharacterSet._id !== '' &&
      characterName !== ''
    ) {
      setReadyToSave(true);
    } else {
      setReadyToSave(false);
    }
  }, [
    previewUrl,
    characterAbilities,
    characterAge,
    characterAlignment,
    characterBodyType,
    characterClass,
    characterEyeColor,
    characterGender,
    characterHairColor,
    characterHairStyle,
    characterLifeAlteringEvent,
    characterMotivations,
    characterName,
    characterPersonalityType,
    characterProfession,
    characterPronouns,
    characterPronouns,
    characterRace,
    characterRelationship,
    characterSkinColor,
    charactersCharacterSet,
  ]);

  const isTabletScreen = useMediaQuery('(max-width:865px)');
  const isMobileScreen = useMediaQuery('(max-width:530px)');

  return (
    <Stack
      direction={isTabletScreen ? 'column' : 'row'}
      gap={isTabletScreen ? '50px' : '0'}
      sx={{
        height: '100%',
        background: `url(${CharacterSheetBGImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        boxSizing: 'border-box',
        padding: isTabletScreen ? '0 10px' : '0 50px',
        paddingTop: '104px',
      }}
    >
      {/* Wizard Input and Review */}
      <Box
        width={
          selectedView === 'summary' ? '100%' : isTabletScreen ? '100%' : '40%'
        }
        position="relative"
      >
        {/* Side navigation */}
        <Stack
          position="absolute"
          gap={isTabletScreen ? '10px' : '44px'}
          pt="10px"
          direction="column"
        >
          <CharacterWizardViewToggle view="race" image_url={RaceImage} />
          <CharacterWizardViewToggle
            view="characteristics"
            image_url={CharacteristicsImage}
          />
          <CharacterWizardViewToggle view="motives" image_url={MotiveImage} />
          <CharacterWizardViewToggle
            view="background"
            image_url={BackgroundInfoImage}
          />
          <CharacterWizardViewToggle view="summary" image_url={SummaryImage} />
        </Stack>
        {/* Views */}
        <Box
          sx={{
            width: selectedView === 'summary' ? '1425px' : '92%',
            height: '100%',
            boxSizing: 'border-box',
            borderImageRepeat: 'round',
            background: `url(${TexturedBackground})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            // float: selectedView === 'summary' ? 'none' : 'right',
            margin: selectedView === 'summary' ? 'auto' : '0 auto',
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: 'calc(100% - 65px)',
              boxSizing: 'border-box',
              border: '8px solid #68583D',
              borderBottom: 0,
              borderImageSource: `url(${CustomBorder})`,
              borderImageSlice: '17',
              borderImageRepeat: 'round',
              backgroundColor: 'rgba(208, 177, 122, 0.74)',
              padding: isMobileScreen ? '42px' : '45px',
              paddingRight: isMobileScreen ? '20px' : '37px',
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                boxSizing: 'border-box',
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                  width: '8px',
                  backgroundColor: '#CFCAAD00',
                  borderRadius: '8px',
                },
                '&.scrollbar': {
                  scrollbarWidth: 'thin',
                  scrollbarColor: '#CFCAAD00',
                  backgroundColor: '#CFCAAD00',
                  borderRadius: '8px',
                },
                '&::-webkit-scrollbar-thumb, &.scrollbar-thumb, &::-webkit-scrollbar-thumb:hover, &.scrollbar-thumb:hover':
                  {
                    backgroundColor: '#41521F',
                    borderRadius: '8px',
                  },
              }}
            >
              {selectedView === 'race' && (
                <CharacterRaceAndClass
                  characterName={characterName}
                  setCharacterName={(char: string) => setCharacterName(char)}
                  characterAge={characterAge}
                  setCharacterAge={(char: number) => setCharacterAge(char)}
                  characterPronouns={characterPronouns}
                  setCharacterPronouns={(char: string) =>
                    setCharacterPronouns(char)
                  }
                  characterGender={characterGender}
                  setCharacterGender={(char: Gender) =>
                    setCharacterGender(char)
                  }
                  characterRace={characterRace}
                  setCharacterRace={(char: string) => setCharacterRace(char)}
                  characterClass={characterClass}
                  setCharacterClass={(char: Class) => setCharacterClass(char)}
                />
              )}
              {selectedView === 'characteristics' && (
                <CharacterCharacteristics
                  characterBodyType={characterBodyType}
                  setCharacterBodyType={(char: Body_Shape) =>
                    setCharacterBodyType(char)
                  }
                  characterSkinColor={characterSkinColor}
                  setCharacterSkinColor={(char: Skin) =>
                    setCharacterSkinColor(char)
                  }
                  characterHairColor={characterHairColor}
                  setCharacterHairColor={(char: string) =>
                    setCharacterHairColor(char)
                  }
                  characterHairStyle={characterHairStyle}
                  setCharacterHairStyle={(char: string) =>
                    setCharacterHairStyle(char)
                  }
                  characterEyeColor={characterEyeColor}
                  setCharacterEyeColor={(char: Eye) =>
                    setCharacterEyeColor(char)
                  }
                  characterAbilities={characterAbilities}
                  setCharacterAbilities={(ab: CharacterAbility) =>
                    setCharacterAbilities(ab)
                  }
                  characterAlignment={characterAlignment}
                  setCharacterAlignment={(char: string) =>
                    setCharacterAlignment(char)
                  }
                />
              )}
              {selectedView === 'motives' && (
                <CharacterMotiveandGoals
                  characterPersonalityType={characterPersonalityType}
                  setCharacterPersonalityType={(char: Personality) =>
                    setCharacterPersonalityType(char)
                  }
                  characterMotivations={characterMotivations}
                  setCharacterMotivations={(char: string[]) =>
                    setCharacterMotivations(char)
                  }
                  characterShortTermGoals={characterShortTermGoals}
                  setCharacterShortTermGoals={(
                    goals: CharacterShort_Term_Goal[],
                  ) => setCharacterShortTermGoals(goals)}
                  characterLongTermGoals={characterLongTermGoals}
                  setCharacterLongTermGoals={(
                    goals: CharacterLong_Term_Goal[],
                  ) => setCharacterLongTermGoals(goals)}
                />
              )}
              {selectedView === 'background' && (
                <CharacterBackgroundInformation
                  characterBackStory={characterBackStory}
                  setCharacterBackStory={(char: string) =>
                    setCharacterBackStory(char)
                  }
                  characterRelationship={characterRelationship}
                  setCharacterRelationship={(char: string) =>
                    setCharacterRelationship(char)
                  }
                  characterLineage={characterLineage}
                  setCharacterLineage={(char: string) =>
                    setCharacterLineage(char)
                  }
                  characterProfession={characterProfession}
                  setCharacterProfession={(char: string) =>
                    setCharacterProfession(char)
                  }
                  characterLifeAlteringEvent={characterLifeAlteringEvent}
                  setCharacterLifeAlteringEvent={(char: string) =>
                    setCharacterLifeAlteringEvent(char)
                  }
                />
              )}
              {selectedView === 'summary' && (
                <CharacterWizardSummary
                  previewUrl={previewUrl!}
                  characterName={characterName}
                  characterAge={characterAge}
                  characterPronouns={characterPronouns}
                  characterGender={characterGender?.name!}
                  characterRace={characterRace}
                  characterClass={characterClass?.name!}
                  characterAlignment={characterAlignment}
                  characterMotivations={characterMotivations}
                  characterPersonalityType={characterPersonalityType?.name!}
                  characterBodyType={characterBodyType?.name!}
                  characterSkinColor={characterSkinColor?.name!}
                  characterHairColor={characterHairColor}
                  characterHairStyle={characterHairStyle}
                  characterEyeColor={characterEyeColor?.name!}
                  characterAbilities={characterAbilities}
                  characterBackStory={characterBackStory}
                  characterProfession={characterProfession}
                  characterRelationship={characterRelationship}
                  characterLineage={characterLineage}
                  characterShortTermGoals={characterShortTermGoals}
                  characterLongTermGoals={characterLongTermGoals}
                />
              )}
            </Box>
          </Box>
          <Stack
            sx={{
              width: '100%',
              height: '65px',
              background: '#371910cc',
              boxSizing: 'border-box',
            }}
            px={isMobileScreen ? '10px' : '47px'}
            py="14px"
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <WizardNavigationButton
                onClick={() => handleBottomNavigation('Back')}
              >
                <ArrowBackIcon sx={{ fontSize: '20px' }} />
                <WizardNavigationButtonText>
                  {selectedView === 'race' ? 'exit' : 'back'}
                </WizardNavigationButtonText>
              </WizardNavigationButton>
              {selectedView !== 'summary' ? (
                <>
                  <Typography
                    sx={{
                      color: '#E9DCC3',
                      fontFamily: 'DM Sans',
                      fontSize: isMobileScreen ? '14px' : '16px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '24px',
                      letterSpacing: '.5px',
                    }}
                  >
                    Save 5 seconds ago
                  </Typography>
                  <WizardNavigationButton
                    onClick={() => handleBottomNavigation('Forward')}
                  >
                    <WizardNavigationButtonText>
                      next
                    </WizardNavigationButtonText>
                    <ArrowForwardIcon sx={{ fontSize: '20px' }} />
                  </WizardNavigationButton>
                </>
              ) : (
                <Stack direction="row" gap="13px">
                  <AttributeWrapper
                    width="260px"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                  >
                    <Select
                      value={charactersCharacterSet.name}
                      onChange={(event: SelectChangeEvent<string>) => {
                        const setName: Character_Set | undefined | null =
                          charactersSets?.character_sets.find(
                            (set) => set?._id === event.target.value,
                          ) as Character_Set | undefined | null;
                        setCharactersCharacterSet({
                          name: setName?.name ?? 'Choose Character Set',
                          _id: event.target.value,
                        });
                        console.log(event.target.value);
                      }}
                      input={
                        <AttributeTextField
                          fullWidth
                          sx={{
                            width: '248px',
                          }}
                        />
                      }
                      renderValue={(selected) => {
                        if (selected === '') {
                          return 'Choose Character Set';
                        }
                        return selected;
                      }}
                    >
                      <MenuItem value="">Select Character Set</MenuItem>
                      {charactersSets?.character_sets.map((character_set) => (
                        <MenuItem
                          key={character_set?._id}
                          value={character_set?._id}
                        >
                          {character_set?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </AttributeWrapper>
                  <Button
                    sx={{
                      display: 'flex',
                      padding: '10px 24px',
                      borderRadius: '4px',
                      color: 'white',
                      fontFamily: 'Passero One',
                      fontSize: '20px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '16px' /* 150% */,
                      letterSpacing: '.1px',
                      height: '40px',
                      background: '#41521F',
                      '&:hover': {
                        background: '#41521F',
                      },
                    }}
                    disabled={!readyToSave}
                    onClick={handleSaveCharacter}
                  >
                    Save Character
                  </Button>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Box>
      </Box>

      {/* Character Image and abilities */}
      {selectedView !== 'summary' && (
        <Box width={isTabletScreen ? '100%' : '33%'}>
          <Stack justifyContent="center" alignItems="center" gap="24px">
            <Stack alignItems="center">
              <Stack
                sx={{
                  height: isMobileScreen ? '340px' : '420px',
                  width: isMobileScreen ? '340px' : '420px',
                  borderRadius: '50%',
                  boxSizing: 'border-box',
                  background:
                    previewUrl !== null && previewUrl !== ''
                      ? `url(${previewUrl})`
                      : '#E3D5BC',
                  backgroundSize: 'cover',
                  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                  padding: '60px',
                  '.replaceImage': {
                    display: 'none',
                  },
                  ':hover': {
                    '.replaceImage': {
                      display: 'flex',
                    },
                  },
                }}
                justifyContent="center"
                alignItems="center"
                gap="20px"
              >
                {previewUrl === null || previewUrl === '' ? (
                  <>
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label htmlFor="fileInput" style={{ width: '100%' }}>
                      <OptionsStack>
                        <OptionsStackIconContainer>
                          <OptionsStackIcon src={UploadIcon} />
                        </OptionsStackIconContainer>
                        <OptionsStackTextContainer>
                          <OptionsStackText>Upload Image</OptionsStackText>
                        </OptionsStackTextContainer>
                      </OptionsStack>
                    </label>
                    <input
                      type="file"
                      accept=".jpg,.png,.webp"
                      style={{ display: 'none' }}
                      onChange={handleFileChange}
                      id="fileInput"
                    />
                    <OptionsStack
                      onClick={() => {
                        setOpenChooseFromLibraryModal(true);
                      }}
                    >
                      <OptionsStackIconContainer>
                        <OptionsStackIcon src={LibraryIcon} />
                      </OptionsStackIconContainer>
                      <OptionsStackTextContainer>
                        <OptionsStackText>My Library</OptionsStackText>
                      </OptionsStackTextContainer>
                    </OptionsStack>
                  </>
                ) : (
                  <Stack direction="row" gap="20px" className="replaceImage">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label htmlFor="fileInput" style={{ width: '100%' }}>
                      <OptionsStack>
                        <OptionsStackIconContainer>
                          <OptionsStackIcon src={UploadIcon} />
                        </OptionsStackIconContainer>
                      </OptionsStack>
                    </label>
                    <input
                      type="file"
                      accept=".jpg,.png,.webp"
                      style={{ display: 'none' }}
                      onChange={handleFileChange}
                      id="fileInput"
                    />
                    <OptionsStack
                      onClick={() => {
                        setOpenChooseFromLibraryModal(true);
                      }}
                    >
                      <OptionsStackIconContainer>
                        <OptionsStackIcon src={LibraryIcon} />
                      </OptionsStackIconContainer>
                    </OptionsStack>
                  </Stack>
                )}
              </Stack>
              <Box sx={{ position: 'relative', mt: '-40px' }}>
                <FlexibleHeightImg
                  width={isMobileScreen ? '340px' : '490px'}
                  src={CharacterNameRibbon}
                  style={{
                    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                  }}
                />
                <Stack
                  direction="row"
                  gap="4px"
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                >
                  <FlexibleHeightImg width="17px" src={FlowerLeft} />
                  <Typography
                    sx={{
                      color: 'transparent',
                      fontFamily: 'Passero One',
                      fontSize: isMobileScreen ? '23px' : '36px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '44px',
                      letterSpacing: '.1px',
                      background:
                        'linear-gradient(180deg, #250503 0%, #7A3900 100%)',
                      backgroundClip: 'text',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                      textWrap: 'nowrap',
                    }}
                  >
                    {characterName !== '' ? characterName : 'Character Name'}
                  </Typography>
                  <FlexibleHeightImg width="17px" src={FlowerRight} />
                </Stack>
              </Box>
            </Stack>
            <Box sx={{ boxSizing: 'border-box' }}>
              <CharacterAbilitiesGen
                variant="characterWizard2"
                abilities={characterAbilities}
                setCharacterAbilities={() => {}}
              />
            </Box>
          </Stack>
        </Box>
      )}

      {/* Character Details */}
      {selectedView !== 'summary' && (
        <Box
          width={isTabletScreen ? '90%' : '27%'}
          paddingLeft={isMobileScreen ? '17px' : '0px'}
          paddingRight={isMobileScreen ? '15px' : '0px'}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
              boxSizing: 'border-box',
              borderImageRepeat: 'round',
              background: `url(${TexturedBackground})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                boxSizing: 'border-box',
                border: '8px solid #68583D',
                borderImageSource: `url(${CustomBorder})`,
                borderImageSlice: '17',
                borderImageRepeat: 'round',
                backgroundColor: 'rgba(208, 177, 122, 0.74)',
                padding: isMobileScreen ? '30px' : '45px',
                paddingRight: isMobileScreen ? '22px' : '37px',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  boxSizing: 'border-box',
                  overflowY: 'scroll',
                  '&::-webkit-scrollbar': {
                    width: '8px',
                    backgroundColor: '#CFCAAD00',
                    borderRadius: '8px',
                  },
                  '&.scrollbar': {
                    scrollbarWidth: 'thin',
                    scrollbarColor: '#CFCAAD00',
                    backgroundColor: '#CFCAAD00',
                    borderRadius: '8px',
                  },
                  '&::-webkit-scrollbar-thumb, &.scrollbar-thumb, &::-webkit-scrollbar-thumb:hover, &.scrollbar-thumb:hover':
                    {
                      backgroundColor: '#41521F',
                      borderRadius: '8px',
                    },
                }}
              >
                {/* Character Details Heading */}
                <Stack justifyContent="center" gap="12px" mb="28px">
                  <SectionHeadingText
                    sx={{ fontSize: '28px!important', textAlign: 'center' }}
                  >
                    Character Details
                  </SectionHeadingText>
                  {/* Class | Race | Alignment | Profession */}
                  <Stack direction="row" gap="10px" justifyContent="center">
                    <Typography
                      sx={{
                        fontFamily: 'Passero One',
                        fontSize: '16px',
                        fontWeight: '400',
                        color: '#250503',
                        textAlign: 'center',
                        textTransform: 'capitalize',
                      }}
                    >
                      {characterClass?.name !== undefined &&
                      characterClass?.name !== ''
                        ? characterClass?.name
                        : 'Class'}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: 'Passero One',
                        fontSize: '16px',
                        fontWeight: '400',
                        color: '#250503',
                        textAlign: 'center',
                        textTransform: 'capitalize',
                      }}
                    >
                      |
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: 'Passero One',
                        fontSize: '16px',
                        fontWeight: '400',
                        color: '#250503',
                        textAlign: 'center',
                        textTransform: 'capitalize',
                      }}
                    >
                      {characterRace !== '' ? characterRace : 'Race'}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: 'Passero One',
                        fontSize: '16px',
                        fontWeight: '400',
                        color: '#250503',
                        textAlign: 'center',
                        textTransform: 'capitalize',
                      }}
                    >
                      |
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: 'Passero One',
                        fontSize: '16px',
                        fontWeight: '400',
                        color: '#250503',
                        textAlign: 'center',
                        textTransform: 'capitalize',
                      }}
                    >
                      {characterAlignment !== ''
                        ? characterAlignment
                        : 'Alignment'}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: 'Passero One',
                        fontSize: '16px',
                        fontWeight: '400',
                        color: '#250503',
                        textAlign: 'center',
                        textTransform: 'capitalize',
                      }}
                    >
                      |
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: 'Passero One',
                        fontSize: '16px',
                        fontWeight: '400',
                        color: '#250503',
                        textAlign: 'center',
                        textTransform: 'capitalize',
                      }}
                    >
                      {characterProfession !== ''
                        ? characterProfession
                        : 'Profession'}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack gap="21px">
                  {/* Age | Gender | Pronouns | Motivations | Personality Type */}
                  <AttributeWrapper>
                    <Stack
                      sx={{
                        border: '20px solid #68583D',
                        borderImageSource: `url(${FloweredBorder})`,
                        borderImageSlice: '44 68',
                        borderImageRepeat: 'stretch',
                        boxSizing: 'border-box',
                        height: '100%',
                      }}
                      direction="row"
                      flexWrap="wrap"
                      rowGap="5px"
                    >
                      <Stack width="50%">
                        <SmallHeadingText>Age</SmallHeadingText>
                        <AttributeText>
                          {characterAge !== 0 ? characterAge : '000'}
                        </AttributeText>
                      </Stack>
                      <Stack width="50%">
                        <SmallHeadingText>Gender</SmallHeadingText>
                        <AttributeText>
                          {characterGender?.name !== undefined &&
                          characterGender?.name !== ''
                            ? characterGender?.name
                            : 'None'}
                        </AttributeText>
                      </Stack>
                      <Stack width="50%">
                        <SmallHeadingText>Pronouns</SmallHeadingText>
                        <AttributeText>
                          {characterPronouns !== ''
                            ? characterPronouns
                            : 'None'}
                        </AttributeText>
                      </Stack>
                      <Stack width="50%">
                        <SmallHeadingText>Motivations</SmallHeadingText>
                        {characterMotivations.length > 0 ? (
                          <Stack
                            direction="row"
                            flexWrap="wrap"
                            columnGap="5px"
                          >
                            {characterMotivations.map((motivation) => (
                              <AttributeText
                                sx={{ display: 'inline!important' }}
                                key={motivation}
                              >
                                {`${motivation},`}
                              </AttributeText>
                            ))}
                          </Stack>
                        ) : (
                          <AttributeText>None</AttributeText>
                        )}
                      </Stack>
                      <Stack width="50%">
                        <SmallHeadingText>Personality Type</SmallHeadingText>
                        <AttributeText>
                          {characterPersonalityType?.name !== undefined &&
                          characterPersonalityType?.name !== ''
                            ? characterPersonalityType?.name
                            : 'None'}
                        </AttributeText>
                      </Stack>
                    </Stack>
                  </AttributeWrapper>
                  {/* Body Type | Skin Color | Hair Color | Hair Style | Eye Color */}
                  <AttributeWrapper>
                    <Stack
                      sx={{
                        border: '20px solid #68583D',
                        borderImageSource: `url(${FloweredBorder})`,
                        borderImageSlice: '44 68',
                        borderImageRepeat: 'stretch',
                        boxSizing: 'border-box',
                        height: '100%',
                      }}
                      direction="row"
                      flexWrap="wrap"
                      rowGap="5px"
                    >
                      <Stack width="50%">
                        <SmallHeadingText>Body Type</SmallHeadingText>
                        <AttributeText>
                          {characterBodyType?.name !== '' &&
                          characterBodyType?.name !== undefined
                            ? characterBodyType?.name
                            : 'None'}
                        </AttributeText>
                      </Stack>
                      <Stack width="50%">
                        <SmallHeadingText>Skin Color</SmallHeadingText>
                        <AttributeText>
                          {characterSkinColor?.name !== '' &&
                          characterSkinColor?.name !== undefined
                            ? characterSkinColor?.name
                            : 'None'}
                        </AttributeText>
                      </Stack>
                      <Stack width="50%">
                        <SmallHeadingText>Hair Color</SmallHeadingText>
                        <AttributeText>
                          {characterHairColor !== ''
                            ? characterHairColor
                            : 'None'}
                        </AttributeText>
                      </Stack>
                      <Stack width="50%">
                        <SmallHeadingText>Hair Style</SmallHeadingText>
                        <AttributeText>
                          {characterHairStyle !== ''
                            ? characterHairStyle
                            : 'None'}
                        </AttributeText>
                      </Stack>
                      <Stack width="50%">
                        <SmallHeadingText>Eye Color</SmallHeadingText>
                        <AttributeText>
                          {characterEyeColor?.name !== '' &&
                          characterEyeColor?.name !== undefined
                            ? characterEyeColor?.name
                            : 'None'}
                        </AttributeText>
                      </Stack>
                    </Stack>
                  </AttributeWrapper>
                  {/* Relationship | Life Altering Event */}
                  <AttributeWrapper>
                    <Stack
                      sx={{
                        border: '20px solid #68583D',
                        borderImageSource: `url(${FloweredBorder})`,
                        borderImageSlice: '44 68',
                        borderImageRepeat: 'stretch',
                        boxSizing: 'border-box',
                        height: '100%',
                      }}
                      direction="row"
                      flexWrap="wrap"
                      rowGap="5px"
                    >
                      <Stack width="100%">
                        <SmallHeadingText>Relationship</SmallHeadingText>
                        <AttributeText>
                          {characterRelationship !== ''
                            ? characterRelationship
                            : 'None'}
                        </AttributeText>
                      </Stack>
                      <Stack width="100%">
                        <SmallHeadingText>Life Altering Event</SmallHeadingText>
                        <AttributeText>
                          {characterLifeAlteringEvent !== ''
                            ? characterLifeAlteringEvent
                            : 'None'}
                        </AttributeText>
                      </Stack>
                    </Stack>
                  </AttributeWrapper>
                  {/* Birth Story */}
                  <BackgroundContentWrapper
                    maxHeight="125px"
                    py="5px"
                    sx={{
                      background: '#E3D5BC!important',
                      border: 'none!important',
                    }}
                  >
                    <CustomScrollWrapper
                      sx={{
                        height: '100%',
                      }}
                    >
                      <Box
                        borderRight="solid 1px #BCAD93"
                        p="12px 30px 12px 12px"
                      >
                        <SmallHeadingText>Birth Story</SmallHeadingText>
                        <AttributeText>
                          {characterBackStory !== ''
                            ? characterBackStory
                            : 'No back story available'}
                        </AttributeText>
                      </Box>
                    </CustomScrollWrapper>
                  </BackgroundContentWrapper>
                  {/* Lineage */}
                  <BackgroundContentWrapper
                    maxHeight="125px"
                    py="5px"
                    sx={{
                      background: '#E3D5BC!important',
                      border: 'none!important',
                    }}
                  >
                    <CustomScrollWrapper
                      sx={{
                        height: '100%',
                      }}
                    >
                      <Box
                        borderRight="solid 1px #BCAD93"
                        p="12px 30px 12px 12px"
                      >
                        <SmallHeadingText>Parents (Lineage)</SmallHeadingText>
                        <AttributeText>
                          {characterLineage !== ''
                            ? characterLineage
                            : 'No back story available'}
                        </AttributeText>
                      </Box>
                    </CustomScrollWrapper>
                  </BackgroundContentWrapper>
                  {/* short term goals */}
                  <Box width="100%">
                    <Stack
                      gap="35px"
                      alignItems="center"
                      direction="row"
                      justifyContent="space-between"
                      sx={{
                        width: '100%',
                        borderRadius: '4px 4px 0px 0px',
                        background: '#41521F',
                        padding: '5px 23px',
                        boxSizing: 'border-box',
                        position: 'relative',
                      }}
                    >
                      <Typography
                        sx={{
                          color: 'white',
                          fontFamily: 'DM Sans',
                          fontSize: '14px',
                          fontStyle: 'normal',
                          fontWeight: '700',
                          lineHeight: 'normal',
                          letterSpacing: '0.46px',
                          textTransform: 'capitalize',
                          textAlign: 'left',
                          width: 'fit-content',
                        }}
                      >
                        Short Term Goals
                      </Typography>
                      <KeyboardArrowDownIcon
                        sx={{ fontSize: '30px', color: 'white' }}
                      />
                    </Stack>
                    <GoalContentWrapper
                      height="127px"
                      py="5px"
                      sx={{
                        background: '#E3D5BC',
                      }}
                    >
                      <CustomScrollWrapper
                        sx={{
                          height: '100%',
                        }}
                      >
                        <Box borderRight="solid 1px #BCAD93" p="0">
                          {characterShortTermGoals.map((note) => (
                            <CharacterNoteView note={note} />
                          ))}
                        </Box>
                      </CustomScrollWrapper>
                    </GoalContentWrapper>
                  </Box>
                  {/* long term goals */}
                  <Box width="100%">
                    <Stack
                      gap="35px"
                      alignItems="center"
                      direction="row"
                      justifyContent="space-between"
                      sx={{
                        width: '100%',
                        borderRadius: '4px 4px 0px 0px',
                        background: '#41521F',
                        padding: '5px 23px',
                        boxSizing: 'border-box',
                        position: 'relative',
                      }}
                    >
                      <Typography
                        sx={{
                          color: 'white',
                          fontFamily: 'DM Sans',
                          fontSize: '14px',
                          fontStyle: 'normal',
                          fontWeight: '700',
                          lineHeight: 'normal',
                          letterSpacing: '0.46px',
                          textTransform: 'capitalize',
                          textAlign: 'left',
                          width: 'fit-content',
                        }}
                      >
                        Long Term Goals
                      </Typography>
                      <KeyboardArrowDownIcon
                        sx={{ fontSize: '30px', color: 'white' }}
                      />
                    </Stack>
                    <GoalContentWrapper
                      height="127px"
                      py="5px"
                      sx={{
                        background: '#E3D5BC',
                      }}
                    >
                      <CustomScrollWrapper
                        sx={{
                          height: '100%',
                        }}
                      >
                        <Box borderRight="solid 1px #BCAD93" p="0">
                          {characterLongTermGoals.map((note) => (
                            <CharacterNoteView note={note} />
                          ))}
                        </Box>
                      </CustomScrollWrapper>
                    </GoalContentWrapper>
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      {showMascott && (
        <FlexibleHeightImg
          src={RwMascottCongrats}
          width="500px"
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
          }}
        />
      )}

      <Box sx={{ position: 'fixed' }}>
        <ChooseFromLibraryModal
          open={openChooseFromLibraryModal}
          onClose={() => {}}
          setOpen={setOpenChooseFromLibraryModal}
          onConfirm={(url) => {
            setPreviewUrl(url);
            setOpenChooseFromLibraryModal(false);
          }}
        />
      </Box>
    </Stack>
  );
}

export default CharacterWizard;
