/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/require-default-props */
import {
  Link as RouterLink,
  useNavigate,
} from 'react-router-dom';
import Link, { LinkProps } from '@mui/material/Link';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Image from 'mui-image';
import { useAuth } from '../../../hooks/use-auth';

import GoogleIcon from '../../../images/google_icon.svg';
import rwlogo from '../../../images/Realm_worlds_logo.png';
import LoginScroll from '../../../images/login-scroll.png';
import { GoogleButton, RMFilledInput, RMTextField } from './LoginBox.styles';
import PasswordReset from '../../modals/PasswordReset';
import { fetchCreateRealmWorldsUser } from '../../../__generated__/realmWorldsApiComponents';

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

function LinkRouter(props: LinkRouterProps) {
  return (
    <Link
      {...props}
      component={RouterLink as any}
      sx={{
        fontFamily: 'Dungeon',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '16px',
        textDecorationLine: 'underline',
      }}
    />
  );
}

type LoginBoxProps = {
  onLogin?: () => void
};

function LoginBox(props: LoginBoxProps): JSX.Element {
  const auth = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [authLoading, setAuthLoading] = useState(false);
  const [authError, setError] = useState(false);
  const [authErrorMessage, setErrorMessage] = useState('');

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleCommunity = () => {
    navigate('/community-view');
  };

  const handleLogin = async () => {
    setAuthLoading(true);
    try {
      await auth?.signin(email, password);
      // if you get here, you have signed in.
      props?.onLogin?.();
    } catch (error: any) {
      setError(true);
      setAuthLoading(false);
      if (error.code === 'auth/wrong-password') {
        setErrorMessage('Invalid password. Please try again.');
      } else if (error.code === 'auth/too-many-requests') {
        setErrorMessage(
          'Too many failed login attempts. Please try again later, or reset your password.',
        );
      } else if (error.code === 'auth/user-not-found') {
        setErrorMessage(
          'We could not find an account with that email address. Please try again.',
        );
      } else {
        setErrorMessage('An error occurred. Please try again later.');
      }
    }
  };
  const handleLoginWithGoogle = async () => {
    console.log('login with google');
    setAuthLoading(true);
    try {
      const result = await auth?.signInWithPopupFlow();
      // if you get here, you have signed in.
      // check if the user new.
      // check if the user new.
      const userDetails = auth?.getAdditionalUserInfoFlow(result);
      const currentUser = result.user;
      const idToken = await auth?.user?.getIdToken();
      if (userDetails?.isNewUser) {
        // TODO update API to use the new user endpoint

        // if the user is new, then we need to notify the backend that this is a new user.
        const newUserResult = await fetchCreateRealmWorldsUser({
          body: {
            displayName: currentUser?.displayName,
            email: currentUser?.email,
            photoUrl: currentUser?.photoURL,
            password: null,
            userId: currentUser?.uid,
            subscriptionTierName: 'Free',
          },
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        });
        console.log('new user result: ', newUserResult);
      }
      props?.onLogin?.();
    } catch (error: any) {
      setError(true);
      setErrorMessage('Could not sign in with Google. Please try again.');
    }
  };

  const validateInput = () => {
    // check email regex
    // check password length
    const emailValidated = /^[\w-.]+@([\w-]+\.)+[\w-]{2,10}$/.test(email);
    // var passwordValidated =
    // /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm.test(password);
    return emailValidated;
  };
  const [openPasswordResetModal, setopenPasswordResetModal] = React.useState(false);

  const styles = {
    container: {
      width: 1203,
      height: 1080,
      backgroundImage: `url(${LoginScroll})`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      gap: 24,

    },
    card: {
      width: '60vw',
      padding: '4px',
      backgroundColor: '#0000',
      boxShadow: 'none',
      display: 'flex',

    },
    cardContent: {
      backgroundColor: '#0000',
    },
    google: {
      display: 'flex',
      width: '524px',
      height: '50px',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '8px',
      flexShrink: 0,
      borderRadius: '4px',
      background: '#FFF',
    },

    loginButton: {
      mt: 3,
      mb: 4,
      height: 60,
      width: '200px',
      borderRadius: 4,
      background: '#41521F',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
      marginRight: '10px',
    },
  };

  return (
    <Container sx={styles.container}>

      <Card sx={styles.card}>

        <CardContent sx={styles.cardContent}>

          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          >
            <Grid container spacing={1} justifyContent="center" alignItems="center">
              <Grid item xs={12} padding={2} />
              <Grid item xs={12}>
                <Image
                  style={{
                    maxWidth: '150px',
                    maxHeight: '150px',
                  }}
                  src={rwlogo}
                  alt="RW Logo"
                />
              </Grid>
              <Grid item xs={12}>

                <Typography
                  px={3}
                  variant="h3"
                  align="center"
                  color="common.black"
                  textAlign="center"
                  fontFamily="Passero One"
                  fontSize="45px"
                  fontStyle="normal"
                  fontWeight={400}
                >
                  Realm Worlds
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <GoogleButton
                  fullWidth
                  variant="outlined"
                  onClick={handleLoginWithGoogle}
                  startIcon={<Avatar sx={{ width: 24, height: 24 }} src={GoogleIcon} />}
                  sx={styles.google}
                >
                  Log in with Gmail
                </GoogleButton>
              </Grid>
              <Grid item xs={12}>
                <Divider>
                  <Typography
                    align="center"
                    color="common.black"
                    fontFamily="Passero One"
                    fontSize="24px"
                    fontStyle="normal"
                    fontWeight={400}
                  >
                    Or

                  </Typography>
                </Divider>
              </Grid>
              <Grid item xs={12}>
                <RMTextField
                  fullWidth
                  variant="filled"
                  id="email"
                  label="Username or Email"
                  name="email"
                  autoComplete="email"
                  error={!validateInput() && !(email.length < 4)}
                  onChange={(e) => { setEmail(e.target.value); }}
                  InputProps={{ disableUnderline: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  variant="filled"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    maxWidth: '524px',
                    margin: '0 auto',
                  }}
                >
                  <InputLabel sx={{ color: 'black' }} htmlFor="password">Password</InputLabel>
                  <RMFilledInput
                    id="password"
                    name="password"
                    disableUnderline
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="current-password"
                    onChange={(e) => { setPassword(e.target.value); }}
                    endAdornment={(
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            {authError ? (
              <FormHelperText
                sx={() => ({
                  mx: 'auto',
                  color: 'red',
                })}
              >
                {authErrorMessage}
              </FormHelperText>
            ) : (
              <></>
            )}
              &nbsp;
            <Grid container>
              <Grid item xs={4.5} />
              <Grid item xs={3}>
                <LinkRouter color="#000" to="/signup" variant="body2">
                  Create Account
                </LinkRouter>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  color="#000"
                  sx={{
                    fontFamily: 'Dungeon',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '16px',
                    textDecorationLine: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={() => setopenPasswordResetModal(true)}
                >
                  Forgot Password?
                </Typography>
                <PasswordReset open={openPasswordResetModal} onClose={() => { console.log('modal closed'); }} setOpen={setopenPasswordResetModal} />
              </Grid>
            </Grid>
              &nbsp;
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  align="center"
                  color="#000"
                  textAlign="center"
                  fontFamily="Dungeon"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight={400}
                  lineHeight="16px"
                >
                  By clicking Log in you accept Realm Worlds
                  {' '}
                  <LinkRouter color="#5693CB" to="/signup" variant="body2">Terms of Service</LinkRouter>
                  {' '}
                  and
                  {' '}
                  <LinkRouter color="#5693CB" to="/signup" variant="body2">Privacy Policy</LinkRouter>
                </Typography>
              </Grid>
            </Grid>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <LoadingButton
                type="submit"
                variant="contained"
                onClick={handleLogin}
                loading={authLoading}
                sx={styles.loginButton}
              >
                LogIn
              </LoadingButton>
              <Button
                variant="contained"
                onClick={handleCommunity}
                sx={{
                  mt: 3,
                  mb: 4,
                  height: 60,
                  width: '300px',
                  borderRadius: 4,
                  background: '#41521F',
                  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                }}
              >
                Visit Community
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Container>

  );
}

export default LoginBox;
