/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable max-len */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { Box, useMediaQuery } from '@mui/material';
import NavBar from '../../../components/nav/DashboardTopNav/NavBar';
import Notification from '../../../components/notifications/Notification';
import useGraphQL from '../../../hooks/useGraphQL';
import {
  GetRealmDocument,
  GetUserProfileDocument,
  GetUserWalletDocument,
} from '../../../__generated__/graphql';
import appStore from '../../../store/AppStore';
// import { getAvaliableCredits } from '../Dashboard';
// import { useAuth } from '../../../hooks/use-auth';
// import CartItemsModal from '../../../components/modals/ShoppingCartPopups/CartItemsModal/CartItemsModal';
// import EmptyCartModal from '../../../components/modals/ShoppingCartPopups/EmptyCartModal/EmptyCartModal';

import { useAuth } from '../../../hooks/use-auth';

import token from '../../../assets/images/azamel_Coin.png';
import plus from '../../../assets/images/Add.png';
import { getAvaliableCredits } from '../Dashboard';
import s from '../../../ui/DashboardView/DashboardView.module.scss';

function RealmDashboard() {
  const isTabletScreen = useMediaQuery('(max-width: 865px');
  const isMobileScreen = useMediaQuery('(max-width:530px)');

  useEffect(() => {
    localStorage.removeItem('activeFrame');
    localStorage.removeItem('activeForeground');
    localStorage.removeItem('activeBackground');
  }, []);

  const { id } = useParams();

  const { data: realm } = useGraphQL(GetRealmDocument, {
    query: {
      _id: id,
    },
  });
  if (realm && realm !== undefined) {
    appStore.setState({
      selectedRealm: realm.realm,
    });
  }

  /*
  const openShoppingCartModal: boolean = appStore(
    (state) => state.openShoppingCartModal,
  );

  const setShoppingCartModal = (bool: boolean) => {
    appStore.setState({
      openShoppingCartModal: bool,
    });
  };

  const openEmptyCartModal: boolean = appStore(
    (state) => state.openEmptyCartModal,
  );

  const setOpenEmptyCartModal = (bool: boolean) => {
    appStore.setState({
      openEmptyCartModal: bool,
    });
  };
  */

  const navigate = useNavigate();

  const auth = useAuth();

  const userProfile = auth?.user?.uid
    ? useGraphQL(GetUserProfileDocument, {
        query: {
          _id: auth.user.uid,
        },
      }).data
    : null;

  const userWallet = auth?.user?.uid
    ? useGraphQL(GetUserWalletDocument, {
        query: {
          _id: auth.user.uid,
        },
      }).data
    : null;

  appStore.setState({
    userName:
      userProfile?.user?.username ?? (auth?.user?.displayName as string),
    userCredits: getAvaliableCredits(userWallet ?? {}),
  });

  const userCredits: number = appStore((state) => state.userCredits);

  const userName: string = appStore((state) => state.userName as string);

  const userPhoto: string | null = appStore(
    (state) => state.userProfilePhoto as string,
  );

  return (
    <div>
      <div
        // establish a container for the navigation bar that will contain the tabs and
        // sets the current realm theme by setting the backgroundimage to the realm theme
        // this will be a full screen container for all content
        style={{
          backgroundImage: `url(${realm?.realm?.thumbnail_url})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          opacity: 0.2,
          width: '100vw',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          zIndex: -999,
          position: 'fixed',
        }}
      />
      <NavBar
        tabs={[
          { name: 'Token Maker', path: 'tokenmaker' },
          { name: 'Characters', path: 'character' },
          { name: 'Campaign', path: 'campaign' },
        ]}
      />
      <Box
        sx={{
          paddingTop: isTabletScreen ? '24px!important' : '48px!important',
          minHeight: isTabletScreen ? 'calc(100vh - 24px)!important' : 'calc(100vh - 48px)!important',
          height: isTabletScreen ? 'auto!important' : 'calc(100vh - 48px)!important',
        }}
      >
        <div className={s.token_wrapper} onClick={() => navigate('/settings')}>
          <img
            className={s.token}
            src={token}
            alt="token"
            onClick={() => navigate('/settings')}
          />
          <p>{userCredits !== null ? userCredits : 'loading...'}</p>
          <button type="button" className={s.addButton}>
            <img src={plus} alt="token" />
          </button>
        </div>

        <Outlet />
      </Box>
      {/*
        <CartItemsModal
          open={openShoppingCartModal}
          onClose={() => {}}
          setOpen={setShoppingCartModal}
        />
        <EmptyCartModal
          open={openEmptyCartModal}
          onClose={() => {}}
          setOpen={setOpenEmptyCartModal}
        />
        */}
      <Notification />
    </div>
  );
}

export default RealmDashboard;
