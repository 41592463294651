/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-confusing-arrow */
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Stack,
  Typography,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  Container,
  useMediaQuery,
  Chip,
} from '@mui/material';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import useGraphQL from '../../../hooks/useGraphQL';
import {
  GetRealmDocument, GetRealmQuery,
} from '../../../__generated__/graphql';
import { ThemeContext } from '../../../context/ThemeContext';
import BackgroundImage from '../../../images/character-catalog.webp';
import CreateCampaignModal from '../../modals/CreateCampaignModal';
import { useAuth } from '../../../hooks/use-auth';
import getRealmCampaigns from './Campaigns.data';
import s from './Campaigns.module.scss';
import RealmSlider from '../RealmSlider';

type Campaign = ReturnType<typeof getRealmCampaigns>[0];

/*
const setSelectedCampaign = (campaign: Campaign | null): void => {
  appStore.setState({
    selectedCampaign: campaign,
  });
};
*/

const CampaignsHeader = (currentCampaign: Campaign | null) => (
  <Box
    sx={{
      backgroundImage: `url(${
        // eslint-disable-next-line react/destructuring-assignment
        currentCampaign ? currentCampaign.image : BackgroundImage
      })`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      pt: 15,
      width: '100vw',
      height: '270px',
      position: 'relative',
      boxSizing: 'border-box',
    }}
  >
    <Typography sx={{
      color: '#FFF',
      textAlign: 'center',
      fontFamily: 'Passero One',
      fontSize: '50px',
      fontStyle: 'normal',
      textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      fontWeight: 400,
      lineHeight: 'normal',
      paddingBottom: 5,
      zIndex: 3,
    }}
    >
      Campaigns
    </Typography>
  </Box>
);

function Campaigns() {
  const { theme } = useContext(ThemeContext);

  const navigate = useNavigate();
  const auth = useAuth();
  const onMobile = useMediaQuery('(max-width: 600px)');
  const onXSMMobile = useMediaQuery('(max-width: 400px)');
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [currentCampaign, setCurrentCampaign] = useState<Campaign | null>(null);
  const [openCreateCampaignModal, setOpenCreateCampaignModal] = React.useState(false);
  const {
    data: realmData,
    error: realmsError,
    isLoading,
    refetch: refetchRealmsData,
  } = useGraphQL(GetRealmDocument, {
  });

  /*
  const {
    data: realmsData,
    error: realmsError,
    isLoading,
    refetch: refetchRealmsData,
  } = useGraphQL(GetRealmsDocument, {
    limit: 100,
    sortBy: RealmSortByInput.CreatedatDesc,
  });
*/

  React.useEffect(() => {
  }, []);

  const handleCollapseToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const renderCreateNewCampaignButton = () => (
    <Button
      variant="contained"
      type="button"
      sx={{
        transform: 'translateY(-50%)',
        zIndex: 3,
        marginRight: '10px',
      }}
      onClick={() => setOpenCreateCampaignModal(!openCreateCampaignModal)}
    >
      {openCreateCampaignModal ? 'Close' : 'Create New Campaign'}
    </Button>
  );

  const renderCreateNewAICampaignButton = () => (
    <Button
      variant="contained"
      type="button"
      sx={{
        transform: 'translateY(-50%)',
        zIndex: 3,
        backgroundColor: 'magenta',
        marginLeft: '10px',
      }}
      onClick={() => setOpenCreateCampaignModal(true)}
    >
      Create AI Campaign
    </Button>
  );

  const renderSideBarToggle = () => (
    <Button
      sx={{
        position: 'absolute',
        top: '120px',
        right: 0,
        transform: 'translateY(-50%)',
        zIndex: 3,
      }}
      type="button"
      onClick={handleCollapseToggle}
    >
      {!isCollapsed ? <ArrowLeft /> : <ArrowRight />}
    </Button>

  );

  const handleCampaignClick = (campaign: Campaign) => {
    try {
      // extract current realm from current url
      const url = window.location.href;
      const realmId = url.split('/realm/')[1].split('/')[0];

      setCurrentCampaign(campaign);
      // navigate(`${campaign?.id}`);
      navigate(`/realm/${realmId}/campaign-view/${campaign.id}`);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        background: `linear-gradient(180deg, #ffffff00 30%, ${theme === 'light' ? '#E9DCC3' : '#313131'} 100%)`,
        zIndex: 2,
      }}
    >
      {
        isCollapsed && <RealmSlider currentRealmId="" />
      }
      {renderSideBarToggle()}
      {CampaignsHeader(currentCampaign)}
      { openCreateCampaignModal && (
        <Box
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            background: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1,
          }}
        >
          <CreateCampaignModal />
        </Box>
      )}
      <Container
        maxWidth="md"
        sx={{
          marginTop: 5,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 30,
        }}
      >
        <Stack
          direction="row"
          spacing={5}
          justifyContent="center"
          sx={{
            marginBottom: 5,
          }}
        >
          {renderCreateNewCampaignButton()}
          {renderCreateNewAICampaignButton()}
        </Stack>
        {getRealmCampaigns().map((campaign: Campaign) => (
          <Card
            sx={{
              background: 'rgba(255, 255, 255, 0.4)',
              borderRadius: '0px 5px 5px 0px',
              boxShadow: '2px 2px 6px 3px rgba(0, 0, 0, 0.3)',
              marginBottom: 10,
              transition: 'transform 0.2s', // Add transition effect
              '&:hover': {
                transform: 'scale(1.05)', // Enlarge slightly when hovered over
              },
            }}
            onClick={() => setCurrentCampaign(campaign)}
          >
            <CardMedia
              component="img"
              height="140"
              image={campaign.image}
              alt={campaign.name}
            />
            <CardContent>
              <Stack spacing={2}>
                <Typography
                  sx={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  {campaign.name}
                  &nbsp;campaign
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                  }}
                >
                  {campaign.description}
                </Typography>
                {campaign.tags ? (
                  <Stack direction="row" spacing={1}>
                    {campaign.tags.map((tag) => (
                      <Chip
                        key={tag}
                        label={tag}
                      />
                    ))}
                  </Stack>
                ) : null}
                {campaign.acts ? (
                  <Stack direction="row" spacing={1}>
                    <ImageList
                      sx={{
                        width: '100%',
                        height: 100,
                        overflow: 'hidden',
                        flexWrap: 'nowrap',
                        transform: 'translateZ(0)',
                      }}
                      cols={3}
                    >
                      {campaign.acts.map((act) => (
                        <ImageListItem key={act.image}>
                          <img
                            src={act.image}
                            alt={act.name}
                            loading="lazy"
                            style={{
                              width: '100%',
                              height: '64px',
                              objectFit: 'none',
                            }}
                          />
                          <ImageListItemBar
                            title={act.name}
                            subtitle={act.description}
                          />
                        </ImageListItem>
                      ))}
                    </ImageList>
                  </Stack>
                ) : null}
                <Stack
                  direction="row"
                  spacing={5}
                  justifyContent="flex-end"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      handleCampaignClick(campaign);
                    }}
                  >
                    View Campaign
                  </Button>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        ))}
      </Container>
    </Box>
  );
}

export default Campaigns;
