/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { Box } from '@mui/material';
import {
  GetTokenSetsDocument,
  Token_Set,
  Token_SetInsertInput,
} from '../../../__generated__/graphql';
import Expansion from './Expansion';
import addIcon from '../../../assets/images/add_24px.png';
import editIcon from '../../../assets/images/mode_edit_24px.png';
import ExpansionFetchTokenItem from './ExpansionFetchTokenItem';
import appStore from '../../../store/AppStore';
import NewTokenSetForm from '../../preview/saveToken/NewTokenSetForm';
import useGraphQL from '../../../hooks/useGraphQL';
import Modal from '../../../ui/Modal/Modal';
import './expansion.scss';

interface Props {
  token_set: Token_Set;
  tokensSlice: number;
  tightSpacing?: boolean;
}

function ExpansionTokenSetsItem({
  token_set,
  tokensSlice,
  tightSpacing,
}: Props) {
  //   console.log(token_set);

  const [statusInfo, setStatusInfo] = React.useState({
    status: '',
    message: '',
    open: false,
    timeout: 5000,
  });
  const [tokenSetExpanded, setTokenSetExpanded] = React.useState(false);
  const [openNewTokenSet, setOpenNewTokenSet] = React.useState(false);

  const [newTokenSet, setNewTokenSet] = React.useState<Token_SetInsertInput>({
    name: '',
    description: '',
    thumbnail_url: '',
    tokens: null,
  });

  const realm = appStore((state) => state.selectedRealm);

  const { data: tokenSets } = useGraphQL(GetTokenSetsDocument, {
    query: {
      realm: {
        _id: realm?._id,
      },
    },
  });

  const handleEditNewTokenSetName = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const data = event.target.value;
    setNewTokenSet({ ...newTokenSet, name: data });
  };

  const handleEditNewTokenSetDescription = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const data = event.target.value;
    setNewTokenSet({ ...newTokenSet, description: data });
  };

  const handleEditNewTokenSetThumbnailUrl = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const data = event.target.value;
    setNewTokenSet({ ...newTokenSet, thumbnail_url: data });
  };

  console.log(token_set);

  return (
    token_set.tokens?.length &&
    token_set.tokens?.length > 0 && (
      <Expansion
        title={token_set.name as string}
        isExpanded={tokenSetExpanded}
        setIsExpanded={() => setTokenSetExpanded(!tokenSetExpanded)}
        headerChildren={
          <>
            <button
              style={{
                padding: '5px',
                display: 'block',
                background: 'transparent',
                outline: 'none',
                border: 'none',
                cursor: 'pointer',
              }}
              type="button"
              onClick={() => {
                appStore.setState({
                  updateTokenSetModalOpen: true,
                  selectedTokenSet: token_set,
                  activeTab: 'none',
                });
              }}
            >
              <img src={editIcon} alt="edit Icon" />
            </button>
            <Modal>
              <Modal.Open opensWindowName="createTokenSet">
                <button
                  style={{
                    padding: '5px',
                    display: 'block',
                    background: 'transparent',
                    outline: 'none',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                  type="button"
                >
                  <img src={addIcon} alt="add Icon" />
                </button>
              </Modal.Open>
              <Modal.Window name="createTokenSet">
                <div
                  style={{
                    padding: '20px',
                    backgroundColor: 'rgba(217, 217, 217, 0.2)',
                    border: '1px solid #FFFFFF',
                    borderRadius: '10px',
                  }}
                >
                  <p
                    style={{ paddingBottom: '10px' }}
                    className="tokenSetFormHeading"
                  >
                    Create a new token set
                  </p>
                  <NewTokenSetForm
                    newTokenSet={newTokenSet}
                    // handleEditNewTokenSetDescription={
                    //   handleEditNewTokenSetDescription
                    // }
                    handleEditNewTokenSetName={handleEditNewTokenSetName}
                    // handleEditNewTokenSetThumbnailUrl={
                    //   handleEditNewTokenSetThumbnailUrl
                    // }
                    realmID={realm?._id}
                    closeNewTokenSetForm={() => setOpenNewTokenSet(false)}
                    appendNewTokenSet={(tokenSet: Token_Set) =>
                      tokenSets?.token_sets.push(tokenSet)
                    }
                    showStatus={(open, message, status, timeout) =>
                      setStatusInfo({
                        open,
                        message,
                        status,
                        timeout,
                      })
                    }
                  />
                </div>
              </Modal.Window>
            </Modal>
          </>
        }
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            columnGap: tightSpacing ? '8px' : '16px',
            rowGap: tightSpacing ? '8px' : '16px',
          }}
        >
          {token_set?.tokens?.slice(0, tokensSlice).map((tokenID: any) => (
            <ExpansionFetchTokenItem tokenID={tokenID} key={tokenID} />
          ))}
        </Box>

        {/* Create new token set
        {openNewTokenSet && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              width: '100%',
            }}
          >

          </Box>
        )} */}
      </Expansion>
    )
  );
}

ExpansionTokenSetsItem.defaultProps = {
  tightSpacing: false,
};

export default ExpansionTokenSetsItem;
