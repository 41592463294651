import {
  Box, Card, CardMedia, Grid, Stack, Typography,
} from '@mui/material';
import React from 'react';
import { Carousel } from '@trendyol-js/react-carousel';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import GoldShield from '../../../../images/gold-small.png';
import DownloadICon from '../../../../images/download red.png';
import CharacterCarousel from '../CharacterCarousel/CharacterCarousel';
import { ShieldImg, DownloadImg } from '../../../../components/styledComps/styledComps';
import appStore from '../../../../store/AppStore';
import { GetManyMediaAssetsDocument } from '../../../../__generated__/graphql';
import useGraphQL from '../../../../hooks/useGraphQL';

type PackVariant = 'catalog' | 'store';

interface Props {
  width?: string,
  variant?: PackVariant,
  pack?: any | null,
  mediaAssets?: string[],
  title?: string,
}

export default function CharacterPack({
  width, variant, pack, mediaAssets, title,
}: Props): JSX.Element {
  const [selected, setSelected] = React.useState(false);
  const openPackPurchaseSummaryModal:
  boolean = appStore(
    (state) => state.openPackPurchaseSummaryModal,
  );
  const setOpenPackPurchaseSummaryModal = (condition: boolean) => appStore.setState({
    openPackPurchaseSummaryModal: condition,
  });
  const setSelectedCharacterPack = (cPack: any) => appStore.setState({
    selectedCharacterPack: cPack,
  });

  const { data: mediaAssetsFetched, error: mediaAssetsError } = useGraphQL(
    GetManyMediaAssetsDocument,
    {
      query: {
        _id_in: mediaAssets ?? [],
      },
    },
  );

  const handleOpenPackPurchaseSummaryModal = () => {
    if (variant === 'store') {
      setSelected(!selected);
      setSelectedCharacterPack({ ...pack, media_assets: mediaAssetsFetched?.media_assets });
      setOpenPackPurchaseSummaryModal(true);
    }
  };

  const CharacterCarousel2 = mediaAssetsFetched?.media_assets?.map((result: any, index) => (
    <Grid
      item
      xs={3}
      sx={{
        p: 1.75,
      }}
      // eslint-disable-next-line react/no-array-index-key
      key={result.name + index}
    >

      <Card
        sx={{
          display: 'flex',
          borderRadius: '5px',
          background: '#FFF',
          border: '1px solid white',
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        }}
      >
        <Box sx={{
          flex: 1,
          width: 90,
          height: 90,
        }}
        >

          <CardMedia
            component="img"
            image={result.thumbnail_url}
            alt={result.name}
            height="100%"
          />

        </Box>
      </Card>
    </Grid>
  ));

  if (mediaAssetsError) console.log(mediaAssetsError);

  React.useEffect(() => {
    if (!openPackPurchaseSummaryModal) setSelected(false);
  }, [openPackPurchaseSummaryModal]);

  return (
    <Box width={width}>
      <Box
        sx={{
          minHeight: '184px',
          minWidth: '100%',
          background: '#fff',
          boxSizing: 'border-box',
          pt: '10px',
          pl: '13px',
          boxShadow: selected ? '0px 4px 10px 0px #41521F' : 'none',
          border: selected ? '2px solid #41521F' : 'none',
        }}
        onClick={handleOpenPackPurchaseSummaryModal}
      >

        {/* Download Icon Start */}
        {variant === 'catalog' && (
          <Box
            sx={{
              paddingRight: '10px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              cursor: 'pointer',
            }}
          >
            <DownloadImg src={DownloadICon} />
          </Box>
        )}
        {/* Download Icon End */}

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          pl={variant === 'catalog' ? '0' : '14px'}
          pr={variant === 'catalog' ? '0' : '27px'}
        >
          <Stack direction="row" gap="17px" alignItems="center">
            {variant === 'catalog' && (
              <ShieldImg style={{ marginRight: '-15px' }} src={GoldShield} />
            )}
            <Typography sx={{
              fontFamily: 'Passero One',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal',
              whiteSpace: 'nowrap',
            }}
            >
              {title !== '' ? title : 'Example Pack'}
            </Typography>
            <Typography
              sx={{
                color: 'var(--white, #FFF)',
                background: '#41531E',
                textAlign: 'center',
                borderRadius: 4,
                fontFamily: 'DM Sans',
                fontSize: '10px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '20px',
                letterSpacing: '0.1px',
                padding: '0 10px',
                whiteSpace: 'nowrap',
              }}
            >
              In Library
              {' '}
              <span style={{ color: 'green', fontSize: '5px' }}>✓</span>
            </Typography>
          </Stack>
          {variant === 'store' && (
            <ShieldImg src={GoldShield} />
          )}
        </Stack>

        <Box
          sx={{
            minHeight: '90px',
            maxWidth: '100%',
            py: '10px',
          }}
        >
          {' '}
          {(CharacterCarousel2 === undefined
          || CharacterCarousel2.length === 0) && (
            <Carousel
              show={4}
              slide={4}
              swiping
              infinite={false}
              rightArrow={(
                <ArrowRightIcon
                  sx={{
                    marginTop: '55px',
                  }}
                />
  )}
              leftArrow={(
                <ArrowLeftIcon sx={{
                  marginTop: '55px',
                }}
                />
  )}
            >
              {CharacterCarousel}
            </Carousel>
          )}
          {CharacterCarousel2 !== undefined && (
            <Carousel
              show={4}
              slide={4}
              swiping
              infinite={false}
              rightArrow={(
                <ArrowRightIcon
                  sx={{
                    marginTop: '55px',
                  }}
                />
              )}
              leftArrow={(
                <ArrowLeftIcon sx={{
                  marginTop: '55px',
                }}
                />
              )}
            >
              {CharacterCarousel2!}
            </Carousel>
          )}
        </Box>

      </Box>
    </Box>
  );
}

CharacterPack.defaultProps = {
  width: '30%',
  variant: 'catalog',
  pack: null,
  mediaAssets: [],
  title: '',
};
