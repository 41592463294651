/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
import React, { useContext } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import {
  ReactiveBase,
  ReactiveList,
  SearchBox,
} from '@appbaseio/reactivesearch';
import BackgroundImage from '../../../images/character-store-background-image.png';
import CharacterPackImage from '../../../images/character-pack.webp';
import PopularIcon from '../../../images/popular icon.png';
import FilterIcon from '../../../images/filter-slider.webp';
import TeamIcon from '../../../images/team-1.svg';
import { ThemeContext } from '../../../context/ThemeContext';
import CharacterPack from './CharacterPack/CharacterPack';
import {
  FilterImg,
  ShieldImg,
} from '../../../components/styledComps/styledComps';
import './charactergenerator.scss';
import appStore from '../../../store/AppStore';
import PackPurchaseSummaryModal from '../../../components/modals/PackPurchaseSummaryModal';
import RecommendedCard from '../../../components/recommendedCard/RecommendedCard';
import SampleLibraryImages from '../../../components/modals/sampleLibraryImages.json';

function CharacterStore() {
  const { theme } = useContext(ThemeContext);
  const [characterPackIsExpanded, setCharacterPackIsExpanded] =
    React.useState(false);
  const [contentPackIsExpanded, setContentPackIsExpanded] =
    React.useState(false);
  const [showFilter, setShowFilter] = React.useState(false);

  const openPackPurchaseSummaryModal: boolean = appStore(
    (state) => state.openPackPurchaseSummaryModal,
  );
  const setOpenPackPurchaseSummaryModal = (condition: boolean) =>
    appStore.setState({
      openPackPurchaseSummaryModal: condition,
    });

  return (
    <Box>
      <Box
        sx={{
          backgroundImage: `url(${BackgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          pt: 15,
          width: '100vw',
          height: '370px',
          position: 'relative',
          boxSizing: 'border-box',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            height: '100%',
            width: '100%',
            background: `linear-gradient(180deg, #ffffff00 30%, ${
              theme === 'light' ? '#E9DCC3' : '#313131'
            } 100%)`,
            zIndex: 2,
          }}
        />
        <Typography
          sx={{
            color: '#FFF',
            textAlign: 'center',
            fontFamily: 'Passero One',
            fontSize: '100px',
            fontStyle: 'normal',
            textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            fontWeight: 400,
            lineHeight: 'normal',
            paddingBottom: 5,
            zIndex: 3,
          }}
        >
          RW Character Store
        </Typography>
      </Box>
      <Box
        sx={{
          px: '120px',
          mt: '40px',
        }}
      >
        <Stack direction="row" alignItems="center" gap="13px" mb="30px">
          <ShieldImg src={PopularIcon} />
          <Typography
            sx={{
              fontFamily: 'Passero One',
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'normal',
              whiteSpace: 'nowrap',
            }}
          >
            Popular Items
          </Typography>
        </Stack>
        <Stack
          width="100%"
          height="140px"
          direction="row"
          flexWrap="nowrap"
          gap="50px"
        >
          <RecommendedCard
            title="Sorcerers"
            width="calc(100%/6)"
            featuredImages={SampleLibraryImages.slice(0, 3)}
          />
          <RecommendedCard
            title="Philosophers"
            width="calc(100%/6)"
            featuredImages={SampleLibraryImages.slice(2, 5)}
          />
          <RecommendedCard
            title="High Priests"
            width="calc(100%/6)"
            featuredImages={SampleLibraryImages.slice(6, 9)}
          />
          <RecommendedCard
            title="Sorcerers"
            width="calc(100%/6)"
            featuredImages={SampleLibraryImages.slice(0, 3)}
          />
          <RecommendedCard
            title="Philosophers"
            width="calc(100%/6)"
            featuredImages={SampleLibraryImages.slice(2, 5)}
          />
          <RecommendedCard
            title="High Priests"
            width="calc(100%/6)"
            featuredImages={SampleLibraryImages.slice(6, 9)}
          />
        </Stack>
      </Box>
      {/* Character Store Reactive Search */}
      <Box
        sx={{
          px: '120px',
          mt: '60px',
        }}
      >
        {/* Search and filter toggle */}
        <ReactiveBase
          app="library_content_packs_index"
          url={process.env.REACT_APP_MONGO_ATLAS_SEARCH_URL as string}
          transformRequest={(request: any) => ({
            ...request,
            headers: {
              ...request.headers,
              Authorization: `Bearer ${localStorage.getItem('realmToken')}`,
            },
          })}
          // @ts-ignore
          mongodb={{
            collection: 'library_content_packs',
            db: 'realm_worlds',
          }}
          analytics={false}
          credentials="none"
        >
          <Box
            sx={{
              display: 'flex',
              gap: '14px',
              alignItems: 'center',
              justifyContent: 'space-between',
              mt: '30px',
            }}
          >
            {/* Search Area */}
            <Stack direction="row" gap="30px" alignItems="center">
              <SearchBox
                componentId="mycharacterpacks-searchbox"
                iconPosition="right"
                dataField={['name']}
                includeFields={['*']}
                autosuggest={false}
                AIConfig={{}}
                AIUIConfig={{}}
                type="search"
                compoundClause="must"
                enableIndexSuggestions={false}
                enterButton={false}
                className="my_custom_searchbox cp"
              />
              <Button
                sx={{
                  background: '#41521F',
                  padding: '10px',
                  boxSizing: 'border-box',
                  color: '#ffffffcc',
                  minWidth: '30px',
                  '&:hover': {
                    background: '#41521F',
                  },
                }}
                onClick={() => setShowFilter(!showFilter)}
              >
                <FilterImg src={FilterIcon} />
              </Button>
            </Stack>
          </Box>
          {/* Content Area */}
          <ReactiveList
            componentId="mycharacterpacks-results"
            dataField="_score"
            size={10}
            pagination
            innerClass={{
              pagination: 'list_pagination',
              resultStats: 'list_resultStats',
              resultsInfo: `list_resultsInfo cpstore ${!showFilter && 'hide'}`,
            }}
            className="dashboard_reactive_list cp"
            compoundClause="must"
            react={{
              and: ['discover-searchbox'],
            }}
            onError={(err) => console.log(err)}
            sortOptions={[
              {
                dataField: 'updated_at',
                sortBy: 'desc',
                label: 'Recent',
              },
              {
                dataField: 'updated_at',
                sortBy: 'asc',
                label: 'Oldest',
              },
              {
                dataField: 'name',
                sortBy: 'asc',
                label: 'Name - A-Z',
              },
              {
                dataField: 'name',
                sortBy: 'desc',
                label: 'Name - Z-A',
              },
            ]}
            showResultStats={false}
            render={({ data }: any) => (
              <>
                {console.log('placeholder: ', data)}
                <Box py="40px">
                  {/* Character Packs */}
                  <>
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap="13px"
                      mb="30px"
                    >
                      <ShieldImg src={CharacterPackImage} />
                      <Typography
                        sx={{
                          fontFamily: 'Passero One',
                          fontSize: '20px',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          lineHeight: 'normal',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        Character Packs
                      </Typography>
                      <ArrowDropDown
                        sx={{ cursor: 'pointer' }}
                        onClick={() =>
                          setCharacterPackIsExpanded(!characterPackIsExpanded)
                        }
                        className={`expansionArrow cp ${
                          characterPackIsExpanded ? 'rotated' : ''
                        }`}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="center"
                      columnGap="30px"
                      rowGap="42px"
                      flexWrap="wrap"
                    >
                      {data &&
                        data
                          .slice(0, 6)
                          .map((asset: any) => (
                            <CharacterPack
                              variant="store"
                              width="calc(33% - 20px)"
                              pack={asset}
                              title={asset.name}
                              key={asset._id}
                              mediaAssets={asset.media_assets}
                            />
                          ))}
                      {characterPackIsExpanded &&
                        data &&
                        data
                          .slice(6)
                          .map((asset: any) => (
                            <CharacterPack
                              variant="store"
                              width="calc(33% - 20px)"
                              pack={asset}
                              title={asset.name}
                              key={asset._id}
                              mediaAssets={asset.media_assets}
                            />
                          ))}
                      {/* <CharacterPack variant="store" width="calc(33% - 20px)" />
                      <CharacterPack variant="store" width="calc(33% - 20px)" />
                      <CharacterPack variant="store" width="calc(33% - 20px)" />
                      <CharacterPack variant="store" width="calc(33% - 20px)" />
                      <CharacterPack variant="store" width="calc(33% - 20px)" />
                      <CharacterPack variant="store" width="calc(33% - 20px)" />
                      <CharacterPack variant="store" width="calc(33% - 20px)" />
                      <CharacterPack variant="store" width="calc(33% - 20px)" />
                      <CharacterPack variant="store" width="calc(33% - 20px)" />
                      {characterPackIsExpanded && (
                      <>
                        <CharacterPack variant="store" width="31.667%" />
                        <CharacterPack variant="store" width="31.667%" />
                        <CharacterPack variant="store" width="31.667%" />
                        <CharacterPack variant="store" width="31.667%" />
                        <CharacterPack variant="store" width="31.667%" />
                        <CharacterPack variant="store" width="31.667%" />
                        <CharacterPack variant="store" width="31.667%" />
                        <CharacterPack variant="store" width="31.667%" />
                        <CharacterPack variant="store" width="31.667%" />
                      </>
                      )} */}
                    </Stack>
                  </>
                  {/* Divider */}
                  <Box
                    sx={{
                      width: '100%',
                      height: '60px',
                      boxSizing: 'border-box',
                      '&:before': {
                        content: '""',
                        display: 'block',
                        height: '1px',
                        width: '100%',
                        borderBottom: 'dashed 2px #250503',
                        paddingTop: '48px',
                      },
                    }}
                  />
                </Box>
                {/* ContentPacks */}
                <>
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap="13px"
                    mb="30px"
                  >
                    <ShieldImg src={TeamIcon} />
                    <Typography
                      sx={{
                        fontFamily: 'Passero One',
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: 'normal',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Content Packs
                    </Typography>
                    <ArrowDropDown
                      sx={{ cursor: 'pointer' }}
                      onClick={() =>
                        setContentPackIsExpanded(!contentPackIsExpanded)
                      }
                      className={`expansionArrow cp ${
                        contentPackIsExpanded ? 'rotated' : ''
                      }`}
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    columnGap="30px"
                    rowGap="42px"
                    flexWrap="wrap"
                    pb="20px"
                  >
                    <CharacterPack variant="store" width="calc(33% - 20px)" />
                    <CharacterPack variant="store" width="calc(33% - 20px)" />
                    <CharacterPack variant="store" width="calc(33% - 20px)" />
                    <CharacterPack variant="store" width="calc(33% - 20px)" />
                    <CharacterPack variant="store" width="calc(33% - 20px)" />
                    <CharacterPack variant="store" width="calc(33% - 20px)" />
                    <CharacterPack variant="store" width="calc(33% - 20px)" />
                    <CharacterPack variant="store" width="calc(33% - 20px)" />
                    <CharacterPack variant="store" width="calc(33% - 20px)" />
                    {characterPackIsExpanded && (
                      <>
                        <CharacterPack
                          variant="store"
                          width="calc(33% - 20px)"
                        />
                        <CharacterPack
                          variant="store"
                          width="calc(33% - 20px)"
                        />
                        <CharacterPack
                          variant="store"
                          width="calc(33% - 20px)"
                        />
                        <CharacterPack
                          variant="store"
                          width="calc(33% - 20px)"
                        />
                        <CharacterPack
                          variant="store"
                          width="calc(33% - 20px)"
                        />
                        <CharacterPack
                          variant="store"
                          width="calc(33% - 20px)"
                        />
                        <CharacterPack
                          variant="store"
                          width="calc(33% - 20px)"
                        />
                        <CharacterPack
                          variant="store"
                          width="calc(33% - 20px)"
                        />
                        <CharacterPack
                          variant="store"
                          width="calc(33% - 20px)"
                        />
                      </>
                    )}
                  </Stack>
                </>
              </>
            )}
          />
        </ReactiveBase>
      </Box>
      {/* Modals */}
      <PackPurchaseSummaryModal
        open={openPackPurchaseSummaryModal}
        onClose={() => {}}
        onConfirm={() => {}}
        setOpen={setOpenPackPurchaseSummaryModal}
      />
    </Box>
  );
}

export default CharacterStore;
